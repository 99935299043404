import React from "react";
import { appTheme as theme } from "../Theme";

export const LargeArrowIcon = ({ width, height, color }) => {
  const WIDTH = width || "24.573";
  const HEIGHT = height || "38.608";
  const COLOR = theme.colors[color] || theme.colors.textGrey;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={WIDTH}
      height={HEIGHT}
      viewBox="0 0 24.573 38.608"
    >
      <path
        id="Path_778"
        data-name="Path 778"
        d="M3819.756,1304c-2.482,1.621-19.756,16.511-19.756,16.511l19.756,16.511"
        transform="translate(-3798 -1301.232)"
        fill="none"
        stroke={COLOR}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.5"
      />
    </svg>
  );
};
