import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { NetworkController } from "../../../../utils";
import { PropertyCard } from "../../../../styles";
import {
  DropDownContainer,
  Spacing,
  DropDown,
  FormRow,
  InlineText,
  Text,
  FormColumn,
} from "../../../../commonalities";

const CarpenSettingsDropDown = ({ shrink, job }) => {
  const [settings, setSettings] = useState(null);
  const [config, setConfig] = useState(null);
  const [subToolWidths, setSubToolWidths] = useState(null);
  const { t } = useTranslation();
  const [calibration, setCalibration] = useState({});
  const [calibrationSelector, setCalibrationSelector] = useState("Fixture");
  const [calibrationData, setCalibrationData] = useState({});

  useEffect(() => {
    NetworkController.cells.getSettingsData({ id: job.Payload.Id }, (res) => {
      setSettings(res.data.settings.Items[0].Payload);
    });
  }, []);

  return (
    <Spacing mTopvw={"0.83"}>
      <DropDownContainer width={"100%"} height={"fit-content"} shrink={shrink}>
        {settings ? (
          <Spacing
            mTopvw={"1.25"}
            mRightvw={"1.25"}
            mLeftvw={"2.5"}
            mBottomvw={"2.5"}
          >
            <FormRow height="100%">
              <FormColumn
                height="fit-content"
                alignItems="stretch"
                width={"21.705%"}
              >
                <Spacing mTopvh="1.20" />
                <FormRow height="100%">
                  <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                    {t("settings-fixture")}
                  </InlineText>
                </FormRow>
                <PropertyCard
                  name={"settings-selectedFixture"}
                  value={settings.Fixture}
                />
                <Spacing mTopvh="2.96" />
                <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                  {t("settings-tool")}
                </InlineText>
                {/* The conditional statement should be removed once all the cells are migrated
                to a CNM version > 4.0.0 */}
                <PropertyCard
                  name={"settings-selectedTool"}
                  value={
                    settings.SelectedToolName
                      ? settings.SelectedToolName
                      : settings.SelectedToolId
                      ? settings.SelectedToolId
                      : "-"
                  }
                />
                <PropertyCard
                    name={"settings-toolWidth"}
                    value={
                        settings.SelectedToolWidth
                          ? settings.SelectedToolWidth + " mm"
                          : "-"
                      }
                />
              </FormColumn>

              <FormColumn
                height="fit-content"
                alignItems="stretch"
                width={"21.705%"}
              >
                <Spacing mTopvh="1.20" />
                <FormRow height="100%">
                  <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                    {t("settings-versionInformation")}
                  </InlineText>
                </FormRow>
                <PropertyCard
                  name={"settings-GeneralVersion"}
                  value={settings.Version}
                />
                <Spacing mTopvh="2.96" />
                <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                  {t("settings-speed")}
                </InlineText>
                <PropertyCard
                  name={"settings-speedCut"}
                  value={settings.CutSpeed ? settings.CutSpeed + " mm/s" : "-"}
                />
                <PropertyCard
                  name={"settings-speedDown"}
                  value={
                    settings.DownSpeed ? settings.DownSpeed + " mm/s" : "-"
                  }
                />
                <PropertyCard
                  name={"settings-speedUp"}
                  value={settings.UpSpeed ? settings.UpSpeed + " mm/s" : "-"}
                />
                <PropertyCard
                  name={"settings-speedLead"}
                  value={settings.LeadSpeed + " mm/s"}
                />
                <PropertyCard
                  name={"settings-downSpeedReduction"}
                  value={
                    settings.DownSpeedReduction
                      ? settings.DownSpeedReduction + " %"
                      : "-"
                  }
                />
              </FormColumn>

              <FormColumn
                height="fit-content"
                alignItems="stretch"
                width={"46.51%"}
              >
                <FormRow height="100%">
                  <Spacing mTopvh="1.02">
                    <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                      {t("settings-calibration")}
                    </InlineText>
                  </Spacing>
                  {Object.keys(calibration).length > 0 && (
                    <Spacing
                      mTop={"auto"}
                      mBottom={"auto"}
                      mLeft={"auto"}
                      setFitContentHeight={true}
                      setFitContent={true}
                    >
                      <DropDown
                        scrollToView={false}
                        fontSize={"xs"}
                        font={"light"}
                        width={"8.67"}
                        borderRadius={"xxs"}
                        boxTopMargin={"35px"}
                        optionList={calibration}
                        onChange={(value) => setCalibrationSelector(value)}
                        value={calibrationSelector}
                      />
                    </Spacing>
                  )}
                </FormRow>
                {Object.keys(calibration).length === 0 && (
                  <>
                    <Spacing mTopvh="1.20" />
                    <InlineText color="black" fontSize={"xs"}>
                      {t("fms-dataUnavailable")}
                    </InlineText>
                  </>
                )}
                {calibrationData[calibrationSelector] &&
                  calibrationData[calibrationSelector].map((element, i) => {
                    return (
                      <div key={i}>
                        {calibrationData[calibrationSelector].length > 1 && (
                          <Spacing mTopvh="1.02">
                            <InlineText
                              color="black"
                              fontSize={"xxs"}
                              font={"semibold"}
                            >
                              {element.name}
                            </InlineText>
                          </Spacing>
                        )}
                        <Spacing mTopvh={"1.48"} />
                        <InlineText color="black" fontSize={"xxxs"}>
                          {t("cell-settingsToolPosition").toUpperCase()}
                        </InlineText>
                        <Spacing mTopvh="1.20" />
                        <FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                X
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.position.x * 1e6) /
                                  1e6 +
                                  " mm"}
                              </Text>
                            </FormColumn>
                          </FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                Y
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.position.y * 1e6) /
                                  1e6 +
                                  " mm"}
                              </Text>
                            </FormColumn>
                          </FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                Z
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.position.z * 1e6) /
                                  1e6 +
                                  " mm"}
                              </Text>
                            </FormColumn>
                          </FormRow>
                        </FormRow>
                        <Spacing mTopvh={"1.48"} />
                        <InlineText color="black" fontSize={"xxxs"}>
                          {t("cell-settingsToolOrientation").toUpperCase()}
                        </InlineText>
                        <Spacing mTopvh="1.20" />
                        <FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                X
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.orientation.x * 1e6) /
                                  1e6}
                              </Text>
                            </FormColumn>
                          </FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                Y
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.orientation.y * 1e6) /
                                  1e6}
                              </Text>
                            </FormColumn>
                          </FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                Z
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.orientation.z * 1e6) /
                                  1e6}
                              </Text>
                            </FormColumn>
                          </FormRow>
                        </FormRow>
                        <FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                W
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.orientation.w * 1e6) /
                                  1e6}
                              </Text>
                            </FormColumn>
                          </FormRow>
                        </FormRow>
                      </div>
                    );
                  })}
                {calibrationData[calibrationSelector] &&
                  calibrationData[calibrationSelector][0].value.size && (
                    <>
                      <Spacing mTopvh={"1.48"} />
                      <InlineText color="black" fontSize={"xxxs"}>
                        {t("cell-settingsSlotSize").toUpperCase()}
                      </InlineText>
                      <Spacing mTopvh="1.20" />
                      <FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-lengthShort")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .size[0] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-widthShort")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .size[1] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-heightShort")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .size[2] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                    </>
                  )}
                {calibrationData[calibrationSelector] &&
                  calibrationData[calibrationSelector][0].value
                    .calibSettings && (
                    <>
                      <Spacing mTopvh={"1.48"} />
                      <InlineText color="black" fontSize={"xxxs"}>
                        {t("cell-settings").toUpperCase()}
                      </InlineText>
                      <Spacing mTopvh="1.20" />
                      <FormRow>
                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-settingsTileLength")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["Tile length"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-settingsTileWidth")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["Tile width"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                      <FormRow>
                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              X
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["X"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              Y
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["Y"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                      <FormRow>
                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"59.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-settingsCuttingDepth")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"39.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["Cutting depth"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                    </>
                  )}
                {calibrationData[calibrationSelector] &&
                  calibrationData[calibrationSelector][0].value
                    .calibMeasurements && (
                    <>
                      <Spacing mTopvh={"1.48"} />
                      <InlineText color="black" fontSize={"xxxs"}>
                        {t("cell-measurements").toUpperCase()}
                      </InlineText>
                      <Spacing mTopvh="1.20" />
                      <FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              X1
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["X1"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              X2
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["X2"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              Y1
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["Y1"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                      <FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              Y2
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["Y2"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              L
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["L"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              R
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["R"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                      <FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              T
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["T"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              B
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["B"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                        <FormRow height="100%" width={"29.68%"}></FormRow>
                      </FormRow>
                    </>
                  )}
              </FormColumn>
            </FormRow>
          </Spacing>
        ) : (
          <Spacing
            mTopvw={"1.25"}
            mRightvw={"1.25"}
            mLeftvw={"2.5"}
            mBottomvw={"2.5"}
            height="289px"
          >
            <InlineText color="black">{t("loading-Settings")}</InlineText>
          </Spacing>
        )}
      </DropDownContainer>
    </Spacing>
  );
};

export default CarpenSettingsDropDown;
