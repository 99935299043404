import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import {
  ArrowIcon,
  CancelIcon,
  InfoIcon,
  WarningIcon,
  Spacing,
  FormColumn,
  Text,
} from "../commonalities";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

const colors = {
  1: "textDarkerGrey",
  2: "yellow",
  3: "red",
};

const useStyles = makeStyles({
  accordionSummaryRoot: {
    padding: "0px",

    height: "fit-content",
    minHeight: "fit-content",
    "&.Mui-expanded": {
      minHeight: "fit-content",
    },
  },
  accordionDetailsRoot: {
    padding: "0px",
    paddingBottom:
      "calc((var(--useMin) * var(--heightLimit) * 1.76 / 100) + (var(--usePercent) * 1.76vh))",
  },
});

const LogsAccordion = ({ id, log, handleChange, expanded }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  var st = DateTime.fromMillis(log.tstamp * 1000);
  var date = st.toLocaleString({
    ...DateTime.DATETIME_MED_WITH_SECONDS,
    hour12: false,
  });
  return (
    <Accordion expanded={expanded === id} onChange={handleChange(id)}>
      <AccordionSummary
        className={classes.accordionSummaryRoot}
        expandIcon={
          <ArrowIcon width="15" color="black" type="down" strokeWidth="0.6" />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Spacing setFlex={true} width="100%">
          <Text
            height="fit-content"
            lineHeight="1.2"
            fontSize="xs"
            flex={true}
            color={"textGrey"}
            width={"28%"}
          >
            <Spacing mTop={"1px"}>{date}</Spacing>
          </Text>
          <Text
            height="fit-content"
            lineHeight="1.2"
            flex={true}
            color={colors[log.msgtype]}
            font={"medium"}
            width={"72%"}
          >
            <>
              {log.msgtype === "1" && (
                <Spacing
                  width="15px"
                  height="15px"
                  margin="auto 0"
                  mRightvw="0.83"
                >
                  <InfoIcon width="20px" height="22px" />
                </Spacing>
              )}
              {log.msgtype === "2" && (
                <Spacing
                  width="15px"
                  height="15px"
                  margin="auto 0"
                  mRightvw="0.83"
                >
                  <WarningIcon width="20px" height="22px" />
                </Spacing>
              )}
              {log.msgtype === "3" && (
                <Spacing
                  width="15px"
                  height="15px"
                  margin="auto 0"
                  mRightvw="0.83"
                >
                  <CancelIcon
                    width="100%"
                    fillColor="red"
                    strokeWidth="2"
                    radius="12"
                  />
                </Spacing>
              )}
              {log.title}
            </>
          </Text>
        </Spacing>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsRoot}>
        <FormColumn alignItems="stretch">
          {log.desc && (
            <Spacing setFlex={true} width="100%">
              <Text
                height="fit-content"
                lineHeight="1.2"
                fontSize="xs"
                flex={true}
                width={"27%"}
              >
                {t("cell-logsDescription")}
              </Text>
              <Text
                height="fit-content"
                fontSize="xs"
                flex={true}
                lineHeight={"22px"}
                width={"73%"}
                color={"textGrey"}
              >
                {log.desc}
              </Text>
            </Spacing>
          )}
          {log.conseqs && (
            <>
              <Spacing mTopvh={"2.5"} />
              <Spacing setFlex={true} width="100%">
                <Text
                  height="fit-content"
                  lineHeight="1.2"
                  fontSize="xs"
                  flex={true}
                  width={"27%"}
                >
                  {t("cell-logsConsequences")}
                </Text>
                <Text
                  height="fit-content"
                  fontSize="xs"
                  flex={true}
                  lineHeight={"22px"}
                  width={"73%"}
                  color={"textGrey"}
                >
                  {log.conseqs}
                </Text>
              </Spacing>
            </>
          )}
          {log.couses && (
            <>
              <Spacing mTopvh={"2.5"} />
              <Spacing setFlex={true} width="100%">
                <Text
                  height="fit-content"
                  lineHeight="1.2"
                  fontSize="xs"
                  flex={true}
                  width={"27%"}
                >
                  {t("cell-logsCauses")}
                </Text>
                <Text
                  height="fit-content"
                  fontSize="xs"
                  flex={true}
                  lineHeight={"22px"}
                  width={"73%"}
                  color={"textGrey"}
                >
                  {log.causes}
                </Text>
              </Spacing>
            </>
          )}
        </FormColumn>
      </AccordionDetails>
    </Accordion>
  );
};

export default LogsAccordion;
