import React, { useState, useEffect } from "react";
import {
  CountryCodes,
  CancelIcon,
  DropDown,
  Input,
  Spacing,
  AlertIcon,
  Tooltip,
} from "..";
import styled from "styled-components";
import InputAdornment from "@material-ui/core/InputAdornment";
import { appTheme } from "../Theme";
const Root = styled.div`
  position: relative;
  width: ${(p) => p.width};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

export default function PhoneNumberInput({
  setPhoneCode,
  error,
  errorMsg,
  onChange,
  width,
  value,
  inline,
  fontSize,
  onClose,
  className,
  isLast,
  variant,
  required,
  name,
  helperText,
  label,
  type,
  dialCode,
  maxHeight,
  containerWidth,
}) {
  if (inline === undefined) {
    inline = false;
  }
  const [countryCode, setCountryCode] = useState("DK");
  const handleChange = (value) => {
    setCountryCode(value);
    onChange({ target: { value: "" } });
    setPhoneCode({
      code: value,
      dialCode: CountryCodes[value].dial_code,
    });
  };
  useEffect(() => {
    if (dialCode) {
      setCountryCode(
        Object.keys(CountryCodes).find(
          (key) => CountryCodes[key]["dial_code"] === dialCode
        )
      );
    }
  }, []);
  return (
    <Root width={width}>
      <Row>
        <Input
          className={className}
          variant={variant}
          error={error}
          value={CountryCodes[countryCode].dial_code + " " + value}
          onChange={onChange}
          width={"100%"}
          required={required ? required : false}
          inline={inline ? "true" : null}
          name={name}
          style={
            variant === "outlined"
              ? {}
              : { marginBottom: !error ? "15.2px" : "0px", marginLeft: "0px" }
          }
          label={label}
          type={type}
          helperText={helperText}
          InputProps={{
            style: {
              fontSize: fontSize
                ? appTheme.fontSize[fontSize]
                : appTheme.fontSize.sm,
              color: variant === "outlined" && appTheme.colors.textDarkerGrey,
            },
            endAdornment:
              variant === "outlined" &&
              (onClose ? (
                <InputAdornment onClick={onClose} position="end">
                  {errorMsg ? (
                    <>
                      <Spacing data-tip data-for="phone">
                        <AlertIcon />
                      </Spacing>
                      <Tooltip id={"phone"} value={errorMsg} />
                    </>
                  ) : (
                    <Spacing setPointer={true} height="18px">
                      <CancelIcon
                        radius="12"
                        strokeWidth={"1.5"}
                        width={"18px"}
                      />
                    </Spacing>
                  )}
                </InputAdornment>
              ) : (
                <InputAdornment onClick={onClose} position="end">
                  {errorMsg ? (
                    <>
                      <Spacing data-tip data-for="phone">
                        <AlertIcon />
                      </Spacing>
                      <Tooltip id={"phone"} value={errorMsg} />
                    </>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              )),
            startAdornment: (
              <InputAdornment position="start">
                <DropDown
                  bgColor={"none"}
                  width={"100%"}
                  textColor={"textDarkGrey"}
                  fontSize={"xs"}
                  font={"regular"}
                  value={countryCode}
                  defaultValue={countryCode}
                  optionList={CountryCodes}
                  isCountryCode={true}
                  containerWidth={containerWidth}
                  borderRadius={"xxs"}
                  subitem={"name"}
                  solidArrow={true}
                  maxHeight={maxHeight}
                  padding={"0px"}
                  height={"18px"}
                  mTop={"4"}
                  widthvw={"12"}
                  maintainColor={true}
                  onChange={(value) => handleChange(value)}
                />
              </InputAdornment>
            ),
          }}
        />
      </Row>
      {variant !== "outlined" &&
        (isLast === undefined ? (
          <Spacing mTopvh={!error ? "1.48" : "0.74"} />
        ) : (
          <Spacing pBottomvh={error ? "0.74" : "1.48"} />
        ))}
    </Root>
  );
}
