import React from "react";
import { useTranslation } from "react-i18next";
import { UsageCard } from "../../../styles";

const EvaloUsageCards = ({ cellDetails }) => {
  const { t } = useTranslation();

  return (
    <>
      <UsageCard
        heading={t("fms-hourlyThroughput")}
        number={
          cellDetails.Usage.TotalArea
            ? parseFloat(
                cellDetails.Usage.TotalArea / (cellDetails.daysPassed * 8)
              ).toFixed(2)
            : 0
        }
        unit={"m²/hr"}
      />
      <UsageCard
        heading={t("fms-areaCleaned")}
        subText={t("fms-dailyAverage")}
        averageUnit={"m²"}
        dailyUsageNumber={
          cellDetails.Usage.DailyUsage && cellDetails.Usage.DailyUsage.TotalArea
            ? parseFloat(cellDetails.Usage.DailyUsage.TotalArea).toFixed(2)
            : 0
        }
        unit={"m²"}
        subTextNumber={
          cellDetails.Usage.TotalArea
            ? (cellDetails.Usage.TotalArea / cellDetails.daysPassed).toFixed(2)
            : "0"
        }
        number={
          cellDetails.Usage.TotalArea
            ? parseFloat(cellDetails.Usage.TotalArea).toFixed(2)
            : 0
        }
      />
      <UsageCard
        heading={t("fms-panelsCleaned")}
        subText={t("fms-dailyAverage")}
        dailyUsageNumber={
          cellDetails.Usage.DailyUsage &&
          cellDetails.Usage.DailyUsage.PanelCount
            ? cellDetails.Usage.DailyUsage.PanelCount
            : 0
        }
        subTextNumber={
          cellDetails.Usage.PanelCount
            ? Math.round(
                (cellDetails.Usage.PanelCount / cellDetails.daysPassed).toFixed(
                  2
                )
              )
            : "0"
        }
        number={cellDetails.Usage.PanelCount ? cellDetails.Usage.PanelCount : 0}
      />
      <UsageCard
        heading={t("fms-workHrsSaved")}
        subText={t("fms-personHrs")}
        dailyUsageNumber={
          cellDetails.Usage.DailyUsage &&
          cellDetails.Usage.DailyUsage.WorkHrsSaved
            ? cellDetails.Usage.DailyUsage.WorkHrsSaved
            : 0
        }
        subTextNumber={
          cellDetails.Usage.WorkHrsSaved
            ? Math.round(
                (
                  cellDetails.Usage.WorkHrsSaved / cellDetails.daysPassed
                ).toFixed(2)
              )
            : "0"
        }
        unit={"hrs"}
        number={
          cellDetails.Usage.WorkHrsSaved ? cellDetails.Usage.WorkHrsSaved : 0
        }
      />
    </>
  );
};

export default EvaloUsageCards;
