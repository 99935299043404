import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const ProfileIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "20";
  const COLOR = theme.colors[color] || theme.colors.black;
  const STROKE_WIDTH = strokeWidth || "1";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      fill={COLOR}
      stroke={COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_1710"
        data-name="Group 1710"
        transform="translate(-18.71 -18.71)"
      >
        <path
          id="Path_765"
          data-name="Path 765"
          d="M42.182,30.7A11.486,11.486,0,1,1,30.7,19.21,11.461,11.461,0,0,1,42.182,30.7Z"
          transform="translate(0 0)"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth={STROKE_WIDTH}
        />
        <circle
          id="Ellipse_33"
          data-name="Ellipse 33"
          cx="3.796"
          cy="3.796"
          r="3.796"
          transform="translate(26.9 23.856)"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth={STROKE_WIDTH}
        />
        <path
          id="Path_766"
          data-name="Path 766"
          d="M74.649,146.714a7.466,7.466,0,0,1-.042.788,11.486,11.486,0,0,1-14.585,0,7.465,7.465,0,0,1-.042-.788,7.334,7.334,0,1,1,14.669,0Z"
          transform="translate(-36.618 -107.932)"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth={STROKE_WIDTH}
        />
      </g>
    </svg>
  );
};
