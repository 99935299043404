import styled from "styled-components";

export const HalfPageLevelImg = styled.img`
  width: ${(p) => (p.width ? p.width : "55%")};
  min-width: 200px;
  margin: ${(p) => (p.margin ? p.margin : "auto")};
  max-width: 60%;
  object-fit: contain;
`;

export const Img = styled.img`
  width: ${(p) => (p.width ? p.width : "18px")};
  height: ${(p) => (p.height ? p.height : "18px")};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) => p.opacity && `opacity:${p.opacity}`};
  ${(p) => p.cursor && `cursor:${p.cursor}`};
`;
