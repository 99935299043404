import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const DeleteIcon = ({ width, color, margin }) => {
  const WIDTH = width || "20";
  const COLOR = theme.colors[color] || "#000";

  return (
    <svg
      style={{ cursor: "pointer", margin: margin }}
      width={WIDTH}
      height={WIDTH}
      fill={COLOR}
      stroke={COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.29 22.29"
    >
      <g
        id="Component_160_6"
        data-name="Component 160 – 6"
        transform="translate(0.5 0.5)"
      >
        <line
          id="Line_1"
          data-name="Line 1"
          x2="18.28"
          transform="translate(0 3.663)"
          fill="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_765"
          data-name="Path 765"
          d="M52.66,53.5l.912,16.011a1.713,1.713,0,0,0,1.711,1.616H64.26A1.713,1.713,0,0,0,65.972,69.5l.807-16"
          transform="translate(-50.581 -49.837)"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_766"
          data-name="Path 766"
          d="M97.481,6.663l-.016-1.87A1.778,1.778,0,0,1,99.243,3h4.065a1.778,1.778,0,0,1,1.778,1.778V6.663"
          transform="translate(-92.136 -3)"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          x2="0.381"
          y2="11.806"
          transform="translate(5.712 6.551)"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_3"
          data-name="Line 3"
          x1="0.381"
          y2="11.806"
          transform="translate(12.187 6.551)"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_4"
          data-name="Line 4"
          y2="11.969"
          transform="translate(9.139 6.528)"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
