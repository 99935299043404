import React from "react";
import { useTranslation } from "react-i18next";
import { PropertyCard } from "../../../styles";

const CarpenHardwareStatus = ({ hardwareStatus }) => {
  const { t } = useTranslation();
  const data = {
    "status-door": hardwareStatus.DoorStatus
      ? hardwareStatus.DoorStatus.toLowerCase()
      : "fms-unavailable",
    "status-frequencyDriveConnected": hardwareStatus.FrequencyDriveConnected
      ? hardwareStatus.FrequencyDriveConnected.toLowerCase()
      : "fms-unavailable",
    "status-frequencyDriveRunning": hardwareStatus.FrequencyDriveRunning
      ? hardwareStatus.FrequencyDriveRunning.toLowerCase()
      : "fms-unavailable",
    "status-frequencyDriveFaulted": hardwareStatus.FrequencyDriveFaulted
      ? hardwareStatus.FrequencyDriveFaulted.toLowerCase()
      : "fms-unavailable",
    "status-robotMode": hardwareStatus.RobotMode
      ? hardwareStatus.RobotMode.toLowerCase()
      : "fms-unavailable",
    "status-EStopped": hardwareStatus.EStopped
      ? hardwareStatus.EStopped.toLowerCase()
      : "fms-unavailable",
    "status-drivesPowered": hardwareStatus.DrivesPowered
      ? hardwareStatus.DrivesPowered.toLowerCase()
      : "fms-unavailable",
  };

  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        return (
          <PropertyCard
            key={key}
            mTop={"1.27"}
            fontSize={"md"}
            name={key}
            value={t(value)}
          />
        );
      })}
    </>
  );
};

export default CarpenHardwareStatus;
