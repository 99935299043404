import React from "react";
import { SettingsAccordion } from "../../../../../styles";
import { UnitsIcon } from "../../../../../icons";

const PanelSettings = ({ settings, show, heading, setExpand }) => {
  const data = {
    "fms-type": settings ? settings.PanelType : "-",
    "cell-height": settings ? settings.Height + " mm" : "-",
    "cell-length": settings ? settings.Length + " mm" : "-",
    "cell-width": settings ? settings.Width + " mm" : "-",
    "cell-weight": settings ? settings.Weight + " mm" : "-",
  };

  return (
    <SettingsAccordion
      expand={"panel"}
      iconImage={<UnitsIcon width="100%" height="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};
export default PanelSettings;
