import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NetworkController } from "../../../utils";
import ProcessRow from "./ProcessRow";
import { PlayBagFilePopUp } from "../..";
import {
  TableContainer,
  Cell,
  Row,
  ToastCard,
  TablePagination,
  HeaderCell,
} from "../../../commonalities";

const MiddleContent = ({
  cellId,
  cellType,
  setLoading,
  setEventListener,
  fromDate,
  toDate,
  toDateRealTime,
  setRefresh,
}) => {
  const [showExecutionPopUp, setShowExecutionPopUp] = useState({
    show: false,
    job: null,
  });
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState({ processId: "", show: "" });
  const autoUpdateJobs = useRef(true);
  const updatedJobs = useRef([]);
  const jobRef = useRef([]);
  const sortOrder = useRef(-1);
  const sortBy = useRef("StartTime");
  const [listJobs, setListJobs] = useState([]);
  const [sort, setSort] = useState("StartTime");
  const [sortingOrder, setSortingOrder] = useState(-1);
  const [pageDetails, setPageDetails] = useState({ current: 0, total: 0 });
  const [pendingJobsCount, setPendingJobsCount] = useState(0);

  const getProcessType = (job) => {
    if (job.Payload.ProcessType === "Command") {
      var metadata =
        typeof job.Payload.Metadata === "string"
          ? JSON.parse(job.Payload.Metadata)
          : job.Payload.Metadata;
      return metadata.CommandType ? metadata.CommandType : "-";
    }
    return job.Payload.ProcessType;
  };

  const setSorting = (sorter, ignoreSortBy) => {
    if (!ignoreSortBy && sorter === sortBy.current) {
      sortOrder.current = -sortOrder.current;
    } else {
      sortOrder.current = -1;
    }

    setSort(sorter);
    setSortingOrder(sortOrder.current);
    sortBy.current = sorter;
    var sortedJobs = jobRef.current.sort((a, b) => {
      if (sortBy.current === "Type" && !a.Payload["Type"]) {
        a.Payload["Type"] = getProcessType(a);
        b.Payload["Type"] = getProcessType(b);
      }
      if (a.Payload[sortBy.current] < b.Payload[sortBy.current]) {
        return -sortOrder.current;
      } else {
        return sortOrder.current;
      }
    });
    setListJobs(sortedJobs);
  };

  const getJobs = () => {
    setLoading(true);
    var queryData = {
      CellID: cellId,
      after: fromDate / 1000,
      before: toDate / 1000,
      lastKey: null,
    };
    NetworkController.cells.getJobs(queryData, (res) => {
      var alljobs = res.data.Items;
      setLoading(false);
      updatedJobs.current = [...alljobs];
      alljobs
        .sort((a, b) => {
          if (sortBy.current === "Type" && !a.Payload["Type"]) {
            a.Payload["Type"] = getProcessType(a);
            b.Payload["Type"] = getProcessType(b);
          }
          if (a.Payload[sortBy.current] < b.Payload[sortBy.current]) {
            return -sortOrder.current;
          } else {
            return sortOrder.current;
          }
        })
        .forEach((job, i) => {
          job.index = alljobs.length - i;
        });
      jobRef.current = alljobs;

      setListJobs(
        alljobs.sort((a, b) => {
          if (sortBy.current === "Type" && !a.Payload["Type"]) {
            a.Payload["Type"] = getProcessType(a);
            b.Payload["Type"] = getProcessType(b);
          }
          if (a.Payload[sortBy.current] < b.Payload[sortBy.current]) {
            return -sortOrder.current;
          } else {
            return sortOrder.current;
          }
        })
      );
      setPageDetails({
        current: alljobs.length !== 0 ? 1 : 0,
        total: Math.ceil(res.data.Items.length / 10),
      });
    });
  };

  const RefreshJobsList = () => {
    if (toDateRealTime.current) {
      setRefresh(true);
      jobRef.current = [...updatedJobs.current];
      if (jobRef.current.length > 0 && pageDetails.current === 0) {
        setPageDetails({
          current: 1,
          total: Math.ceil(updatedJobs.current.length / 10),
        });
      }
      setListJobs(
        jobRef.current.sort((a, b) => {
          if (sortBy.current === "Type" && !a.Payload["Type"]) {
            a.Payload["Type"] = getProcessType(a);
            b.Payload["Type"] = getProcessType(b);
          }
          if (a.Payload[sortBy.current] < b.Payload[sortBy.current]) {
            return -sortOrder.current;
          } else {
            return sortOrder.current;
          }
        })
      );
    }
  };

  const UpdateJobs = (data) => {
    var jobData = {
      Payload: data,
      StartTime: data.StartTime,
      CellId: data.CellID,
      index: updatedJobs.current.length + 1,
    };
    updatedJobs.current.push(jobData);
    if (autoUpdateJobs.current) {
      RefreshJobsList();
    }
    setPendingJobsCount(updatedJobs.current.length - jobRef.current.length);
  };

  useEffect(() => {
    getJobs();
    setEventListener(UpdateJobs);
  }, []);

  useEffect(() => {
    if (toDateRealTime.current === false) {
      getJobs();
      setSorting("StartTime", true);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    setPendingJobsCount(updatedJobs.current.length - jobRef.current.length);
    if (
      updatedJobs.current.length - jobRef.current.length === 0 &&
      showDetail.processId === ""
    ) {
      autoUpdateJobs.current = true;
    }
  }, [listJobs]);

  useEffect(() => {
    if (showDetail.processId !== "" || sort !== "StartTime") {
      autoUpdateJobs.current = false;
    } else if (
      updatedJobs.current.length - jobRef.current.length === 0 &&
      showDetail.processId === "" &&
      sort === "StartTime"
    ) {
      autoUpdateJobs.current = true;
    }
  }, [showDetail, sort]);

  return (
    <>
      {pendingJobsCount !== 0 && (
        <ToastCard
          onRefresh={() => {
            if (toDateRealTime.current) {
              RefreshJobsList();
            } else {
              setRefresh(true);
            }
          }}
          number={pendingJobsCount}
          heading={t("fms-newIncomingProcesses")}
        />
      )}
      <TableContainer minWidth={"500px"} width={"100%"}>
        <Row width={"100%"}>
          <Cell
            textAlign="center"
            hPadding="0"
            vPadding="1.48"
            width={"2.325%"}
          ></Cell>
          <Cell
            textAlign="center"
            hPadding="0"
            vPadding="1.48"
            nowrap={true}
            fontSize={"xs"}
            width={"4.651%"}
          >
            {t("JobNo")}
          </Cell>
          <Cell
            textAlign="center"
            hPadding="0"
            vPadding="1.48"
            width={"1.551%"}
          ></Cell>
          <HeaderCell
            textAlign="center"
            onClick={() => setSorting("StartTime")}
            width={"30%"}
            heading={"jobStartTime"}
            isAscending={sortingOrder}
            isSelected={sort === "StartTime"}
          />
          <HeaderCell
            textAlign="center"
            onClick={() => setSorting("Type")}
            width={"13.953%"}
            heading={"jobProcessType"}
            isAscending={sortingOrder}
            isSelected={sort === "Type"}
          />
          <HeaderCell
            textAlign="center"
            onClick={() => setSorting("Status")}
            width={"13.953%"}
            heading={"jobStatus"}
            isAscending={sortingOrder}
            isSelected={sort === "Status"}
          />
          <HeaderCell
            textAlign="center"
            onClick={() => setSorting("ExecutionTime")}
            width={"17%"}
            heading={"jobExecutionTime"}
            isAscending={sortingOrder}
            isSelected={sort === "ExecutionTime"}
          />
          <Cell
            textAlign="center"
            hPadding="0"
            vPadding="1.48"
            width={"2%"}
          ></Cell>
          <Cell
            hPadding="0"
            vPadding="1.48"
            nowrap={true}
            fontSize={"xs"}
            width={"17.054%"}
          >
            {t("jobActions")}
          </Cell>
          <Cell
            textAlign="center"
            hPadding="0"
            vPadding="1.48"
            width={"3%"}
          ></Cell>
        </Row>
        {listJobs
          .slice((pageDetails.current - 1) * 10, pageDetails.current * 10)
          .map((item, index) => {
            return (
              <ProcessRow
                cellType={cellType}
                showExecutionPopUp={showExecutionPopUp}
                setShowExecutionPopUp={setShowExecutionPopUp}
                setShowDetail={setShowDetail}
                showDetail={showDetail}
                key={item.StartTime}
                job={item}
              />
            );
          })}
      </TableContainer>
      <TablePagination
        displayCount={
          jobRef.current.slice(
            (pageDetails.current - 1) * 10,
            pageDetails.current * 10
          ).length
        }
        totalItems={jobRef.current.length}
        currentPage={pageDetails.current}
        totalPages={pageDetails.total}
        setCurrentPage={(value) => {
          setPageDetails({ ...pageDetails, current: value });
        }}
      />
      {showExecutionPopUp.show && (
        <PlayBagFilePopUp
          setShowPopUp={setShowExecutionPopUp}
          job={showExecutionPopUp.job}
        />
      )}
    </>
  );
};

export default MiddleContent;
