import React from "react";
import { appTheme as theme } from "../Theme";
/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const DoubleArrowIcon = ({
  width,
  height,
  color,
  strokeWidth,
  pointed,
  shorterGap, //This parameter is used to shorten the vertical gap between the arrows
  transform,
}) => {
  const WIDTH = width || "16";
  const HEIGHT = height || "16";
  const COLOR = theme.colors[color] || theme.colors.white;
  const STROKE_WIDTH = strokeWidth || "2";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={WIDTH}
      height={HEIGHT}
      viewBox="0 0 16 16"
      transform={transform}
    >
      <g id="Group_3146" data-name="Group 3146">
        <path
          id="Path_2103"
          data-name="Path 2103"
          d={
            pointed
              ? "M2341-4402.065l6.5-6.938,6.5,6.938"
              : "M2341-4409l8,5.994,8-5.994"
          }
          transform="translate(-2341 4409)"
          fill="none"
          stroke={COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={STROKE_WIDTH}
        />
        <path
          id="Path_2318"
          data-name="Path 2318"
          d={
            pointed
              ? "M2341-4402.065l6.5-6.938,6.5,6.938"
              : "M2341-4409l8,5.994,8-5.994"
          }
          transform={
            shorterGap ? "translate(-2341 4416)" : "translate(-2341 4418.007)"
          }
          fill="none"
          stroke={COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={STROKE_WIDTH}
        />
      </g>
    </svg>
  );
};
