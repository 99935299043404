import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const PlayIcon = ({ fill, width, color, style }) => {
  const WIDTH = width || "24";
  const COLOR = color || theme.colors.black;

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      fill={COLOR}
      stroke={COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={style}
    >
      <g
        id="Play_Icon"
        data-name="Play Icon"
        transform="translate(24) rotate(90)"
        fill={fill ? theme.colors[color] : "#fff"}
      >
        <path
          d="M 20.76393890380859 23.50006294250488 L 3.236068964004517 23.50006294250488 C 2.712399005889893 23.50006294250488 2.235399007797241 23.23411178588867 1.960089087486267 22.78866195678711 C 1.684779047966003 22.34320259094238 1.660229086875916 21.79763221740723 1.894419074058533 21.32925224304199 L 10.65834903717041 3.801332712173462 C 10.91795921325684 3.282122611999512 11.419508934021 2.972152709960938 11.99999904632568 2.972152709960938 C 12.58048915863037 2.972152709960938 13.08203887939453 3.282122611999512 13.34164905548096 3.801332712173462 L 22.1055793762207 21.32925224304199 C 22.33976936340332 21.79763221740723 22.31521987915039 22.34320259094238 22.03990936279297 22.78866195678711 C 21.76459884643555 23.23412322998047 21.2876091003418 23.50006294250488 20.76393890380859 23.50006294250488 Z"
          stroke="none"
        />
        <path
          d="M 11.99999904632568 3.472143173217773 C 11.61299896240234 3.472143173217773 11.27863883972168 3.678792953491211 11.10556888580322 4.024932861328125 L 2.341638565063477 21.55285263061523 C 2.116598129272461 22.00294303894043 2.297279357910156 22.38320350646973 2.385408401489258 22.52579307556152 C 2.473539352416992 22.66839218139648 2.73284912109375 23.00006294250488 3.236059188842773 23.00006294250488 L 20.76393890380859 23.00006294250488 C 21.26714897155762 23.00006294250488 21.52645874023438 22.66839218139648 21.61458969116211 22.52579307556152 C 21.70271873474121 22.38320350646973 21.88339996337891 22.00294303894043 21.65835952758789 21.55285263061523 L 12.89443874359131 4.024942398071289 C 12.72135925292969 3.678792953491211 12.38698863983154 3.472143173217773 11.99999904632568 3.472143173217773 M 11.99999904632568 2.472146987915039 C 12.71016693115234 2.472146987915039 13.42033386230469 2.840673446655273 13.78885936737061 3.577722549438477 L 22.55278968811035 21.10564231872559 C 23.21768951416016 22.4354419708252 22.25069808959961 24.00006294250488 20.76393890380859 24.00006294250488 L 3.236059188842773 24.00006294250488 C 1.749298095703125 24.00006294250488 0.7823085784912109 22.4354419708252 1.447208404541016 21.10564231872559 L 10.21113872528076 3.577722549438477 C 10.57966423034668 2.840673446655273 11.28983116149902 2.472146987915039 11.99999904632568 2.472146987915039 Z"
          stroke="none"
          fill={color ? theme.colors[color] : "#000"}
        />
      </g>
    </svg>
  );
};
