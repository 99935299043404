import React from "react";
import {
  Spacing,
  Text,
  ShadowDiv,
  FormColumn,
  Row,
} from "../../../commonalities";
import { useTranslation } from "react-i18next";
import { CNMSettings } from "./settings/CNM";
import { EvaloSettings } from "./settings/Evalo";
import { CarpenSettings } from "./settings/Carpen";
import InformationCard from "./Information/InformationCard";

const LeftColumn = ({
  versionInfo,
  cellDetails,
  location,
  setCellDetails,
  settings,
  config,
}) => {
  const { t } = useTranslation();
  const cellInformation = {
    "unitName": cellDetails.Name,
    "type": cellDetails.Type,
    "serialNumber": cellDetails.SerialNumber,
    "owner": cellDetails.Owner,
    "location": location,
  };

  function Settings(props) {
    const cellType = props.cellType;
    if(cellType === "CNM"){
      return <CNMSettings versionInfo={versionInfo} settings={settings} config={config}/>;
    }
    if(cellType === "Evalo"){
      return <EvaloSettings settings={settings} />;
    }
    return <CarpenSettings versionInfo={versionInfo} settings={settings} config={config}/>;
  }

  return (
    <>
      <Spacing widthvw="14.17" height="fit-content">
        <Row
          width="100%"
          height="fit-content"
          bgColor="backgroundGrey"
          shadow="0"
        >
          <FormColumn alignItems="stretch" padding="0px">
            <InformationCard
              isEditable={true}
              data={cellInformation}
              setCellDetails={setCellDetails}
              cellDetails={cellDetails}
            />
            <Spacing mTopvw={"1.67"}>
              <ShadowDiv width="100%">
                <Spacing
                  setFlex={true}
                  width="100%"
                  alignItems="center"
                  heightvh="8.14"
                  mLeftvw="1.67"
                  mRightvw="1.67"
                >
                  <Text height="fit-content" font={"medium"} lineHeight="1.2">
                    {t("settings")}
                  </Text>
                </Spacing>
              </ShadowDiv>
            </Spacing>
            <Settings cellType />
          </FormColumn>
        </Row>
      </Spacing>
    </>
  );
};

export default LeftColumn;
