import React, { useState, useEffect } from "react";
import { SettingsAccordion } from "../../../../../styles";
import { DepthIcon } from "../../../../../icons";

const DepthSettings = ({ settings, config, show, heading, setExpand }) => {
  const [data, setData] = useState({
    "cell-settingsDepthCut": settings ? settings.CutDepth + " mm" : "-",
    "cell-settingsDepthSteps": settings ? settings.CutSteps : "-",
  });

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      "cell-settingsDepthCut": settings ? settings.CutDepth + " mm" : "-",
      "cell-settingsDepthSteps": settings ? settings.CutSteps : "-",
    }));
  }, [settings]);

  useEffect(() => {
    if (
      config &&
      config.tools.tools.length > 0 &&
      config.tools.tools[0].sub_tools
    ) {
      config.tools.tools[0].sub_tools.map((element) => {
        return element.properties.map((prop) => {
          if (prop.key === "wear") {
            setData((prev) => ({
              ...prev,
              ["cell-settingsDepthWear"]: prop.value + " mm",
            }));
          }
        });
      });
    }
  }, [config]);

  return (
    <SettingsAccordion
      expand={"depth"}
      iconImage={<DepthIcon width="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default DepthSettings;
