import React from "react";
import { useTranslation } from "react-i18next";
import { PropertyCard } from "../../../styles";

const EvaloHardwareStatus = ({ hardwareStatus }) => {
  const { t } = useTranslation();
  const data = {
    "cell-status500barPump": hardwareStatus["500BarPump"]
      ? hardwareStatus["500BarPump"]
      : "fms-unavailable",
    "cell-status1000barPump": hardwareStatus["1000BarPump"]
      ? hardwareStatus["1000BarPump"]
      : "fms-unavailable",
  };

  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        return (
          <PropertyCard
            key={key}
            mTop={"1.27"}
            fontSize={"md"}
            name={key}
            value={t(value)}
          />
        );
      })}
    </>
  );
};

export default EvaloHardwareStatus;
