import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import {
  SearchIcon,
  CancelIcon,
  Spacing,
  AlertIcon,
  CalendarIcon,
  Tooltip,
} from "..";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { DateTimePicker } from "@material-ui/pickers";
import { appTheme } from "../Theme";
import i18n from "../i18n";

export const Input = styled(TextField)`
  width: ${(p) => (p.width ? p.width : "auto")};
  ${(p) => p.inline && `display:inline-block`};
`;

export const TextInput = styled.input`
  border: none;
  outline: none;
  text-align: right;
  max-width: 75px;
  ${(p) => p.height && `height: ${p.height}`};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.md};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.light)};
  ${(p) => p.padding && `padding: ${p.padding}`};
`;

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: appTheme.borderRadius.xxs,
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: "14px",
    },
  },
  rootPhone: {
    "& .MuiOutlinedInput-root": {
      borderRadius: appTheme.borderRadius.xxs,
    },
  },
});

const ErrorInput = styled(Input)`
  margin-right: auto;
  ${(p) => p.width && `width: ${p.width}`};
`;

export const InputWithBorderError = ({
  width,
  name,
  value,
  onChange,
  onKeyDown,
  onBlur,
  errorMsg,
}) => {
  const classes = useStyles();

  return (
    <ErrorInput
      className={classes.root}
      variant="outlined"
      error={errorMsg !== ""}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      width={width}
      InputProps={{
        endAdornment: (
          <InputAdornment style={{ cursor: "pointer" }} position="end">
            {errorMsg && (
              <>
                <Spacing data-tip data-for={name}>
                  <AlertIcon />
                </Spacing>
                <Tooltip id={name} value={errorMsg} />
              </>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const BorderInput = styled.input`
  width: ${(p) => (p.width ? p.width : "100%")};
  height: 15px;
  padding: 9px;
  font-size: ${(p) => p.theme.fontSize.md};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.light)};
  border-radius: ${(p) => p.theme.borderRadius.xxxs};
  border: solid ${(p) => p.theme.colors.grey} 1px;
  border-weight: 1.3px;
  text-align: left;
  outline: none;
  color: ${(p) => p.theme.colors.textDarkerGrey};
`;

export const InputWithBorder = ({ width, name, value, onChange }) => {
  return (
    <Spacing width={width ? width : "46%"}>
      <Spacing color="textDarkerGrey" fontSize="xs" mBottom="5px">
        {name}
      </Spacing>
      <BorderInput onChange={onChange} value={value}></BorderInput>
    </Spacing>
  );
};

export const InputWithError = ({
  isLast,
  required,
  name,
  value,
  error,
  label,
  type,
  onChange,
  helperText,
  width,
  inline,
}) => {
  if (inline === undefined) {
    inline = false;
  }
  return (
    <>
      <Input
        required={required ? required : false}
        inline={inline ? inline : null}
        style={{ marginBottom: !error ? "15.2px" : "0px", marginLeft: "0px" }}
        onChange={onChange}
        name={name}
        value={value}
        helperText={helperText}
        error={error}
        width={width ? width : "100%"}
        label={label}
        type={type}
        InputLabelProps={{ style: { fontSize: appTheme.fontSize.md } }}
        inputProps={{ style: { fontSize: appTheme.fontSize.md } }}
      />
      {isLast === undefined ? (
        <Spacing mTopvh={!error ? "1.48" : "0.74"} />
      ) : (
        <Spacing pBottomvh={error ? "0.74" : "1.48"} />
      )}
    </>
  );
};

export const SearchInput = ({
  searchInput,
  setSearchInput,
  variant,
  width,
  height,
}) => {
  const { t } = useTranslation();
  return (
    <Input
      value={searchInput}
      placeholder={t("buttons-search")}
      width={width ? width : "700px"}
      variant={variant ? variant : "outlined"}
      onChange={(e) => setSearchInput(e.target.value)}
      InputProps={{
        style: {
          height: height ? height : "32.5px",
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon width={"12px"} />
          </InputAdornment>
        ),
        endAdornment: searchInput ? (
          <InputAdornment
            style={{ cursor: "pointer" }}
            onClick={() => setSearchInput("")}
            position="end"
          >
            <CancelIcon width={"15px"} />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export const DateTimeInput = ({
  dateText,
  date,
  setDate,
  dateInput,
  setDateInput,
  width,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Input
        id={"inputID"}
        width={width ? width : "400px"}
        height={"32px"}
        variant="outlined"
        value={dateInput.toLocaleString(
          {
            ...DateTime.DATETIME_MED_WITH_SECONDS,
            hour12: false,
          },
          {
            locale: i18n.language,
          }
        )}
        inputProps={{ style: { fontSize: 14, color: "#707074" } }}
        onClick={() => setDate(true)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarIcon width="16px" height="16px" alt={dateText} />
            </InputAdornment>
          ),
          endAdornment:
            dateInput !== t(dateText) ? (
              <InputAdornment
                style={{ cursor: "pointer" }}
                position="end"
                onClick={(e) => {
                  setDateInput(t(dateText));
                  setDate(false);
                  e.stopPropagation();
                }}
              >
                <CancelIcon width={"15px"} />
              </InputAdornment>
            ) : null,
        }}
      />
      <DateTimePicker
        value={dateInput}
        TextFieldComponent={() => null}
        ampm={false}
        open={date}
        onClose={() => {
          setDate(false);
        }}
        onChange={(date) => setDateInput(date)}
      />
    </>
  );
};

const TextAreaBottom = styled.div`
  color: ${(p) => p.theme.colors.textGrey};
  align-self: left;
  left: 0;
  margin-top: 1vh;
  font-size: ${(p) => p.theme.fontSize.xxs};
  font-family: ${(p) => p.theme.font.light};
`;

const TextAreaInput = styled.textarea`
  padding: 20px;
  align-self: top;
  height: ${(p) => (p.height ? p.height : "200px")};
  width: ${(p) => (p.width ? p.width : "250px")};
  font-family: ${(p) => p.theme.font.light};
  font-size: ${(p) => p.theme.fontSize.sm};
  background: white;
  border-color: ${(p) => p.theme.colors.textGrey};
  color: ${(p) => p.theme.colors.textGrey};
  border-radius: 10px;
  resize: none;
  vertical-align: top;
  ::placeholder {
    color: ${(p) => p.theme.colors.textGrey};
    font-family: ${(p) => p.theme.font.light};
    text-align: left;
  }
`;

export const TextArea = ({
  name,
  value,
  placeholder,
  onChange,
  charLength,
  maxLength,
  width,
  height,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TextAreaInput
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        value={value}
        width={width}
        height={height}
        name={name}
      ></TextAreaInput>
      <TextAreaBottom>
        {charLength + "/" + maxLength + " " + t("product-characters")}
      </TextAreaBottom>
    </>
  );
};
