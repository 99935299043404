import React, { useEffect, useState } from "react";
import { Spacing } from "..";
import { appTheme } from "../Theme";
import ToolTip from "react-tooltip";
import styled from "styled-components";
import { debounce } from "@material-ui/core";

const TooltipContainer = styled(ToolTip)`
  border-radius: 10px !important;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.26);
`;

export const Tooltip = ({ el, id, value, textColor }) => {
  id = id ? id : value;
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = (el) => {
    if (el === undefined || el === null) {
      return false;
    }
    var curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === "visible") el.style.overflow = "hidden";
    var isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
  };

  const udpateAndResizeHandler = debounce(() => {
    el ? setIsOverflowing(checkOverflow(el.current)) : setIsOverflowing(true);
  }, 300);

  useEffect(() => {
    window.addEventListener("resize", udpateAndResizeHandler);
    return () => {
      window.removeEventListener("resize", udpateAndResizeHandler);
    };
  }, []);

  useEffect(() => {
    udpateAndResizeHandler();
  }, [value]);

  return (
    <>
      {isOverflowing && (
        <TooltipContainer
          backgroundColor="white"
          isCapture={true}
          id={id}
          place="top"
          effect="solid"
          textColor={
            textColor ? appTheme.colors[textColor] : appTheme.colors.red
          }
        >
          <Spacing height="20px">{value}</Spacing>
        </TooltipContainer>
      )}
    </>
  );
};

const defaultTooltipStyle = {
  defaultBannerStyle: {
    borderRadius: "3px",
    width: "fit-content",
    padding: "1px 5px",
    transition: "opacity 0.3s ease-in",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.26)",
    whiteSpace: "pre",
    background: "white",
    fontSize: "12px",
    opacity: "0.9",
    position: "absolute",
    pointerEvents: "none",
  },
  defaultPointerStyle: {
    display: "block",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "100%",
    width: "0",
    height: "0",
    marginLeft: "auto",
    marginRight: "auto",
    top: "100%",
    borderTop: "6px solid white",
    borderRight: "4px solid transparent",
    borderBottom: "0 solid transparent",
    borderLeft: "4px solid transparent",
  },
};

const rowStyle = `
  display: flex; 
  gap: 10px; 
  justify-content: space-between;
`;

export const ChartTooltip = (context, customTooltip) => {
  // Tooltip element
  var innerHtml = "";
  var tooltipElement = document.getElementById("chartjs-tooltip");
  var tooltipModel = context.tooltip;

  // Create tooltip element if it doesn't exist
  if (!tooltipElement) {
    tooltipElement = document.createElement("div");
    tooltipElement.id = "chartjs-tooltip";
    tooltipElement.innerHTML = "";
    document.body.appendChild(tooltipElement);
  }

  if (tooltipModel.opacity === 0) {
    tooltipElement.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipElement.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipElement.classList.add(tooltipModel.yAlign);
  } else {
    tooltipElement.classList.add("no-transform");
  }

  for (let i = 0; i < customTooltip?.data?.length; i++) {
    let tooltipHtml = "";
    var valueColor = tooltipModel.labelColors[i].borderColor;

    // Find for {{ }} placeholders in the displayTemplate provided
    tooltipHtml += customTooltip.displayTemplate?.replace(
      /{{(.*?)}}/g,
      (match, key) => {
        // If the var in placeholder, exists inside colors object, display it with color
        if (customTooltip.colors?.[key.trim()]) {
          return `<div style='color:${valueColor};'>${
            customTooltip.data[i][key.trim()]
          }</div>`;
        }
        // Else display it without color
        else {
          return `<div>${customTooltip.data[i]?.[key.trim()]}</div>`;
        }
      }
    );
    innerHtml += `<div style="${rowStyle}"> ${tooltipHtml} </div>`;
  }

  innerHtml += "</tbody>";
  innerHtml += `<div id="tooltip-pointer"></div>`;

  tooltipElement.innerHTML = innerHtml;
  var position = context.chart.canvas.getBoundingClientRect();
  var pointerElement = document.getElementById("tooltip-pointer");

  // The customPointerStyle overrides the defaultPointerStyle which is assigned to the style property of the pointerElement
  Object.assign(
    pointerElement.style,
    defaultTooltipStyle.defaultPointerStyle,
    customTooltip?.styles?.pointerStyle
  );

  // The customTooltipStyle overrides the defaultTooltipStyle which is assigned to the style property of the tooltipElement
  Object.assign(
    tooltipElement.style,
    defaultTooltipStyle.defaultBannerStyle,
    customTooltip?.styles?.bannerStyle
  );

  tooltipElement.style.left =
    position.left +
    window.pageXOffset +
    tooltipModel.caretX -
    tooltipElement.offsetWidth / 2 +
    "px";
  tooltipElement.style.top =
    position.top +
    window.pageYOffset +
    tooltipModel.caretY -
    (tooltipElement.offsetHeight + 10) +
    "px";
};

//HTML of the tooltip used in the map
export const MapTooltip = (
  statusColor,
  statusText,
  name,
  innerCircleColor,
  circleColor,
  t
) => {
  return `
    <div class='parent'> 
      <div class='row'> 
        <div class='heading'> 
          ${name} 
        </div> 
      <div style='background:${circleColor}' class='outerCircle'>
        <div style='background: ${innerCircleColor}' class='innerCircle'>
        </div>
      </div> 
    </div> 
    <div style='margin-top:0px; margin-left:15px; color: ${appTheme.colors[statusColor]};'> 
      ${statusText} 
    </div>
    </div></div> 
    <div class='overlay'> 
    </div></div>`;
};
