import { keyframes } from "styled-components";

export function Fade(fromOpacity, toOpacity) {
  return keyframes`
      0% {
        opacity: ${fromOpacity};
      }
      100% {
        opacity: ${toOpacity};
      }
    `;
}

export function Expand(fromHeight = "20%", toHeight = "100%") {
  return keyframes`
  0%{
    height: ${fromHeight};
  }
  100% {
    height: ${toHeight}; 
  }
`;
}

export function Shrink(fromHeight = "100%", toHeight = "20%") {
  return keyframes`
  0%{
    height: ${fromHeight};
  }
  100% {
    height: ${toHeight}; 
  }
`;
}

export function Rotate() {
  return keyframes`
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
      -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
      -moz-transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }

    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
      -webkit-transform: translate3d(-50%, -50%, 0) rotate(360deg);
      -moz-transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  `;
}
