import React, { useState } from "react";
import styled from "styled-components";
import { Spacing, Text, DashedContainer } from "..";
import { UploadIcon } from "..";

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const ButtonImgContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  height: 20%;
  width: 20%;
  margin-bottom: 5%;
  margin-right: 3%;
  border: 2px black;
  border-radius: 4px;
`;

const UploadImageBox = ({
  name,
  file,
  text,
  subtext,
  imageSource,
  handleChange,
  errorMsg,
}) => {
  const [input, setInput] = useState(file);

  const handleUploadChange = (event) => {
    handleChange(name, event.target.files[0]);
    setInput(event.target.files[0]);
  };

  return (
    <Spacing>
      <DashedContainer
        width="135px"
        height="135px"
        display="inline-block"
        alignItems="normal"
        justify="flex-start"
        flexDirection="row"
        cursor="default"
        position="relative"
        textAlign="center"
        overflow="hidden"
      >
        <Img src={imageSource}></Img>
        <label>
          <input
            type="file"
            id="file-upload"
            hidden
            multiple={false}
            onChange={handleUploadChange}
          />
          <ButtonImgContainer>
            <UploadIcon width="100%" />
          </ButtonImgContainer>
        </label>
      </DashedContainer>
      <Spacing mTopvh="1" />
      <Text textAlign="center">{text}</Text>
      <Text textAlign="center" color="textDarkerGrey" fontSize="xxs">
        {subtext}
      </Text>
      <Spacing mTopvh="1" />
      {errorMsg !== "" && (
        <Text textAlign="center" fontSize="xxs" color="red">
          {errorMsg}
        </Text>
      )}
    </Spacing>
  );
};

export default UploadImageBox;
