import React from "react";
import { useTranslation } from "react-i18next";
import { SettingsAccordion } from "../../../../../styles";
import { VersionIcon } from "../../../../../commonalities";

const VersionInformation = ({ versionInfo, show, heading, setExpand }) => {
  const { t } = useTranslation();
  const data = {};
  data["FMS " + t("cell-settingsGeneralVersion")] = versionInfo.FMSVersion
    ? versionInfo.FMSVersion
    : "-";
  data["Craft " + t("cell-settingsGeneralVersion")] = versionInfo.CraftVersion
    ? versionInfo.CraftVersion
    : "-";
  data["App " + t("cell-settingsGeneralVersion")] = versionInfo.AppVersion
    ? versionInfo.AppVersion
    : "-";
  data["Firmware " + t("cell-settingsGeneralVersion")] =
    versionInfo.FirmwareVersion ? versionInfo.FirmwareVersion : "-";
  data["Hardware " + t("cell-settingsGeneralVersion")] =
    versionInfo.HardwareVersion ? versionInfo.HardwareVersion : "-";

  return (
    <SettingsAccordion
      expand={"version"}
      iconImage={<VersionIcon width="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default VersionInformation;
