import React from "react";
import { appTheme as theme } from "../commonalities/Theme";

export const DepthIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "72";
  const COLOR = theme.colors[color] || theme.colors.textDarkGrey;
  const STROKE_WIDTH = strokeWidth || "3";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
    >
      <g
        id="Group_2899"
        data-name="Group 2899"
        transform="translate(-1696 -296)"
      >
        <g id="Layer_3" data-name="Layer 3" transform="translate(698.129 -588)">
          <path
            id="Path_803"
            data-name="Path 803"
            d="M1061.87,998.159v9.059a2.06,2.06,0,0,1-2.059,2.059h-51.882a2.06,2.06,0,0,1-2.059-2.059v-9.059a2.06,2.06,0,0,1,2.059-2.059h9.191a24.611,24.611,0,0,0,16.751,6.547V996.1h25.941A2.06,2.06,0,0,1,1061.87,998.159Z"
            transform="translate(0 -61.276)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth={STROKE_WIDTH}
          />
          <circle
            id="Ellipse_12"
            data-name="Ellipse 12"
            cx="4.941"
            cy="4.941"
            r="4.941"
            transform="translate(1028.93 911.768)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth={STROKE_WIDTH}
          />
          <path
            id="Path_804"
            data-name="Path 804"
            d="M1063.282,916.685a24.615,24.615,0,0,1-7.951,18.144h-16.755v6.542a24.685,24.685,0,1,1,24.706-24.685Z"
            transform="translate(-4.706)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <path
            id="Path_805"
            data-name="Path 805"
            d="M1067.872,995.7"
            transform="translate(-36.472 -61.03)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
            strokeDasharray="12"
          />
          <path
            id="Path_806"
            data-name="Path 806"
            d="M1056.748,937.793a16.627,16.627,0,1,0-14.618,2.848"
            transform="translate(-12.665 -7.923)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
            strokeDasharray="7 10"
          />
        </g>
        <rect
          id="Rectangle_9618"
          data-name="Rectangle 9618"
          width="72"
          height="72"
          transform="translate(1696 296)"
          fill="none"
        />
      </g>
    </svg>
  );
};
