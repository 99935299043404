import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const MinusIcon = ({ height, width, color }) => {
  const WIDTH = width || "11";
  const HEIGHT = height || "1";
  const COLOR = theme.colors[color] || theme.colors.black;

  return (
    <svg
      width={WIDTH}
      height={HEIGHT}
      fill={COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 1"
    >
      <g
        id="Group_2265"
        data-name="Group 2265"
        transform="translate(-322.5 -294.5)"
      >
        <line
          id="Line_212"
          data-name="Line 212"
          y2="10"
          transform="translate(333 295) rotate(90)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
