import { DateTime } from "luxon";
import { SessionManager, NetworkController } from ".";
import Utility from "./Utility";
import { SubscribeAndPublish } from "../AWS";

export const getCells = (callback) => {
  if (SessionManager.cells === null) {
    getCellsFromServer(callback);
  } else {
    callback();
  }
};

//getting the cells from the server and formatting the data in the required JSON format.
export const getCellsFromServer = (callback) => {
  var cellData = {};
  var shadowData = {};
  var cellsShadowsLoaded = JSON.parse(sessionStorage.getItem("cellsShadows"));
  NetworkController.cells.get("", (res) => {
    SessionManager.jobHistory = res.data.jobHistory;
    res.data.cells.forEach((cell) => {
      cell.Usage.DailyUsage = null;
      if (
        SessionManager.cells &&
        SessionManager.cells[cell.CellID].Usage.DailyUsage
      ) {
        cell.Usage.DailyUsage =
          SessionManager.cells[cell.CellID].Usage.DailyUsage;
      }
      cellData[cell.CellID] = cell;
      shadowData[cell.CellID] = {};
      var cities = [];
      if (cellsShadowsLoaded) {
        Object.keys(cellsShadowsLoaded).forEach((cellsId) => {
          var locData = cellsShadowsLoaded[cellsId];
          var city = null;
          if (
            locData.location &&
            locData.locationCoordinates &&
            ((locData.locationData.status &&
              locData.locationData.status === "running") ||
              !locData.locationData.status)
          ) {
            city = locData.location.split(",")[0];
          }
          if (
            city &&
            locData.location &&
            city !== undefined &&
            !city.includes("undefined") &&
            !cities.includes(city)
          ) {
            cities.push(city);
          }
        });
        SessionManager.activeLocations = {
          activeCities: cities.length,
        };
      }
      shadowData[cell.CellID].settings =
        cellsShadowsLoaded && cellsShadowsLoaded[cell.CellID]?.settings
          ? cellsShadowsLoaded[cell.CellID].settings
          : null;
      shadowData[cell.CellID].hardwareStatus =
        cellsShadowsLoaded && cellsShadowsLoaded[cell.CellID]?.hardwareStatus
          ? cellsShadowsLoaded[cell.CellID].hardwareStatus
          : {};
      shadowData[cell.CellID].config =
        cellsShadowsLoaded && cellsShadowsLoaded[cell.CellID]?.config
          ? cellsShadowsLoaded[cell.CellID].config
          : null;
      shadowData[cell.CellID].softwareVersions = cellsShadowsLoaded
        ? cellsShadowsLoaded[cell.CellID]?.versions
          ? cellsShadowsLoaded[cell.CellID].versions
          : cellsShadowsLoaded[cell.CellID]?.SoftwareVersions
          ? cellsShadowsLoaded[cell.CellID].SoftwareVersions
          : null
        : null;
      shadowData[cell.CellID].locationData =
        cellsShadowsLoaded && cellsShadowsLoaded[cell.CellID]?.locationData
          ? cellsShadowsLoaded[cell.CellID].locationData
          : null;
      shadowData[cell.CellID].location = SessionManager.t("fms-unavailable");
      if (
        shadowData[cell.CellID].locationData &&
        ((shadowData[cell.CellID].locationData.status &&
          shadowData[cell.CellID].locationData.status === "running") ||
          !shadowData[cell.CellID].locationData.status)
      ) {
        shadowData[cell.CellID].location =
          cellsShadowsLoaded && cellsShadowsLoaded[cell.CellID]?.location
            ? cellsShadowsLoaded[cell.CellID].location
            : SessionManager.t("fms-unavailable");
        shadowData[cell.CellID].locationCoordinates =
          cellsShadowsLoaded &&
          cellsShadowsLoaded[cell.CellID]?.locationCoordinates
            ? cellsShadowsLoaded[cell.CellID].locationCoordinates
            : null;
      }
      shadowData[cell.CellID].status =
        cellsShadowsLoaded && cellsShadowsLoaded[cell.CellID]?.status
          ? cellsShadowsLoaded[cell.CellID].status
          : {
              programStatus: "fms-unavailable",
              programStatusCode: 3,
              programStatusEnd: "",
            };
      var usageDate = null;
      if (typeof cell.ProvisioningDate === "string") {
        usageDate = DateTime.fromISO(cell.ProvisioningDate);
      } else {
        usageDate = DateTime.fromMillis(cell.ProvisioningDate);
      }
      var date = new Date();
      var currentDate = DateTime.fromMillis(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );
      cellData[cell.CellID]["daysPassed"] = Utility.getNumberOfWeekdays(
        usageDate,
        currentDate
      );
    });
    SessionManager.cells = cellData;
    SessionManager.cellsShadows = shadowData;
    SubscribeAndPublish();
    callback();
  });
};
