import styled from "styled-components";
import { Button, TextButton, Spacing, A } from "../";
import { Fade } from "../";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const PopUpHeading = styled.div`
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  color: ${(p) => p.theme.colors.black};
  font-family: ${(p) =>
    p.font ? p.theme.font[p.font] : p.theme.font.semibold};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.lg};
  ${(p) => p.setInline && `display:inline-block`};
`;

export const PopUpText = styled.div`
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.black)};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.light)};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.sm};
  ${(p) => p.setInline && `display:inline-block`};
`;

export const PopUpBG = styled.div`
  position: fixed;
  display: flex;
  z-index: 10;
  right: 0px;
  bottom: 0px;
  width: 100%;
  animation-name: ${(p) => (p.fadeIn ? Fade(0, 1) : Fade(1, 0))};
  backdrop-filter: blur(3px);
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  height: 100%;
  background: rgba(189, 189, 189, 0.4);
  @media (max-width: ${(p) => p.theme.widthLimit}) {
    height: 100vh;
    background: none;
    backdrop-filter: blur(0px);
  }
  @media (max-height: ${(p) => p.theme.heightLimit}) {
    height: 100vh;
    background: none;
    backdrop-filter: blur(0px);
  }
`;

export const BlurBG = styled.div`
  position: fixed;
  display: none;
  z-index: 10;
  backdrop-filter: blur(3px);
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: rgba(189, 189, 189, 0.4);
  @media (max-width: ${(p) => p.theme.widthLimit}) {
    display: flex;
  }
  @media (max-height: 100vh) {
    display: flex;
  }
`;

export const PopUp = styled.div`
  margin: auto;
  z-index: 11;
  left: 100px;
  max-width: 90%;
  max-height: 90%;
  width: ${(p) => (p.width ? p.width : "auto")};
  ${(p) =>
    p.widthvw &&
    `width:calc((var(--useMinWidth) * var(--widthLimit) * ${p.widthvw} / 100) + (var(--usePercentWidth) * ${p.widthvw}vw))`};
  height: ${(p) => (p.height ? p.height : "auto")};
  ${(p) => p.minWidth && `min-width:${p.minWidth}`};
  ${(p) => p.minHeight && `min-height:${p.minHeight}`};
  ${(p) => p.padding && `padding:${p.padding}`};
  ${(p) =>
    p.paddingvw &&
    `padding:calc((var(--useMinWidth) * var(--widthLimit) * ${p.paddingvw} / 100) + (var(--usePercentWidth) * ${p.paddingvw}vw))`};
  ${(p) =>
    p.vPadding &&
    p.hPadding &&
    `padding: calc((var(--useMin) * var(--heightLimit) * ${p.vPadding} / 100) + (var(--usePercent) * ${p.vPadding}vh)) calc((var(--useMinWidth) * var(--widthLimit) * ${p.hPadding} / 100) + (var(--usePercentWidth) * ${p.hPadding}vw))`};
  border-radius: ${(p) => p.theme.borderRadius.sm};
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  @media (max-width: ${(p) => p.theme.widthLimit}),
    (max-height: ${(p) => p.theme.heightLimit}) {
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    margin: auto;
    height: fit-content;
  }
  overflow: ${(p) => (p.overflow ? p.overflow : "auto")};
  scrollbar-color: transparent transparent;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const PopUpTemplate = ({
  setShowPopUp,
  vPadding,
  hPadding,
  width,
  minWidth,
  height,
  children,
  widthvw,
  paddingvw,
  padding,
  overflow,
  top,
}) => {
  const [fadeIn, setFadeIn] = useState(true);

  return (
    <>
      <BlurBG
        fadeIn={fadeIn}
        onMouseDown={(e) => {
          setFadeIn(false);
          setTimeout(() => {
            setShowPopUp(false);
          }, 70);
        }}
      />
      <PopUpBG
        top={top}
        fadeIn={fadeIn}
        onMouseDown={(e) => {
          setFadeIn(false);
          setTimeout(() => {
            setShowPopUp(false);
          }, 70);
        }}
      >
        <PopUp
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          padding={padding}
          paddingvw={paddingvw}
          widthvw={widthvw}
          vPadding={vPadding}
          hPadding={hPadding}
          width={width}
          minWidth={minWidth}
          height={height}
          overflow={overflow}
        >
          {children}
        </PopUp>
      </PopUpBG>
    </>
  );
};

export const PopUpButtons = ({
  confirmText,
  onConfirmClick,
  cancelText,
  onCancelClick,
  cancelRoute,
  bgColor,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Spacing
        width="max-content"
        mTopvh={"3.7"}
        mLeft={"auto"}
        mRight={"auto"}
      >
        <Button bgColor={bgColor} onClick={onConfirmClick} fontSize={"md"}>
          {t(confirmText)}
        </Button>
      </Spacing>
      {onCancelClick && (
        <Spacing
          width="max-content"
          mTopvh={"0.74"}
          mLeft={"auto"}
          mRight={"auto"}
        >
          <TextButton onClick={onCancelClick}>
            {t(cancelText ? cancelText : "buttons-cancel")}
          </TextButton>
        </Spacing>
      )}
      {cancelRoute && (
        <Spacing
          width="max-content"
          mTopvh={"3.7"}
          mLeft={"auto"}
          mRight={"auto"}
        >
          <A
            fontSize={"md"}
            font={"regular"}
            to={cancelRoute}
            color={"textDarkerGrey"}
          >
            {t(cancelText ? cancelText : "buttons-cancel")}
          </A>
        </Spacing>
      )}
    </>
  );
};
