import React, { useState } from "react";
import {
  LanguageSettings,
  AdvancedSettings,
  CalibrationSettings,
  ToolsSettings,
  VersionInformation,
  SpeedSettings,
  FixtureSettings,
  DepthSettings,
} from "./";
import { useTranslation } from "react-i18next";

const CNMSettings = ({ versionInfo, settings, config }) => {
  const [expand, setExpand] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <LanguageSettings
        settings={settings}
        show={expand === "language"}
        setExpand={setExpand}
        heading={t("cell-unitsLanguage")}
      />
      <FixtureSettings
        settings={settings}
        show={expand === "fixture"}
        setExpand={setExpand}
        heading={t("cell-settingsFixture")}
      />
      <ToolsSettings
        settings={settings}
        config={config}
        show={expand === "tool"}
        setExpand={setExpand}
        heading={t("cell-tool")}
      />
      <CalibrationSettings
        settings={settings}
        config={config}
        show={expand === "toolCalibration"}
        setExpand={setExpand}
        heading={t("cell-calibration")}
      />
      <SpeedSettings
        settings={settings}
        show={expand === "speed"}
        setExpand={setExpand}
        heading={t("cell-settingsSpeed")}
      />
      <DepthSettings
        settings={settings}
        config={config}
        show={expand === "depth"}
        setExpand={setExpand}
        heading={t("cell-settingsDepth")}
      />
      <AdvancedSettings
        settings={settings}
        show={expand === "advanced"}
        setExpand={setExpand}
        heading={t("cell-settingsRubberMat")}
      />
      <VersionInformation
        versionInfo={versionInfo}
        show={expand === "version"}
        setExpand={setExpand}
        heading={t("cell-versionInformation")}
      />
    </>
  );
};

export default CNMSettings;
