import React, { useState, useEffect } from "react";
import { Spacing, InlineText } from "../../../../commonalities";
import { useTranslation } from "react-i18next";
import Utility from "../../../../utils/Utility";

const EvaloMiddleHeader = ({ fromDate, toDate, data }) => {
  const { t } = useTranslation();
  const [processData, setProcessData] = useState({
    totalArea: 0,
    personHours: 0,
    successfulJobs: 0,
    preemptedJobs: 0,
    erroneousJobs: 0,
  });

  useEffect(() => {
    var totalArea = 0;
    var personHours = 0;
    var successfulJobs = 0;
    var preemptedJobs = 0;
    var erroneousJobs = 0;

    data.forEach((item) => {
      if (item.Payload.ProcessType === "Cleaning") {
        if (item.Payload.Status === "Success") {
          var metadata = item.Payload.Metadata;
          totalArea += parseFloat(metadata.SurfaceArea);
          personHours = 0;
          successfulJobs += 1;
        } else if (
          item.Payload.Status === "Preempt" ||
          item.Payload.Status === "Cancelled"
        ) {
          preemptedJobs += 1;
        } else if (item.Payload.Status === "Job Failed") {
          erroneousJobs += 1;
        }
      }
    });
    setProcessData({
      totalArea: totalArea,
      personHours: personHours,
      successfulJobs: successfulJobs,
      preemptedJobs: preemptedJobs,
      erroneousJobs: erroneousJobs,
    });
  }, [data]);

  return (
    <>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.totalArea
            ? (
                parseFloat(processData.totalArea) /
                Utility.getNumberOfWeekdays(fromDate, toDate)
              ).toFixed(2)
            : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("fms-avgThroughput")}
        </InlineText>
      </Spacing>

      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.totalArea
            ? parseFloat(processData.totalArea).toFixed(2) + " m² "
            : "0 m²"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("fms-totalArea")}
        </InlineText>
      </Spacing>

      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.personHours ? processData.personHours : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("fms-personHrs")}
        </InlineText>
      </Spacing>

      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.successfulJobs ? processData.successfulJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("fms-successfulJobs")}
        </InlineText>
      </Spacing>

      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.preemptedJobs ? processData.preemptedJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("fms-preemptedJobs")}
        </InlineText>
      </Spacing>

      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.erroneousJobs ? processData.erroneousJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("fms-errors")}
        </InlineText>
      </Spacing>
    </>
  );
};

export default EvaloMiddleHeader;
