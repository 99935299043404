import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const LogsIcon = ({ height, width, color, fill, style }) => {
  const WIDTH = width || "20";
  const HEIGHT = height || "24";
  const COLOR = theme.colors[color] || theme.colors.black;

  return (
    <svg
      width={WIDTH}
      height={HEIGHT}
      fill={COLOR}
      stroke={fill ? theme.colors.white : COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 24"
      style={style}
    >
      <g
        id="Robot_Log_Icon"
        data-name="Robot Log Icon"
        transform="translate(-1512 -1600)"
      >
        <g
          id="Rectangle_7920"
          data-name="Rectangle 7920"
          transform="translate(1512 1600)"
          fill={fill ? "#000" : "#fff"}
          stroke={COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        >
          <rect width="20" height="24" rx="3" stroke="none" />
          <rect
            x="0.5"
            y="0.5"
            width="19"
            height="23"
            rx="2.5"
            fill={fill ? theme.colors[color] : "none"}
          />
        </g>
        <path
          id="Path_1023"
          data-name="Path 1023"
          d="M1504,1600s3.207,1.8,4.429,2.553c.2.137.281.211.281.453,0,.2-.042.274-.281.432-1.179.691-4.429,2.559-4.429,2.559"
          transform="translate(12.5 5)"
          fill={fill ? color : "none"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_78"
          data-name="Line 78"
          x2="11"
          transform="translate(1516.5 1614.997)"
          fill={fill ? theme.colors[color] : "none"}
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          id="Line_80"
          data-name="Line 80"
          x2="11"
          transform="translate(1516.5 1618.997)"
          fill={fill ? theme.colors[color] : "none"}
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          id="Line_79"
          data-name="Line 79"
          x2="5"
          transform="translate(1522.5 1610.997)"
          fill={fill ? theme.colors[color] : "none"}
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
