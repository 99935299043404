import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import {
  DoubleArrowIcon,
  Spacing,
  InlineText,
  FormRow,
  ShadowDiv,
} from "../../../commonalities";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { DateTimePicker } from "@material-ui/pickers";
import { NetworkController } from "../../../utils";
import { ResetIcon } from "../../../commonalities";
import CNMMiddleHeader from "./CNM/CNMMiddleHeader";
import EvaloMiddleHeader from "./Evalo/EvaloMiddleHeader";
import CarpenMiddleHeader from "./Carpen/CarpenMiddleHeader";

const useStyles = makeStyles({
  accordionSummaryRoot: {
    "&.Mui-expanded": {},
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "auto",
    },
  },
});

const MiddleHeader = ({
  cellId,
  cellType,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  toDateRealTime,
  refresh,
  setRefresh,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [showRefresh, setShowRefresh] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState({
    from: false,
    to: false,
  });
  const [expand, setExpand] = useState(false);
  const toggleAcordion = () => {
    if (expand) {
      resetDates();
    } else {
      getJobs(fromDate, toDate);
    }
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    getJobs(fromDate, toDate);
  }, []);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      resetDates();
    }
  }, [refresh]);

  useEffect(() => {
    if (toDateRealTime.current) {
      const timer = setInterval(() => {
        setToDate(DateTime.local());
      }, 1000);
      return () => clearInterval(timer);
    }
  });

  useEffect(() => {
    if (!toDateRealTime.current) {
      getJobs(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  const resetDates = () => {
    setToDate(DateTime.local());
    setFromDate(DateTime.local().minus({ days: 31 }));
    getJobs(DateTime.local().minus({ days: 31 }), DateTime.local());
    var timer = setTimeout(() => {
      toDateRealTime.current = true;
      setShowRefresh(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const datePickerChanged = () => {
    toDateRealTime.current = false;
    setExpand(true);
    setShowRefresh(true);
  };

  const getJobs = (from, to) => {
    var query = {
      CellID: cellId,
      after: from / 1000,
      before: to / 1000,
      lastKey: null,
    };
    NetworkController.cells.getJobs(query, (res) => {
      setFilteredData(res.data.Items);
    });
  };

  return (
    <ShadowDiv width="100%" borderRadius="sm">
      <FormRow vPadding="0.35" hPadding="0.67">
        <Accordion
          disablegutters="true"
          expanded={expand}
          elevation={0}
          style={{ width: "100%" }}
        >
          <AccordionSummary
            className={classes.accordionSummaryRoot}
            disablegutters="true"
            expandIcon={
              <DoubleArrowIcon
                width={17}
                height={18}
                color="black"
                pointed={true}
                shorterGap={true}
                strokeWidth="1"
                transform="scale(1 -1)"
              />
            }
            IconButtonProps={{
              onClick: toggleAcordion,
            }}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <InlineText
              fontSize="lg"
              font="medium"
              color="black"
              alignSelf={"center"}
            >
              {t("jobLogs")}
            </InlineText>
            <Spacing setFlex={true} hideYOverflow={true} mLeft="auto">
              <DateTimePicker
                value={fromDate}
                TextFieldComponent={() => null}
                ampm={false}
                open={datePickerOpen.from}
                onAccept={datePickerChanged}
                onClose={() => {
                  setDatePickerOpen({ from: false, to: false });
                }}
                onChange={(date) =>
                  setFromDate(
                    date >= toDate
                      ? toDate.minus({ minutes: 1 })
                      : date.set({ second: 0 })
                  )
                }
              />
              <Spacing
                onClick={() => setDatePickerOpen({ from: true, to: false })}
                setPointer={true}
              >
                <InlineText fontSize="xxxs">{t("since")}</InlineText>
                <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                  {fromDate.toLocaleString({
                    ...DateTime.DATETIME_MED_WITH_SECONDS,
                    hour12: false,
                  })}
                </InlineText>
              </Spacing>
              <Spacing width="80px" textAlign="center" mTop={"auto"}>
                <InlineText
                  color="black"
                  fontSize="xs"
                  font="semibold"
                  setInline={true}
                >
                  -
                </InlineText>
              </Spacing>
              <DateTimePicker
                value={toDate}
                TextFieldComponent={() => null}
                ampm={false}
                open={datePickerOpen.to}
                onAccept={datePickerChanged}
                onClose={() => {
                  setDatePickerOpen({ from: false, to: false });
                }}
                onChange={(date) =>
                  setToDate(
                    date <= fromDate
                      ? fromDate.plus({ minutes: 1 })
                      : date.set({ second: 0 })
                  )
                }
              />
              <Spacing
                onClick={() => setDatePickerOpen({ from: false, to: true })}
                setPointer={true}
              >
                <InlineText fontSize="xxxs">{t("until")}</InlineText>
                <Spacing width="170px">
                  <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                    {toDate.toLocaleString({
                      ...DateTime.DATETIME_MED_WITH_SECONDS,
                      hour12: false,
                    })}
                  </InlineText>
                </Spacing>
              </Spacing>
              {showRefresh && (
                <Spacing padding="5px" margin="auto" onClick={resetDates}>
                  <ResetIcon />
                </Spacing>
              )}
            </Spacing>
          </AccordionSummary>
          <AccordionDetails disablegutters="true">
            <FormRow>
              {(cellType === "CNM") ? <CNMMiddleHeader data={filteredData} />
                : ((cellType === "Carpen") ? <CarpenMiddleHeader data={filteredData} />
                : <EvaloMiddleHeader fromDate={fromDate} toDate={toDate} data={filteredData}/>
              )}
            </FormRow>
          </AccordionDetails>
        </Accordion>
      </FormRow>
    </ShadowDiv>
  );
};

export default MiddleHeader;
