import React from "react";
import { PropertyCard } from "../../../../styles";
import { useTranslation } from "react-i18next";
import {
  FormRow,
  Spacing,
  DropDownContainer,
  Text,
} from "../../../../commonalities";

const CarpenJobInfoDropDown = ({ shrink, job }) => {
  const { t } = useTranslation();
  var metadata =
    typeof job.Payload.Metadata === "string"
      ? JSON.parse(job.Payload.Metadata)
      : job.Payload.Metadata;

  return (
    <Spacing mTopvw={"0.84"}>
      <DropDownContainer height={"150px"} shrink={shrink}>
        <Spacing
          pTopvw={"1.67"}
          pRightvw={"1.25"}
          pBottomvw={"2.5"}
          pLeftvw={"2.5"}
        >
          <Spacing mTop="10px" mRight="auto" hideYOverflow={true}>
            <Text font={"semibold"} fontSize="xs">
              {t("jobInformation")}
            </Text>
          </Spacing>
          <Spacing mTopvh="1.48" height="fit-content" hideYOverflow={true}>
            <FormRow>
              <FormRow width={"24.806%"}>
                <PropertyCard
                  name={"numberOfCuts"}
                  value={metadata.NumberOfCuts ? metadata.NumberOfCuts : "-"}
                />
              </FormRow>
              <FormRow width={"24.806%"}>
                <PropertyCard
                  name={"numberOfParts"}
                  value={metadata.NumberOfParts ? metadata.NumberOfParts : "-"}
                />
              </FormRow>
              <FormRow width={"24.806%"}>
                <PropertyCard
                  name={"cutLength"}
                  value={
                    metadata.CutLength
                      ? parseFloat(metadata.CutLength).toFixed(2) + " mm"
                      : "-"
                  }
                />
              </FormRow>
            </FormRow>
            <Spacing mTopvh={"1.02"} />
            <FormRow>
              <FormRow width={"24.806%"}>
                <PropertyCard
                  name={"blockLength"}
                  value={
                    metadata.BlockLength ? metadata.BlockLength + " mm" : "-"
                  }
                />
              </FormRow>
              <FormRow width={"24.806%"}>
                <PropertyCard
                  name={"blockWidth"}
                  value={metadata.BlockWidth ? metadata.BlockWidth + " mm" : "-"}
                />
              </FormRow>
              <FormRow width={"24.806%"}>
                <PropertyCard
                  name={"blockHeight"}
                  value={
                    metadata.BlockHeight ? metadata.BlockHeight + " mm" : "-"
                  }
                />
              </FormRow>
            </FormRow>
          </Spacing>
        </Spacing>
      </DropDownContainer>
    </Spacing>
  );
};

export default CarpenJobInfoDropDown;
