import React from "react";
import { appTheme as theme } from "../Theme";

export const ResetIcon = ({ width, height, color, strokeWidth }) => {
  const WIDTH = width || "14.189";
  const HEIGHT = height || "15.076";
  const COLOR = theme.colors[color] || theme.colors.black;
  const STROKE_WIDTH = strokeWidth || "1";

  return (
    <svg
      width={WIDTH}
      height={HEIGHT}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.189 15.076"
    >
      <g
        id="Group_3147"
        data-name="Group 3147"
        transform="translate(-422.024 274.89)"
      >
        <path
          id="Path_2074"
          data-name="Path 2074"
          d="M435.522-270.939a7.033,7.033,0,0,0-5.96-3.293,7.038,7.038,0,0,0-7.038,7.038,7.038,7.038,0,0,0,7.038,7.038,7.033,7.033,0,0,0,5.962-3.3"
          transform="translate(0 -0.158)"
          fill="none"
          stroke={COLOR}
          strokeLinecap="round"
          strokeWidth={STROKE_WIDTH}
        />
        <path
          id="Path_1041"
          data-name="Path 1041"
          d="M451.992-270.548h3.935v-3.935"
          transform="translate(-20.403 0.093)"
          fill="none"
          stroke={COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={STROKE_WIDTH}
        />
      </g>
    </svg>
  );
};
