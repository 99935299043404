import styled from "styled-components";
import React from "react";
import { SessionManager } from "../../utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LargeArrowIcon, Spacing, Tooltip } from "..";

export const H1 = styled.h1`
  ${(p) => p.zIndex && `z-index: ${p.zIndex}`};
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.color && `color:${p.color}`};
`;
export const H2 = styled.h2`
  ${(p) => p.zIndex && `z-index: ${p.zIndex}`};
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.color && `color:${p.color}`};
`;

export const H3 = styled.h3`
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  ${(p) => p.textAlign && `text-align: ${p.textAlign}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.color && `color:${p.color}`};
`;

export const H4 = styled.h4`
  color: ${(p) => p.theme.colors.orange};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.padding && `padding:${p.padding}`};
  ${(p) => p.color && `color:${p.color}`};
`;

export const H5 = styled.h5`
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  ${(p) => p.textAlign && `text-align: ${p.textAlign}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.color && `color:${p.color}`};
`;

const AppHeaderText = styled.div`
  cursor: pointer;
  position: absolute;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  z-index: 1;
  font-size: ${(p) => p.theme.fontSize.xl};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.regular)};
  color: #111111;
  margin-left: calc(
    (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
      (var(--usePercentWidth) * 3.33vw)
  );

  height: calc(
    (var(--useMin) * var(--heightLimit) * 8.14 / 100) +
      (var(--usePercent) * 8.14vh)
  );
`;

export const AppHeading = ({ redirection }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <AppHeaderText onClick={() => history.push(redirection)}>
      {t(SessionManager.appHeading)}
    </AppHeaderText>
  );
};

const PageHeaderContainer = styled.div`
  display: flex;
  width: calc(
    100% -
      (
        (var(--useMinWidth) * var(--widthLimit) * 5 / 100) +
          (var(--usePercentWidth) * 5vw)
      ) * 2
  );
  background: ${(p) => p.theme.colors.backgroundGrey};
  height: 60px;
`;

const PageHeaderText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(
    100% -
      (
        (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
          (var(--usePercentWidth) * 3.33vw)
      ) * 2
  );
  margin-left: ${(p) => (p.mLeft ? p.mLeft : "auto")};
  margin-right: auto;
  align-self: flex-end;
  margin-bottom: calc(
    (var(--useMin) * var(--heightLimit) * 2.4 / 100) +
      (var(--usePercent) * 2.4vh)
  );
  font-size: ${(p) => p.theme.fontSize.xxxl};
  font-family: ${(p) => p.theme.font.regular};
  color: #111111;
  letter-spacing: 1px;
  z-index: 3;
`;

export const PageHeading = ({ heading, redirection, mLeft }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const el = React.useRef(undefined);

  return (
    <PageHeaderContainer>
      <PageHeaderText mLeft={mLeft} ref={el} data-tip data-for={t(heading)}>
        <Spacing
          setInlineFlex={true}
          mRightvw={redirection && "1.67"}
          setPointer={true}
        >
          {redirection && (
            <Spacing onClick={() => history.push(redirection)}>
              <LargeArrowIcon width="20px" height="29px" />
            </Spacing>
          )}
        </Spacing>
        {t(heading)}
      </PageHeaderText>
      <Tooltip el={el} value={t(heading)} textColor={"black"} />
    </PageHeaderContainer>
  );
};
