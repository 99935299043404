import React, { useEffect, useState } from "react";
import { CellCard } from "../../styles";
import { useTranslation } from "react-i18next";
import SessionManager from "../../utils/SessionManager";
import { EditCellPopUp } from "..";
import { ScrollingContainer, FormColumn } from "../../commonalities";

export default function LeftColumn({
  cells,
  cellsLocation,
  search,
  status,
  setCellDetails,
}) {
  const { t } = useTranslation();
  const [showEditPopUp, setShowEditPopUp] = useState({
    show: false,
    cellDetails: null,
  });
  //ERROR, OK, WARNING, NOT_CONNECTED, NO_WATER, DOOR_OPEN, DEFRROSTING, TOOL_ERROR, E_STOPPED
  const colors = [
    "red",
    "green",
    "orange",
    "grey",
    "red",
    "red",
    "orange",
    "red",
    "red",
  ];

  useEffect(() => {
    const body = document.getElementById("body");
    if (showEditPopUp.show) {
      if (body.offsetHeight > 720) {
        body.style = "overflow-y: hidden;";
      }
    } else {
      body.style = "";
    }
  }, [showEditPopUp]);
  const showEditCellPopUp = (show, cellID) => {
    setShowEditPopUp({ show: show, cellDetails: SessionManager.cells[cellID] });
  };

  return (
    <>
      <FormColumn
        alignItems="stretch"
        height="100%"
        gap="1.67"
        widthvw={"22.615"}
        borderRadius="xxs"
      >
        <ScrollingContainer
          width="fit-content"
          mLeft="-5px"
          height="auto"
          color="transparent"
          scrollWidth="0px"
          padding="0px 5px"
        >
          <FormColumn gap="1.48">
            {Object.values(cells)
              .filter((cell) => {
                if (
                  cell.SerialNumber.toLowerCase().includes(
                    search.toLowerCase()
                  ) ||
                  cell.Owner.toLowerCase().includes(search.toLowerCase()) ||
                  cell.Type.toLowerCase().includes(search.toLowerCase()) ||
                  cell.Name.toLowerCase().includes(search.toLowerCase()) ||
                  cellsLocation[cell.CellID]
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .sort((a, b) => {
                var aValue =
                  a.Usage.OnlineStats &&
                  a.Usage.OnlineStats[6].lastSession.online
                    ? a.Usage.OnlineStats[6].lastSession.online
                    : 0;
                var bValue =
                  b.Usage.OnlineStats &&
                  b.Usage.OnlineStats[6].lastSession.online
                    ? b.Usage.OnlineStats[6].lastSession.online
                    : 0;
                if (aValue >= bValue) {
                  return -1;
                } else {
                  return 1;
                }
              })
              .map((cell) => {
                var displayStatus = {
                  color: "grey",
                  text: t("cell-offline"),
                };
                if (cell.ConnectionStatus) {
                  if (
                    status &&
                    status[cell.CellID] &&
                    !status[cell.CellID].programStatus
                      ?.toLowerCase()
                      .includes("ready") &&
                    !status[cell.CellID].programStatus
                      ?.toLowerCase()
                      .includes("not") &&
                    !status[cell.CellID].programStatus
                      ?.toLowerCase()
                      .includes("goto") &&
                    !status[cell.CellID].programStatus
                      ?.toLowerCase()
                      .includes("unavailable")
                  ) {
                    displayStatus = {
                      color: colors[status[cell.CellID].programStatusCode],
                      text: t(status[cell.CellID].programStatus),
                    };
                  } else {
                    displayStatus = {
                      color: "green",
                      text: t("cell-online"),
                    };
                  }
                }
                return (
                  <CellCard
                    search={search}
                    status={displayStatus}
                    location={cellsLocation[cell.CellID]}
                    showEditCellPopUp={showEditCellPopUp}
                    key={cell.CellID}
                    cellData={cell}
                  />
                );
              })}
          </FormColumn>
        </ScrollingContainer>
      </FormColumn>
      {showEditPopUp.show && (
        <EditCellPopUp
          setCellDetails={setCellDetails}
          cellDetails={showEditPopUp.cellDetails}
          setShowPopUp={showEditCellPopUp}
        />
      )}
    </>
  );
}
