import { useStore } from "..";

export const SET_AUTH_USER = "SET_AUTH_USER";
export const CLEAR_AUTH_USER = "CLEAR_AUTH_USER";

export const AuthActions = () => {
  const [, dispatch] = useStore();

  const setAuth = (userData) => {
    dispatch({
      type: SET_AUTH_USER,
      payload: {
        auth: userData,
      },
    });
  };

  const clearAuth = () => {
    sessionStorage.clear();
    dispatch({
      type: CLEAR_AUTH_USER,
    });
  };

  return { setAuth, clearAuth };
};
