import { useHistory } from "react-router-dom";
import React from "react";
import { useStore } from "../../store";
import { useState, useEffect, useRef } from "react";
import {
  DeleteIcon,
  EditIcon,
  PageHeading,
  PageBody,
  Row,
  TableContainer,
  HeaderCell,
  DataCell,
  Cell,
  Spacing,
  TablePagination,
  Loader,
  IconHover,
} from "../../commonalities";
import SiteHeader from "../../Header";
import * as Routes from "../../routes/Routes";
import * as Topics from "../../AWS/IoTTopics";
import { useTranslation } from "react-i18next";
import { NetworkController, SessionManager } from "../../utils";
import { DeleteCellPopUp, EditCellPopUp } from "..";
import { getCells } from "../../utils";

const ManageCells = () => {
  const colors = ["red", "green", "orange", "textDarkGrey"];
  const history = useHistory();
  const { t } = useTranslation();
  const [{ auth }] = useStore();
  const [sort, setSort] = useState("Name");
  const [displayCells, setDisplayCells] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(true);
  const [shadows, setShadows] = useState(SessionManager.cellsShadows);
  const [isAscending, setisAscending] = useState(1);
  const [showDeletePopUp, setShowDeletePopUp] = useState({
    show: false,
    CellID: null,
    cellName: "",
  });
  const [showEditPopUp, setShowEditPopUp] = useState({
    show: false,
    cellDetails: null,
  });
  const [pageDetails, setPageDetails] = useState({ current: 1, total: 1 });
  const displayCount = useRef(0);
  const allCells = useRef([]);

  const IOTEventListener = (cellID, topic) => {
    if (topic === Topics.CONNECTED_TOPIC_SUB) {
      setUpdate(!update);
    } else if (topic === Topics.LOCATION_UPDATE_TOPIC_ACCEPTED) {
      setShadows({
        ...SessionManager.cellsShadows,
      });
      setUpdate(!update);
    } else if (topic === Topics.STATUS_UPDATE_TOPIC_ACCEPTED) {
      setShadows({
        ...SessionManager.cellsShadows,
      });
      setUpdate(!update);
    }
  };

  useEffect(() => {
    SessionManager.cells ? setLoading(false) : setLoading(true);
    if (auth.user.userType !== "Admin") {
      history.push(Routes.ACCESS_DENIED);
    } else {
      SessionManager.IOTEventListener = IOTEventListener;
    }
    return () => {
      SessionManager.IOTEventListener = null;
    };
  }, []);

  useEffect(() => {
    if (SessionManager.cells) {
      allCells.current = Object.keys(SessionManager.cells);
      setPageDetails({
        current: 1,
        total: Math.ceil(Object.keys(SessionManager.cells).length / 10),
      });
      setUpdate(!update);
    } else {
      setLoading(true);
      getCells(() => {
        allCells.current = Object.keys(SessionManager.cells);
        setLoading(false);
        setPageDetails({
          current: 1,
          total: Math.ceil(Object.keys(SessionManager.cells).length / 10),
        });
        setUpdate(!update);
      });
    }
  }, []);

  const deleteCell = (CellID) => {
    setLoading(true);
    NetworkController.cells.delete(SessionManager.cells[CellID], (res) => {
      if (res.status === 200) {
        delete SessionManager.cells[CellID];
        allCells.current = Object.keys(SessionManager.cells);
        setDisplayCells(allCells.current);
        setUpdate(!update);
        setLoading(false);
      }
    });
  };

  const setCellDetails = (details) => {
    SessionManager.cells[details.CellID] = details;
    setUpdate(!update);
  };

  const setSorting = (sortBy) => {
    if (sort === sortBy) {
      setisAscending(-isAscending);
    } else {
      setisAscending(1);
    }
    setSort(sortBy);
  };

  const setEditPopUp = (show) => {
    setShowEditPopUp({ show: false, cellDetails: null });
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { backgroundColor: "#000", color: "#fff" }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };

  useEffect(() => {
    if (SessionManager.cells) {
      var cells = [...Object.keys(SessionManager.cells)];
      allCells.current = (
        search === ""
          ? cells
          : cells.filter((cellID) => {
            const cell = SessionManager.cells[cellID];
            if (
              cell.SerialNumber.toLowerCase().includes(
                search.toLowerCase()
              ) ||
              cell.Owner.toLowerCase().includes(search.toLowerCase()) ||
              cell.Type.toLowerCase().includes(search.toLowerCase()) ||
              cell.Name.toLowerCase().includes(search.toLowerCase()) ||
              SessionManager.cellsShadows[cell.CellID].location
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return true;
            } else {
              return false;
            }
          })
      ).sort(function (a, b) {
        if (sort === "Location") {
          if (
            SessionManager.cellsShadows[a].location.toLowerCase() <
            SessionManager.cellsShadows[b].location.toLowerCase()
          ) {
            return -isAscending;
          } else {
            return isAscending;
          }
        } else {
          if (
            SessionManager.cells[a][sort].toString().toLowerCase() <
            SessionManager.cells[b][sort].toString().toLowerCase()
          ) {
            return -isAscending;
          } else {
            return isAscending;
          }
        }
        return 0;
      });
      setDisplayCells(allCells.current);
    }
  }, [search, sort, isAscending, update, pageDetails]);

  displayCount.current = 0;

  return (
    <>
      <SiteHeader search={search} setSearch={setSearch} />

      {loading && <Loader />}
      <PageBody>
        <PageHeading
          redirection={Routes.CONTROL_ROOM}
          heading="manageCells"
        />
        <TableContainer
          minWidth="960px"
          widthvw="77.53"
          setMLeft={true}
          setMBottom={true}
          mRightvw="3.33"
        >
          <Row width={"100%"}>
            <Cell vPadding={"1.67"} width={"2.413%"} />
            <Cell fontSize={"xs"} vPadding={"1.67"} width={"4.039%"} />
            <HeaderCell
              onClick={() => setSorting("Name")}
              width={"12.365%"}
              heading={"unitName"}
              isAscending={isAscending}
              isSelected={sort === "Name"}
            />
            <HeaderCell
              onClick={() => setSorting("SerialNumber")}
              isAscending={isAscending}
              isSelected={sort === "SerialNumber"}
              heading={"serialNumber"}
              width={"17.642%"}
            />
            <HeaderCell
              onClick={() => setSorting("Type")}
              isAscending={isAscending}
              isSelected={sort === "Type"}
              heading={"type"}
              width={"11.828%"}
            />
            <HeaderCell
              onClick={() => setSorting("Owner")}
              isAscending={isAscending}
              isSelected={sort === "Owner"}
              heading={"owner"}
              width={"11.828%"}
            />
            <HeaderCell
              onClick={() => setSorting("ConnectionStatus")}
              isAscending={isAscending}
              isSelected={sort === "ConnectionStatus"}
              heading={"status"}
              width={"12.365%"}
            />
            <HeaderCell
              onClick={() => setSorting("Location")}
              isAscending={isAscending}
              isSelected={sort === "Location"}
              heading={"location"}
              width={"12.903%"}
            />
            <Cell fontSize={"xs"} vPadding={"1.67"} width={"5.376%"} />
            <Cell vPadding={"1.67"} width={"1.613%"} />
          </Row>
          {displayCells
            .slice((pageDetails.current - 1) * 10, pageDetails.current * 10)
            .map((cellID, index) => {
              const cell = SessionManager.cells[cellID];
              var i = (pageDetails.current - 1) * 10 + (index + 1);
              displayCount.current += 1;
              var displayStatus = {
                color: "textDarkGrey",
                text: "Offline",
              };
              if (cell?.ConnectionStatus) {
                if (
                  shadows &&
                  shadows[cell.CellID]?.status &&
                  !shadows[cell.CellID].status?.programStatus
                    .toLowerCase()
                    .includes("ready") &&
                  !shadows[cell.CellID].status?.programStatus
                    .toLowerCase()
                    .includes("not") &&
                  !shadows[cell.CellID].status?.programStatus
                    .toLowerCase()
                    .includes("goto") &&
                  !shadows[cell.CellID].status?.programStatus
                    .toLowerCase()
                    .includes("unavailable")
                ) {
                  displayStatus = {
                    color:
                      colors[shadows[cell.CellID].status?.programStatusCode],
                    text: t(shadows[cell.CellID].status?.programStatus),
                  };
                } else {
                  displayStatus = {
                    color: "green",
                    text: "Online",
                  };
                }
              }
              return (
                <Spacing key={cell.SerialNumber} mTopvh={"1.67"}>
                  <Row width={"100%"}>
                    <Cell vPadding={"1.67"} width={"2.413%"} />
                    <DataCell
                      vPadding={"1.67"}
                      width={"4.039%"}
                      value={i}
                      tooltipValue={i}
                    />
                    <DataCell
                      vPadding={"1.67"}
                      width={"12.365%"}
                      value={getHighlightedText(cell.Name, search)}
                      tooltipValue={cell.Name}
                    />
                    <DataCell
                      vPadding={"1.67"}
                      width={"17.642%"}
                      value={getHighlightedText(cell.SerialNumber, search)}
                      tooltipValue={cell.SerialNumber}
                    />
                    <DataCell
                      vPadding={"1.67"}
                      width={"11.828%"}
                      value={getHighlightedText(cell.Type, search)}
                      tooltipValue={cell.Type}
                    />
                    <DataCell
                      vPadding={"1.67"}
                      width={"11.828%"}
                      value={getHighlightedText(cell.Owner, search)}
                      tooltipValue={cell.Owner}
                    />
                    <DataCell
                      vPadding={"1.67"}
                      color={displayStatus.color}
                      width={"12.365%"}
                      value={displayStatus.text}
                      tooltipValue={displayStatus.text}
                    />
                    <DataCell
                      vPadding={"1.67"}
                      width={"12.903%"}
                      value={getHighlightedText(
                        shadows[cell.CellID]?.location,
                        search
                      )}
                    />
                    <Cell vPadding={"0.84"} width={"5.376%"} textAlign={"left"}>
                      <Spacing setFitContent={true} margin={"auto"}>
                        <IconHover
                          onClick={() =>
                            setShowEditPopUp({ show: true, cellDetails: cell })
                          }
                          setPointer={true}
                          setInline={true}
                        >
                          <EditIcon
                            width={"18px"}
                            color="black"
                            strokeWidth="0.8"
                          />
                        </IconHover>
                        <IconHover
                          onClick={() =>
                            setShowDeletePopUp({
                              show: true,
                              CellID: cell.CellID,
                              cellName: cell.Name,
                            })
                          }
                          setPointer={true}
                          mLeft={"16px"}
                          setInline={true}
                        >
                          <DeleteIcon width={"18px"} />
                        </IconHover>
                      </Spacing>
                    </Cell>
                    <Cell vPadding={"1.67"} width={"1.613%"} />
                  </Row>
                </Spacing>
              );
            })}
          <TablePagination
            displayCount={displayCount.current}
            totalItems={
              SessionManager.cells
                ? Object.keys(SessionManager.cells).length
                : 0
            }
            currentPage={pageDetails.current}
            setCurrentPage={(value) => {
              setPageDetails({ ...pageDetails, current: value });
            }}
            totalPages={pageDetails.total}
          />
        </TableContainer>
      </PageBody>
      {showDeletePopUp.show && (
        <DeleteCellPopUp
          setShowDeletePopUp={setShowDeletePopUp}
          deleteCell={deleteCell}
          cellId={showDeletePopUp.CellID}
          cellName={showDeletePopUp.cellName}
        />
      )}

      {showEditPopUp.show && (
        <EditCellPopUp
          setCellDetails={setCellDetails}
          cellDetails={showEditPopUp.cellDetails}
          setShowPopUp={setEditPopUp}
        />
      )}
    </>
  );
};

export default ManageCells;
