import styled from "styled-components";
import React from "react";
import { Spacing, ArrowIcon, Tooltip } from "..";
import { useTranslation } from "react-i18next";

export const TableContainer = styled.div`
  ${(p) => p.minWidth && `min-width: ${p.minWidth}`};
  width: ${(p) =>
    p.width
      ? p.width
      : p.widthvw
      ? `calc(
        (var(--useMinWidth) * var(--widthLimit) * ${p.widthvw} / 100) +
          (var(--usePercentWidth) * ${p.widthvw}vw)
      )`
      : `calc(
    100% -
      (
        (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
          (var(--usePercentWidth) * 3.33vw)
      ) * 2
  )`};
  padding: ${(p) =>
    p.setPadding &&
    `0px
    calc(
      (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
        (var(--usePercentWidth) * 3.33vw)
    )`};
  height: 100%;
  margin-bottom: ${(p) =>
    p.setMBottom &&
    `calc(
    (var(--useMin) * var(--widthLimit) * 0.25 / 100) +
      (var(--usePercent) * 0.25vw)
  )`};
  margin-left: ${(p) =>
    p.setMLeft &&
    ` calc(
    (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
      (var(--usePercentWidth) * 3.33vw)
  )`};
  margin-right: ${(p) =>
    p.mRightvw &&
    ` calc(
    (var(--useMinWidth) * var(--widthLimit) * ${p.mRightvw} / 100) +
      (var(--usePercentWidth) * ${p.mRightvw}vw)
  )`};
  background: ${(p) => p.theme.colors.backgroundGrey};
`;

export const Row = styled.div`
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
  display: flex;
  flex-direction: row;
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  background: ${(p) => (p.bgColor ? p.bgColor : p.theme.colors.white)};
  margin: ${(p) => (p.margin ? p.margin : "0px")};
  ${(p) => p.setJustify && `justify-content: space-between`};
  width: ${(p) => (p.width ? p.width : "75%")};
  box-shadow: ${(p) =>
    p.shadow ? p.shadow : "0px 2px 6px rgba(0, 0, 0, 0.2)"};
  border-radius: ${(p) =>
    p.borderRadius
      ? p.theme.borderRadius[p.borderRadius]
      : p.theme.borderRadius.sm};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.xs};
  ${(p) =>
    p.gap &&
    `gap: calc(
    (
      (var(--useMinWidth) * var(--widthLimit) * ${p.gap} / 100) +
        (var(--usePercentWidth) * ${p.gap}vw)
    )
  )`};
  ${(p) => p.height && `height:${p.height}`};
  ${(p) =>
    p.heightvh &&
    `height:calc((var(--useMin) * var(--heightLimit) * ${p.heightvh} / 100) + (var(--usePercent) * ${p.heightvh}vh))`};
  ${(p) => p.setPointer && `cursor:pointer`};
`;

export const Cell = styled.div`
  ${(p) =>
    !p.nowrap &&
    `text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;`}

  ${(p) =>
    p.justify &&
    `display: flex;
  justify-content: ${p.justify};`}
  align-self: center;
  padding: calc(
      (
          var(--useMin) * var(--heightLimit) *
            ${(p) => (p.vPadding ? p.vPadding : "0.84")} / 100
        ) + (var(--usePercent) * ${(p) => (p.vPadding ? p.vPadding : "0.84")}vh)
    )
    calc(
      (
          var(--useMinWidth) * var(--widthLimit) *
            ${(p) => (p.hPadding ? p.hPadding : "0.84")} / 100
        ) +
        (
          var(--usePercentWidth) *
            ${(p) => (p.hPadding ? p.hPadding : "0.84")}vw
        )
    );
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.sm};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.light)};
  width: ${(p) => (p.width ? p.width : "fit-content")};
  height: ${(p) => (p.height ? p.height : "")};
  ${(p) => p.color && `color: ${p.theme.colors[p.color]}`};
  visibility: ${(p) => (p.visibility ? p.visibility : "visible")};
  ${(p) => p.setPointer && `cursor: pointer`};
`;

export const DataCell = ({
  width,
  value,
  color,
  nowrap,
  vPadding,
  tooltipValue,
}) => {
  const tooltip = tooltipValue ? tooltipValue : String(value);
  const el = React.useRef(undefined);

  return (
    <>
      <Cell
        color={color}
        ref={el}
        data-tip
        data-for={tooltip}
        width={width}
        vPadding={vPadding}
        nowrap={nowrap}
      >
        {value}
      </Cell>
      <Tooltip el={el} value={tooltip} textColor={"black"} />
    </>
  );
};

export const HeaderCell = ({
  onClick,
  isSelected,
  isAscending,
  heading,
  width,
  textAlign,
}) => {
  const { t } = useTranslation();
  const el = React.useRef(undefined);
  heading = t(heading).toUpperCase();

  return (
    <>
      <Cell
        onClick={onClick}
        setPointer={true}
        font={isSelected ? "medium" : "light"}
        fontSize={"xs"}
        textAlign={textAlign ? textAlign : "left"}
        width={width}
        vPadding={"1.67"}
        ref={el}
        data-tip
        data-for={heading}
      >
        {heading}
        <Spacing setInline={true} mLeft={"6px"}>
          <ArrowIcon
            width="13"
            height="10"
            color={isSelected ? "black" : "textDarkGrey"}
            type={isSelected ? (isAscending === 1 ? "down" : "up") : "down"}
            strokeWidth="1"
          />
        </Spacing>
      </Cell>
      <Tooltip el={el} value={heading} textColor={"black"} />
    </>
  );
};
