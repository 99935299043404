import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserController from "../../network/UserController";
import { useStore } from "../../../../store/Store";
import {
  Spacing,
  InputWithError,
  Loader,
  PopUpHeading,
  PopUpTemplate,
  PopUpButtons,
} from "../../..";

const ChangePasswordPopUp = ({ setShowPopUp }) => {
  const { t } = useTranslation();
  const [{ auth }] = useStore();
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const updateClicked = () => {
    var oldPasswordError = "",
      newPasswordError = "",
      confirmNewPasswordError = "";
    var hasError = false;
    if (values.oldPassword.length === 0) {
      hasError = true;
      oldPasswordError = t("login-requiredField");
    }
    if (values.newPassword.length === 0) {
      newPasswordError = t("login-requiredField");
      hasError = true;
    }
    if (values.newPassword !== values.confirmNewPassword) {
      hasError = true;
      confirmNewPasswordError = t("passwordMismatchError");
    }
    setErrors({
      oldPassword: oldPasswordError,
      newPassword: newPasswordError,
      confirmNewPassword: confirmNewPasswordError,
    });
    if (hasError) {
      return;
    }
    var data = {
      email: auth.user.email,
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
      updatePassword: true,
    };
    setLoading(true);
    UserController.user.changePassword(data, (res) => {
      setLoading(false);
      if (res.data.status === 203) {
        setErrors({
          oldPassword: t("incorrectPassword"),
          newPassword: "",
          confirmNewPassword: "",
        });
      } else if (res.data.status === 200) {
        setShowPopUp(false);
      }
    });
  };

  return (
    <>
      {loading && <Loader />}
      <PopUpTemplate setShowPopUp={setShowPopUp} widthvw="26.35">
        <Spacing paddingvw={"3.75"}>
          <PopUpHeading top={"sm"} textAlign={"left"}>
            {t("changePassword")}
          </PopUpHeading>
          <Spacing mTopvh="4.17" />
          <InputWithError
            helperText={errors.oldPassword}
            error={errors.oldPassword !== ""}
            value={values.oldPassword}
            onChange={handleChange}
            name={"oldPassword"}
            width={"100%"}
            label={t("oldPassword")}
            type={values.oldPassword ? "password" : ""}
          />
          <InputWithError
            helperText={errors.newPassword}
            error={errors.newPassword !== ""}
            value={values.newPassword}
            onChange={handleChange}
            name={"newPassword"}
            width={"100%"}
            label={t("newPassword")}
            type={values.newPassword ? "password" : ""}
          />
          <InputWithError
            isLast={true}
            helperText={errors.confirmNewPassword}
            error={errors.confirmNewPassword !== ""}
            value={values.confirmNewPassword}
            onChange={handleChange}
            name={"confirmNewPassword"}
            width={"100%"}
            label={t("confirmNewPassword")}
            type={values.confirmNewPassword ? "password" : ""}
          />
          <PopUpButtons
            confirmText="changePassword"
            onConfirmClick={() => {
              updateClicked();
            }}
          />
        </Spacing>
      </PopUpTemplate>
    </>
  );
};

export default ChangePasswordPopUp;
