import { SessionManager } from "../../utils";

export const UpdateCellInformation = (cellID, data, metadata) => {
  if (
    data.ProcessType === "Home" ||
    data.ProcessType === "Maintenance" ||
    data.ProcessType === "Transport"
  ) {
    data.ProcessType = "Command";
  }

  if (data.ProcessType === "Fabrication" && data.Status === "Success") {
    if (SessionManager.jobHistory[cellID]) {
      SessionManager.jobHistory[cellID]["6"] += 1;
    } else {
      SessionManager.jobHistory[cellID] = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 1,
      };
    }
    SessionManager.cells[cellID].Usage.TotalJobs += 1;
    SessionManager.cells[cellID].Usage.CutLength =
      parseFloat(metadata.CutLength) +
      parseFloat(SessionManager.cells[cellID].Usage.CutLength);
    SessionManager.cells[cellID].Usage.NumberOfCuts += parseInt(
      metadata.NumberOfCuts
    );
    SessionManager.cells[cellID].Usage.NumberOfParts += parseInt(
      metadata.NumberOfParts
    );
    if (SessionManager.cells[cellID].Usage.DailyUsage) {
      SessionManager.cells[cellID].Usage.DailyUsage.Cuts += parseInt(
        metadata.NumberOfCuts
      );
      SessionManager.cells[cellID].Usage.DailyUsage.Parts += parseInt(
        metadata.NumberOfParts
      );
      SessionManager.cells[cellID].Usage.DailyUsage.CutLength =
        parseFloat(SessionManager.cells[cellID].Usage.DailyUsage.CutLength) +
        parseFloat(metadata.CutLength);
    }
  } else if (
    data.ProcessType === "Command" &&
    metadata.CommandType === "Maintenance" &&
    metadata.BladesChanged
  ) {
    if (metadata.BladesChanged.New === true) {
      SessionManager.cells[cellID].Usage.BladesChanged.New += 1;
    } else if (metadata.BladesChanged.Old === true) {
      SessionManager.cells[cellID].Usage.BladesChanged.Old += 1;
    }
  }
};
