import React from "react";
import { SettingsAccordion } from "../../../../../styles";
import { RubberMatIcon } from "../../../../../icons";

const AdvancedSettings = ({ settings, show, heading, setExpand }) => {
  const data = {
    "cell-settingsAdvancedRubber": settings
      ? settings.MatSelector === "1"
        ? "On"
        : "Off"
      : "-",
    "cell-settingsAdvancedHeight": settings
      ? settings.MatHeight
        ? settings.MatHeight + " mm"
        : "-"
      : "-",
  };

  return (
    <SettingsAccordion
      expand={"advanced"}
      iconImage={<RubberMatIcon width="100%" height="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default AdvancedSettings;
