import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  AppHeading,
  InputWithError,
  Spacing,
  A,
  Loader,
  FormHeading,
  Form,
  GrayPageContainer,
  FlexContainer,
  InlineText,
  CenteredContent,
  ShadowDiv,
} from "../..";
import * as Routes from "../../../routes/Routes";
import { useState, useEffect } from "react";
import UserController from "../network/UserController";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const query = new URLSearchParams(useLocation().search);
  const { t } = useTranslation();
  const history = useHistory();
  const [resetResponse, setResetResponse] = useState("");
  const [values, setValues] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  useEffect(() => {
    UserController.user.resetAPI(
      { resetPasswordToken: params.token, email: query.get("email") },
      (res) => {
        if (res.data.status === 200) {
        } else {
          if (res.data.status === 201) {
            setResetResponse(t("forgotPassword-linkNotValid"));
          } else {
            setResetResponse(t("forgotPassword-invalidToken"));
          }
        }
      }
    );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    var passwordRegEx = new RegExp(
      /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/
    );
    var hasError = false;
    var newPasswordError = "";
    var confirmNewPasswordError = "";
    if (values.newPassword === "") {
      hasError = true;
      newPasswordError = t("login-requiredField");
    } else if (values.newPassword.length < 8) {
      hasError = true;
      newPasswordError = t("invalidPasswordLength");
    } else if (!passwordRegEx.test(values.newPassword)) {
      hasError = true;
      newPasswordError = t("register-invalidPassword");
    }
    if (values.confirmNewPassword === "") {
      hasError = true;
      confirmNewPasswordError = t("login-requiredField");
    } else if (values.confirmNewPassword !== values.newPassword) {
      hasError = true;
      confirmNewPasswordError = t("passwordMismatchError");
    }
    setErrors({
      newPassword: newPasswordError,
      confirmNewPassword: confirmNewPasswordError,
    });
    if (hasError) {
      return;
    }
    const userDetails = {
      email: query.get("email"),
      password: values.newPassword,
      resetPasswordToken: params.token,
    };
    setLoading(true);
    UserController.user.resetPassword(userDetails, (res) => {
      if (res.data.status === 200) {
        setResetResponse("");
        setSuccess(true);
      } else {
        if (res.data.status === 201) {
          setResetResponse(t("forgotPassword-linkNotValid"));
        } else {
          setResetResponse(t("forgotPassword-invalidToken"));
        }
      }
      setLoading(false);
    });
  };
  return (
    <>
      {loading && <Loader />}
      <GrayPageContainer>
        <AppHeading redirection={Routes.LOGIN} />
        <CenteredContent>
          <FlexContainer>
            <Spacing margin="auto" width="500px">
              <Spacing mTopvh="10" />
              <ShadowDiv
                width={"fit-content"}
                margin={"auto"}
                padding="14.4%"
              >
                {resetResponse === "" ? (
                  <Form onSubmit={SubmitForm}>
                    {!success && (
                      <FormHeading textAlign={"left"}>
                        {t("resetPassword")}
                      </FormHeading>
                    )}
                    {!success ? (
                      <Spacing mTopvh={"3.7"}>
                        <InputWithError
                          type={"password"}
                          onChange={handleChange}
                          value={values.newPassword}
                          helperText={errors.newPassword}
                          error={errors.newPassword !== ""}
                          name={"newPassword"}
                          width={"100%"}
                          label={t("newPassword")}
                        />
                        <InputWithError
                          isLast={true}
                          type={"password"}
                          onChange={handleChange}
                          value={values.confirmNewPassword}
                          helperText={errors.confirmNewPassword}
                          error={errors.confirmNewPassword !== ""}
                          name={"confirmNewPassword"}
                          width={"100%"}
                          label={t("confirmNewPassword")}
                        />
                        <Spacing mBottomvh={"2.22"} mTopvh={"2.69"}>
                          <Button type={"submit"}>
                            {t("resetPassword")}
                          </Button>
                        </Spacing>
                        <A fontSize={"md"} font={"regular"} to={Routes.LOGIN}>
                          {t("buttons-backToLogin")}
                        </A>
                      </Spacing>
                    ) : (
                      <Spacing>
                        <FormHeading>
                          {t("resetPassword-successHeading")}
                        </FormHeading>
                        <Spacing mTopvh={"3.7"}>
                          <InlineText>
                            {t("resetPassword-successMsg")}
                          </InlineText>
                        </Spacing>
                        <Spacing mTopvh={"3.7"}>
                          <Button onClick={() => history.push(Routes.LOGIN)}>
                            {t("buttons-continueLogin")}
                          </Button>
                        </Spacing>
                      </Spacing>
                    )}
                  </Form>
                ) : (
                  <Spacing>
                    <FormHeading>{t("resetPassword-errorHeading")}</FormHeading>
                    <Spacing mTopvh={"3.7"}>
                      <InlineText>{resetResponse}</InlineText>
                    </Spacing>
                    <Spacing mTopvh={"3.7"}>
                      <Button onClick={() => history.push(Routes.LOGIN)}>
                        {t("buttons-continueLogin")}
                      </Button>
                    </Spacing>
                  </Spacing>
                )}
              </ShadowDiv>
              <Spacing mBottomvh="10" />
            </Spacing>
          </FlexContainer>
        </CenteredContent>
      </GrayPageContainer>
    </>
  );
};

export default ResetPassword;
