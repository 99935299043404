import JWT from "jsonwebtoken";
// Added to avoid the eslint error in mapbox package
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

var manager = null;

class SessionManager {
  constructor() {
    this.appHeading = "fms-application";
    this.t = null;
    this.colors = null;
    this.mqttConnected = false;
    this.jobHistory = {};
    this.subscribed = false;
    this.mapPopUp = new mapboxgl.Popup({
      className: "map-popup",
      closeOnClick: false,
    }).setMaxWidth("200px");
    this.mapCenter = { lng: 10.38846, lat: 55.39806 };
    this.map = null;
    this.token = sessionStorage.getItem("token");
    this.decodedToken = JWT.decode(this.token, { complete: true });
    this.IOTEventListener = null;
    this.realtimeLogsCallback = null;
    this.mqttClient = null;
    this.clientID = null;
    this.language = "";
    this.email = "";
    this.users = null;
    this.userData = JSON.parse(sessionStorage.getItem("UserData"));
    this.activeLocations = { activeLocations: 0, activeCities: 0 };
    this.cells = null;
    this.cellsShadows = JSON.parse(sessionStorage.getItem("cellsShadows"));
    this.statusUpdater = {};
    this.sessionChecker = null;
    this.showSessionExpiredPopUp = null;

    if (this.userData) {
      this.startSessionValidityCheck();
    }
  }
  startSessionValidityCheck = () => {
    this.sessionChecker = setInterval(this.checkSessionValidity, 60000);
  };
  checkSessionValidity = () => {
    var currentTime = new Date().getTime() / 1000;
    if (
      this.decodedToken !== null &&
      this.decodedToken.payload.exp - currentTime < 60
    ) {
      this.showSessionExpiredPopUp(true);
    }
  };

  initialiseMap = (container) => {
    this.map = new mapboxgl.Map({
      container: "world-map",
      style: process.env.REACT_APP_MAPBOX_STYLE,
      center: [this.mapCenter.lng, this.mapCenter.lat],
      attributionControl: false,
      minZoom: 0.35,
      dragRotate: false,
    })
      .addControl(new mapboxgl.NavigationControl())
      .fitBounds(
        [
          [0.00136, 45.15708],
          [18.70136, 68.75708],
        ],
        { duration: 0 }
      );
  };
}

if (manager === null) {
  manager = new SessionManager();
}

export default manager;
