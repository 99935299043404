import React from "react";
import { SettingsAccordion } from "../../../../../styles";
import { SpeedIcon } from "../../../../../icons";

const SpeedSettings = ({ settings, show, heading, setExpand }) => {
  const data = {
    "settings-speedCut": settings ? settings.CutSpeed + " mm/s" : "-",
    "settings-speedDown": settings ? settings.DownSpeed + " mm/s" : "-",
    "settings-speedUp": settings ? settings.UpSpeed + " mm/s" : "-",
    "settings-speedLead": settings ? settings.LeadSpeed + " mm/s" : "-",
    "settings-downSpeedReduction": settings
      ? settings.DownSpeedReduction + " mm/s"
      : "-",
  };

  return (
    <SettingsAccordion
      expand={"speed"}
      iconImage={<SpeedIcon width="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default SpeedSettings;
