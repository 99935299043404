import styled from "styled-components";

export const Text = styled.div`
  ${(p) =>
    p.wrapText &&
    `text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;`}
  background-color: ${(p) => (p.bgColor ? p.bgColor : "rgba(0,0,0,0.0)")};
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "5%")};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
  float: ${(p) => (p.float ? p.float : "left")};
  margin-top: ${(p) => (p.mTop ? p.mTop : "0px")};
  ${(p) =>
    p.mTopvh &&
    `margin-top: calc((var(--useMin) * var(--heightLimit) * ${p.mTopvh} / 100) + (var(--usePercent) * ${p.mTopvh}vh))`};
  margin-left: ${(p) => (p.mLeft ? p.mLeft : "0px")};
  margin-right: ${(p) => (p.mRight ? p.mRight : "0px")};
  margin-bottom: ${(p) => (p.mBottom ? p.mBottom : "0px")};
  ${(p) =>
    p.mBottomvh &&
    `margin-bottom:calc((var(--useMin) * var(--heightLimit) *  ${p.mBottomvh}/ 100) +(var(--usePercent) *${p.mBottomvh} vh))`};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.md};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : "black")};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.light)};
  ${(p) => p.setPointer && `cursor:pointer`};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "1.6")};
  ${(p) =>
    p.mLeftvw &&
    ` margin-left:calc(
    (var(--useMinWidth) * var(--widthLimit) * ${p.mLeftvw} / 100) +
      (var(--usePercentWidth) * ${p.mLeftvw}vw)
  )`}
  ${(p) => p.alignSelf && `align-self:${p.alignSelf}`};
  ${(p) => p.flex && `display:flex`};
`;

export const RoundedBorderText = styled.div`
  width: fit-content;
  ${(p) => p.mLeft && `margin-left:${p.mLeft}`};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.regular)};
  ${(p) => p.mRight && `margin-right:${p.mRight}`};
  border: ${(p) => (p.borderWeight ? p.borderWeight : "2px")} solid
    ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.green)};
  ${(p) => p.fontSize && `font-size:${p.fontSize}`};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.green)};
  border-radius: ${(p) => p.theme.borderRadius.sm};
  padding: ${(p) => (p.padding ? p.padding : "10px 34px 10px 34px")};
`;

export const InlineText = styled.div`
  ${(p) =>
    p.nowrap &&
    `text-overflow: ellipsis;
  white-space: nowrap; 
overflow: hidden;`}
  ${(p) =>
    p.wordBreak &&
    `word-wrap: break-word;
   white-space: pre-wrap;
 `}
  color: ${(p) =>
    p.color ? p.theme.colors[p.color] : p.theme.colors.textGrey};
  ${(p) => p.setInline && `display:inline-block`};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.md};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.light)};
  ${(p) => p.lineHeight && `line-height:${p.lineHeight}`};
  ${(p) => p.alignSelf && `align-self:${p.alignSelf}`};
  ${(p) => p.letterSpacing && `letter-spacing: ${p.letterSpacing}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.minWidth && `min-width:${p.minWidth}`};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "inherited")};
`;

export const Pill = styled.div`
  ${(p) =>
    p.nowrap &&
    `text-overflow: ellipsis;
      white-space: nowrap; 
      overflow: hidden;`}
  width: ${(p) =>
    p.widthvw
      ? `calc(
    (var(--useMinWidth) * 1250px * ${p.widthvw} / 100) +
    (var(--usePercentWidth) * ${p.widthvw}vw)
    )`
      : "auto"};
  color: ${(p) => p.theme.colors[p.fontColor]};
  text-align: center;
  height: fit-content;
  padding: ${(p) =>
    p.padding
      ? p.padding
      : `calc(
    (var(--useMin) * var(--heightLimit) * 0.74 / 100) +
      (var(--usePercent) * 0.74vh)
  ) calc(
    (var(--useMinWidth) * var(--widthLimit) * 1.05 / 100) +
      (var(--usePercentWidth) * 1.05vw)
  )`};
  background: ${(p) => p.theme.colors[p.color]};
  border-radius: ${(p) => p.theme.borderRadius.md};
  font-size: ${(p) => p.theme.fontSize.xs};
  font-family: ${(p) => p.theme.font.light};
`;
