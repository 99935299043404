import React from "react";
import { useTranslation } from "react-i18next";
import { FormRow, Spacing, InlineText } from "../commonalities";

const PropertyCard = ({ name, value, mTop, fontSize, minWidth }) => {
  const { t } = useTranslation();
  return (
    <>
      <Spacing mTopvh={mTop ? mTop : "1.02"} />
      <FormRow>
        <InlineText fontSize={fontSize ? fontSize : "xs"} color="textDarkGrey">
          {t(name)}
        </InlineText>
        <InlineText
          textAlign="right"
          minWidth={minWidth ? minWidth : "30%"}
          fontSize={fontSize ? fontSize : "xs"}
          color="black"
        >
          {value}
        </InlineText>
      </FormRow>
    </>
  );
};

export default PropertyCard;
