import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserController from "../network/UserController";
import { SessionManager } from "../../../utils";
import {
  Button,
  AppHeading,
  InputWithError,
  Spacing,
  A,
  Loader,
  PhoneNumberInput,
  ShadowDiv,
  FormHeading,
  CenteredContent,
  GrayPageContainer,
  FlexContainer,
  FormRow,
  Cell,
  Form,
  InlineText,
  Span,
  FormContainer,
  HalfPageLevelImg,
} from "../..";
import { useState } from "react";
import * as Routes from "../../../routes/Routes";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js";

const Register = ({ image }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [emailResent, setEmailResent] = useState(null);
  const [phoneCode, setPhoneCode] = useState({
    code: "DK",
    dialCode: "+45",
  });

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    emailID: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companyAddress: "",
    phoneNumber: "",
  });
  const [stage, setStage] = useState("register");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    emailID: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companyAddress: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handlePhoneNumberChange = (e) => {
    const number = e.target.value.slice(phoneCode.dialCode.length + 1).trim();
    const isValidNumber = /^\d+$/.test(number);
    if (number !== "" && !isValidNumber) {
      return;
    }
    setValues({
      ...values,
      ["phoneNumber"]: e.target.value,
    });
  };

  const resendEmail = () => {
    var userDetails = {
      email: values.emailID,
      resendMail: true,
      language: SessionManager.language,
      verificationLink: window.location.href.replace(Routes.REGISTER, ""),
    };
    setLoading(true);
    UserController.user.registerUser(
      {
        userDetails: userDetails,
        redirect: window.location.href.replace(Routes.REGISTER, Routes.LOGIN),
      },
      (res) => {
        setLoading(false);
        if (res.data.status === 201) {
          setEmailResent(t("accountAlreadyVerified"));
        } else if (res.data.status === 202) {
          setTimeout(() => {
            setEmailResent(null);
          });
          setEmailResent(t("noUserExists"));
        } else {
          setEmailResent(t("emailResent"));
        }
      }
    );
  };

  useEffect(() => {
    setErrors({
      emailID: emailResent ? (
        <>
          {t("userNotVerified")}
          <Span
            setPointer={emailResent ? false : true}
            font={emailResent ? "light" : "bold"}
            onClick={() => {
              if (!emailResent) {
                resendEmail();
              }
            }}
            color={emailResent ? "green" : "orange"}
            margin="4px"
          >
            {emailResent ? emailResent : t("login-resendMail")}
          </Span>
        </>
      ) : (
        ""
      ),
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      companyName: "",
      companyAddress: "",
      phoneNumber: "",
    });
  }, [emailResent]);

  const SubmitForm = (e) => {
    e.preventDefault();
    var emailRegEx = new RegExp(/\S+@\S+\.\S+/);
    var passwordRegEx = new RegExp(
      /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/
    );
    var nameRegEx = new RegExp(/^[a-zA-Z]+$/);
    var firstNameError = "",
      lastNameError = "",
      emailIDError = "",
      passwordError = "",
      confirmPasswordError = "",
      companyNameError = "",
      companyAddressError = "",
      phoneNumberError = "";
    var hasError = false;
    if (values.firstName === "") {
      hasError = true;
      firstNameError = t("login-requiredField");
    } else if (!nameRegEx.test(values.firstName)) {
      hasError = true;
      firstNameError = t("register-invalidName");
    }
    if (values.lastName === "") {
      hasError = true;
      lastNameError = t("login-requiredField");
    } else if (!nameRegEx.test(values.lastName)) {
      hasError = true;
      lastNameError = t("register-invalidName");
    }
    if (!emailRegEx.test(values.emailID)) {
      hasError = true;
      emailIDError = t("invalidEmailAddress");
    }
    if (values.emailID === "") {
      hasError = true;
      emailIDError = t("login-requiredField");
    } else if (!emailRegEx.test(values.emailID)) {
      hasError = true;
      emailIDError = t("invalidEmailAddress");
    }
    if (values.password === "") {
      hasError = true;
      passwordError = t("login-requiredField");
    } else if (values.password.length < 8) {
      hasError = true;
      passwordError = t("invalidPasswordLength");
    } else if (!passwordRegEx.test(values.password)) {
      hasError = true;
      passwordError = t("register-invalidPassword");
    }
    if (values.companyName === "") {
      hasError = true;
      companyNameError = t("login-requiredField");
    }
    if (values.confirmPassword === "") {
      hasError = true;
      confirmPasswordError = t("login-requiredField");
    } else if (values.confirmPassword !== values.password) {
      hasError = true;
      confirmPasswordError = t("passwordMismatchError");
    }
    const phoneNumber = parsePhoneNumber(values.phoneNumber, phoneCode.code);
    const phoneLength = values.phoneNumber.split(" ").length;
    if (phoneLength === 1) {
      values.phoneNumber = "";
    } else if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
      hasError = true;
      phoneNumberError = t("register-invalidNumber");
    }

    setErrors({
      firstName: firstNameError,
      lastName: lastNameError,
      emailID: emailIDError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
      companyName: companyNameError,
      companyAddress: companyAddressError,
      phoneNumber: phoneNumberError,
    });
    if (hasError) {
      return;
    }
    var userDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailID,
      password: values.password,
      confirmPassword: values.confirmPassword,
      companyName: values.companyName,
      companyAddress: values.companyAddress,
      phoneNumber: values.phoneNumber,
      userType: "Normal",
      verificationLink: window.location.href.replace(Routes.REGISTER, ""),
    };
    userDetails["creationDate"] = new Date();
    userDetails["language"] = i18n.language;
    setLoading(true);
    UserController.user.registerUser(
      {
        userDetails: userDetails,
        redirect: window.location.href.replace(Routes.REGISTER, Routes.LOGIN),
      },
      (res) => {
        setLoading(false);
        if (res.data.status === 201) {
          if (!res.data.isVerified) {
            emailIDError = (
              <>
                {t("userNotVerified")}
                <Span
                  setPointer={true}
                  font={emailResent ? "light" : "bold"}
                  onClick={resendEmail}
                  color={emailResent ? "green" : "orange"}
                  margin="4px"
                >
                  {emailResent ? emailResent : t("resendEmail")}
                </Span>
              </>
            );
          } else {
            emailIDError = t("duplicateEmailError");
          }
        } else if (res.data.status === 200) {
          setStage("response");
        }
        setErrors({
          firstName: firstNameError,
          lastName: lastNameError,
          emailID: emailIDError,
          password: passwordError,
          confirmPassword: confirmPasswordError,
          companyName: companyNameError,
          companyAddress: companyAddressError,
          phoneNumber: phoneNumberError,
        });
      }
    );
  };
  return (
    <>
      {loading && <Loader />}
      <GrayPageContainer>
        <AppHeading redirection={Routes.LOGIN} />
        <CenteredContent textAlign={"left"}>
          <FlexContainer width="100vw" minWidth="1250px">
            {stage === "register" && (
              <>
                <HalfPageLevelImg src={image} />
                <FormContainer>
                  <Form
                    noValidate={true}
                    onSubmit={(e) => {
                      e.preventDefault();
                      SubmitForm(e);
                    }}
                  >
                    <Spacing margin="auto">
                      <Spacing mTopvh="3" mBottomvh="3">
                        <FormHeading>{t("buttons-register")}</FormHeading>
                        <Spacing mTopvh={"3.7"} />
                        <FormRow>
                          <InputWithError
                            helperText={errors.firstName}
                            error={errors.firstName !== ""}
                            value={values.firstName}
                            onChange={handleChange}
                            name={"firstName"}
                            required={true}
                            inline={"true"}
                            width={"100%"}
                            label={t("register-firstName")}
                          />
                          <Cell width={"6%"} />
                          <InputWithError
                            helperText={errors.lastName}
                            error={errors.lastName !== ""}
                            value={values.lastName}
                            onChange={handleChange}
                            name={"lastName"}
                            required={true}
                            width={"100%"}
                            label={t("register-lastName")}
                          />
                        </FormRow>
                        <InputWithError
                          helperText={errors.emailID}
                          error={errors.emailID !== ""}
                          value={values.emailID}
                          onChange={handleChange}
                          name={"emailID"}
                          required={true}
                          width={"100%"}
                          label={t("register-email")}
                        />
                        <FormRow>
                          <InputWithError
                            helperText={errors.password}
                            error={errors.password !== ""}
                            value={values.password}
                            onChange={handleChange}
                            name={"password"}
                            required={true}
                            inline={"true"}
                            width={"100%"}
                            label={t("password")}
                            type={"password"}
                          />
                          <Cell width={"6%"} />
                          <InputWithError
                            helperText={errors.confirmPassword}
                            error={errors.confirmPassword !== ""}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            name={"confirmPassword"}
                            required={true}
                            width={"100%"}
                            label={t("register-confirmPassword")}
                            type={"password"}
                          />
                        </FormRow>
                        <InputWithError
                          helperText={errors.companyName}
                          error={errors.companyName !== ""}
                          value={values.companyName}
                          onChange={handleChange}
                          name={"companyName"}
                          required={true}
                          width={"100%"}
                          label={t("register-companyName")}
                        />
                        <InputWithError
                          helperText={errors.companyAddress}
                          error={errors.companyAddress !== ""}
                          value={values.companyAddress}
                          onChange={handleChange}
                          name={"companyAddress"}
                          width={"100%"}
                          label={t("register-companyAddress")}
                        />
                        <FormRow>
                          <PhoneNumberInput
                            helperText={errors.phoneNumber}
                            error={errors.phoneNumber !== ""}
                            value={values.phoneNumber
                              .slice(phoneCode.dialCode.length + 1)
                              .trim()}
                            onChange={(e) => {
                              handlePhoneNumberChange(e);
                            }}
                            setPhoneCode={setPhoneCode}
                            name={"phoneNumber"}
                            width={"47%"}
                            maxHeight={"150px"}
                            containerWidth={"16"}
                            inline={true}
                            label={t("register-phoneNumber")}
                          />
                        </FormRow>
                        <Spacing mTopvh={"2.69"} mBottom={"46px"}>
                          <Button type={"submit"}>
                            {t("buttons-register")}
                          </Button>
                        </Spacing>
                        <Spacing
                          setInline={true}
                          fontSize={"md"}
                          color={"textGrey"}
                        >
                          {t("register-accountAlreadyExists")}
                        </Spacing>
                        <Spacing mLeft={"4px"} setInline={true}>
                          <A
                            fontSize={"md"}
                            color={"orange"}
                            font={"bold"}
                            to={Routes.LOGIN}
                          >
                            {t("buttons-login")}
                          </A>
                        </Spacing>
                      </Spacing>
                    </Spacing>
                  </Form>
                </FormContainer>
              </>
            )}
          </FlexContainer>
        </CenteredContent>
        {stage === "response" && (
          <CenteredContent>
            <FlexContainer>
              <Spacing margin="auto" width="500px">
                <Spacing mTopvh="10" />
                <ShadowDiv
                  width={"fit-content"}
                  margin={"auto"}
                  padding="12.5%"
                >
                  <Form>
                    <FormHeading fontSize={"xl"} font={"semibold"}>
                      {t("register-successHeading")}
                    </FormHeading>
                    <Spacing mTopvh={"3.7"}>
                      <InlineText>{t("register-successMsg1")}</InlineText>
                      <Spacing mTopvh={"2.4"} setInline={"true"}>
                        <InlineText font="regular" color="black">
                          {values.emailID}
                        </InlineText>
                      </Spacing>
                      <Spacing mTopvh={"2.4"} mLeft={"5px"} setInline={"true"}>
                        <InlineText>{t("register-successMsg2")}</InlineText>
                      </Spacing>
                    </Spacing>
                    <Spacing mTopvh={"2.4"}>
                      <InlineText>{t("register-successMsg3")}</InlineText>
                    </Spacing>
                    <Spacing mTopvh={"3.7"}>
                      <Button onClick={() => history.push(Routes.LOGIN)}>
                        {t("buttons-continueLogin")}
                      </Button>
                      <Spacing
                        setPointer={emailResent ? false : true}
                        onClick={() => {
                          if (emailResent == null) {
                            resendEmail();
                          }
                        }}
                        mTopvh={"2.22"}
                      >
                        <InlineText
                          font={"regular"}
                          color={emailResent ? "green" : "textDarkGrey"}
                        >
                          {emailResent
                            ? emailResent.replace(".", "")
                            : t("register-resendVerificationMail")}
                        </InlineText>
                      </Spacing>
                    </Spacing>
                  </Form>
                </ShadowDiv>
                <Spacing mBottomvh="10" />
              </Spacing>
            </FlexContainer>
          </CenteredContent>
        )}
      </GrayPageContainer>
    </>
  );
};

export default Register;
