import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 * @param {string} strokeWidth
 */

export const PlusIcon = ({ width, color, margin, strokeWidth }) => {
  const WIDTH = width || "16";
  const COLOR = theme.colors[color] || theme.colors.black;
  const STROKE_WIDTH = strokeWidth || "2px";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={WIDTH}
      height={WIDTH}
      viewBox="0 0 16 16"
      style={{ margin: margin }}
    >
      <g
        id="Group_3319"
        data-name="Group 3319"
        transform="translate(-1518 -729)"
      >
        <line
          id="Line_344"
          data-name="Line 344"
          fill="none"
          stroke={COLOR}
          strokeLinecap="round"
          strokeWidth={STROKE_WIDTH}
          y2="12"
          transform="translate(1526 731)"
        />
        <line
          id="Line_345"
          data-name="Line 345"
          fill="none"
          stroke={COLOR}
          strokeLinecap="round"
          strokeWidth={STROKE_WIDTH}
          x2="12"
          transform="translate(1520 737)"
        />
      </g>
    </svg>
  );
};
