import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NetworkController } from "../../../utils";
import { LogsAccordion } from "../../../styles";
import {
  Spacing,
  DropDownContainer,
  ScrollingContainer,
  InlineText,
} from "../../../commonalities";

const LogsDropDown = ({ shrink, id }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState("");
  const [logs, setLogs] = useState(null);

  useEffect(() => {
    NetworkController.cells.getLogsData({ id: id }, (res) => {
      setLogs(res.data.Items[0].Payload.Logs);
    });
  }, []);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  return (
    <Spacing mTopvw={"0.84"}>
      <DropDownContainer
        height={"350px"}
        shrink={shrink}
        pTopvw="1.67"
        pLeftvw="2.5"
      >
        <Spacing height="auto" mRight="auto">
          <InlineText font={logs ? "semibold" : "medium"} color="black">
            {logs ? t("cell-consoleLogs") : t("cell-loadingLogs")}
          </InlineText>
        </Spacing>
        <Spacing mTopvh="1.48" mRightvw="1.25" height="350px">
          <ScrollingContainer width="100%" mLeft="0px">
            {logs && logs.length > 0 ? (
              <>
                {typeof logs == "string" ? (
                  <InlineText lineHeight="24px" fontSize={"xs"} color="black">
                    {logs}
                  </InlineText>
                ) : (
                  logs.map((log, i) => {
                    let key = log.tstamp + "-" + i;
                    return (
                      <Spacing key={key} mRightvw={"1.67"}>
                        <LogsAccordion
                          id={key}
                          log={log}
                          handleChange={handleChange}
                          expanded={expanded}
                        />
                      </Spacing>
                    );
                  })
                )}
              </>
            ) : (
              logs && (
                <InlineText fontSize={"xs"} color="black">
                  {t("cell-logsUnavailable")}
                </InlineText>
              )
            )}
          </ScrollingContainer>
        </Spacing>
      </DropDownContainer>
    </Spacing>
  );
};

export default LogsDropDown;
