import SessionManager from "../../utils/SessionManager";
import * as Topics from "../IoTTopics";
import NetworkController from "../../utils/NetworkController";
import { UpdateCellInformation as UpdateCNMCellInfo } from "./CNMCallback";
import { UpdateCellInformation as UpdateEvaloCellInfo } from "./EvaloCallback";
import { UpdateCellInformation as UpdateCarpenCellInfo } from "./CarpenCallback";
import { UpdateCellShadows } from "./ShadowCallback";
import { getCellsFromServer } from "../../utils";

//function to set status of the cell without any flicker.
const setConnectionStatus = (status, cellID) => {
  if (SessionManager.cells[cellID]) {
    SessionManager.cells[cellID].ConnectionStatus = status;
    if (SessionManager.statusUpdater[cellID]) {
      return;
    }
    SessionManager.statusUpdater[cellID] = setTimeout(() => {
      delete SessionManager.statusUpdater[cellID];
      if (SessionManager.IOTEventListener != null) {
        if (
          SessionManager.cells[cellID] &&
          SessionManager.cells[cellID].Usage &&
          SessionManager.cells[cellID].Usage.OnlineStats
        ) {
          getCellsFromServer(() => {
            SessionManager.IOTEventListener(
              cellID,
              Topics.CONNECTED_TOPIC_SUB,
              SessionManager.cells[cellID].ConnectionStatus
            );
          }, SessionManager.t("fms-unavailable"));
        }
      }
    }, 100);
  }
};

//this function is called when data is published on any of the subscribed topics.
export const IoTTopicCallBack = async (topic, payload) => {
  var splitTopic = topic.split("/");

  //connection
  if (topic.includes(Topics.CONNECTED_TOPIC_SUB)) {
    var cellID = splitTopic[splitTopic.length - 1];
    console.log("Cell is online!");
    setConnectionStatus(true, cellID);
  }
  //disconnection
  else if (topic.includes(Topics.DISCONNECTED_TOPIC_SUB)) {
    var cellID = splitTopic[splitTopic.length - 1];
    console.log("Cell is offline!");
    setConnectionStatus(false, cellID);
  }
  //new process completed
  else if (topic.includes(Topics.PROCESS_COMPLETED_TOPIC)) {
    var data = JSON.parse(payload);
    var cellID = data.CellID;
    NetworkController.cells.getCell({ id: cellID }, (res) => {
      var cellType = res.data.Items[0].Type;
      var metadata =
        typeof data.Metadata === "string"
          ? JSON.parse(data.Metadata)
          : data.Metadata;
      if (cellType === "CNM") {
        UpdateCNMCellInfo(cellID, data, metadata);
      } else if (cellType === "Evalo"){
        UpdateEvaloCellInfo(cellID, data, metadata);
      } else{
        UpdateCarpenCellInfo(cellID, data, metadata);
      }
      if (SessionManager.IOTEventListener != null) {
        SessionManager.IOTEventListener(
          cellID,
          Topics.PROCESS_COMPLETED_TOPIC,
          data
        );
      }
    });
  }
  //shadow updated
  else {
    var recievedTopic = "";
    topic = topic.replace(Topics.AWS_TOPIC_STARTING, "");
    var splitTopic = topic.split("/");
    var cellID = splitTopic[0];
    var data = JSON.parse(payload);

    if (SessionManager.cells[cellID]) {
      recievedTopic = await UpdateCellShadows(
        cellID,
        topic,
        data.state.reported
      );
    }

    if (topic.includes(Topics.LOGS_GET_TOPIC_ACCEPTED)) {
      if (SessionManager.realtimeLogsCallback) {
        SessionManager.realtimeLogsCallback(data);
      }
    } else {
      if (SessionManager.IOTEventListener) {
        SessionManager.IOTEventListener(cellID, recievedTopic);
      }
    }
    sessionStorage.setItem(
      "cellsShadows",
      JSON.stringify(SessionManager.cellsShadows)
    );
  }
};
