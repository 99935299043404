import React from "react";
import { useTranslation } from "react-i18next";
import { SettingsAccordion } from "../../../../../styles";
import { FixtureIcon } from "../../../../../icons";

const FixtureSettings = ({ settings, show, heading, setExpand }) => {
  const { t } = useTranslation();
  const data = {
    "cell-settingsFixture": settings
      ? settings.Fixture
        ? settings.Fixture === "1"
          ? t("cell-singleSlot")
          : settings.Fixture + " " + t("cell-fixtureSlot")
        : "-"
      : "-",
  };

  return (
    <SettingsAccordion
      expand={"fixture"}
      iconImage={<FixtureIcon width="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default FixtureSettings;
