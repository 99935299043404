import React from "react";
import { appTheme as theme } from "../commonalities/Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const TileIcon = ({ fill, width, color, style }) => {
  const WIDTH = width || "24";
  const COLOR = theme.colors[color] || theme.colors.black;
  const FILL = fill ? theme.colors[color] : "none"; 

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      fill={COLOR}
      stroke={COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={style}
    >
      <g
        id="Tile_Dimensions_Icon"
        data-name="Tile Dimensions Icon"
        transform="translate(-1312 -544)"
      >
        <g
          id="Rectangle_135"
          data-name="Rectangle 135"
          transform="translate(1317 549)"
          fill="#fff"
          strokeWidth="1"
        >
          <rect width="14" height="14" stroke="none" />
          <rect
            x="0.5"
            y="0.5"
            width="13"
            height="13"
            fill={FILL}
          />
        </g>
        <g
          id="Group_1423"
          data-name="Group 1423"
          transform="translate(1317.143 544)"
        >
          <line
            id="Line_40"
            data-name="Line 40"
            x2="14"
            transform="translate(-0.143 1)"
            fill={FILL}
            strokeWidth="1"
          />
          <line
            id="Line_41"
            data-name="Line 41"
            y1="2.4"
            transform="translate(-0.143)"
            fill={FILL}
            strokeWidth="1"
          />
          <line
            id="Line_42"
            data-name="Line 42"
            y1="2.4"
            transform="translate(13.857)"
            fill={FILL}
            strokeWidth="1"
          />
        </g>
        <g
          id="Group_1424"
          data-name="Group 1424"
          transform="translate(1333.6 563.2) rotate(-90)"
        >
          <line
            id="Line_40-2"
            data-name="Line 40"
            x2="14.4"
            transform="translate(0 1.2)"
            fill={FILL}
            strokeWidth="1"
          />
          <line
            id="Line_41-2"
            data-name="Line 41"
            y1="2.4"
            fill={FILL}
            strokeWidth="1"
          />
          <line
            id="Line_42-2"
            data-name="Line 42"
            y1="2.4"
            transform="translate(14.4)"
            fill={FILL}
            strokeWidth="1"
          />
        </g>
        <g
          id="Group_1425"
          data-name="Group 1425"
          transform="translate(1331.2 568) rotate(180)"
        >
          <line
            id="Line_40-3"
            data-name="Line 40"
            x2="14.4"
            transform="translate(0 1.2)"
            fill={FILL}
            strokeWidth="1"
          />
          <line
            id="Line_41-3"
            data-name="Line 41"
            y1="2.4"
            transform="translate(0)"
            fill={FILL}
            strokeWidth="1"
          />
          <line
            id="Line_42-3"
            data-name="Line 42"
            y1="2.4"
            transform="translate(14.4)"
            fill={FILL}
            strokeWidth="1"
          />
        </g>
        <g
          id="Group_1426"
          data-name="Group 1426"
          transform="translate(1314.4 548.8) rotate(90)"
        >
          <line
            id="Line_40-4"
            data-name="Line 40"
            x2="14.4"
            transform="translate(0 1.2)"
            fill={FILL}
            strokeWidth="1"
          />
          <line
            id="Line_41-4"
            data-name="Line 41"
            y1="2.4"
            fill={FILL}
            strokeWidth="1"
          />
          <line
            id="Line_42-4"
            data-name="Line 42"
            y1="2.4"
            transform="translate(14.4)"
            fill={FILL}
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
