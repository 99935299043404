import React from "react";
import { Spacing, InlineText } from "../../../../commonalities";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";

const CarpenMiddleHeader = ({ data }) => {
  const { t } = useTranslation();
  const [processData, setProcessData] = useState({
    totalCutCount: 0,
    totalCutLength: 0,
    bladesChanged: 0,
    successfulJobs: 0,
    preemptedJobs: 0,
    erroneousJobs: 0,
  });

  useEffect(() => {
    var cutsCount = 0;
    var cutsLength = 0;
    var bladesChanged = 0;
    var successfulJobs = 0;
    var preemptedJobs = 0;
    var erroneousJobs = 0;

    data.forEach((item) => {
      if (item.Payload.ProcessType === "Fabrication") {
        if (item.Payload.Status === "Success") {
          var metadata = item.Payload.Metadata;
          cutsCount += parseFloat(metadata.NumberOfCuts);
          cutsLength += parseFloat(metadata.CutLength);
          successfulJobs += 1;
        } else if (
          item.Payload.Status === "Preempt" ||
          item.Payload.Status === "Cancelled"
        ) {
          preemptedJobs += 1;
        } else if (
          item.Payload.Status === "Error" ||
          item.Payload.Status === "Robot Error" ||
          item.Payload.Status === "Planning Error" ||
          item.Payload.Status === "Timed Out" ||
          item.Payload.Status === "Job Failed"
        ) {
          erroneousJobs += 1;
        }
      } else if (item.Payload.ProcessType === "Command") {
        var metadata = item.Payload.Metadata;
        if (metadata.CommandType === "Maintenance" && metadata.BladesChanged) {
          if (
            metadata.BladesChanged.New === true ||
            metadata.BladesChanged.Old === true
          ) {
            bladesChanged += 1;
          }
        }
      }
    });
    setProcessData({
      totalCutCount: cutsCount,
      totalCutLength: cutsLength,
      bladesChanged: bladesChanged,
      successfulJobs: successfulJobs,
      preemptedJobs: preemptedJobs,
      erroneousJobs: erroneousJobs,
    });
  }, [data]);

  return (
    <>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.totalCutCount ? processData.totalCutCount : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("totalCuts")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.totalCutLength
            ? (parseFloat(processData.totalCutLength) / 1000).toFixed(2) + " m "
            : "0 m"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("totalCutLength")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.bladesChanged ? processData.bladesChanged : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("bladeChanges")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.successfulJobs ? processData.successfulJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("successfulJobs")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.preemptedJobs ? processData.preemptedJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("preemptedJobs")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.erroneousJobs ? processData.erroneousJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("errors")}
        </InlineText>
      </Spacing>
    </>
  );
};

export default CarpenMiddleHeader;
