import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, Spacing, ArrowIcon } from "..";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import i18n from "../i18n";

const Slide = styled.div`
  display: flex;
  transition: "all 0.5s ease";
  overflow: "hidden";
  opacity: ${(p) => (p.current ? "1" : "0")};
  transform: ${(p) => (p.current ? "translateX(0)" : "translateX(-50%)")};
`;

const DotsContainer = styled.div`
  position: absolute;
  bottom: ${(p) => (p.mBottom ? p.mBottom : "20px")};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
`;

const TextContainer = styled.div`
  position: absolute;
  align-self: end;
  width: 20%;
  color: #fff;
  bottom: calc(
    (var(--useMin) * var(--heightLimit) * 7.4 / 100) +
      (var(--usePercent) * 7.4vh)
  );
  left: calc(
    (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
      (var(--usePercentWidth) * 3.33vw)
  );
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: calc(
    (var(--useMin) * var(--heightLimit) * 7.4 / 100) +
      (var(--usePercent) * 7.4vh)
  );
  right: calc(
    (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
      (var(--usePercentWidth) * 3.33vw)
  );
`;

const Button = styled(Link)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: black;
  padding: 12px 20px;
  border-radius: 25px;
  border: 0;
  border: none;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  background-color: white;
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.lg};
  font-family: ${(p) => p.theme.font.regular};
`;

const CarousalDot = styled.div`
  width: ${(p) => (p.dotSize ? p.dotSize : p.isActive ? "9px" : "8px")};
  height: ${(p) => (p.dotSize ? p.dotSize : p.isActive ? "9px" : "8px")};
  border-radius: 50%;
  color: #f1f1f155;
  margin: 0 4px;
  cursor: pointer;
  background: ${(p) =>
    p.isActive
      ? p.activeColor
        ? p.theme.colors[p.activeColor]
        : "#ffffff"
      : "#81807F"};
`;

const CarousalImage = styled.div`
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "90vh")};
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, ${(p) => p.gradient})
    ),
    url(${(p) => p.imgSrc}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: ${(p) => (p.display ? p.display : "block")};
`;

const PDFViewer = styled.div`
  display: ${(p) => (p.display ? p.display : "block")};
`;

const Carousal = ({
  data,
  buttonText,
  gradient,
  activeColor,
  type,
  autoScroll,
  dotSize,
  dotsMargin,
  scale,
}) => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(false);
  const slideLength = data.length;
  let slideInterval;
  let intervalTime = 5000;

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const moveDot = (index) => {
    setCurrentSlide(index);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    if (loading) {
      setCurrentSlide(0);
    }
  }, [loading]);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <>
      {data.map((slide, index) => {
        return (
          <Slide current={index === currentSlide} key={index}>
            {type === "PDF" ? (
              <PDFViewer display={index === currentSlide ? "block" : "none"}>
                <Document
                  loading={() => {
                    setLoading(true);
                  }}
                  error={() => {}}
                  onLoadSuccess={() => {
                    setLoading(false);
                  }}
                  file={slide.image}
                >
                  <Page scale={scale} pageNumber={1} />
                </Document>
              </PDFViewer>
            ) : (
              <CarousalImage
                display={index === currentSlide ? "block" : "none"}
                gradient={gradient}
                imgSrc={slide.image}
                alt="slide"
              >
                <TextContainer>
                  {slide.heading && (
                    <Text
                      font={"regular"}
                      color={"white"}
                      mBottom={"10px"}
                      fontSize={"xxl"}
                    >
                      {slide.heading}
                    </Text>
                  )}
                  {slide.description && (
                    <>
                      <Text color={"white"} fontSize={"md"}>
                        {i18n.exists(slide.description)
                          ? t(slide.description)
                          : ""}
                      </Text>
                    </>
                  )}
                </TextContainer>
                {buttonText && (
                  <ButtonContainer>
                    <Button
                      fontSize="md"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        whiteSpace: "nowrap",
                      }}
                      to={slide.route}
                    >
                      {t(buttonText)}
                      <Spacing mRightvh={"1.5"}></Spacing>
                      <div
                        style={{
                          alignSelf: "center",
                        }}
                      >
                        <ArrowIcon
                          height={"12px"}
                          width={"12px"}
                          color="black"
                          strokeWidth="1.5"
                          type="right"
                          style={{ margin: "15% 0% 0% 0%" }}
                        />
                      </div>
                    </Button>
                  </ButtonContainer>
                )}
              </CarousalImage>
            )}
            <DotsContainer mBottom={dotsMargin}>
              {slideLength > 1 &&
                !loading &&
                Array.from({ length: slideLength }).map((item, index) => (
                  <CarousalDot
                    dotSize={
                      currentSlide === index
                        ? `${parseFloat(dotSize) + 1}px`
                        : dotSize
                    }
                    key={index}
                    onClick={() => moveDot(index)}
                    isActive={currentSlide === index}
                    activeColor={activeColor}
                  ></CarousalDot>
                ))}
            </DotsContainer>
          </Slide>
        );
      })}
    </>
  );
};

export default Carousal;
