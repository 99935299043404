//JSON data to display all the countries while entering contact number
export const CountryCodes = {
  AF: {
    name: "Afghanistan (+93)",
    dial_code: "+93",
    code: "AF",
  },
  AX: {
    name: "Aland Islands (+358)",
    dial_code: "+358",
    code: "AX",
  },
  AL: {
    name: "Albania (+355)",
    dial_code: "+355",
    code: "AL",
  },
  DZ: {
    name: "Algeria (+213)",
    dial_code: "+213",
    code: "DZ",
  },
  AS: {
    name: "AmericanSamoa (+1 684)",
    dial_code: "+1 684",
    code: "AS",
  },
  AD: {
    name: "Andorra (+376)",
    dial_code: "+376",
    code: "AD",
  },
  AO: {
    name: "Angola (+244)",
    dial_code: "+244",
    code: "AO",
  },
  AI: {
    name: "Anguilla (+1 264)",
    dial_code: "+1 264",
    code: "AI",
  },
  AQ: {
    name: "Antarctica (+672)",
    dial_code: "+672",
    code: "AQ",
  },
  AG: {
    name: "Antigua and Barbuda (+1268)",
    dial_code: "+1268",
    code: "AG",
  },
  AR: {
    name: "Argentina (+54)",
    dial_code: "+54",
    code: "AR",
  },
  AM: {
    name: "Armenia (+374)",
    dial_code: "+374",
    code: "AM",
  },
  AW: {
    name: "Aruba (+297)",
    dial_code: "+297",
    code: "AW",
  },
  AU: {
    name: "Australia (+61)",
    dial_code: "+61",
    code: "AU",
  },
  AT: {
    name: "Austria (+43)",
    dial_code: "+43",
    code: "AT",
  },
  AZ: {
    name: "Azerbaijan (+994)",
    dial_code: "+994",
    code: "AZ",
  },
  BS: {
    name: "Bahamas (+1 242)",
    dial_code: "+1 242",
    code: "BS",
  },
  BH: {
    name: "Bahrain (+973)",
    dial_code: "+973",
    code: "BH",
  },
  BD: {
    name: "Bangladesh (+880)",
    dial_code: "+880",
    code: "BD",
  },
  BB: {
    name: "Barbados (+1 246)",
    dial_code: "+1 246",
    code: "BB",
  },
  BY: {
    name: "Belarus (+375)",
    dial_code: "+375",
    code: "BY",
  },
  BE: {
    name: "Belgium (+32)",
    dial_code: "+32",
    code: "BE",
  },
  BZ: {
    name: "Belize (+501)",
    dial_code: "+501",
    code: "BZ",
  },
  BJ: {
    name: "Benin (+229)",
    dial_code: "+229",
    code: "BJ",
  },
  BM: {
    name: "Bermuda (+1 441)",
    dial_code: "+1 441",
    code: "BM",
  },
  BT: {
    name: "Bhutan (+975)",
    dial_code: "+975",
    code: "BT",
  },
  BO: {
    name: "Bolivia, Plurinational State of (+591)",
    dial_code: "+591",
    code: "BO",
  },
  BA: {
    name: "Bosnia and Herzegovina (+387)",
    dial_code: "+387",
    code: "BA",
  },
  BW: {
    name: "Botswana (+267)",
    dial_code: "+267",
    code: "BW",
  },
  BR: {
    name: "Brazil (+55)",
    dial_code: "+55",
    code: "BR",
  },
  IO: {
    name: "British Indian Ocean Territory (+246)",
    dial_code: "+246",
    code: "IO",
  },
  BN: {
    name: "Brunei Darussalam (+673)",
    dial_code: "+673",
    code: "BN",
  },
  BG: {
    name: "Bulgaria (+359)",
    dial_code: "+359",
    code: "BG",
  },
  BF: {
    name: "Burkina Faso (+226)",
    dial_code: "+226",
    code: "BF",
  },
  BI: {
    name: "Burundi (+257)",
    dial_code: "+257",
    code: "BI",
  },
  KH: {
    name: "Cambodia (+855)",
    dial_code: "+855",
    code: "KH",
  },
  CM: {
    name: "Cameroon (+237)",
    dial_code: "+237",
    code: "CM",
  },
  CA: {
    name: "Canada (+1)",
    dial_code: "+1",
    code: "CA",
  },
  CV: {
    name: "Cape Verde (+238)",
    dial_code: "+238",
    code: "CV",
  },
  KY: {
    name: "Cayman Islands (+ 345)",
    dial_code: "+ 345",
    code: "KY",
  },
  CF: {
    name: "Central African Republic (+236)",
    dial_code: "+236",
    code: "CF",
  },
  TD: {
    name: "Chad (+235)",
    dial_code: "+235",
    code: "TD",
  },
  CL: {
    name: "Chile (+56)",
    dial_code: "+56",
    code: "CL",
  },
  CN: {
    name: "China (+86)",
    dial_code: "+86",
    code: "CN",
  },
  CX: {
    name: "Christmas Island (+61)",
    dial_code: "+61",
    code: "CX",
  },
  CC: {
    name: "Cocos (Keeling) Islands (+61)",
    dial_code: "+61",
    code: "CC",
  },
  CO: {
    name: "Colombia (+57)",
    dial_code: "+57",
    code: "CO",
  },
  KM: {
    name: "Comoros (+269)",
    dial_code: "+269",
    code: "KM",
  },
  CG: {
    name: "Congo (+242)",
    dial_code: "+242",
    code: "CG",
  },
  CD: {
    name: "Congo, The Democratic Republic of the Congo (+243)",
    dial_code: "+243",
    code: "CD",
  },
  CK: {
    name: "Cook Islands (+682)",
    dial_code: "+682",
    code: "CK",
  },
  CR: {
    name: "Costa Rica (+506)",
    dial_code: "+506",
    code: "CR",
  },
  CI: {
    name: "Cote d'Ivoire (+225)",
    dial_code: "+225",
    code: "CI",
  },
  HR: {
    name: "Croatia (+385)",
    dial_code: "+385",
    code: "HR",
  },
  CU: {
    name: "Cuba (+53)",
    dial_code: "+53",
    code: "CU",
  },
  CY: {
    name: "Cyprus (+357)",
    dial_code: "+357",
    code: "CY",
  },
  CZ: {
    name: "Czech Republic (+420)",
    dial_code: "+420",
    code: "CZ",
  },
  DK: {
    name: "Denmark (+45)",
    dial_code: "+45",
    code: "DK",
  },
  DJ: {
    name: "Djibouti (+253)",
    dial_code: "+253",
    code: "DJ",
  },
  DM: {
    name: "Dominica (+1 767)",
    dial_code: "+1 767",
    code: "DM",
  },
  DO: {
    name: "Dominican Republic (+1 849)",
    dial_code: "+1 849",
    code: "DO",
  },
  EC: {
    name: "Ecuador (+593)",
    dial_code: "+593",
    code: "EC",
  },
  EG: {
    name: "Egypt (+20)",
    dial_code: "+20",
    code: "EG",
  },
  SV: {
    name: "El Salvador (+503)",
    dial_code: "+503",
    code: "SV",
  },
  GQ: {
    name: "Equatorial Guinea (+240)",
    dial_code: "+240",
    code: "GQ",
  },
  ER: {
    name: "Eritrea (+291)",
    dial_code: "+291",
    code: "ER",
  },
  EE: {
    name: "Estonia (+372)",
    dial_code: "+372",
    code: "EE",
  },
  ET: {
    name: "Ethiopia (+251)",
    dial_code: "+251",
    code: "ET",
  },
  FK: {
    name: "Falkland Islands (Malvinas) (+500)",
    dial_code: "+500",
    code: "FK",
  },
  FO: {
    name: "Faroe Islands (+298)",
    dial_code: "+298",
    code: "FO",
  },
  FJ: {
    name: "Fiji (+679)",
    dial_code: "+679",
    code: "FJ",
  },
  FI: {
    name: "Finland (+358)",
    dial_code: "+358",
    code: "FI",
  },
  FR: {
    name: "France (+33)",
    dial_code: "+33",
    code: "FR",
  },
  GF: {
    name: "French Guiana (+594)",
    dial_code: "+594",
    code: "GF",
  },
  PF: {
    name: "French Polynesia (+689)",
    dial_code: "+689",
    code: "PF",
  },
  GA: {
    name: "Gabon (+241)",
    dial_code: "+241",
    code: "GA",
  },
  GM: {
    name: "Gambia (+220)",
    dial_code: "+220",
    code: "GM",
  },
  GE: {
    name: "Georgia (+995)",
    dial_code: "+995",
    code: "GE",
  },
  DE: {
    name: "Germany (+49)",
    dial_code: "+49",
    code: "DE",
  },
  GH: {
    name: "Ghana (+233)",
    dial_code: "+233",
    code: "GH",
  },
  GI: {
    name: "Gibraltar (+350)",
    dial_code: "+350",
    code: "GI",
  },
  GR: {
    name: "Greece (+30)",
    dial_code: "+30",
    code: "GR",
  },
  GL: {
    name: "Greenland (+299)",
    dial_code: "+299",
    code: "GL",
  },
  GD: {
    name: "Grenada (+1 473)",
    dial_code: "+1 473",
    code: "GD",
  },
  GP: {
    name: "Guadeloupe (+590)",
    dial_code: "+590",
    code: "GP",
  },
  GU: {
    name: "Guam (+1 671)",
    dial_code: "+1 671",
    code: "GU",
  },
  GT: {
    name: "Guatemala (+502)",
    dial_code: "+502",
    code: "GT",
  },
  GG: {
    name: "Guernsey (+44)",
    dial_code: "+44",
    code: "GG",
  },
  GN: {
    name: "Guinea (+224)",
    dial_code: "+224",
    code: "GN",
  },
  GW: {
    name: "Guinea-Bissau (+245)",
    dial_code: "+245",
    code: "GW",
  },
  GY: {
    name: "Guyana (+595)",
    dial_code: "+595",
    code: "GY",
  },
  HT: {
    name: "Haiti (+509)",
    dial_code: "+509",
    code: "HT",
  },
  VA: {
    name: "Holy See (Vatican City State) (+379)",
    dial_code: "+379",
    code: "VA",
  },
  HN: {
    name: "Honduras (+504)",
    dial_code: "+504",
    code: "HN",
  },
  HK: {
    name: "Hong Kong (+852)",
    dial_code: "+852",
    code: "HK",
  },
  HU: {
    name: "Hungary (+36)",
    dial_code: "+36",
    code: "HU",
  },
  IS: {
    name: "Iceland (+354)",
    dial_code: "+354",
    code: "IS",
  },
  IN: {
    name: "India (+91)",
    dial_code: "+91",
    code: "IN",
  },
  ID: {
    name: "Indonesia (+62)",
    dial_code: "+62",
    code: "ID",
  },
  IR: {
    name: "Iran, Islamic Republic of Persian Gulf (+98)",
    dial_code: "+98",
    code: "IR",
  },
  IQ: {
    name: "Iraq (+964)",
    dial_code: "+964",
    code: "IQ",
  },
  IE: {
    name: "Ireland (+353)",
    dial_code: "+353",
    code: "IE",
  },
  IM: {
    name: "Isle of Man (+44)",
    dial_code: "+44",
    code: "IM",
  },
  IL: {
    name: "Israel (+972)",
    dial_code: "+972",
    code: "IL",
  },
  IT: {
    name: "Italy (+39)",
    dial_code: "+39",
    code: "IT",
  },
  JM: {
    name: "Jamaica (+1 876)",
    dial_code: "+1 876",
    code: "JM",
  },
  JP: {
    name: "Japan (+81)",
    dial_code: "+81",
    code: "JP",
  },
  JE: {
    name: "Jersey (+44)",
    dial_code: "+44",
    code: "JE",
  },
  JO: {
    name: "Jordan (+962)",
    dial_code: "+962",
    code: "JO",
  },
  KZ: {
    name: "Kazakhstan (+7 7)",
    dial_code: "+7 7",
    code: "KZ",
  },
  KE: {
    name: "Kenya (+254)",
    dial_code: "+254",
    code: "KE",
  },
  KI: {
    name: "Kiribati (+686)",
    dial_code: "+686",
    code: "KI",
  },
  KP: {
    name: "Korea, Democratic People's Republic of Korea (+850)",
    dial_code: "+850",
    code: "KP",
  },
  KR: {
    name: "Korea, Republic of South Korea (+82)",
    dial_code: "+82",
    code: "KR",
  },
  KW: {
    name: "Kuwait (+965)",
    dial_code: "+965",
    code: "KW",
  },
  KG: {
    name: "Kyrgyzstan (+996)",
    dial_code: "+996",
    code: "KG",
  },
  LA: {
    name: "Laos (+856)",
    dial_code: "+856",
    code: "LA",
  },
  LV: {
    name: "Latvia (+371)",
    dial_code: "+371",
    code: "LV",
  },
  LB: {
    name: "Lebanon (+961)",
    dial_code: "+961",
    code: "LB",
  },
  LS: {
    name: "Lesotho (+266)",
    dial_code: "+266",
    code: "LS",
  },
  LR: {
    name: "Liberia (+231)",
    dial_code: "+231",
    code: "LR",
  },
  LY: {
    name: "Libyan Arab Jamahiriya (+218)",
    dial_code: "+218",
    code: "LY",
  },
  LI: {
    name: "Liechtenstein (+423)",
    dial_code: "+423",
    code: "LI",
  },
  LT: {
    name: "Lithuania (+370)",
    dial_code: "+370",
    code: "LT",
  },
  LU: {
    name: "Luxembourg (+352)",
    dial_code: "+352",
    code: "LU",
  },
  MO: {
    name: "Macao (+853)",
    dial_code: "+853",
    code: "MO",
  },
  MK: {
    name: "Macedonia (+389)",
    dial_code: "+389",
    code: "MK",
  },
  MG: {
    name: "Madagascar (+261)",
    dial_code: "+261",
    code: "MG",
  },
  MW: {
    name: "Malawi (+265)",
    dial_code: "+265",
    code: "MW",
  },
  MY: {
    name: "Malaysia (+60)",
    dial_code: "+60",
    code: "MY",
  },
  MV: {
    name: "Maldives (+960)",
    dial_code: "+960",
    code: "MV",
  },
  ML: {
    name: "Mali (+223)",
    dial_code: "+223",
    code: "ML",
  },
  MT: {
    name: "Malta (+356)",
    dial_code: "+356",
    code: "MT",
  },
  MH: {
    name: "Marshall Islands (+692)",
    dial_code: "+692",
    code: "MH",
  },
  MQ: {
    name: "Martinique (+596)",
    dial_code: "+596",
    code: "MQ",
  },
  MR: {
    name: "Mauritania (+222)",
    dial_code: "+222",
    code: "MR",
  },
  MU: {
    name: "Mauritius (+230)",
    dial_code: "+230",
    code: "MU",
  },
  YT: {
    name: "Mayotte (+262)",
    dial_code: "+262",
    code: "YT",
  },
  MX: {
    name: "Mexico (+52)",
    dial_code: "+52",
    code: "MX",
  },
  FM: {
    name: "Micronesia, Federated States of Micronesia (+691)",
    dial_code: "+691",
    code: "FM",
  },
  MD: {
    name: "Moldova (+373)",
    dial_code: "+373",
    code: "MD",
  },
  MC: {
    name: "Monaco (+377)",
    dial_code: "+377",
    code: "MC",
  },
  MN: {
    name: "Mongolia (+976)",
    dial_code: "+976",
    code: "MN",
  },
  ME: {
    name: "Montenegro (+382)",
    dial_code: "+382",
    code: "ME",
  },
  MS: {
    name: "Montserrat (+1664)",
    dial_code: "+1664",
    code: "MS",
  },
  MA: {
    name: "Morocco (+212)",
    dial_code: "+212",
    code: "MA",
  },
  MZ: {
    name: "Mozambique (+258)",
    dial_code: "+258",
    code: "MZ",
  },
  MM: {
    name: "Myanmar (+95)",
    dial_code: "+95",
    code: "MM",
  },
  NA: {
    name: "Namibia (+264)",
    dial_code: "+264",
    code: "NA",
  },
  NR: {
    name: "Nauru (+674)",
    dial_code: "+674",
    code: "NR",
  },
  NP: {
    name: "Nepal (+977)",
    dial_code: "+977",
    code: "NP",
  },
  NL: {
    name: "Netherlands (+31)",
    dial_code: "+31",
    code: "NL",
  },
  AN: {
    name: "Netherlands Antilles (+599)",
    dial_code: "+599",
    code: "AN",
  },
  NC: {
    name: "New Caledonia (+687)",
    dial_code: "+687",
    code: "NC",
  },
  NZ: {
    name: "New Zealand (+64)",
    dial_code: "+64",
    code: "NZ",
  },
  NI: {
    name: "Nicaragua (+505)",
    dial_code: "+505",
    code: "NI",
  },
  NE: {
    name: "Niger (+227)",
    dial_code: "+227",
    code: "NE",
  },
  NG: {
    name: "Nigeria (+234)",
    dial_code: "+234",
    code: "NG",
  },
  NU: {
    name: "Niue (+683)",
    dial_code: "+683",
    code: "NU",
  },
  NF: {
    name: "Norfolk Island (+672)",
    dial_code: "+672",
    code: "NF",
  },
  MP: {
    name: "Northern Mariana Islands (+1 670)",
    dial_code: "+1 670",
    code: "MP",
  },
  NO: {
    name: "Norway (+47)",
    dial_code: "+47",
    code: "NO",
  },
  OM: {
    name: "Oman (+968)",
    dial_code: "+968",
    code: "OM",
  },
  PK: {
    name: "Pakistan (+92)",
    dial_code: "+92",
    code: "PK",
  },
  PW: {
    name: "Palau (+680)",
    dial_code: "+680",
    code: "PW",
  },
  PS: {
    name: "Palestinian Territory, Occupied (+970)",
    dial_code: "+970",
    code: "PS",
  },
  PA: {
    name: "Panama (+507)",
    dial_code: "+507",
    code: "PA",
  },
  PG: {
    name: "Papua New Guinea (+675)",
    dial_code: "+675",
    code: "PG",
  },
  PY: {
    name: "Paraguay (+595)",
    dial_code: "+595",
    code: "PY",
  },
  PE: {
    name: "Peru (+51)",
    dial_code: "+51",
    code: "PE",
  },
  PH: {
    name: "Philippines (+63)",
    dial_code: "+63",
    code: "PH",
  },
  PN: {
    name: "Pitcairn (+872)",
    dial_code: "+872",
    code: "PN",
  },
  PL: {
    name: "Poland (+48)",
    dial_code: "+48",
    code: "PL",
  },
  PT: {
    name: "Portugal (+351)",
    dial_code: "+351",
    code: "PT",
  },
  PR: {
    name: "Puerto Rico (+1 939)",
    dial_code: "+1 939",
    code: "PR",
  },
  QA: {
    name: "Qatar (+974)",
    dial_code: "+974",
    code: "QA",
  },
  RO: {
    name: "Romania (+40)",
    dial_code: "+40",
    code: "RO",
  },
  RU: {
    name: "Russia (+7)",
    dial_code: "+7",
    code: "RU",
  },
  RW: {
    name: "Rwanda (+250)",
    dial_code: "+250",
    code: "RW",
  },
  RE: {
    name: "Reunion (+262)",
    dial_code: "+262",
    code: "RE",
  },
  BL: {
    name: "Saint Barthelemy (+590)",
    dial_code: "+590",
    code: "BL",
  },
  SH: {
    name: "Saint Helena, Ascension and Tristan Da Cunha (+290)",
    dial_code: "+290",
    code: "SH",
  },
  KN: {
    name: "Saint Kitts and Nevis (+1 869)",
    dial_code: "+1 869",
    code: "KN",
  },
  LC: {
    name: "Saint Lucia (+1 758)",
    dial_code: "+1 758",
    code: "LC",
  },
  MF: {
    name: "Saint Martin (+590)",
    dial_code: "+590",
    code: "MF",
  },
  PM: {
    name: "Saint Pierre and Miquelon (+508)",
    dial_code: "+508",
    code: "PM",
  },
  VC: {
    name: "Saint Vincent and the Grenadines (+1 784)",
    dial_code: "+1 784",
    code: "VC",
  },
  WS: {
    name: "Samoa (+685)",
    dial_code: "+685",
    code: "WS",
  },
  SM: {
    name: "San Marino (+378)",
    dial_code: "+378",
    code: "SM",
  },
  ST: {
    name: "Sao Tome and Principe (+239)",
    dial_code: "+239",
    code: "ST",
  },
  SA: {
    name: "Saudi Arabia (+966)",
    dial_code: "+966",
    code: "SA",
  },
  SN: {
    name: "Senegal (+221)",
    dial_code: "+221",
    code: "SN",
  },
  RS: {
    name: "Serbia (+381)",
    dial_code: "+381",
    code: "RS",
  },
  SC: {
    name: "Seychelles (+248)",
    dial_code: "+248",
    code: "SC",
  },
  SL: {
    name: "Sierra Leone (+232)",
    dial_code: "+232",
    code: "SL",
  },
  SG: {
    name: "Singapore (+65)",
    dial_code: "+65",
    code: "SG",
  },
  SK: {
    name: "Slovakia (+421)",
    dial_code: "+421",
    code: "SK",
  },
  SI: {
    name: "Slovenia (+386)",
    dial_code: "+386",
    code: "SI",
  },
  SB: {
    name: "Solomon Islands (+677)",
    dial_code: "+677",
    code: "SB",
  },
  SO: {
    name: "Somalia (+252)",
    dial_code: "+252",
    code: "SO",
  },
  ZA: {
    name: "South Africa (+27)",
    dial_code: "+27",
    code: "ZA",
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands (+500)",
    dial_code: "+500",
    code: "GS",
  },
  ES: {
    name: "Spain (+34)",
    dial_code: "+34",
    code: "ES",
  },
  LK: {
    name: "Sri Lanka (+94)",
    dial_code: "+94",
    code: "LK",
  },
  SD: {
    name: "Sudan (+249)",
    dial_code: "+249",
    code: "SD",
  },
  SR: {
    name: "Suriname (+597)",
    dial_code: "+597",
    code: "SR",
  },
  SJ: {
    name: "Svalbard and Jan Mayen (+47)",
    dial_code: "+47",
    code: "SJ",
  },
  SZ: {
    name: "Swaziland (+268)",
    dial_code: "+268",
    code: "SZ",
  },
  SE: {
    name: "Sweden (+46)",
    dial_code: "+46",
    code: "SE",
  },
  CH: {
    name: "Switzerland (+41)",
    dial_code: "+41",
    code: "CH",
  },
  SY: {
    name: "Syrian Arab Republic (+963)",
    dial_code: "+963",
    code: "SY",
  },
  TW: {
    name: "Taiwan (+886)",
    dial_code: "+886",
    code: "TW",
  },
  TJ: {
    name: "Tajikistan (+992)",
    dial_code: "+992",
    code: "TJ",
  },
  TZ: {
    name: "Tanzania, United Republic of Tanzania (+255)",
    dial_code: "+255",
    code: "TZ",
  },
  TH: {
    name: "Thailand (+66)",
    dial_code: "+66",
    code: "TH",
  },
  TL: {
    name: "Timor-Leste (+670)",
    dial_code: "+670",
    code: "TL",
  },
  TG: {
    name: "Togo (+228)",
    dial_code: "+228",
    code: "TG",
  },
  TK: {
    name: "Tokelau (+690)",
    dial_code: "+690",
    code: "TK",
  },
  TO: {
    name: "Tonga (+676)",
    dial_code: "+676",
    code: "TO",
  },
  TT: {
    name: "Trinidad and Tobago (+1 868)",
    dial_code: "+1 868",
    code: "TT",
  },
  TN: {
    name: "Tunisia (+216)",
    dial_code: "+216",
    code: "TN",
  },
  TR: {
    name: "Turkey (+90)",
    dial_code: "+90",
    code: "TR",
  },
  TM: {
    name: "Turkmenistan (+993)",
    dial_code: "+993",
    code: "TM",
  },
  TC: {
    name: "Turks and Caicos Islands (+1 649)",
    dial_code: "+1 649",
    code: "TC",
  },
  TV: {
    name: "Tuvalu (+688)",
    dial_code: "+688",
    code: "TV",
  },
  UG: {
    name: "Uganda (+256)",
    dial_code: "+256",
    code: "UG",
  },
  UA: {
    name: "Ukraine (+380)",
    dial_code: "+380",
    code: "UA",
  },
  AE: {
    name: "United Arab Emirates (+971)",
    dial_code: "+971",
    code: "AE",
  },
  GB: {
    name: "United Kingdom (+44)",
    dial_code: "+44",
    code: "GB",
  },
  US: {
    name: "United States (+1)",
    dial_code: "+1",
    code: "US",
  },
  UY: {
    name: "Uruguay (+598)",
    dial_code: "+598",
    code: "UY",
  },
  UZ: {
    name: "Uzbekistan (+998)",
    dial_code: "+998",
    code: "UZ",
  },
  VU: {
    name: "Vanuatu (+678)",
    dial_code: "+678",
    code: "VU",
  },
  VE: {
    name: "Venezuela, Bolivarian Republic of Venezuela (+58)",
    dial_code: "+58",
    code: "VE",
  },
  VN: {
    name: "Vietnam (+84)",
    dial_code: "+84",
    code: "VN",
  },
  VG: {
    name: "Virgin Islands, British (+1 284)",
    dial_code: "+1 284",
    code: "VG",
  },
  VI: {
    name: "Virgin Islands, U.S. (+1 340)",
    dial_code: "+1 340",
    code: "VI",
  },
  WF: {
    name: "Wallis and Futuna (+681)",
    dial_code: "+681",
    code: "WF",
  },
  YE: {
    name: "Yemen (+967)",
    dial_code: "+967",
    code: "YE",
  },
  ZM: {
    name: "Zambia (+260)",
    dial_code: "+260",
    code: "ZM",
  },
  ZW: {
    name: "Zimbabwe (+263)",
    dial_code: "+263",
    code: "ZW",
  },
};
