import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} height
 * @param {string} color
 */
export const ArrowIcon = ({
  width,
  height,
  color,
  strokeWidth,
  type,
  style,
}) => {
  const WIDTH = width || "16";
  const HEIGHT = height || "8";
  const COLOR = theme.colors[color] || theme.colors.textGrey;
  const STROKE_WIDTH = strokeWidth || "1.5";

  return (
    <>
      {type === "up" && (
        <svg
          id="Group_2964"
          data-name="Group 2964"
          xmlns="http://www.w3.org/2000/svg"
          width={WIDTH}
          height={HEIGHT}
          viewBox="0 0 16 8"
          style={style}
        >
          <g
            id="Group_2861"
            data-name="Group 2861"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          >
            <rect
              id="Rectangle_8849"
              data-name="Rectangle 8849"
              width="16"
              height="8"
              fill="none"
            />
          </g>
          <path
            id="Path_2032"
            data-name="Path 2032"
            d="M7371.5,6077.828l5.588-5.215,5.588,5.215"
            transform="translate(-7369.001 -6071.328)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
        </svg>
      )}
      {type === "down" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={WIDTH}
          height={HEIGHT}
          viewBox="0 0 16 8"
        >
          <g
            id="Group_2963"
            data-name="Group 2963"
            transform="translate(-1720 -224)"
          >
            <g
              id="Group_2861"
              data-name="Group 2861"
              transform="translate(1720 224)"
              style={{ mixBlendMode: "multiply", isolation: "isolate" }}
            >
              <rect
                id="Rectangle_8849"
                data-name="Rectangle 8849"
                width="16"
                height="8"
                fill="none"
              />
            </g>
            <path
              id="Path_2032"
              data-name="Path 2032"
              d="M7371.5,6072.613l5.588,5.215,5.588-5.215"
              transform="translate(-5649.001 -5847.113)"
              fill="none"
              stroke={COLOR}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={STROKE_WIDTH}
            />
          </g>
        </svg>
      )}
      {type === "left" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={WIDTH}
          height={HEIGHT}
          viewBox="0 0 7.99 14.482"
        >
          <path
            id="Path_1298"
            data-name="Path 1298"
            d="M0,6.18,6.181,0l6.18,6.18"
            transform="translate(0.75 13.422) rotate(-90)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </svg>
      )}
      {type === "right" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={WIDTH}
          height={HEIGHT}
          viewBox="0 0 8.594 15.189"
        >
          <path
            id="Path_2176"
            data-name="Path 2176"
            d="M7374,6074l6.181,6.18,6.18-6.18"
            transform="translate(-6072.586 7387.775) rotate(-90)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
        </svg>
      )}
    </>
  );
};
