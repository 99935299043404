import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Spacing, InlineText, DropDown, FormRow, Text, ChartTooltip } from "..";
import { appTheme } from "../Theme";
import _ from "lodash";

const getTooltip = (context, chartStyle) => {
  let indexAxis = chartStyle?.customChartStyle?.indexAxis || "x";

  // Array of objects where each object represents a point and holds name, xcoords and ycoords of the same.
  let tooltipData = [];
  if (context.tooltip.body) {
    tooltipData = context.tooltip.body.map((bodyItem, i) => {
      // The bodyItem holds a property 'linear' which is an array of strings. The string contains '{dataName}: {nonIndexAxisCoordinate}'
      const body = bodyItem.lines.toString().replace(" ", "").split(":");

      // If the data name is not provided
      if (body.length === 1) body.unshift("");

      let name = body[0];
      const [xcoord, ycoord] =
        indexAxis === "x"
          ? [context.tooltip.title[i], body[1]]
          : [body[1], context.tooltip.title[i]];

      return {
        name,
        xcoord,
        ycoord,
      };
    });
  }

  const customTooltip = {
    // This string will be displayed on the tooltip after replacing the placeholders with appropriate values
    displayTemplate: "{{name}} {{ycoord}}",
    data: tooltipData,
    // Specify which variables in the displayTemplate should be colored by including them in this object and setting their values to true.
    colors: {
      ycoord: true,
    },
    styles: {
      bannerStyle: {},
      pointerStyle: {},
    },
  };

  // If a custom tooltip function is provided, it is called with the context and customTooltip as the arguments
  if (chartStyle?.customChartStyle?.plugins?.tooltip?.external) {
    chartStyle.customChartStyle.plugins.tooltip.external(
      context,
      customTooltip
    );
  } else {
    ChartTooltip(context, customTooltip);
  }
};

const getChartStyleOptions = (chartStyle) => {
  const defaultChartStyle = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          size: appTheme.fontSize.xxs,
          font: appTheme.font.light,
        },
      },
      title: {
        font: {
          size: appTheme.fontSize.xxs,
          family: appTheme.font.light,
        },
      },

      // When hovered over a point, there are three information that can be displayed
      // 1. name (name of the line)
      // 2. xcoord (the x coordinate)
      // 3. ycoord (the y coordinate)

      tooltip: {
        enabled: false,
        // Gets executed when a point is hovered on the graph
        external: (context) => getTooltip(context, chartStyle),
      },
    },
    layout: {
      padding: {
        top: 20,
      },
    },
    scales: {
      x: {
        offset: true,
        type: chartStyle.xScaleType,
        labels: [],
        grid: {
          display: false,
          borderWidth: 2,
          borderColor: "#707070",
          tickLength: 0,
          tickWidth: 0,
        },
        title: {
          font: {
            size: appTheme.fontSize.xxs,
            family: appTheme.font.light,
          },
          color: appTheme.colors.textGrey,
        },
        ticks: {
          color: appTheme.colors.textDarkGrey,
          font: {
            size: 12,
            weight: 550,
            family: appTheme.font.regular,
          },
          padding: 10,
          // This callback function is used to modify the ticks displayed in x-axis
          callback: function (value, index, values) {
            // If a custom callback function is provided, it is called with the tickLabel, index and values as the arguments
            // The modified tick returned by the custom callback function is returned
            if (chartStyle?.customChartStyle?.scales?.x?.ticks?.callback) {
              return chartStyle.customChartStyle.scales.x.ticks.callback(
                this.getLabelForValue(value),
                index,
                values
              );
            }
            // If no custom callback function is provided, the tick is just displayed as it is
            return this.getLabelForValue(value);
          },
        },
      },
      y: {
        offset: false,
        type: chartStyle.yScaleType,
        labels: [],
        grid: {
          borderWidth: 0,
          tickLength: 0,
          tickWidth: 0,
        },
        title: {
          font: {
            size: appTheme.fontSize.xxs,
            family: appTheme.font.light,
          },
          color: appTheme.colors.textGrey,
        },
        ticks: {
          color: appTheme.colors.textDarkerGrey,
          font: {
            size: 8,
            weight: 500,
            family: appTheme.font.regular,
          },
          padding: 5,
          beginAtZero: true,
          count: 6,
          labelOffset: -6,
          // This callback function is used to modify the ticks displayed in y-axis
          callback: function (value, index, values) {
            // If a custom callback function is provided, it is called with the tickLabel, index and values as the arguments
            // The modified tick returned by the custom callback function is returned
            if (chartStyle?.customChartStyle?.scales?.y?.ticks?.callback) {
              return chartStyle.customChartStyle.scales.y.ticks.callback(
                this.getLabelForValue(value),
                index,
                values
              );
            }
            // If no custom callback function is provided, the tick is just displayed as it is
            return this.getLabelForValue(value);
          },
        },
      },
    },
  };

  // Override custom chart styles with default chart styles
  return _.mergeWith(
    defaultChartStyle,
    chartStyle.customChartStyle,
    (existingValue, newValue, property) => {
      // Exclude following properties from being overriden
      if (property === "callback") return existingValue;
      if (property === "external") return existingValue;
    }
  );
};

// Function to set the axis type of the line chart
const setChartScaleType = (dataset, chartStyle) => {
  /* The coordinates of the chart for each line graph in the dataset can be either passed as two separate arrays xcoords and ycoords 
     which holds X Coordinates and Y Coordinates respectively of a line graph (Hereafter referred to as 'Format-1') 
     or as 2D array named coords where each element is an array [x, y] representing the coordinate of the line graph (Hereafter referred to as 'Format-2') 
     
     Example (Format 1) - 
     dataset = [
      {
        xcoords: [2, 3, 7]
        ycoords: [10, 12, 19]
      }
     ]
     
     Example (Format 2) - 
     dataset = [
      {
        coords = [
          [2, 10],
          [3, 12], 
          [7, 19]
        ]
      }
     ]
     
  */

  // If the first graph of the dataset is in Format-1
  if (dataset[0]?.xcoords && dataset[0]?.ycoords) {
    // Check the type of 1st coordinate (first entry in xcoords, first entry in ycoords)
    // If it's a string then change scale type of the respective axis to "category" from it's default of "linear"
    chartStyle.xScaleType =
      typeof dataset[0]?.xcoords[0] === "string" ? "category" : "linear";
    chartStyle.yScaleType =
      typeof dataset[0]?.ycoords[0] === "string" ? "category" : "linear";
  }

  // If the first graph of the dataset is in Format-2
  if (dataset[0]?.coords) {
    // Check the type of 1st Coordinate (1st array in the 2D array 'coords')
    // If it's string then change scale type of the respective axis to "category" from it's default of "linear"
    chartStyle.xScaleType =
      typeof dataset[0]?.coords[0]?.[0] === "string" ? "category" : "linear";
    chartStyle.yScaleType =
      typeof dataset[0]?.coords[0]?.[1] === "string" ? "category" : "linear";
  }
};

const getFormattedData = (canvas, inputDataset) => {
  const datasets = inputDataset.map((inputData) => {
    let data;
    if (inputData.coords) {
      data = inputData.coords.map(([x, y]) => ({ x, y }));
    } else if (inputData.xcoords && inputData.ycoords) {
      const { xcoords, ycoords } = inputData;
      data = xcoords.map((x, index) => ({ x, y: ycoords[index] }));
    }

    if (typeof inputData?.style?.borderColor === "function") {
      inputData.style.borderColor = inputData.style.borderColor(
        canvas,
        inputData
      );
    }

    /* The style object contains the styling of a particular line in the line chart
       The following properties can be used to customize the appearance of the line

       tension: number (smoothens the line and makes it look like a curve)
       stepped: boolean (shows the line as stepped line & tension will be ignored )
       pointStyle: string (shape of the coordinate point - star, circle, triangle etc.) 

       borderColor: string (color of the line)
       borderWidth: number (thickness of the line)
       borderDash: array ([x, y] where x = stroke in pixels, y = gap in pixels)

       pointBackgroundColor: string (color of the coordinate point)
       pointHoverBackgroundColor: string (color of the coordinate point when hovered)

       pointRadius: number (radius of the coordinate point)
       pointHoverRadius: number (radius of the coordinate point when hovered)

       pointBorderColor: string (border color of the coordinate point)
       pointHoverBorderColor: string (border color of the coordinate point when hovered)

       pointBorderWidth: number (border width of the coordinate point)
       pointHoverBorderWidth: number (border width of the coordinate point when hovered)
    
    */
    const transformedData = {
      label: inputData.name,
      data,
      ...inputData.style,
    };
    return transformedData;
  });
  return { datasets };
};

export default function BrokenLineChart({
  dataset,
  chartStyle,
  filters,
  handleFilterChange,
}) {
  const [filter, setFilter] = useState(
    filters ? Object.keys(filters)[0] || "" : ""
  );

  setChartScaleType(dataset, chartStyle);

  return (
    <>
      <FormRow>
        <Spacing mTop="auto" mBottom="auto">
          {chartStyle?.title && (
            <InlineText fontSize="lg" font="medium" color="black">
              {chartStyle.title}
            </InlineText>
          )}
          {chartStyle?.subtitle && (
            <>
              <Spacing mBottomvh="0.8" />
              <InlineText fontSize="xxxxs">{chartStyle.subtitle}</InlineText>
            </>
          )}
        </Spacing>
        {filters && (
          <>
            {Object.keys(filters).length > 1 ? (
              <DropDown
                scrollToView={false}
                boxTopMargin={"0px"}
                width={"47%"}
                height={"auto"}
                widthvw={"5"}
                fontSize="xs"
                font="light"
                optionList={filters}
                id="typeField"
                onChange={(value) => {
                  setFilter(value);
                  handleFilterChange(value);
                }}
                value={filter}
                name="userType"
              />
            ) : null}
          </>
        )}
      </FormRow>
      <Spacing mBottomvh="1.08" />
      <Spacing margin="auto" mTop="0px" width="100%" mLeft="-5px" heightvh="22">
        <Line
          data={(canvas) => getFormattedData(canvas, dataset)}
          options={getChartStyleOptions(chartStyle)}
        />
      </Spacing>
      {chartStyle?.caption && (
        <Text
          fontSize="xxs"
          textAlign="center"
          color="textDarkGrey"
          height="auto"
        >
          {chartStyle.caption}
        </Text>
      )}
    </>
  );
}
