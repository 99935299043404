export const AWS_TOPIC_STARTING = "$aws/things/";
export const CUSTOM_TOPIC_STARTING = "sculptor/";

export const PROCESS_COMPLETED_TOPIC = "sculptor/process/new";

export const STATUS_UPDATE_TOPIC_ACCEPTED =
  "/shadow/name/StatusShadow/update/accepted";
export const STATUS_GET_TOPIC_ACCEPTED =
  "/shadow/name/StatusShadow/get/accepted";
export const STATUS_GET_TOPIC = "/shadow/name/StatusShadow/get";

export const SETTINGS_UPDATE_TOPIC_ACCEPTED =
  "/shadow/name/SettingsShadow/update/accepted";
export const SETTINGS_GET_TOPIC_ACCEPTED =
  "/shadow/name/SettingsShadow/get/accepted";
export const SETTINGS_GET_TOPIC = "/shadow/name/SettingsShadow/get";

export const LOCATION_UPDATE_TOPIC_ACCEPTED =
  "/shadow/name/LocationShadow/update/accepted";
export const LOCATION_GET_TOPIC_ACCEPTED =
  "/shadow/name/LocationShadow/get/accepted";
export const LOCATION_GET_TOPIC = "/shadow/name/LocationShadow/get";

export const CNM_STATUS_UPDATE_TOPIC_ACCEPTED =
  "/shadow/name/CNMStatusShadow/update/accepted";
export const CNM_STATUS_GET_TOPIC_ACCEPTED =
  "/shadow/name/CNMStatusShadow/get/accepted";
export const CNM_STATUS_GET_TOPIC = "/shadow/name/CNMStatusShadow/get";

export const HARDWARE_STATUS_UPDATE_TOPIC_ACCEPTED =
  "/shadow/name/HardwareStatusShadow/update/accepted";
export const HARDWARE_STATUS_GET_TOPIC_ACCEPTED =
  "/shadow/name/HardwareStatusShadow/get/accepted";
export const HARDWARE_STATUS_GET_TOPIC =
  "/shadow/name/HardwareStatusShadow/get";

export const SOFTWARE_VERSION_UPDATE_TOPIC_ACCEPTED =
  "/shadow/name/SoftwareVersionShadow/update/accepted";
export const SOFTWARE_VERSION_GET_TOPIC_ACCEPTED =
  "/shadow/name/SoftwareVersionShadow/get/accepted";
export const SOFTWARE_VERSION_GET_TOPIC =
  "/shadow/name/SoftwareVersionShadow/get";

export const CONFIG_UPDATE_TOPIC_ACCEPTED =
  "/shadow/name/ConfigShadow/update/accepted";
export const CONFIG_GET_TOPIC_ACCEPTED =
  "/shadow/name/ConfigShadow/get/accepted";
export const CONFIG_GET_TOPIC = "/shadow/name/ConfigShadow/get";

export const LOGS_GET_TOPIC = "/controller/logs/fetch";
export const LOGS_GET_TOPIC_ACCEPTED = "/controller/logs/fetch/accepted/";

export const CONNECTED_TOPIC = "$aws/events/presence/connected/#";
export const DISCONNECTED_TOPIC = "$aws/events/presence/disconnected/#";

export const CONNECTED_TOPIC_SUB = "$aws/events/presence/connected/";
export const DISCONNECTED_TOPIC_SUB = "$aws/events/presence/disconnected/";
