import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */

export const SearchIcon = ({ width, height, color }) => {
  const WIDTH = width || "16.707"; 
  const HEIGHT = height || "16.707"; 
  const COLOR = theme.colors[color] || theme.colors.textDarkerGrey;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={WIDTH}
      height={HEIGHT}
      viewBox="0 0 16.707 16.707"
    >
      <g
        id="Group_1745"
        data-name="Group 1745"
        transform="translate(-584 -980)"
      >
        <g
          id="Ellipse_34"
          data-name="Ellipse 34"
          transform="translate(584 980)"
          fill="none"
          stroke={COLOR}
        >
          <circle cx="6.857" cy="6.857" r="6.857" stroke="none" />
          <circle cx="6.857" cy="6.857" r="6.357" fill="none" />
        </g>
        <line
          id="Line_75"
          data-name="Line 75"
          x2="4.571"
          y2="4.571"
          transform="translate(595.429 991.429)"
          fill="none"
          stroke={COLOR}
        />
      </g>
    </svg>
  );
};
