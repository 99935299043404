import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const WarningIcon = ({
  width,
  height,
  color,
  fillColor,
  strokeWidth,
}) => {
  const WIDTH = width || "32";
  const HEIGHT = height || "34";
  const COLOR = theme.colors[color] || theme.colors.black;
  const STROKE_WIDTH = strokeWidth || "1";

  return (
    <svg
      width={WIDTH}
      height={HEIGHT}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 34"
    >
      <g
        id="Group_3020"
        data-name="Group 3020"
        transform="translate(-848 -664)"
      >
        <path
          id="Polygon_2"
          data-name="Polygon 2"
          d="M12.788,2.833a2,2,0,0,1,3.424,0L27.167,20.966A2,2,0,0,1,25.455,24H3.545a2,2,0,0,1-1.712-3.034Z"
          transform="translate(850 664)"
          fill={theme.colors[fillColor] || "#fc0"}
        />
        <g
          id="Group_3018"
          data-name="Group 3018"
          transform="translate(36.5 4.15)"
        >
          <g
            id="Rectangle_9274"
            data-name="Rectangle 9274"
            transform="translate(826.5 667.85)"
            stroke={COLOR}
            strokeWidth={STROKE_WIDTH}
          >
            <rect width="3" height="10" rx="1.5" stroke="none" />
            <rect x="0.5" y="0.5" width="2" height="9" rx="1" fill="none" />
          </g>
          <g
            id="Rectangle_9275"
            data-name="Rectangle 9275"
            transform="translate(826.5 678.85)"
            stroke={COLOR}
            strokeWidth={STROKE_WIDTH}
          >
            <rect width="3" height="3" rx="1.5" stroke="none" />
            <rect x="0.5" y="0.5" width="2" height="2" rx="1" fill="none" />
          </g>
        </g>
        <rect
          id="Rectangle_9699"
          data-name="Rectangle 9699"
          width="32"
          height="24"
          transform="translate(848 664)"
          fill="none"
        />
      </g>
    </svg>
  );
};
