import React from "react";
import { useTranslation } from "react-i18next";
import { UsageCard } from "../../../styles";

const CNMUsageCards = ({ cellDetails }) => {
  const { t } = useTranslation();

  return (
    <>
      <UsageCard
        heading={t("totalCuts")}
        subText={t("dailyAverage")}
        dailyUsageNumber={
          cellDetails.Usage.DailyUsage && cellDetails.Usage.DailyUsage.Cuts
            ? cellDetails.Usage.DailyUsage.Cuts
            : 0
        }
        subTextNumber={
          cellDetails.Usage.NumberOfCuts
            ? Math.round(
                parseFloat(
                  cellDetails.Usage.NumberOfCuts / cellDetails.daysPassed
                ).toFixed(2)
              )
            : 0
        }
        number={
          cellDetails.Usage.NumberOfCuts ? cellDetails.Usage.NumberOfCuts : 0
        }
      />
      <UsageCard
        heading={t("totalCutLength")}
        subText={t("dailyAverage")}
        averageUnit={"m"}
        dailyUsageNumber={
          cellDetails.Usage.DailyUsage && cellDetails.Usage.DailyUsage.CutLength
            ? (
                parseFloat(cellDetails.Usage.DailyUsage.CutLength) / 1000
              ).toFixed(2)
            : 0
        }
        unit={"m"}
        subTextNumber={
          cellDetails.Usage.CutLength
            ? cellDetails.Usage.DailyUsage
              ? (
                  cellDetails.Usage.CutLength /
                  1000 /
                  cellDetails.daysPassed
                ).toFixed(2)
              : "0"
            : 0
        }
        number={
          cellDetails.Usage.CutLength
            ? parseFloat(cellDetails.Usage.CutLength / 1000).toFixed(2)
            : 0
        }
      />
      <UsageCard
        heading={t("partsProduced")}
        subText={t("dailyAverage")}
        dailyUsageNumber={
          cellDetails.Usage.DailyUsage && cellDetails.Usage.DailyUsage.Parts
            ? cellDetails.Usage.DailyUsage.Parts
            : 0
        }
        subTextNumber={
          cellDetails.Usage.NumberOfParts
            ? Math.round(
                (
                  cellDetails.Usage.NumberOfParts / cellDetails.daysPassed
                ).toFixed(2)
              )
            : "0"
        }
        number={
          cellDetails.Usage.NumberOfParts ? cellDetails.Usage.NumberOfParts : 0
        }
      />
      <UsageCard
        heading={t("bladeLife")}
        subText={t("bladeChanges")}
        subTextNumber={
          cellDetails.Usage.BladesChanged
            ? cellDetails.Usage.BladesChanged.New +
              cellDetails.Usage.BladesChanged.Old
            : 0
        }
        unit={"m"}
        number={
          cellDetails.Usage.BladesChanged
            ? (
                parseFloat(cellDetails.Usage.CutLength) /
                1000 /
                (cellDetails.Usage.BladesChanged.New +
                  cellDetails.Usage.BladesChanged.Old +
                  1)
              ).toFixed(2)
            : 0
        }
      />
    </>
  );
};

export default CNMUsageCards;
