import styled from "styled-components";
import React from "react";
import { DoubleArrowIcon } from "..";

const ExpandIcon = styled.div`
  margin-left: 20px;
  height: 34px;
  width: 29px;
  min-width: 29px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const IconExpander = ({ expand, onClick }) => {
  return (
    <ExpandIcon onClick={onClick}>
      {expand ? (
        <DoubleArrowIcon height="12px" width="12px" transform="scale(1 -1)" />
      ) : (
        <DoubleArrowIcon height="12px" width="12px" />
      )}
    </ExpandIcon>
  );
};

export const DivExpander = styled.div`
  width: 100%;
  height: ${(p) => (p.height ? p.height : "250px")};
  animation-name: ${(p) =>
    p.expand
      ? "<Expand height={p.height} />"
      : "<Collapse height={p.height} />"};
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  margin-top: 10px;
`;
