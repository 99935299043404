import axios from "axios";
import { NetworkController, SessionManager } from "../../../utils";

require("dotenv").config();

var userController = null;

class User {
  registerUser = (data, callback) => {
    //setLoading(true);
    NetworkController.iam.registerUser(data, callback)
  };

  verifyUser = (data, callback) => {
    //setLoading(true);
    NetworkController.iam.verifyUser(data, callback);
  };

  loginUser = (data, callback) => {
    //setLoading(true);
    NetworkController.iam.loginUser(data, callback);
  };

  approveUser(data, callback) {
    NetworkController.iam.approveUser(data, callback);
  }

  getAllUsers(callback) {
    NetworkController.iam.getAllUsers(callback);
  }

  deleteUser(data, callback) {
    NetworkController.iam.deleteUser(data, callback);
  }

  forgotPassword(data, callback) {
    NetworkController.iam.forgotPassword(data, callback);
  }

  resetAPI(data, callback) {
    NetworkController.iam.resetAPI(data, callback);
  }

  resetPassword(data, callback) {
    NetworkController.iam.resetPassword(data, callback);
  }

  updateUser(data, callback) {
    NetworkController.iam.updateUser(data, callback);
  }

  changePassword(data, callback) {
    NetworkController.iam.changePassword(data, callback);
  }

  updateUserRole(data, callback) {
    NetworkController.iam.updateUser(data, callback);
  }

  updateUserRole1(data, callback) {
    axios
      .put(process.env.REACT_APP_USER_DETAILS_API, data, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        res.status = res.data.status;
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
}

class UserController {
  constructor() {
    this.user = new User();
  }
}

if (userController == null) {
  userController = new UserController();
}

export default userController;
