import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SessionManager } from "../../../utils";
import { LogsAccordion } from "../../../styles";
import {
  Spacing,
  PopUpHeading,
  PopUpTemplate,
  InlineText,
  ScrollingContainer,
  FormColumn,
} from "../../../commonalities";

const LogsPopUp = ({ setShowPopUp, cellId }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState("");
  const [logs, setLogs] = useState(t("fetchingLogs"));
  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };
  useEffect(() => {
    if (SessionManager.mqttClient) {
      SessionManager.mqttClient.publish(
        "sculptor/" + cellId + "/controller/logs/fetch",
        JSON.stringify({ fetch_log: SessionManager.clientID })
      );
      SessionManager.realtimeLogsCallback = (logs) => {
        if (logs.Logs.length === 0) {
          setLogs(t("cell-logsUnavailable"));
        } else setLogs(logs.Logs);
      };
    }
    return () => {
      SessionManager.realtimeLogsCallback = null;
    };
  }, []);
  return (
    <PopUpTemplate setShowPopUp={setShowPopUp} width={"680px"}>
      <Spacing padding="40px">
        <FormColumn alignItems="left" height="auto">
          <PopUpHeading textAlign={"left"} fontSize="xl">
            {t("realtimeLogs")}
          </PopUpHeading>
          <Spacing mTopvh={"3.89"} />
          <ScrollingContainer
            height={"350px"}
            mLeft="0px"
            width="100%"
            overflowX="visible"
            setFlexColumn={true}
          >
            {typeof logs === "string" ? (
              <InlineText
                minWidth="430px"
                lineHeight="24px"
                fontSize={"xs"}
                color="black"
              >
                {logs}
              </InlineText>
            ) : (
              logs.map((log, i) => {
                let key = log.tstamp + "-" + i;
                return (
                  <Spacing key={key} mRightvw={"1.67"} minWidth="560px">
                    <LogsAccordion
                      id={key}
                      log={log}
                      handleChange={handleChange}
                      expanded={expanded}
                    />
                  </Spacing>
                );
              })
            )}
          </ScrollingContainer>
        </FormColumn>
      </Spacing>
    </PopUpTemplate>
  );
};

export default LogsPopUp;
