import React from "react";
import { SettingsAccordion } from "../../../../../styles";
import { UnitsIcon } from "../../../../../icons";

const LanguageSettings = ({ settings, show, heading, setExpand }) => {
  const units = {
    1: "mm",
    10: "cm",
    1000: "m",
  };
  const data = {
    "cell-settingsGeneralUnit": settings ? units[settings.UnitFactor] : "-",
    "cell-settingsGeneralLanguage": settings
      ? settings.Language.toLowerCase()
      : "-",
  };

  return (
    <SettingsAccordion
      expand={"language"}
      iconImage={<UnitsIcon width="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default LanguageSettings;
