import { SessionManager } from "../../utils";

export const UpdateCellInformation = (cellID, data, metadata) => {
  if (data.ProcessType === "Cleaning" && data.Status === "Success") {
    if (SessionManager.jobHistory[cellID]) {
      SessionManager.jobHistory[cellID]["6"] += 1;
    } else {
      SessionManager.jobHistory[cellID] = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 1,
      };
    }
    SessionManager.cells[cellID].Usage.TotalJobs += 1;
    SessionManager.cells[cellID].Usage.TotalArea += parseFloat(
      metadata.SurfaceArea
    );
    SessionManager.cells[cellID].Usage.PanelCount += 1;
    SessionManager.cells[cellID].Usage.WorkHrsSaved = 0;

    if (SessionManager.cells[cellID].Usage.DailyUsage) {
      SessionManager.cells[cellID].Usage.DailyUsage.TotalArea += parseFloat(
        metadata.SurfaceArea
      );
      SessionManager.cells[cellID].Usage.DailyUsage.PanelCount += 1;
      SessionManager.cells[cellID].Usage.DailyUsage.WorkHrsSaved = 0;
    }
  }
};
