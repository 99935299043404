import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CalibrationAccordion, SettingsAccordion } from "../../../../../styles";
import { CalibrationIcon } from "../../../../../icons";

const CalibrationSettings = ({
  settings,
  config,
  show,
  heading,
  setExpand,
}) => {
  const { t } = useTranslation();
  const [calib, setCalib] = useState({ Fixture: {}, Tools: {}, Slots: [] });
  const [expanded, setExpanded] = useState("");

  useEffect(() => {
    if (config && settings) {
      var calibs = {
        Fixture: { transform: [] },
        Tools: { transform: [] },
        Slots: [],
      };

      //The if block should be removed once all the cells are migrated to a CNM version > 4.0.0
      if (settings.SelectedToolName) {
        config.tools.tools.forEach((element) => {
          if (element.tool_name === settings.SelectedToolName) {
            element.sub_tools.forEach((subTool) => {
              if (subTool.properties.length > 0) {
                // Tool transform position is stored in m in database
                // We convert it to mm to be in sync with application and other settings
                var toolTransform = {
                  orientation: subTool.calib_transform.orientation,
                  position: {
                    x: subTool.calib_transform.position.x * 1000,
                    y: subTool.calib_transform.position.y * 1000,
                    z: subTool.calib_transform.position.z * 1000,
                  },
                };
                calibs.Tools.transform.push({
                  name: subTool.sub_tool_name,
                  value: toolTransform,
                });
              }
            });
          }
        });
      } else {
        config.tools.tools.forEach((subTool) => {
          if (subTool.tool_id === settings.SelectedToolId) {
            var toolTransform = {
              orientation: subTool.calib_transform.orientation,
              position: {
                x: subTool.calib_transform.position.x * 1000,
                y: subTool.calib_transform.position.y * 1000,
                z: subTool.calib_transform.position.z * 1000,
              },
            };
            calibs.Tools.transform.push({
              name: settings.SelectedToolId,
              value: toolTransform,
            });
          }
        });
      }

      config.fixtures.fixtures.forEach((element) => {
        if (parseInt(element.wobj_count) === parseInt(settings.Fixture)) {
          calibs.Fixture.transform = [
            { name: "Fixture", value: element.base_transform },
          ];
          calibs.Fixture.size = element.wobj_data[0].size;
          for (var i = 0; i < element.wobj_data.length; i++) {
            let key = "F" + settings.Fixture + "_S" + (i + 1) + "_";
            let calibSettings = {
              "cell-settingsTileLength": settings[key + "TileX"] + " mm",
              "cell-settingsTileWidth": settings[key + "TileY"] + " mm",
              X: settings[key + "XCutCalib"] + " mm",
              Y: settings[key + "YCutCalib"] + " mm",
              "cell-settingsCuttingDepth":
                settings[key + "CutDepthCalib"] + " mm",
            };

            let calibMeasurements = {
              X1: settings[key + "X1Calib"] + " mm",
              X2: settings[key + "X2Calib"] + " mm",
              Y1: settings[key + "Y1Calib"] + " mm",
              Y2: settings[key + "Y2Calib"] + " mm",
              L: settings[key + "LCalib"] + " mm",
              R: settings[key + "RCalib"] + " mm",
              T: settings[key + "TCalib"] + " mm",
              B: settings[key + "BCalib"] + " mm",
            };

            calibs.Slots.push({
              transform: [{ name: key, value: element.wobj_data[i].transform }],
              calibSettings: calibSettings,
              calibMeasurements: calibMeasurements,
            });
          }
        }
      });
      setCalib(calibs);
    }
  }, [config, settings]);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  return (
    <SettingsAccordion
      expand={"toolCalibration"}
      iconImage={<CalibrationIcon width="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
    >
      <CalibrationAccordion
        handleChange={handleChange}
        expanded={expanded}
        id={"Tool"}
        calib={calib.Tools}
        title={t("settings-tool")}
      />
      <CalibrationAccordion
        isLast={calib.Slots.length === 0}
        handleChange={handleChange}
        expanded={expanded}
        id={"Fixture"}
        calib={calib.Fixture}
        title={t("settings-fixture")}
      />
      {calib.Slots.map((item, index) => {
        var date = new Date();
        return (
          <CalibrationAccordion
            isLast={calib.Slots.length === index + 1}
            key={"Slot" + index + date.getTime()}
            handleChange={handleChange}
            expanded={expanded}
            id={"Slot" + index}
            calib={item}
            title={t("cell-fixtureSlot") + " " + (index + 1)}
          />
        );
      })}
    </SettingsAccordion>
  );
};

export default CalibrationSettings;
