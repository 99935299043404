import React, { useState } from "react";
import {
  Spacing,
  ShadowDiv,
  DetailsContainer,
  Text,
  FormRow,
  EditIcon,
} from "../../../../commonalities";
import { useTranslation } from "react-i18next";
import { EditCellPopUp } from "../../..";
import InformationCardItem from "./InformationCardItem";

const InformationCard = ({ isEditable, cellDetails, setCellDetails, data }) => {
  const { t } = useTranslation();
  const [showPopUp, setShowPopUp] = useState(false);

  return (
    <>
      <ShadowDiv minWidth={"150px"} width={"100%"}>
        <DetailsContainer width={"75%"} margin={"1.67"}>
          <FormRow>
            <Text height="auto" fontSize={"lg"} font={"medium"}>
              {t("cell-information")}
            </Text>
            {isEditable && (
              <Spacing
                onClick={() => {
                  if (!showPopUp) {
                    setShowPopUp(true);
                  }
                }}
                setPointer={true}
                mLeft={"auto"}
                padding="2%"
              >
                <EditIcon color="black" strokeWidth="0.8" width={20} />
              </Spacing>
            )}
          </FormRow>
          <Spacing mTopvh={"1.48"} />
          {Object.entries(data).map((data, i) => {
            return <InformationCardItem key={i} data={data} />;
          })}
        </DetailsContainer>
      </ShadowDiv>
      {showPopUp && (
        <EditCellPopUp
          setCellDetails={setCellDetails}
          cellDetails={cellDetails}
          setShowPopUp={setShowPopUp}
        />
      )}
    </>
  );
};

export default InformationCard;
