import { useHistory } from "react-router-dom";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { DataCell, DeleteIcon } from "../..";
import { DeleteUserPopUp } from "../..";
import { Header as SiteHeader } from "../../../";
import {
  Spacing,
  Row,
  Cell,
  DropDown,
  Loader,
  PageHeading,
  PageBody,
  HeaderCell,
  TablePagination,
  TableContainer,
  RoundedBorderText,
} from "../..";
import * as Routes from "../../../routes/Routes";
import { useTranslation } from "react-i18next";
import UserController from "../network/UserController";
import { SessionManager } from "../../../utils";
import { useStore } from "../../../store";

const ManageUsers = ({ redirection }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [{ auth }] = useStore();
  const [search, setSearch] = useState("");
  const userDetails = JSON.parse(sessionStorage.getItem("UserData"));
  const [users, setUsers] = useState({ ...SessionManager.users });
  const [pageDetails, setPageDetails] = useState({ current: 1, total: 1 });
  const [loading, setLoading] = useState(false);
  const displayCount = useRef(0);
  const displayUsers = useRef([]);
  const [showDeletePopUp, setShowDeletePopUp] = useState({
    show: false,
    email: null,
    userName: "",
  });
  const [sort, setSort] = useState("FullName");
  const [isAscending, setIsAscending] = useState(1);

  const setSorting = (sortBy) => {
    if (sort === sortBy) {
      setIsAscending(-isAscending);
    } else {
      setIsAscending(1);
    }
    setSort(sortBy);
  };

  useEffect(() => {
    if (userDetails["userType"] !== "Admin") {
      history.push(Routes.ACCESS_DENIED);
    }
  }, []);

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { backgroundColor: "#000", color: "#fff" }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };
  const getUsers = () => {
    setLoading(true);
    UserController.user.getAllUsers((res) => {
      var data = {};
      res.data.users.forEach((user, i) => {
        data[user.Email] = user;
      });
      setLoading(false);
      SessionManager.users = data;
      setUsers({ ...SessionManager.users });
      setPageDetails({
        current: 1,
        total: Math.ceil(res.data.users.length / 10),
      });
    });
  };

  const deleteUser = (userEmail) => {
    setShowDeletePopUp({ show: false, email: null, userName: null });
    setLoading(true);
    UserController.user.deleteUser({ email: userEmail }, (res) => {
      delete SessionManager.users[userEmail];
      setUsers({ ...SessionManager.users });
      setLoading(false);
      setPageDetails({
        ...pageDetails,
        total: Math.ceil(Object.keys(SessionManager.users).length / 10),
      });
      if (auth.user.email === userEmail) {
        sessionStorage.clear();
        history.push(Routes.LOGIN);
        return;
      }
    });
  };

  useEffect(() => {
    displayUsers.current = (
      search === ""
        ? SessionManager.users !== null
          ? Object.values(SessionManager.users)
          : []
        : Object.values(SessionManager.users)
    )
      .filter((user) => {
        if (
          (user.FirstName + " " + user.LastName)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          user.Email.toLowerCase().includes(search.toLowerCase()) ||
          user.CompanyName.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      })
      .sort((a, b) => {
        if (sort === "FullName") {
          a["FullName"] = a["FirstName"] + a["LastName"];
          b["FullName"] = b["FirstName"] + b["LastName"];
        }
        if (
          a[sort]?.toString().toLowerCase() < b[sort]?.toString().toLowerCase()
        ) {
          return -isAscending;
        } else {
          return isAscending;
        }
      });
    setUsers(displayUsers.current);
  }, [search, isAscending, sort, pageDetails]);

  const approveUser = (userEmail, firstName, lastName) => {
    setLoading(true);
    UserController.user.approveUser(
      {
        language: SessionManager.language,
        email: userEmail,
        firstName: firstName,
        lastName: lastName,
        loginLink: window.location.href.replace(Routes.MANAGE_USERS, ""),
      },
      (res) => {
        SessionManager.users[userEmail].isApproved = true;
        setLoading(false);
        setUsers({ ...SessionManager.users });
      }
    );
  };
  useEffect(() => {
    getUsers();
  }, []);
  displayCount.current = 0;

  return (
    <>
      <SiteHeader search={search} setSearch={setSearch} />
      {loading && <Loader />}
      <PageBody>
        <PageHeading heading={"manageUsersTitle"} redirection={redirection} />
        <TableContainer setPadding={true} setMBottom={true} width={"75%"}>
          <Row width={"100%"}>
            <Cell width={"2.413%"} />
            <Cell width={"2.425%"} />
            <Cell width={"1.075%"} />
            <HeaderCell
              onClick={() => setSorting("FullName")}
              isAscending={isAscending}
              isSelected={sort === "FullName"}
              heading={"userName"}
              width={"22%"}
            />
            <HeaderCell
              onClick={() => setSorting("Email")}
              isAscending={isAscending}
              isSelected={sort === "Email"}
              heading={"emailID"}
              width={"26%"}
            />
            <HeaderCell
              onClick={() => setSorting("CompanyName")}
              isAscending={isAscending}
              isSelected={sort === "CompanyName"}
              heading={"company"}
              width={"16.752%"}
            />
            <HeaderCell
              onClick={() => setSorting("isApproved")}
              isAscending={isAscending}
              isSelected={sort === "isApproved"}
              heading={"status"}
              width={"16.516%"}
            />
            <HeaderCell
              onClick={() => setSorting("UserType")}
              isAscending={isAscending}
              isSelected={sort === "UserType"}
              heading={"role"}
              width={"9.152%"}
            />
            <Cell width={"1.075%"} />
            <Cell width={"2.688%"} />
            <Cell width={"1.613%"} />
          </Row>
          {displayUsers.current
            .slice((pageDetails.current - 1) * 10, pageDetails.current * 10)
            .map((user, index) => {
              var i = (pageDetails.current - 1) * 10 + (index + 1);
              displayCount.current += 1;
              return (
                <Spacing key={user.Email + i} mTopvh={"1.67"}>
                  <Row width={"100%"}>
                    <Cell width={"2.413%"} />
                    <DataCell width={"2.425%"} value={i} tooltipValue={i} />
                    <Cell width={"1.075%"} />
                    <DataCell
                      width={"22%"}
                      value={getHighlightedText(
                        user.FirstName + " " + user.LastName,
                        search
                      )}
                      tooltipValue={user.FirstName + " " + user.LastName}
                    />
                    <DataCell
                      width={"26%"}
                      value={getHighlightedText(user.Email, search)}
                      tooltipValue={user.Email}
                    />
                    <DataCell
                      width={"16.752%"}
                      value={getHighlightedText(user.CompanyName, search)}
                      tooltipValue={user.CompanyName}
                    />
                    <DataCell
                      nowrap={true}
                      width={"16.516%"}
                      vPadding={"0.74"}
                      color={user.isApproved ? "green" : null}
                      value={
                        user.isApproved ? (
                          <RoundedBorderText
                            padding="5px 15px"
                            font="small"
                            borderWeight="1px"
                          >
                            {t("userApproved")}
                          </RoundedBorderText>
                        ) : (
                          <Spacing width="110px">
                            <DropDown
                              scrollToView={false}
                              bgColor={"orange"}
                              textColor={"white"}
                              arrowColor={"white"}
                              width={"100%"}
                              vPadding={"0.65"}
                              mTop={"4"}
                              hPadding={"1"}
                              borderRadius={"sm"}
                              height="auto"
                              defaultValue={t("userApprovalPending")}
                              optionList={{ approved: t("approveUser") }}
                              onChange={(value) => {
                                approveUser(
                                  user.Email,
                                  user.FirstName,
                                  user.LastName
                                );
                              }}
                              value={t(user.UserType)}
                              widthvw="8"
                            />
                          </Spacing>
                        )
                      }
                    />
                    <DataCell
                      width={"9.152%"}
                      value={getHighlightedText(user.UserType, search)}
                      tooltipValue={user.UserType}
                    />
                    <Cell width={"1.075%"} />
                    <Cell
                      setPointer={true}
                      padding={"0px 0px 0px 0px"}
                      onClick={() =>
                        setShowDeletePopUp({
                          show: true,
                          email: user.Email,
                          userName: user.FirstName + " " + user.LastName,
                        })
                      }
                      width={"2.688%"}
                    >
                      <Spacing margin={"auto"} setFitContent={true}>
                        <DeleteIcon />
                      </Spacing>
                    </Cell>
                    <Cell width={"1.613%"} />
                  </Row>
                </Spacing>
              );
            })}
          <TablePagination
            displayCount={displayCount.current}
            totalItems={users ? Object.keys(users).length : 0}
            currentPage={pageDetails.current}
            setCurrentPage={(value) => {
              setPageDetails({ ...pageDetails, current: value });
            }}
            totalPages={pageDetails.total}
          />
        </TableContainer>
      </PageBody>
      {showDeletePopUp.show && (
        <DeleteUserPopUp
          email={showDeletePopUp.email}
          userName={showDeletePopUp.userName}
          deleteUser={deleteUser}
          setShowDeletePopUp={setShowDeletePopUp}
        />
      )}
    </>
  );
};

export default ManageUsers;
