import React from "react";
import { SettingsAccordion } from "../../../../../styles";
import { SpeedIcon } from "../../../../../icons";

const SpeedSettings = ({ settings, show, heading, setExpand }) => {
  const data = {
    "cell-settingsTop": settings ? settings.Top_Speed + " mm/s" : "-",
    "cell-settingsSide": settings ? settings.Side_Speed + " mm/s" : "-",
  };

  return (
    <SettingsAccordion
      expand={"speed"}
      iconImage={<SpeedIcon width="100%" height="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default SpeedSettings;
