import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FormColumn,
  BrokenLineChart,
  BarChart,
  ShadowDiv,
  ChartTooltip,
} from "../../../commonalities";
import { defaults } from "react-chartjs-2";
import { getData as getBLChartData } from "./BrokenLineChartData";
import { getData as getBarChartData } from "./BarChartData";
import i18n from "../../../commonalities/i18n";

export default function RightColumn({ setUpdateGraphFunction }) {
  const { t } = useTranslation();

  const availableChartFilters = {
    active: t("cell-last24hrs"),
    mostUsed: t("cell-mostUsed"),
    leastUsed: t("cell-leastUsed"),
  };

  let currentBarChartOption = Object.keys(availableChartFilters)[0];
  const [barChartData, setBarChartData] = useState(
    getBarChartData(currentBarChartOption)
  );

  let currentBLChartOption = Object.keys(availableChartFilters)[0];
  const [BLChartCaption, setBLChartCaption] = useState("");
  const [BLChartData, setBLChartData] = useState(
    getBLChartData(currentBLChartOption)
  );

  const BLChartStyles = {
    title: t("controlRoom-production"),
    caption: BLChartCaption,
    customChartStyle: {
      scales: {
        x: {
          ticks: {
            callback: (value, index, values) => {
              return value[0];
            },
          },
        },
        y: {
          suggestedMin: 0,
          suggestedMax: 20,
        },
      },
    },
  };

  const BarChartStyles = {
    title: t("controlRoom-activeHours"),
    subtitle: t("controlRoom-weeklyAverage").toUpperCase(),
    customChartStyle: {
      plugins: {
        tooltip: {
          external: (context, customTooltip) => {
            // Modify ycoord in customTooltip.data
            let { data } = customTooltip;
            for (let i = 0; i < data?.length; i++) {
              data[i].ycoord = JSON.parse(data[i].ycoord);
              let a = data[i].ycoord[1] - data[i].ycoord[0];
              const hours = Math.floor(a);
              const minutes = Math.round((a - hours) * 60);
              data[i].ycoord =
                hours === 0 ? `${minutes}m` : `${hours}h ${minutes}m`;
            }
            ChartTooltip(context, customTooltip);
          },
        },
      },
      scales: {
        y: {
          min: -6,
          max: 6,
          grid: {
            lineWidth: [1, 1, 1, 2, 1, 1, 1],
            color: [
              defaults.borderColor,
              defaults.borderColor,
              defaults.borderColor,
              "#707070",
              defaults.borderColor,
              defaults.borderColor,
              defaults.borderColor,
            ],
          },
          ticks: {
            stepSize: 2,
            callback: function (value, index, values) {
              if (index < 3) {
                return 12 - Math.abs(value) + " am";
              } else if (index > 3) {
                return Math.abs(value) + " pm";
              } else {
                return "12pm";
              }
            },
          },
        },
      },
    },
  };

  const handleBLChartDropDownChange = (value) => {
    setBLChartData(getBLChartData(value));
    currentBLChartOption = value;
  };

  const handleBarChartDropDownChange = (value) => {
    setBarChartData(getBarChartData(value));
    currentBarChartOption = value;
  };

  const updateGraphData = () => {
    setBLChartData(getBLChartData(currentBLChartOption));
    setBarChartData(getBarChartData(currentBarChartOption));
  };

  setUpdateGraphFunction(updateGraphData);

  useEffect(() => {
    const now = new Date();
    const from = new Date(now - 6 * 24 * 60 * 60 * 1000);

    //The date is converted to the selected locale and changed to the format "Jan 1 1970"
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const fromDateStr = from
      .toLocaleString(i18n.language, options)
      .replace(",", "")
      .replace(".", "");
    const toDateStr = now
      .toLocaleString(i18n.language, options)
      .replace(",", "")
      .replace(".", "");

    // The stored date is printed in the format "Jan 1 1970 - Jan 7 1970"
    setBLChartCaption(`${fromDateStr} - ${toDateStr}`);
  }, []);

  return (
    <FormColumn
      gap="2.96"
      padding="0 5px"
      widthvw={"22.615"}
      height="100%"
      alignItems="stretch"
    >
      <ShadowDiv
        borderRadius="sm"
        vPadding={"2.6"}
        hPadding={"1.67"}
        height={"30.13"}
        width={`calc(100%- (2 * 1.67vw))`}
        flexDirection="column"
      >
        <BrokenLineChart
          dataset={BLChartData}
          chartStyle={BLChartStyles}
          filters={availableChartFilters}
          handleFilterChange={handleBLChartDropDownChange}
        />
      </ShadowDiv>

      <ShadowDiv
        borderRadius="sm"
        vPadding={"2.6"}
        hPadding={"1.67"}
        height={"30.13"}
        width={`calc(100%- (2 * 1.67vw))`}
        flexDirection="column"
      >
        <BarChart
          dataset={barChartData}
          chartStyle={BarChartStyles}
          filters={availableChartFilters}
          handleFilterChange={handleBarChartDropDownChange}
        />
      </ShadowDiv>
    </FormColumn>
  );
}
