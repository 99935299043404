import React from "react";
import { useTranslation } from "react-i18next";
import { PropertyCard } from "../../../styles";

const CNMHardwareStatus = ({ hardwareStatus }) => {
  const { t } = useTranslation();
  const data = {
    "cell-statusCleaningWaterPump": hardwareStatus.CleaningWaterPump
      ? "fms-" + hardwareStatus.CleaningWaterPump.toLowerCase()
      : "fms-unavailable",
    "cell-statusToolWaterPump": hardwareStatus.ToolWaterPump
      ? "fms-" + hardwareStatus.ToolWaterPump.toLowerCase()
      : "fms-unavailable",
    "cell-statusWaterLevel": hardwareStatus.WaterLevelBottomTank
      ? "fms-" + hardwareStatus.WaterLevelBottomTank.toLowerCase()
      : "fms-unavailable",
    "cell-statusDoor": hardwareStatus.DoorStatus
      ? "fms-" + hardwareStatus.DoorStatus.toLowerCase()
      : "fms-unavailable",
    "cell-statusFrequencyDriveConnected": hardwareStatus.FrequencyDriveConnected
      ? "fms-" + hardwareStatus.FrequencyDriveConnected.toLowerCase()
      : "fms-unavailable",
    "cell-statusFrequencyDriveRunning": hardwareStatus.FrequencyDriveRunning
      ? "fms-" + hardwareStatus.FrequencyDriveRunning.toLowerCase()
      : "fms-unavailable",
    "cell-statusFrequencyDriveFaulted": hardwareStatus.FrequencyDriveFaulted
      ? "fms-" + hardwareStatus.FrequencyDriveFaulted.toLowerCase()
      : "fms-unavailable",
    "cell-statusRobotMode": hardwareStatus.RobotMode
      ? "fms-" + hardwareStatus.RobotMode.toLowerCase()
      : "fms-unavailable",
    "cell-statusEStopped": hardwareStatus.EStopped
      ? "fms-" + hardwareStatus.EStopped.toLowerCase()
      : "fms-unavailable",
    "cell-statusDrivesPowered": hardwareStatus.DrivesPowered
      ? "fms-" + hardwareStatus.DrivesPowered.toLowerCase()
      : "fms-unavailable",
  };

  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        return (
          <PropertyCard
            key={key}
            mTop={"1.27"}
            fontSize={"md"}
            name={key}
            value={t(value)}
          />
        );
      })}
    </>
  );
};

export default CNMHardwareStatus;
