import React from "react";
import {
  Spacing,
  Row,
  ShadowDiv,
  InlineText,
  Text,
} from "../commonalities";

const UsageCard = ({
  heading,
  subText,
  subTextNumber,
  averageUnit,
  number,
  dailyUsageNumber,
  unit,
}) => {
  return (
    <Spacing setFlex={true} widthvw="22.09" mTopvw="0.28" mBottomvw="1.67">
      <ShadowDiv
        borderRadius="sm"
        width="18.435"
        flexDirection="column"
        padding="1.67"
      >
        <Row shadow={"unset"} width="100%">
          <InlineText color="black" font="medium" fontSize="lg">
            {heading}
          </InlineText>
          <Text
            flex={true}
            lineHeight="1.2"
            font="bold"
            color="green"
            width="auto"
            mRight="6px"
            mLeft="auto"
          >
            {dailyUsageNumber
              ? "+" + dailyUsageNumber + " " + (unit ? unit : "")
              : ""}
          </Text>
        </Row>
        <Row shadow={"unset"} margin={"auto 0 0 0"} width={"100%"}>
          <Spacing>
            <Spacing>
              <Spacing mBottomvh={"0.37"} setInline={true}>
                <Text flex={true} height="auto" lineHeight="1.2" float="none">
                  {subTextNumber}
                </Text>
              </Spacing>
              {averageUnit && (
                <Spacing setInline={true} mLeftvw={"0.42"}>
                  <Text
                    flex={true}
                    height="auto"
                    lineHeight="1.2"
                    float="none"
                    fontSize="xs"
                  >
                    {averageUnit}
                  </Text>
                </Spacing>
              )}
            </Spacing>
            <InlineText letterSpacing="0" alignSelf="flex-end" fontSize="xs">
              {subText}
            </InlineText>
          </Spacing>
          <InlineText
            color="black"
            alignSelf="flex-end"
            font="medium"
            fontSize="xxl"
            mBottom="-4px"
            mLeft="auto"
          >
            {number}
          </InlineText>
          <InlineText
            color="black"
            alignSelf="flex-end"
            font="medium"
            mBottom="-1"
            mLeft="6px"
          >
            {unit}
          </InlineText>
        </Row>
      </ShadowDiv>
    </Spacing>
  );
};

export default UsageCard;
