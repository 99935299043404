import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import styled from "styled-components";
import {
  Row,
  Spacing,
  FormRow,
  SearchIcon,
  PlusIcon,
  ArrowIcon,
  TickIcon,
  AlertIcon,
  ScrollingContainer,
  OptionsContainer,
  SolidArrowIcon,
  Flexbox,
  Tooltip,
  InlineText,
} from "..";
import { appTheme } from "../Theme";

const Root = styled.div`
  display: flex;
  width: ${(p) =>
    p.width.includes("%")
      ? p.width
      : `calc((var(--useMinWidth) * var(--widthLimit) * ${
          p.width ? p.width : "none"
        } / 100) + (var(--usePercentWidth) * ${
          p.width ? p.width : "none"
        }vw))`};
  position: relative;
  flex-direction: column;
  margin-left: ${(p) => (p.mLeft ? p.mLeft : "0px")};
  min-width: ${(p) => (p.minWidth ? p.minWidth : "0px")};
`;

const Select = styled.div`
  display: flex;
  height: ${(p) => (p.height ? p.height : "15px")};
  cursor: pointer;
  ${(p) => p.fontSize && `font-size:${p.theme.fontSize[p.fontSize]}`};
  ${(p) => p.font && `font-family:${p.theme.font[p.font]}`};
  color: ${(p) =>
    p.textColor ? p.theme.colors[p.textColor] : p.theme.colors.black};
  background-color: ${(p) => (p.bgColor ? p.theme.colors[p.bgColor] : "none")};
  border: ${(p) =>
    p.bgColor
      ? "none"
      : `1px solid ${
          p.borderColor ? p.theme.colors[p.borderColor] : p.theme.colors.grey
        }`};
  border-radius: ${(p) =>
    p.borderRadius
      ? p.theme.borderRadius[p.borderRadius]
      : p.theme.borderRadius.xxxs};
  padding: ${(p) => `calc(
      (var(--useMin) * var(--heightLimit) * ${p.vPadding || "1.02"} / 100) +
        (var(--usePercent) * ${p.vPadding || "1.02"}vh)
    )
    calc(
      (var(--useMinWidth) * var(--widthLimit) * ${p.hPadding || "0.83"} / 100) +
        (var(--usePercentWidth) * ${p.hPadding || "0.83"}vw)
    )`};
  ${(p) => p.padding && `padding:${p.padding}`};
`;

const OptionsRow = styled(Row)`
  box-shadow: none;
  width: auto;
  padding: 0px;
  cursor: pointer;
  margin-left: calc(
    (var(--useMinWidth) * var(--widthLimit) * 0.83 / 100) +
      (var(--usePercentWidth) * 0.84vw)
  );
  margin-right: calc(
    (var(--useMinWidth) * var(--widthLimit) * 0.83 / 100) +
      (var(--usePercentWidth) * 0.84vw)
  );
`;

export const OptionCell = styled.div`
  width: ${(p) =>
    p.widthvw
      ? `calc((var(--useMinWidth) * var(--widthLimit) * ${p.widthvw} / 100) + (var(--usePercentWidth) * ${p.widthvw}vw))`
      : "fit-content"};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  padding: calc(
      (var(--useMin) * var(--heightLimit) * 0.84 / 100) +
        (var(--usePercent) * 0.84vh)
    )
    0px;
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.sm};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.light)};
  ${(p) => p.color && `color: ${p.theme.colors[p.color]}`};
  visibility: ${(p) => (p.visibility ? p.visibility : "visible")};
`;

const Input = styled.input`
  border: none;
  max-width: 90px;
  padding: 0px;
  border-color: transparent;
  &:focus {
    outline: none;
  }
`;

const LineDiv = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: ${(p) => p.theme.colors.textDarkGrey};
`;

const ImgContainer = styled.div`
  vertical-align: middle;
  cursor: pointer;
  padding: ${(p) => (p.padding ? p.padding : "0")};
`;

export const MultiSelectionDropDown = ({
  showLabel,
  labelText,
  dropdownText,
  minWidth,
  onClose,
  textColor,
  width,
  onChange,
  optionList,
  value,
  fontSize,
  font,
  search,
  mTop,
}) => {
  const [isActive, setActive] = useState(false);
  const [dropdownDisplayText, setDropdownDisplayText] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const compnentRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (compnentRef.current && !compnentRef.current.contains(event.target)) {
        setActive(false);
        onClose && onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let text = "";
    if (showLabel) {
      if (value.length === 0) {
        text = dropdownText;
      } else {
        value.forEach((val) => {
          if (text !== "") {
            text += ", ";
          }
          text += optionList[val];
        });
      }
    } else {
      text = dropdownText;
      if (value.length > 0) {
        text += " (" + value.length + ")";
      }
    }
    setDropdownDisplayText(text);
  });

  return (
    <Root
      minWidth={minWidth}
      ref={(ref) => {
        compnentRef.current = ref;
      }}
      width={width}
    >
      {showLabel && (
        <Spacing fontSize="xs" color="textDarkerGrey" mBottom="5px">
          {labelText}
          {value.length > 0 && " (" + value.length + ")"}
        </Spacing>
      )}
      <Select
        fontSize={fontSize}
        font={font}
        textColor={
          (isActive || value.length > 0) && !showLabel ? "orange" : textColor
        }
        onClick={() => setActive(!isActive)}
        borderColor={
          (isActive || value.length > 0) && !showLabel ? "orange" : textColor
        }
      >
        {dropdownDisplayText}
        <Spacing mLeft={"auto"}>
          <ImgContainer alt={"expand"}>
            <Flexbox>
              <ArrowIcon
                color={
                  (isActive || value.length > 0) && !showLabel
                    ? "orange"
                    : "textGrey"
                }
                height={"18"}
                type={isActive ? "up" : "down"}
              />
            </Flexbox>
          </ImgContainer>
        </Spacing>
      </Select>
      {isActive && (
        <OptionsContainer mTop={mTop}>
          <ScrollingContainer mLeft={"0"} maxHeight={"120px"}>
            {search && (
              <>
                <OptionsRow width={"100%"}>
                  <Spacing mTop={"6px"} widthvw={"1.25"}>
                    <SearchIcon width={"11px"} />
                  </Spacing>
                  <OptionCell widthvw={"0.5"} />
                  <OptionCell font={"light"} fontSize={"xs"} width={"auto"}>
                    <Input
                      type="text"
                      value={searchInput}
                      placeholder={t("buttons-search")}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </OptionCell>
                </OptionsRow>
                <OptionsRow>
                  <LineDiv />
                </OptionsRow>
              </>
            )}
            {Object.keys(optionList)
              .filter((item) => {
                return optionList[item]
                  ?.toLocaleLowerCase()
                  ?.includes(searchInput.toLocaleLowerCase());
              })
              .map((item) => {
                return (
                  <OptionsRow
                    color={
                      value.indexOf(item) >= 0
                        ? appTheme.colors.black
                        : appTheme.colors.textDarkGrey
                    }
                    key={item}
                    onClick={() => {
                      value.includes(item)
                        ? value.splice(value.indexOf(item), 1)
                        : value.push(item);
                      onChange(value);
                    }}
                    width={"100%"}
                  >
                    <Spacing mTop="7px" widthvw={"1.25"}>
                      <PlusIcon
                        width={"12px"}
                        color={value.indexOf(item) >= 0 ? "orange" : "grey"}
                      />
                    </Spacing>
                    <OptionCell widthvw={"0.5"} />
                    <OptionCell
                      font={value.indexOf(item) >= 0 ? "regular" : "light"}
                      fontSize={"xs"}
                      width={"auto"}
                    >
                      {optionList[item]}
                    </OptionCell>
                  </OptionsRow>
                );
              })}
          </ScrollingContainer>
        </OptionsContainer>
      )}
    </Root>
  );
};

export const DropDown = ({
  name,
  minWidth,
  onClose,
  bgColor,
  arrowColor,
  textColor,
  width,
  onChange,
  optionList,
  value,
  defaultValue,
  borderRadius,
  padding,
  vPadding,
  hPadding,
  fontSize,
  font,
  mLeft,
  errorMsg,
  height,
  mTop,
  solidArrow,
  subitem,
  containerWidth,
  widthvw,
  maxHeight,
  maintainColor,
  scrollToView = true,
}) => {
  const [isActive, setActive] = useState(false);
  const compnentRef = useRef(null);
  const el = React.useRef(undefined);
  useEffect(() => {
    function handleClickOutside(event) {
      if (compnentRef.current && !compnentRef.current.contains(event.target)) {
        setActive(false);
        onClose && onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    const scrollToSelection = setTimeout(() => {
      isActive &&
        scrollToView &&
        document.getElementById(value).scrollIntoView({
          behavior: "smooth",
        });
    }, 100);
    return () => {
      setTimeout(scrollToSelection);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  return (
    <Root
      minWidth={minWidth}
      mLeft={mLeft}
      ref={(ref) => {
        compnentRef.current = ref;
      }}
      width={width}
    >
      <Select
        fontSize={fontSize}
        font={font}
        textColor={textColor}
        padding={padding}
        vPadding={vPadding}
        hPadding={hPadding}
        bgColor={bgColor}
        height={height}
        borderColor={errorMsg ? "red" : "grey"}
        borderRadius={borderRadius}
        onClick={() => setActive(!isActive)}
      >
        <InlineText nowrap={"true"} fontSize={"sm"} color={textColor}>
          {typeof optionList[value] === "object"
            ? value
            : optionList[value]
            ? optionList[value]
            : defaultValue}
        </InlineText>
        <Spacing mLeft={"auto"}>
          <FormRow>
            {solidArrow ? (
              <ImgContainer padding="0px 0px 0px 3px">
                <Flexbox>
                  <SolidArrowIcon
                    width={"7px"}
                    height={"18"}
                    type={isActive ? "up" : "down"}
                  />
                </Flexbox>
              </ImgContainer>
            ) : (
              <ImgContainer>
                <Flexbox>
                  <ArrowIcon
                    color={arrowColor}
                    height={"18"}
                    type={isActive ? "up" : "down"}
                  />
                </Flexbox>
              </ImgContainer>
            )}
            {errorMsg ? (
              <>
                <Spacing mLeftvw="0.5" />
                <Spacing data-tip data-for={name}>
                  <AlertIcon />
                </Spacing>
                <Tooltip id={name} value={errorMsg} />
              </>
            ) : (
              <></>
            )}
          </FormRow>
        </Spacing>
      </Select>

      {isActive && (
        <OptionsContainer
          mTop={mTop}
          width={containerWidth}
          borderRadius={borderRadius}
        >
          <ScrollingContainer mLeft={"0"} maxHeight={maxHeight || "120px"}>
            {Object.keys(optionList).map((item) => {
              return (
                <OptionsRow
                  id={item}
                  key={item}
                  onClick={() => {
                    if (item !== value) {
                      onChange(item);
                    }
                    setActive(false);
                  }}
                  width={"100%"}
                >
                  <OptionCell fontSize={"xs"} widthvw={"1.25"}>
                    {(item === value ||
                      Object.keys(optionList).length === 1) && (
                      <TickIcon width={"13"} height={"11"} color={"orange"} />
                    )}
                  </OptionCell>
                  <OptionCell widthvw={"0.5"} />
                  <OptionCell
                    widthvw={widthvw || "auto"}
                    style={{
                      fontSize: fontSize
                        ? appTheme.fontSize[fontSize]
                        : appTheme.fontSize.sm,
                    }}
                  >
                    <InlineText
                      color={
                        maintainColor
                          ? "black"
                          : item === value
                          ? "black"
                          : "textDarkGrey"
                      }
                      font={maintainColor && item === value && "regular"}
                      fontSize={"sm"}
                      nowrap={"true"}
                      ref={el}
                      data-tip
                      data-for={
                        typeof optionList[item] === "object"
                          ? optionList[item][subitem]
                          : optionList[item]
                      }
                    >
                      {typeof optionList[item] === "object"
                        ? optionList[item][subitem]
                        : optionList[item]}
                    </InlineText>
                    <Tooltip
                      el={el}
                      textColor={"black"}
                      value={
                        typeof optionList[item] === "object"
                          ? optionList[item][subitem]
                          : optionList[item]
                      }
                    />
                  </OptionCell>
                </OptionsRow>
              );
            })}
          </ScrollingContainer>
        </OptionsContainer>
      )}
    </Root>
  );
};
