import React from "react";
import { appTheme as theme } from "../commonalities/Theme";

export const ToolIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "72";
  const COLOR = theme.colors[color] || theme.colors.textDarkGrey;
  const STROKE_WIDTH = strokeWidth || "3";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
    >
      <g
        id="Group_2894"
        data-name="Group 2894"
        transform="translate(-728 -816)"
      >
        <g
          id="Group_1761"
          data-name="Group 1761"
          transform="translate(-184 256)"
        >
          <circle
            id="Ellipse_13"
            data-name="Ellipse 13"
            cx="28"
            cy="28"
            r="28"
            transform="translate(920 568)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeWidth={STROKE_WIDTH}
            strokeDasharray="6 10"
          />
          <circle
            id="Ellipse_14"
            data-name="Ellipse 14"
            cx="20"
            cy="20"
            r="20"
            transform="translate(928 576)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeWidth={STROKE_WIDTH}
          />
          <circle
            id="Ellipse_15"
            data-name="Ellipse 15"
            cx="5.49"
            cy="5.49"
            r="5.49"
            transform="translate(942.556 590.556)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth={STROKE_WIDTH}
          />
        </g>
        <rect
          id="Rectangle_9611"
          data-name="Rectangle 9611"
          width="72"
          height="72"
          transform="translate(728 816)"
          fill="none"
        />
      </g>
    </svg>
  );
};
