import React from "react";
import { appTheme as theme } from "../commonalities/Theme";

export const CalibrationIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "72";
  const COLOR = theme.colors[color] || theme.colors.textDarkGrey;
  const STROKE_WIDTH = strokeWidth || "3";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
    >
      <g
        id="Group_2896"
        data-name="Group 2896"
        transform="translate(-808 -912)"
      >
        <g
          id="Layer_3"
          data-name="Layer 3"
          transform="translate(80.986 -779.422)"
        >
          <g
            id="Group_1583"
            data-name="Group 1583"
            transform="translate(742.014 1699.422)"
          >
            <path
              id="Path_826"
              data-name="Path 826"
              d="M821.012,1777.012a27.623,27.623,0,0,0,3.638-2.542"
              transform="translate(-786.16 -1743.666)"
              fill="none"
              stroke={COLOR}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={STROKE_WIDTH}
            />
            <path
              id="Path_827"
              data-name="Path 827"
              d="M772.325,1786.837a35.827,35.827,0,0,0,15.119,0"
              transform="translate(-758.925 -1750.837)"
              fill="none"
              stroke={COLOR}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={STROKE_WIDTH}
            />
            <path
              id="Path_828"
              data-name="Path 828"
              d="M749.655,1774.414a27.639,27.639,0,0,0,3.664,2.563"
              transform="translate(-746.284 -1743.633)"
              fill="none"
              stroke={COLOR}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={STROKE_WIDTH}
            />
            <path
              id="Path_829"
              data-name="Path 829"
              d="M788.147,1711.375a5.05,5.05,0,1,1-7.171-4.589v-5.243a2.121,2.121,0,0,1,4.242,0v5.243A5.054,5.054,0,0,1,788.147,1711.375Z"
              transform="translate(-762.15 -1699.422)"
              fill="none"
              stroke={COLOR}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={STROKE_WIDTH}
            />
            <path
              id="Path_830"
              data-name="Path 830"
              d="M784.242,1773.683l-2.863-2.956-2.705-2.792-.87.357-14.847-30.254-14.022,28.571-.825,1.683-2.643,5.388-1.168-.982a2.814,2.814,0,0,1-.782-3.437l1.164-2.371-2.666-1.09,16.542-33.7a5.292,5.292,0,0,0,8.805.008L783.9,1765.8l-.645.264.479,3.7Z"
              transform="translate(-742.014 -1717.683)"
              fill="none"
              stroke={COLOR}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={STROKE_WIDTH}
            />
          </g>
        </g>
        <rect
          id="Rectangle_9613"
          data-name="Rectangle 9613"
          width="72"
          height="72"
          transform="translate(808 912)"
          fill="none"
        />
      </g>
    </svg>
  );
};
