import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Spacing,
  Loader,
  AppHeading,
  H2,
  H3,
  H4,
  InlineText,
  FormHeading,
  GrayPageContainer,
  FlexContainer,
  CenteredContent,
  ShadowDiv,
} from "../..";
import * as Routes from "../../../routes/Routes";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import UserController from "../network/UserController";
import { SessionManager } from "../../../utils";

const AccountVerification = () => {
  const params = useParams();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const [verificationResponse, setVerificationResponse] = useState(0);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    UserController.user.verifyUser(
      { verificationToken: params.token, 
        email: query.get("email") 
      },
      (res) => {
        setVerificationResponse(res.data.status);
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      {loading && <Loader />}
      <GrayPageContainer>
        <AppHeading redirection={Routes.LOGIN} />
        <FlexContainer>
          <Spacing margin="auto" width="900px">
            <Spacing mTopvh="10" />
            <ShadowDiv
              width={"fit-content"}
              margin={"auto"}
              padding="8%"
            >
              {verificationResponse === 200 &&
                (SessionManager.appHeading === "pc-application" ? (
                  <CenteredContent>
                    <FormHeading>{t("user-accountVerified")}</FormHeading>
                    <Spacing mTopvh={"3.7"}>
                      <H2>{t("accountVerification-thankYou")}</H2>
                      <H3>{t("accountVerification-accountVerified")}</H3>
                      <H4>{t("accountVerification-clickBelow")}</H4>
                    </Spacing>
                    <Spacing mTopvh={"3.7"}>
                      <Button onClick={() => history.push(Routes.LOGIN)}>
                        {t("buttons-continueLogin")}
                      </Button>
                    </Spacing>
                  </CenteredContent>
                ) : (
                  <CenteredContent>
                    <FormHeading>{t("user-accountVerified")}</FormHeading>
                    <Spacing mTopvh={"3.7"}>
                      <InlineText>{t("user-verifiedMsg1")}</InlineText>
                      <Spacing mTopvh={"2.4"} setInline={"true"}>
                        <InlineText font="regular" color="black">{query.get("email")}</InlineText>
                      </Spacing>
                      <Spacing mTopvh={"2.4"}>
                        <InlineText>{t("user-verifiedMsg2")}</InlineText>
                      </Spacing>
                      <Spacing mTopvh={"2.4"}>
                        <InlineText>{t("user-verifiedMsg3")}</InlineText>
                      </Spacing>
                    </Spacing>
                    <Spacing mTopvh={"3.7"}>
                      <Button onClick={() => history.push(Routes.LOGIN)}>
                        {t("buttons-continueLogin")}
                      </Button>
                    </Spacing>
                  </CenteredContent>
                ))}
              {verificationResponse === 201 && (
                <CenteredContent>
                  <FormHeading>{t("user-accountVerified")}</FormHeading>
                  <Spacing mTopvh={"3.7"} mBottomvh={"2.4"}>
                    <InlineText>
                      {t("accountVerification-accountAlreadyVerified")}
                    </InlineText>
                    <Spacing mTopvh={"2.4"} />
                    <InlineText>
                      {t("accountVerification-clickBelow")}
                    </InlineText>
                  </Spacing>
                  <Spacing>
                    <Button onClick={() => history.push(Routes.LOGIN)}>
                      {t("buttons-continueLogin")}
                    </Button>
                  </Spacing>
                </CenteredContent>
              )}
              {verificationResponse === 202 && (
                <CenteredContent>
                  <FormHeading>{t("accountVerification-error")}</FormHeading>
                  <Spacing mTopvh={"3.7"}>
                    <InlineText>
                      {t("accountVerification-linkNotValid")}
                    </InlineText>
                    <Spacing mTopvh={"2.4"}>
                      <InlineText fontSize={"sm"}>
                        {t("accountVerification-registerAgain")}
                      </InlineText>
                    </Spacing>
                  </Spacing>
                  <Spacing mTopvh={"3.7"}>
                    <Button onClick={() => history.push(Routes.REGISTER)}>
                      {t("buttons-register")}
                    </Button>
                  </Spacing>
                </CenteredContent>
              )}
            </ShadowDiv>
            <Spacing mBottomvh="10" />
          </Spacing>
        </FlexContainer>
      </GrayPageContainer>
    </>
  );
};

export default AccountVerification;
