export const LOGIN = "/";
export const REGISTER = "/registration";
export const VERIFY = "/verification/:token";
export const FORGOT_PASSWORD = "/password";
export const RESET_PASSWORD = "/reset/:token";

export const PAGE_NOT_FOUND = "/404";
export const PAGE_ERROR = "/404"; 
export const ACCESS_DENIED = "/403";

export const CONTROL_ROOM = "/";
export const CELL_DETAILS = "/details/:CellID";
export const MANAGE_USERS = "/users";
export const MANAGE_CELLS = "/cells";
export const USER_PROFILE = "/profile";
