import { makeStyles } from "@material-ui/core/styles";
import { Header as SiteHeader } from "../../../";
import React from "react";
import { useState, useEffect, useRef } from "react";
import parsePhoneNumber from "libphonenumber-js";
import JwtDecode from "jwt-decode";
import styled from "styled-components";
import {
  Button,
  Input,
  Row,
  Cell,
  Spacing,
  Loader,
  PhoneNumberInput,
  PageHeading,
  PageBody,
  CancelIcon,
  AlertIcon,
  TableContainer,
  EditIcon,
  Tooltip,
  ChangePasswordPopUp,
} from "../..";
import { useTranslation } from "react-i18next";
import { SessionManager } from "../../../utils";
import UserController from "../network/UserController";
import { AuthActions, useStore } from "../../../store";
import InputAdornment from "@material-ui/core/InputAdornment";
import { appTheme } from "../../Theme";

const Edit = styled(Input)`
  margin-right: auto;
  width: 100%;
`;

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: appTheme.borderRadius.xxs,
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: "14px",
    },
  },
  rootPhone: {
    "& .MuiOutlinedInput-root": {
      borderRadius: appTheme.borderRadius.xxs,
    },
  },
});
const UserProfile = ({ redirection }) => {
  const [showChangePasswordPopUp, setShowChangePasswordPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasError = useRef(false);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const authActions = AuthActions();
  const [{ auth }] = useStore();
  const classes = useStyles();
  var nameRegEx = new RegExp(/^[a-zA-Z\s]+$/);
  const [phoneCode, setPhoneCode] = useState({
    code: "DK",
    dialCode: "+45",
  });
  const [userDetails, setUserDetails] = useState({});
  const [edit, setEdit] = useState({
    firstName: false,
    lastName: false,
    companyAddress: false,
    phoneNumber: false,
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    companyAddress: "",
    phoneNumber: "",
  });

  const handleEditChange = (fieldName, value) => {
    setEdit({
      ...edit,
      [fieldName]: value,
    });
  };

  const handleUserDetailsChange = (fieldName, e) => {
    setUserDetails({
      ...userDetails,
      [fieldName]: e.target.value,
    });
  };

  const handlePhoneNumberChange = (e) => {
    const number = e.target.value.slice(phoneCode.dialCode.length + 1).trim();
    const isValidNumber = /^\d+$/.test(number);
    if (number !== "" && !isValidNumber) {
      return;
    }
    handleUserDetailsChange("phoneNumber", e);
  };

  useEffect(() => {
    if (auth.user) {
      setUserDetails({
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
        email: auth.user.email,
        companyName: auth.user.companyName,
        companyAddress: auth.user.companyAddress,
        phoneNumber:
          auth.user.phoneNumber.length === 1 ? "" : auth.user.phoneNumber,
      });
    }
  }, [auth.user]);

  useEffect(() => {
    var errors = {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      companyAddress: "",
      phoneNumber: "",
    };
    hasError.current = false;
    if (userDetails.firstName === "") {
      hasError.current = true;
      errors.firstName = t("login-requiredField");
    } else if (!nameRegEx.test(userDetails.firstName)) {
      hasError.current = true;
      errors.firstName = t("register-invalidName");
    }
    if (userDetails.lastName === "") {
      hasError.current = true;
      errors.lastName = t("login-requiredField");
    } else if (!nameRegEx.test(userDetails.lastName)) {
      hasError.current = true;
      errors.lastName = t("register-invalidName");
    }
    if (userDetails.phoneNumber) {
      const phoneNumber = parsePhoneNumber(
        userDetails.phoneNumber,
        phoneCode.code
      );
      if (
        userDetails.phoneNumber.slice(phoneCode.dialCode.length + 1).trim() ===
        ""
      ) {
        userDetails.phoneNumber = "";
      } else if (
        (!phoneNumber && userDetails.phoneNumber !== "") ||
        (phoneNumber && !phoneNumber.isValid())
      ) {
        hasError.current = true;
        errors.phoneNumber = t("register-invalidNumber");
      }
    }
    setErrors(errors);
  }, [edit, userDetails]);

  const updateUser = () => {
    if (hasError.current) {
      return;
    }
    var data = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      companyName: userDetails.companyName,
      companyAddress: userDetails.companyAddress,
      phoneNumber: userDetails.phoneNumber,
      updateUser: true,
    };
    setLoading(true);
    UserController.user.updateUser(data, (res) => {
      sessionStorage.setItem("token", res.data.token);
      var userData = JwtDecode(res.data.token);
      sessionStorage.setItem("UserData", JSON.stringify(userData.userData));
      SessionManager.email = userData.userData.email;
      authActions.setAuth(userData.userData);
      setUserDetails({
        firstName: userData.userData.firstName,
        lastName: userData.userData.lastName,
        email: userData.userData.email,
        companyName: userData.userData.companyName,
        companyAddress: userData.userData.companyAddress,
        phoneNumber: userData.userData.phoneNumber,
      });
      setLoading(false);
    });
    const isError = Object.values(errors).every((error) => error === "");
    if (isError) {
      setEdit({
        firstName: false,
        lastName: false,
        companyAddress: false,
        phoneNumber: false,
      });
    }
  };

  return (
    <>
      <SiteHeader search={search} setSearch={setSearch} />
      {loading && <Loader />}
      <PageBody>
        <PageHeading heading={"usersProfile"} redirection={redirection} />
        <TableContainer minWidth="470px" widthvw="37.93" setMLeft={true}>
          <Row width={"97%"} borderRadius={"xs"}>
            <Cell width={"2%"} />
            <Cell vPadding={"1.67"} color={"textDarkerGrey"} width={"35%"}>
              {t("register-firstName")}
            </Cell>
            <Cell width={edit.firstName || errors.firstName ? "65%" : "55%"}>
              {edit.firstName || errors.firstName ? (
                <Edit
                  className={classes.root}
                  variant="outlined"
                  error={errors.firstName !== ""}
                  value={userDetails.firstName}
                  onChange={(e) => handleUserDetailsChange("firstName", e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() => {
                          setUserDetails({
                            ...userDetails,
                            firstName: auth.user.firstName,
                          });
                          handleEditChange("firstName", false);
                        }}
                        position="end"
                      >
                        {errors.firstName ? (
                          <>
                            <Spacing data-tip data-for="firstName">
                              <AlertIcon />
                            </Spacing>
                            <Tooltip
                              id={"firstName"}
                              value={errors.firstName}
                            />
                          </>
                        ) : (
                          <Spacing setPointer={true} height="18px">
                            <CancelIcon
                              radius="12"
                              strokeWidth={"1.5"}
                              width={"18px"}
                            />
                          </Spacing>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <>{userDetails.firstName}</>
              )}
            </Cell>
            {!edit.firstName && !errors.firstName && (
              <Cell
                onClick={() => handleEditChange("firstName", true)}
                setPointer={true}
                width={"5%"}
              >
                <EditIcon color="black" width={"18px"} strokeWidth="0.85" />
                <Spacing mRight={"14px"} />
              </Cell>
            )}
          </Row>
          <Spacing mTopvh="1.67" />
          <Row width={"97%"} borderRadius={"xs"}>
            <Cell width={"2%"} />
            <Cell vPadding={"1.67"} color={"textDarkerGrey"} width={"35%"}>
              {t("register-lastName")}
            </Cell>
            <Cell width={edit.lastName || errors.lastName ? "65%" : "55%"}>
              {edit.lastName || errors.lastName ? (
                <Edit
                  className={classes.root}
                  variant="outlined"
                  error={errors.lastName !== ""}
                  value={userDetails.lastName}
                  onChange={(e) => handleUserDetailsChange("lastName", e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() => {
                          setUserDetails({
                            ...userDetails,
                            lastName: auth.user.lastName,
                          });
                          handleEditChange("lastName", false);
                        }}
                        position="end"
                      >
                        {errors.lastName ? (
                          <>
                            <Spacing data-tip data-for="lastName">
                              <AlertIcon />
                            </Spacing>
                            <Tooltip id={"lastName"} value={errors.lastName} />
                          </>
                        ) : (
                          <Spacing setPointer={true} height="18px">
                            <CancelIcon
                              radius="12"
                              strokeWidth={"1.5"}
                              width={"18px"}
                            />
                          </Spacing>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <>{userDetails.lastName}</>
              )}
            </Cell>
            {!edit.lastName && !errors.lastName && (
              <Cell
                onClick={() => handleEditChange("lastName", true)}
                setPointer={true}
                width={"5%"}
              >
                <EditIcon color="black" width={"18px"} strokeWidth="0.85" />
                <Spacing mRight={"14px"} />
              </Cell>
            )}
          </Row>
          <Spacing mTopvh="1.67" />
          <Row width={"97%"} borderRadius={"xs"}>
            <Cell width={"2%"} />
            <Cell vPadding={"1.67"} color={"textDarkerGrey"} width={"35%"}>
              {t("register-email")}
            </Cell>
            <Cell vPadding={"1.67"} width="65%">
              {userDetails.email}
            </Cell>
          </Row>
          <Spacing mTopvh="1.67" />
          <Row width={"97%"} borderRadius={"xs"}>
            <Cell width={"2%"} />
            <Cell vPadding={"1.67"} color={"textDarkerGrey"} width={"35%"}>
              {t("register-companyName")}
            </Cell>
            <Cell vPadding={"1.67"} width={"65%"}>
              {userDetails.companyName}
            </Cell>
          </Row>
          <Spacing mTopvh="1.67" />
          <Row width={"97%"} borderRadius={"xs"}>
            <Cell width={"2%"} />
            <Cell vPadding={"1.67"} color={"textDarkerGrey"} width={"35%"}>
              {t("register-companyAddress")}
            </Cell>
            <Cell
              width={
                edit.companyAddress || errors.companyAddress ? "65%" : "55%"
              }
            >
              {edit.companyAddress || errors.companyAddress ? (
                <Edit
                  error={errors.companyAddress !== ""}
                  className={classes.root}
                  variant="outlined"
                  value={userDetails.companyAddress}
                  onChange={(e) => handleUserDetailsChange("companyAddress", e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() => {
                          setUserDetails({
                            ...userDetails,
                            companyAddress: auth.user.companyAddress,
                          });
                          handleEditChange("companyAddress", false);
                        }}
                        position="end"
                      >
                        {errors.companyAddress ? (
                          <>
                            <Spacing data-tip data-for="companyAddress">
                              <AlertIcon />
                            </Spacing>
                            <Tooltip
                              id={"companyAddress"}
                              value={errors.companyAddress}
                            />
                          </>
                        ) : (
                          <Spacing setPointer={true} height="18px">
                            <CancelIcon
                              radius="12"
                              strokeWidth={"1.5"}
                              width={"18px"}
                            />
                          </Spacing>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <>{userDetails.companyAddress}</>
              )}
            </Cell>
            {!edit.companyAddress && !errors.companyAddress && (
              <Cell
                onClick={() => handleEditChange("companyAddress", true)}
                setPointer={true}
                width={"5%"}
              >
                <EditIcon color="black" width={"18px"} strokeWidth="0.85" />
                <Spacing mRight={"14px"} />
              </Cell>
            )}
          </Row>
          <Spacing mTopvh="1.67" />
          <Row width={"97%"} borderRadius={"xs"}>
            <Cell width={"2%"} />
            <Cell vPadding={"1.67"} color={"textDarkerGrey"} width={"35%"}>
              {t("register-phoneNumber")}
            </Cell>
            <Cell
              width={edit.phoneNumber || errors.phoneNumber ? "65%" : "55%"}
              nowrap={true}
            >
              {edit.phoneNumber || errors.phoneNumber ? (
                <PhoneNumberInput
                  className={classes.rootPhone}
                  setPhoneCode={setPhoneCode}
                  variant="outlined"
                  error={errors.phoneNumber !== ""}
                  errorMsg={errors.phoneNumber}
                  value={userDetails.phoneNumber
                    .slice(phoneCode.dialCode.length + 1)
                    .trim()}
                  dialCode={userDetails.phoneNumber
                    .split(" ")
                    .slice(0, -1)
                    .join(" ")}
                  onClose={() => {
                    setUserDetails({
                      ...userDetails,
                      phoneNumber: auth.user.phoneNumber,
                    });
                    handleEditChange("phoneNumber", false);
                  }}
                  onChange={(e) => handlePhoneNumberChange(e)}
                  maxHeight={"180px"}
                  containerWidth={"16"}
                />
              ) : (
                <>{userDetails.phoneNumber}</>
              )}
            </Cell>
            {!edit.phoneNumber && !errors.phoneNumber && (
              <Cell
                onClick={() => handleEditChange("phoneNumber", true)}
                setPointer={true}
                width={"5%"}
              >
                <EditIcon color="black" width={"18px"} strokeWidth="0.85" />
                <Spacing mRight={"14px"} />
              </Cell>
            )}
          </Row>
          <Spacing mTopvh="1.67" />
          <Row width={"97%"} borderRadius={"xs"}>
            <Cell width={"2%"} />
            <Cell vPadding={"1.67"} color={"textDarkerGrey"} width={"35%"}>
              {t("password")}
            </Cell>
            <Cell
              vPadding={"1.67"}
              onClick={() => setShowChangePasswordPopUp(true)}
              color={"textDarkGrey"}
              width={"65%"}
            >
              <Spacing font={"bold"} setPointer={true}>
                {t("changePassword")}
              </Spacing>
            </Cell>
          </Row>
          <Spacing mTopvh={"2.96"} mLeft={""}>
            <Button
              padding={"10px 45px"}
              borderRadius={"xs"}
              onClick={updateUser}
            >
              {t("buttons-update")}
            </Button>
          </Spacing>
        </TableContainer>
      </PageBody>
      {showChangePasswordPopUp && (
        <ChangePasswordPopUp setShowPopUp={setShowChangePasswordPopUp} />
      )}
    </>
  );
};

export default UserProfile;
