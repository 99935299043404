import { SessionManager } from "../utils";
import AwsIotSdk from "aws-iot-device-sdk";
import * as Topics from "./IoTTopics";
import { IoTTopicCallBack } from "./IoTCallbacks/IoTCallback";

//this function connects to AWS IoT client
export const ConnectToAWSIoT = () => {
  try {
    var date = new Date();
    var id = SessionManager.email + date.getTime();
    SessionManager.clientID = id;
    var region = process.env.REACT_APP_AWS_REGION;
    var endpoint = process.env.REACT_APP_IOT_ENDPOINT;
    console.log("accessKeyId: " + sessionStorage.getItem("accessKeyId"));
    console.log("secretKey: " + sessionStorage.getItem("secretKey"));
    SessionManager.mqttClient = new AwsIotSdk.device({
      region: region,
      host: endpoint,
      clientId: id,
      protocol: "wss",
      maximumReconnectTimeMs: 8000,
      debug: false,
      payload: "json",
      accessKeyId: sessionStorage.getItem("accessKeyId"),
      secretKey: sessionStorage.getItem("secretKey"),
      sessionToken: sessionStorage.getItem("sessionToken"),
    });
    //subscribing to mqtt client when signed in
    SessionManager.mqttClient.on("connect", () => {
      SessionManager.mqttConnected = true;
      console.log("DEVICE connected!");
      SubscribeAndPublish();
    });
    SessionManager.mqttClient.on("close", () => {
      console.log("DEVICE closed!");
    });
    SessionManager.mqttClient.on("error", (err) => {
      console.log("DEVICE error! " + err);
      SessionManager.mqttConnected = false;
    });
    SessionManager.mqttClient.on("disconnect", (reason) => {
      console.log("DEVICE disconnected!" + JSON.stringify(reason));
    });

    SessionManager.mqttClient.on("message", (topic, payload) => {
      IoTTopicCallBack(topic, payload);
    });
  } catch (err) {
    console.log(err);
  }
};
export default ConnectToAWSIoT;

//subscribe to all the topics required to update the cells data in real time.
//here we also publish to the topics to get the data from the shadows.
export const SubscribeAndPublish = () => {
  if (SessionManager.cells && SessionManager.mqttConnected) {
    if (!SessionManager.subscribed) {
      SessionManager.subscribed = true;
      SessionManager.mqttClient.subscribe(Topics.CONNECTED_TOPIC);
      SessionManager.mqttClient.subscribe(Topics.DISCONNECTED_TOPIC);
      SessionManager.mqttClient.subscribe(Topics.PROCESS_COMPLETED_TOPIC);
      SessionManager.mqttClient.subscribe(
        Topics.CUSTOM_TOPIC_STARTING +
          "+" +
          Topics.LOGS_GET_TOPIC_ACCEPTED +
          SessionManager.clientID
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.STATUS_UPDATE_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.STATUS_GET_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.SETTINGS_UPDATE_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.SETTINGS_GET_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.LOCATION_UPDATE_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.LOCATION_GET_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING +
          "+" +
          Topics.CNM_STATUS_UPDATE_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.CNM_STATUS_GET_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING +
          "+" +
          Topics.HARDWARE_STATUS_UPDATE_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING +
          "+" +
          Topics.HARDWARE_STATUS_GET_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING +
          "+" +
          Topics.SOFTWARE_VERSION_UPDATE_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING +
          "+" +
          Topics.SOFTWARE_VERSION_GET_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.CONFIG_GET_TOPIC_ACCEPTED
      );
      SessionManager.mqttClient.subscribe(
        Topics.AWS_TOPIC_STARTING + "+" + Topics.CONFIG_UPDATE_TOPIC_ACCEPTED
      );
      setTimeout(() => {
        Object.values(SessionManager.cells).forEach((cell) => {
          SessionManager.mqttClient.publish(
            Topics.AWS_TOPIC_STARTING + cell.CellID + Topics.SETTINGS_GET_TOPIC
          );
          SessionManager.mqttClient.publish(
            Topics.AWS_TOPIC_STARTING + cell.CellID + Topics.LOCATION_GET_TOPIC
          );
          SessionManager.mqttClient.publish(
            Topics.AWS_TOPIC_STARTING + cell.CellID + Topics.STATUS_GET_TOPIC
          );
          SessionManager.mqttClient.publish(
            Topics.AWS_TOPIC_STARTING +
              cell.CellID +
              Topics.CNM_STATUS_GET_TOPIC
          );
          SessionManager.mqttClient.publish(
            Topics.AWS_TOPIC_STARTING +
              cell.CellID +
              Topics.HARDWARE_STATUS_GET_TOPIC
          );
          SessionManager.mqttClient.publish(
            Topics.AWS_TOPIC_STARTING +
              cell.CellID +
              Topics.SOFTWARE_VERSION_GET_TOPIC
          );
          SessionManager.mqttClient.publish(
            Topics.AWS_TOPIC_STARTING + cell.CellID + Topics.CONFIG_GET_TOPIC
          );
        });
      }, 200);
    }
  }
};
