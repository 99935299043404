import React from "react";
import { appTheme as theme } from "../commonalities/Theme";

export const RubberMatIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "72";
  const COLOR = theme.colors[color] || theme.colors.textDarkerGrey;
  const STROKE_WIDTH = strokeWidth || "3";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
    >
      <g
        id="Group_3052"
        data-name="Group 3052"
        transform="translate(-1792 -392)"
      >
        <g
          id="Group_3051"
          data-name="Group 3051"
          transform="translate(-1192 -186)"
        >
          <path
            id="Path_2233"
            data-name="Path 2233"
            d="M120,40V86.117H77.18V49.882H68.945a4.941,4.941,0,1,1,0-9.882Z"
            transform="translate(2927.996 552)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <path
            id="Path_2234"
            data-name="Path 2234"
            d="M77.18,56.941v46.117H68.945A4.94,4.94,0,0,1,64,98.117V52a4.94,4.94,0,0,0,4.941,4.941Z"
            transform="translate(2927.996 544.941)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1099"
            data-name="Line 1099"
            x1="42.721"
            y2="42.721"
            transform="translate(3005.177 592.103)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1101"
            data-name="Line 1101"
            x1="31.596"
            y2="32.553"
            transform="translate(3016.405 605.404)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1103"
            data-name="Line 1103"
            x1="18.191"
            y2="20.106"
            transform="translate(3029.809 617.851)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1105"
            data-name="Line 1105"
            x1="5.745"
            y2="6.702"
            transform="translate(3042.255 631.255)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1107"
            data-name="Line 1107"
            x1="13.404"
            y1="13.404"
            transform="translate(3034.596 592)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1109"
            data-name="Line 1109"
            x1="25.851"
            y1="25.851"
            transform="translate(3022.149 592)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1111"
            data-name="Line 1111"
            x1="39.255"
            y1="39.255"
            transform="translate(3008.745 592)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1113"
            data-name="Line 1113"
            x1="45.335"
            y1="45.689"
            transform="translate(2995.505 592.213)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1116"
            data-name="Line 1116"
            x1="22.741"
            y1="22.741"
            transform="translate(3005.466 615.162)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1118"
            data-name="Line 1118"
            x1="9.265"
            y1="9.265"
            transform="translate(3005.466 628.637)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1120"
            data-name="Line 1120"
            x1="9.686"
            y2="9.686"
            transform="translate(2998.729 592)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1123"
            data-name="Line 1123"
            x1="16.424"
            y2="16.424"
            transform="translate(3005.466 592)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_1125"
            data-name="Line 1125"
            x1="29.058"
            y2="29.057"
            transform="translate(3005.466 592)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
        </g>
        <rect
          id="Rectangle_9830"
          data-name="Rectangle 9830"
          width="72"
          height="72"
          transform="translate(1792 392)"
          fill="none"
        />
      </g>
    </svg>
  );
};
