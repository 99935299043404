import React from "react";
import { useTranslation } from "react-i18next";
import {
  Spacing,
  PopUpHeading,
  PopUpText,
  PopUpTemplate,
  PopUpButtons,
} from "../../..";

const DeleteCellPopUp = ({
  deleteUser,
  userName,
  email,
  setShowDeletePopUp,
}) => {
  const { t } = useTranslation();

  return (
    <PopUpTemplate setShowPopUp={setShowDeletePopUp}>
      <Spacing paddingvh={"4.67"} pRightvw={"4.25"} pLeftvw={"4.25"}>
        <PopUpHeading>{t("deleteUserHeading")}</PopUpHeading>
        <Spacing mTopvh={"3.7"} />
        <PopUpText color={"textGrey"}>{t("deleteUserMsg1")}</PopUpText>
        <Spacing mTopvh={"1.1"} textAlign="center" mLeft={"auto"} mRight="auto">
          <Spacing mLeft={"auto"} setInline={true} mRight={"5px"}>
            <PopUpText setInline={true} font={"medium"} color={"textDarkGrey"}>
              {userName}
            </PopUpText>
          </Spacing>
          <PopUpText setInline={true} color={"textGrey"}>
            {"(" + email + ")?"}
          </PopUpText>
        </Spacing>
        <PopUpButtons
          bgColor="red"
          confirmText="buttons-delete"
          onConfirmClick={() => {
            deleteUser(email);
          }}
          onCancelClick={() => {
            setShowDeletePopUp({ show: false, email: null, userName: null });
          }}
        />
      </Spacing>
    </PopUpTemplate>
  );
};

export default DeleteCellPopUp;
