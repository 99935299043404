import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SessionManager } from "../../utils";
// Added to avoid the eslint error in mapbox package
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import {
  Text,
  InlineText,
  Spacing,
  FormColumn,
  ShadowDiv,
  MapTooltip,
} from "../../commonalities";
import { getMapCenter, getMapMarker } from "../../styles";
var Victor = require("victor");

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function MiddleColumn({
  fleetSize,
  unitsOnline,
  totalJobs,
  activeLocations,
  setMapUpdateFunction,
}) {
  const { t } = useTranslation();
  const cellCoordinates = useRef({});
  const mapContainer = useRef(null);
  const mapMarker = useRef({});
  const updateMap = (CellID) => {
    updateMapMarker(CellID);
  };
  const colors = ["red", "green", "orange", "textGrey"];

  const updateStatus = (CellID) => {
    if (SessionManager.cellsShadows[CellID].locationCoordinates) {
      updateMapMarker(CellID);
    }
  };

  // getting the cell status in proper format and also getting it in the correct language.
  const getCellDisplayStatus = (CellID, t) => {
    var displayStatus = {
      color: "textGrey",
      text: t("cell-offline"),
    };
    if (SessionManager.cells[CellID].ConnectionStatus) {
      if (
        SessionManager.cellsShadows[CellID].status &&
        SessionManager.cellsShadows[CellID].status &&
        !SessionManager.cellsShadows[CellID].status.programStatus
          .toLowerCase()
          .includes("ready") &&
        !SessionManager.cellsShadows[CellID].status.programStatus
          .toLowerCase()
          .includes("not") &&
        !SessionManager.cellsShadows[CellID].status.programStatus
          .toLowerCase()
          .includes("goto") &&
        !SessionManager.cellsShadows[CellID].status.programStatus
          .toLowerCase()
          .includes("unavailable")
      ) {
        displayStatus = {
          color:
            colors[
              SessionManager.cellsShadows[CellID].status.programStatusCode
            ],
          text: t(SessionManager.cellsShadows[CellID].status.programStatus),
        };
      } else {
        displayStatus = {
          color: "green",
          text: t("cell-online"),
        };
      }
    }
    return displayStatus;
  };

  const updateMapMarker = (CellID) => {
    if (SessionManager.cellsShadows[CellID].locationCoordinates) {
      var cellLoc =
        SessionManager.cellsShadows[CellID].locationCoordinates.split(",");
      if (mapMarker.current[CellID]) {
        mapMarker.current[CellID].remove();
      }
      cellCoordinates.current[CellID] = new Victor(cellLoc[1], cellLoc[0]);
      mapMarker.current[CellID] = new mapboxgl.Marker(
        getMapMarker(
          () => {
            const displayStatus = getCellDisplayStatus(CellID, t);
            SessionManager.mapPopUp.remove();
            SessionManager.mapPopUp
              .setHTML(
                MapTooltip(
                  displayStatus.color,
                  displayStatus.text,
                  SessionManager.cells[CellID].Name,
                  `rgba(${SessionManager.cells[CellID].Color.r},${SessionManager.cells[CellID].Color.g},${SessionManager.cells[CellID].Color.b},1)`,
                  `rgba(${SessionManager.cells[CellID].Color.r},${SessionManager.cells[CellID].Color.g},${SessionManager.cells[CellID].Color.b},0.5)`,
                  t
                )
              )
              .setLngLat([
                cellCoordinates.current[CellID].x,
                cellCoordinates.current[CellID].y,
              ])
              .addTo(SessionManager.map);
          },
          () => {
            SessionManager.mapPopUp.remove();
          },
          `rgba(${SessionManager.cells[CellID].Color.r},${SessionManager.cells[CellID].Color.g},${SessionManager.cells[CellID].Color.b},1)`,
          `rgba(${SessionManager.cells[CellID].Color.r},${SessionManager.cells[CellID].Color.g},${SessionManager.cells[CellID].Color.b},0.5)`
        )
      )
        .setLngLat([
          cellCoordinates.current[CellID].x,
          cellCoordinates.current[CellID].y,
        ])
        .addTo(SessionManager.map)
        .setOffset([0, -19]);
      SessionManager.map.fitBounds(getMapCenter(cellCoordinates.current));
    }
  };

  useEffect(() => {
    setMapUpdateFunction(updateMap, updateStatus);
    SessionManager.initialiseMap(mapContainer);
  }, []);

  useEffect(() => {
    if (fleetSize > 0) {
      Object.keys(SessionManager.cellsShadows).forEach((CellID) => {
        if (
          SessionManager.cellsShadows[CellID] &&
          SessionManager.cellsShadows[CellID].locationCoordinates
        ) {
          updateMap(CellID);
        }
      });
    }
  }, [fleetSize]);

  return (
    <>
      <FormColumn
        alignItems="stretch"
        height="100%"
        widthvw={"45.039"}
        borderRadius="xxs"
        padding="0 5px"
        gap="2.96"
      >
        <ShadowDiv
          borderRadius="sm"
          display="visbile"
          height={"50.67"}
          vPadding={"2.96"}
          hPadding={"1.67"}
        >
          <Spacing mTop="0px" mBottomvh="2.22">
            <InlineText fontSize="lg" font="medium" color="black">
              {t("controlRoom-overview")}
            </InlineText>
          </Spacing>
          <Spacing
            heightvh={"45.48"}
            width="100%"
            borderRadius="xxs"
            id="world-map"
            ref={mapContainer}
          />
        </ShadowDiv>
        <ShadowDiv
          borderRadius="sm"
          width={"100%"}
          vPadding={"2.96"}
          gap={"0.835"}
        >
          <FormColumn
            setJustify={true}
            heightvh="8.14"
            width="45%"
            alignItems="stretch"
          >
            <Text
              lineHeight="1.2"
              height="70%"
              textAlign="center"
              mBottomvh="0.462"
              font="medium"
              width="auto"
              fontSize="xxl"
            >
              {fleetSize}
            </Text>
            <Text
              lineHeight="1.2"
              textAlign="center"
              width="auto"
              height="30%"
              fontSize="sm"
              font="regular"
              color="textGrey"
            >
              {t("controlRoom-fleetSize")}
            </Text>
          </FormColumn>
          <FormColumn
            setJustify={true}
            heightvh="8.14"
            width="45%"
            alignItems="stretch"
          >
            <Text
              lineHeight="1.2"
              height="70%"
              textAlign="center"
              mBottomvh="0.462"
              font="medium"
              width="auto"
              fontSize="xxl"
            >
              {unitsOnline}
            </Text>
            <Text
              lineHeight="1.2"
              textAlign="center"
              width="auto"
              height="30%"
              fontSize="sm"
              font="regular"
              color="textGrey"
            >
              {t("controlRoom-unitsOnline")}
            </Text>
          </FormColumn>
          <FormColumn
            setJustify={true}
            heightvh="8.14"
            width="45%"
            alignItems="stretch"
          >
            <Text
              lineHeight="1.2"
              height="70%"
              textAlign="center"
              mBottomvh="0.462"
              font="medium"
              width="auto"
              fontSize="xxl"
            >
              {activeLocations && activeLocations.length !== 0
                ? activeLocations
                : 0}
            </Text>
            <Text
              lineHeight="1.2"
              textAlign="center"
              width="auto"
              height="30%"
              fontSize="sm"
              font="regular"
              color="textGrey"
            >
              {t("controlRoom-activeCities")}
            </Text>
          </FormColumn>
          <FormColumn
            setJustify={true}
            heightvh="8.14"
            width="45%"
            alignItems="stretch"
          >
            <Text
              lineHeight="1.2"
              height="70%"
              textAlign="center"
              mBottomvh="0.462"
              font="medium"
              width="auto"
              fontSize="xxl"
            >
              {totalJobs}
            </Text>
            <Text
              lineHeight="1.2"
              textAlign="center"
              width="auto"
              height="30%"
              fontSize="sm"
              font="regular"
              color="textGrey"
            >
              {t("controlRoom-totalJobs")}
            </Text>
          </FormColumn>
        </ShadowDiv>
      </FormColumn>
    </>
  );
}
