import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NetworkController, SessionManager } from "../../../utils";
import {
  Button,
  Spacing,
  PopUpHeading,
  PopUpTemplate,
  Loader,
  InputWithError,
  Text,
  FormColumn,
} from "../../../commonalities";

const EditCellPopUp = ({ cellDetails, setShowPopUp, setCellDetails }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    name: cellDetails.Name,
    owner: cellDetails.Owner,
  });

  const [errors, setErrors] = useState({
    name: "",
    owner: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const updateClicked = () => {
    var regex = /^[A-Za-z0-9_-]+$/;
    var isValid = regex.test(values.name);
    var isOwnerValid = regex.test(values.owner);
    var isError = false;
    var nameError = "";
    var ownerError = "";
    if (!isValid) {
      nameError = t("cell-invalidInput");
      isError = true;
    }
    if (!isOwnerValid) {
      ownerError = t("cell-invalidInput");
      isError = true;
    }
    if (values.owner === "") {
      ownerError = t("login-requiredField");
      isError = true;
    }
    if (values.name === "") {
      nameError = t("login-requiredField");
      isError = true;
    }
    if (
      Object.values(SessionManager.cells).filter((cell) => {
        if (cell.CellID !== cellDetails.CellID) {
          return cell.Name.toLowerCase() === values.name.toLowerCase();
        } else {
          return false;
        }
      }).length === 1
    ) {
      nameError = t("cell-duplicateCellName");
      isError = true;
    }
    setErrors({
      name: nameError,
      owner: ownerError,
    });
    if (isError) {
      return;
    }
    if (
      values.name !== cellDetails.Name ||
      values.owner !== cellDetails.Owner
    ) {
      var celldata = {
        Name: values.name,
        Owner: values.owner,
        CellID: cellDetails.CellID,
      };
      setLoading(true);
      NetworkController.cells.update(celldata, (res) => {
        setLoading(false);
        if (res.status === 200) {
          var data = SessionManager.cells;
          data[celldata.CellID].Owner = res.data.Owner;
          data[celldata.CellID].Name = res.data.Name;
          SessionManager.cells = data;
          setShowPopUp(false);
          setCellDetails({ ...data[celldata.CellID] });
        }
      });
    } else {
      setShowPopUp(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <PopUpTemplate
        setShowPopUp={setShowPopUp}
        width="400px"
        minWidth={"15vw"}
      >
        <Spacing paddingvh="6.67">
          <FormColumn alignItems="left" height="auto">
            <PopUpHeading
              top={"none"}
              fontSize={"xl"}
              textAlign={"left"}
              font="regular"
            >
              {t("cell-editCell")}
            </PopUpHeading>
            <Spacing mTopvh={"4.17"} />

            <InputWithError
              onChange={handleChange}
              value={values.name}
              helperText={errors.name}
              error={errors.name !== ""}
              name={"name"}
              width={"100%"}
              label={t("cell-unitName")}
              type={"text"}
            />

            <Text fontSize="xxs" mBottomvh="0.74" color="textGrey">
              {t("cell-typeUnit")}
            </Text>
            <Text fontSize="sm">{cellDetails.Type}</Text>

            <Spacing mTopvh={"3.24"}>
              <Text
                height="auto"
                fontSize="xxs"
                mBottomvh="0.74"
                color="textGrey"
              >
                {t("cell-unitSerialNumber")}
              </Text>
              <Text height="auto" fontSize="sm">
                {cellDetails.SerialNumber}
              </Text>
            </Spacing>

            <Spacing mBottomvh={"2.69"} mTopvh={"3.24"}>
              <InputWithError
                onChange={handleChange}
                value={values.owner}
                helperText={errors.owner}
                error={errors.owner !== ""}
                name={"owner"}
                width={"100%"}
                label={t("fms-owner")}
                type={"text"}
              />
            </Spacing>

            <Button onClick={updateClicked} width="fit-content">
              {t("buttons-update")}
            </Button>
          </FormColumn>
        </Spacing>
      </PopUpTemplate>
    </>
  );
};

export default EditCellPopUp;
