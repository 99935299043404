import React from "react";
import {
  PopUpButtons,
  AppHeading,
  InputWithError,
  Spacing,
  Loader,
  FormHeading,
  EmptyDiv,
  GrayPageContainer,
  FlexContainer,
  RoundedBorderText,
  ShadowDiv,
} from "../..";
import * as Routes from "../../../routes/Routes";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import UserController from "../network/UserController";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [response, setResponse] = useState("");
  const [values, setValues] = useState({
    emailID: "",
  });

  const [errors, setErrors] = useState({
    emailID: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    var emailRegEx = new RegExp(/\S+@\S+\.\S+/);
    var hasError = false;
    var emailError = "";
    if (!emailRegEx.test(values.emailID)) {
      hasError = true;
      emailError = t("invalidEmailAddress");
    }
    if (values.emailID === "") {
      hasError = true;
      emailError = t("login-requiredField");
    }
    setErrors({
      emailID: emailError,
    });
    if (hasError) {
      setResponse("");
      return;
    }
    setLoading(true);
    UserController.user.forgotPassword(
      {
        resetLink: window.location.href.replace(Routes.FORGOT_PASSWORD, ""),
        email: values.emailID,
        language: i18n.language,
        redirect: window.location.href.replace(
          Routes.FORGOT_PASSWORD,
          Routes.LOGIN
        ),
      },
      (res) => {
        if (res.data.status === 201) {
          setErrors({
            emailID: t("noUserExists"),
          });
        } else {
          setResponse(t("forgotPassword-resetPasswordLinkSent"));
          setTimeout(() => {
            setResponse("");
          }, 15000);
        }
        setLoading(false);
      }
    );
  };
  return (
    <>
      {loading && <Loader />}
      <GrayPageContainer>
        <AppHeading redirection={Routes.LOGIN} />
        <FlexContainer>
          <Spacing margin="auto" width="500px">
            <Spacing mTopvh="10" />
            <ShadowDiv width="344px" padding="14.4%" margin="auto">
              <EmptyDiv>
                <FormHeading>{t("resetPassword")}</FormHeading>
                <Spacing mTopvh={"3.7"} />
                <InputWithError
                  isLast={true}
                  onChange={handleChange}
                  value={values.emailID}
                  helperText={errors.emailID}
                  error={errors.emailID !== ""}
                  name={"emailID"}
                  width={"100%"}
                  label={t("register-email")}
                  type={"email"}
                />
                {response !== "" && (
                  <Spacing mTopvh={"3.7"} mBottomvh={"1.48"}>
                    <RoundedBorderText
                      mLeft="auto"
                      mRight="auto"
                      fontSize="md"
                      type={"submit"}
                    >
                      {response}
                    </RoundedBorderText>
                  </Spacing>
                )}
                {response === "" && (
                  <PopUpButtons
                    confirmText={"buttons-sendPassword"}
                    onConfirmClick={SubmitForm}
                    cancelText={"buttons-backToLogin"}
                    cancelRoute={Routes.LOGIN}
                  />
                )}
              </EmptyDiv>
            </ShadowDiv>
            <Spacing mBottomvh="10" />
          </Spacing>
        </FlexContainer>
      </GrayPageContainer>
    </>
  );
};

export default ForgotPassword;
