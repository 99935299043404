import React from "react";
import { useTranslation } from "react-i18next";
import { H3, H4, Spacing, CenteredFullView } from "../..";
import { SessionManager } from "../../../utils";
import { ThemeProvider } from "styled-components";
import { appTheme } from "../../Theme";

function NotSupportedBrowser({ heading, text }) {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={appTheme}>
      <Spacing textAlign={"center"}>
        <CenteredFullView>
          <H3>{t(heading)}</H3>
          <H4>{t(text).replace("*APP_NAME*", SessionManager.appName)}</H4>
        </CenteredFullView>
      </Spacing>
    </ThemeProvider>
  );
}

export default NotSupportedBrowser;
