import React, { useState } from "react";
import {
  CalibrationSettings,
  ToolsSettings,
  VersionInformation,
  SpeedSettings,
} from "./";
import { useTranslation } from "react-i18next";

const CarpenSettings = ({ versionInfo, settings, config }) => {
  const [expand, setExpand] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <ToolsSettings
        settings={settings}
        config={config}
        show={expand === "tool"}
        setExpand={setExpand}
        heading={t("settings-tool")}
      />
      <CalibrationSettings
        settings={settings}
        config={config}
        show={expand === "toolCalibration"}
        setExpand={setExpand}
        heading={t("settings-Calibration")}
      />
      <SpeedSettings
        settings={settings}
        show={expand === "speed"}
        setExpand={setExpand}
        heading={t("settings-speed")}
      />
      <VersionInformation
        versionInfo={versionInfo}
        show={expand === "version"}
        setExpand={setExpand}
        heading={t("settings-versionInformation")}
      />
    </>
  );
};

export default CarpenSettings;
