import React, { useState } from "react";
import {
  DropDownContainer,
  ArrowIcon,
  Spacing,
  InlineText,
  ScrollingContainer,
  ShadowDiv,
} from "../commonalities";
import PropertyCard from "./PropertyCard";

const SettingsAccordion = ({
  expand,
  setExpand,
  iconImage,
  show,
  heading,
  children,
}) => {
  const [startShrink, setStartShrink] = useState(false);

  const clickFunction = () => {
    if (!show) {
      setExpand(expand);
    } else if (show && !startShrink) {
      setStartShrink(true);
      setTimeout(() => {
        setExpand("");
        setStartShrink(false);
      }, 200);
    }
  };

  return (
    <>
      {!show ? (
        <Spacing onClick={clickFunction} mTopvw={"0.835"}>
          <ShadowDiv borderRadius="sm" width="100%">
            <Spacing
              setFlex={true}
              width={"100%"}
              alignItems="center"
              mTopvh="1.67"
              mBottomvh="1.67"
              mLeftvw="1.67"
              mRightvw="0.83"
              setPointer={true}
            >
              <InlineText color="black" fontSize={"sm"} alignSelf={"center"}>
                {heading}
              </InlineText>
              <Spacing mLeft={"auto"} mRight="5px">
                <ArrowIcon
                  strokeWidth={"1"}
                  color={"textDarkGrey"}
                  width="11.5px"
                  height="10px"
                  type="down"
                />
              </Spacing>
            </Spacing>
          </ShadowDiv>
        </Spacing>
      ) : (
        <Spacing mTopvw={"0.835"}>
          <DropDownContainer shrink={startShrink} animationDuration="0.3s">
            <ScrollingContainer
              height="auto"
              width="auto"
              position="relative"
              mTopvw="1.25"
              mBottomvw="1.67"
              mLeftvw="1.67"
              mRightvw="0.83"
              overflowY="hidden"
            >
              <Spacing
                onClick={clickFunction}
                setFlex={true}
                heightvh="11.11"
                setPointer={true}
                margin={"0px"}
              >
                <ShadowDiv
                  bgColor="iconBgGrey"
                  height="9.63"
                  width="45%"
                  margin="auto auto auto 3px"
                  borderRadius="xxs"
                  shadow="0px 2px 4px rgba(0, 0, 0, 0.16)"
                >
                  {iconImage}
                </ShadowDiv>
                <Spacing mLeft={"auto"} mRight="5px">
                  <ArrowIcon
                    strokeWidth={"1"}
                    color={"textDarkGrey"}
                    width="11.5px"
                    height="10px"
                    type="up"
                  />
                </Spacing>
              </Spacing>
              <Spacing mTopvh={"1.48"}>
                <InlineText
                  font={"medium"}
                  fontSize={"sm"}
                  alignSelf={"center"}
                  color="black"
                >
                  {heading}
                </InlineText>
              </Spacing>
              <Spacing mTopvh={"1.48"} />
              {!("0" in children)
                ? Object.entries(children).map(([key, value]) => {
                    return (
                      <PropertyCard
                        key={key}
                        name={key}
                        value={value}
                        minWidth={"35%"}
                      />
                    );
                  })
                : children}
            </ScrollingContainer>
          </DropDownContainer>
        </Spacing>
      )}
    </>
  );
};

export default SettingsAccordion;
