import React, { useState } from "react";
import { Row } from "../../../commonalities";
import LogsDropDown from "./LogsDropDown";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import {
  SettingsIcon,
  LogsIcon,
  PlayIcon,
  Cell,
  Spacing,
} from "../../../commonalities";
import { TileIcon } from "../../../icons";
import CNMJobInfo from "./CNM/CNMJobInfoDropDown";
import EvaloJobInfo from "./Evalo/EvaloJobInfoDropDown";
import CarpenJobInfo from "./Carpen/CarpenJobInfoDropDown";
import CNMSettingsDropDown from "./CNM/CNMSettingsDropDown";
import EvaloSettingsDropDown from "./Evalo/EvaloSettingsDropDown";
import CarpenSettingsDropDown from "./Carpen/CarpenSettingsDropDown";

const ProcessRow = ({
  cellType,
  job,
  showDetail,
  setShowDetail,
  showExecutionPopUp,
  setShowExecutionPopUp,
}) => {
  const { t } = useTranslation();
  var st = DateTime.fromMillis(job.Payload.StartTime * 1000);
  const [shrink, setShrink] = useState(false);
  const colors = {
    Success: "green",
    Preempt: "orange",
    Error: "red",
    RobotError: "red",
    PlanningError: "red",
    TimedOut: "red",
    Cancelled: "orange",
    ExecutionFailed: "red",
    JobFailed: "red",
  };
  const handleClick = (clickedAction) => {
    if (
      showDetail.show === clickedAction &&
      showDetail.processId === job.Payload.StartTime
    ) {
      setShrink(true);
      setShowDetail({ show: "", processId: "" });
      setShrink(false);
    } else {
      setShowDetail({
        show: clickedAction,
        processId: job.Payload.StartTime,
      });
    }
  };
  var processType;
  if (job.Payload.ProcessType === "Command") {
    var metadata =
      typeof job.Payload.Metadata === "string"
        ? JSON.parse(job.Payload.Metadata)
        : job.Payload.Metadata;
    processType = metadata.CommandType ? metadata.CommandType : "-";
  } else {
    processType = job.Payload.ProcessType;
  }
  processType = processType.replaceAll(" ", "");
  const status = job.Payload.Status.replaceAll(" ", "");
  const executionTime = job.Payload.ExecutionTime.toFixed(2) + "s";
  return (
    <>
      <Spacing mTopvh={"1.48"}>
        <Row width={"100%"}>
          <Cell vPadding="0.74" hPadding="0" width={"2.325%"}>
            {""}
          </Cell>
          <Cell
            vPadding="0.74"
            textAlign="center"
            hPadding="0"
            nowrap={true}
            width={"4.651%"}
          >
            {job.index}
          </Cell>
          <Cell vPadding="0.74" hPadding="0" width={"1.551%"}>
            {""}
          </Cell>
          <Cell
            vPadding="0.74"
            textAlign="center"
            hPadding="0"
            nowrap={true}
            width={"30%"}
          >
            {st.toLocaleString({
              ...DateTime.DATETIME_MED_WITH_SECONDS,
              hour12: false,
            })}
          </Cell>
          <Cell vPadding="0.74" hPadding="0" width={"1.551%"}>
            {""}
          </Cell>
          <Cell
            vPadding="0.74"
            textAlign="center"
            hPadding="0"
            nowrap={true}
            width={"13.953%"}
          >
            {t(processType.toLocaleLowerCase())}
          </Cell>
          <Cell
            vPadding="0.74"
            textAlign="center"
            hPadding="0"
            nowrap={true}
            width={"1.551%"}
          >
            {""}
          </Cell>
          <Cell
            vPadding="0.74"
            textAlign="center"
            hPadding="0"
            nowrap={true}
            font={"medium"}
            color={colors[job.Payload.Status.split(" ").join("")]}
            width={"13.953%"}
          >
            {t(status.toLocaleLowerCase())}
          </Cell>
          <Cell vPadding="0.74" hPadding="0" width={"1.551%"}>
            {""}
          </Cell>
          <Cell
            vPadding="0.74"
            textAlign="center"
            hPadding="0"
            nowrap={true}
            width={"15.504%"}
          >
            {executionTime}
          </Cell>
          <Cell vPadding="0.74" hPadding="0" width={"1.551%"}>
            {""}
          </Cell>
          <Cell
            textAlign="center"
            hPadding="0"
            nowrap={true}
            vPadding={"0.86"}
            height={"fit-content"}
            width={"20%"}
          >
            <Row setJustify={true} shadow="0" width="auto" textAlign="center">
              <Spacing
                width="25%"
                alignSelf="center"
                setPointer={true}
                onClick={() => handleClick("settings")}
              >
                <Spacing mTop="13%">
                  <SettingsIcon
                    width="55%"
                    height="55%"
                    color={
                      showDetail.show === "settings" &&
                        showDetail.processId === job.Payload.StartTime
                        ? "orange"
                        : "black"
                    }
                    fill={
                      showDetail.show === "settings" &&
                      showDetail.processId === job.Payload.StartTime &&
                      true
                    }
                  />
                </Spacing>
              </Spacing>
              <Spacing
                width="25%"
                alignSelf="center"
                setPointer={true}
                onClick={() => handleClick("logs")}
              >
                <Spacing mTop="13%">
                  <LogsIcon
                    width="42%"
                    height="42%"
                    color={
                      showDetail.show === "logs" &&
                      showDetail.processId === job.Payload.StartTime &&
                      "orange"
                    }
                    fill={
                      showDetail.show === "logs" &&
                      showDetail.processId === job.Payload.StartTime &&
                      true
                    }
                  />
                </Spacing>
              </Spacing>
              <Spacing
                width="25%"
                alignSelf="center"
                setPointer={true}
                onClick={() => handleClick("jobInfo")}
              >
                <Spacing mTop="13%">
                  <TileIcon
                    width="55%"
                    height="55%"
                    color={
                      showDetail.show === "jobInfo" &&
                      showDetail.processId === job.Payload.StartTime &&
                      "orange"
                    }
                    fill={
                      showDetail.show === "jobInfo" &&
                      showDetail.processId === job.Payload.StartTime &&
                      true
                    }
                  />
                </Spacing>
              </Spacing>
              <Spacing
                width="25%"
                alignSelf="center"
                setPointer={cellType === "CNM" ? true : false}
                onClick={() =>
                  setShowExecutionPopUp({
                    show: cellType === "CNM",
                    job: job,
                  })
                }
              >
                <Spacing mTop="13%">
                  <PlayIcon
                    width="55%"
                    color={
                      cellType === "CNM"
                        ? showExecutionPopUp.show &&
                        showExecutionPopUp.job === job &&
                        "orange"
                        : "textGrey"
                    }
                    fill={
                      showExecutionPopUp.show &&
                      showExecutionPopUp.job === job &&
                      true
                    }
                  ></PlayIcon>
                </Spacing>
              </Spacing>
            </Row>
          </Cell>
          <Cell vPadding="0.74" hPadding="0" width={"2.325%"}>
            {""}
          </Cell>
        </Row>
      </Spacing>
      {showDetail.show === "logs" &&
        showDetail.processId === job.Payload.StartTime && (
          <LogsDropDown id={job.Payload.Id} shrink={shrink} />
        )}

      {(showDetail.show === "jobInfo" && cellType === "Carpen") && (showDetail.processId === job.Payload.StartTime) ?
        <CarpenJobInfo job={job} shrink={shrink} />
        : ((showDetail.show === "jobInfo" && cellType === "CNM") && (showDetail.processId === job.Payload.StartTime) ?
          <CNMJobInfo job={job} shrink={shrink} />
          : ((showDetail.show === "jobInfo" && cellType === "Evalo") && (showDetail.processId === job.Payload.StartTime) ?
            <EvaloJobInfo job={job} shrink={shrink} /> : null))
      }

      {(showDetail.show === "settings" && cellType === "Carpen") && (showDetail.processId === job.Payload.StartTime) ?
        <CarpenSettingsDropDown job={job} shrink={shrink} />
        : ((showDetail.show === "settings" && cellType === "CNM") && (showDetail.processId === job.Payload.StartTime) ?
          <CNMSettingsDropDown job={job} shrink={shrink} />
          : ((showDetail.show === "settings" && cellType === "Evalo") && (showDetail.processId === job.Payload.StartTime) ?
            <EvaloSettingsDropDown job={job} shrink={shrink} /> : null))
      }
    </>
  );
};

export default ProcessRow;
