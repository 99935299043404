import React, { useState, useRef } from "react";
import * as Routes from "../../routes/Routes";
import * as Topics from "../../AWS/IoTTopics";
import { useTranslation } from "react-i18next";
import { SessionManager } from "../../utils";
import {
  A,
  Loader,
  PageHeading,
  SettingsIcon,
  RightAlignedContainer,
  Spacing,
  PageBody,
  Row,
} from "../../commonalities";
import { useEffect } from "react";
import LeftColumn from "./LeftColumn";
import MiddleColumn from "./MiddleColumn";
import SiteHeader from "../../Header";
import RightColumn from "./rightColumn/RightColumn";
import { useStore } from "../../store";
import { getCells } from "../../utils";

const ControlRoom = () => {
  const { t } = useTranslation();
  var updateMap = null;
  var updateMapStatus = null;
  var updateGraphData = null;
  const [cells, setCells] = useState({});
  const [cellsLocation, setCellsLocation] = useState({});
  const [status, setStatus] = useState({});
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [{ auth }] = useStore();
  const [activeLocation, setActiveLocation] = useState({
    activeCities: SessionManager.activeLocations.activeCities,
  });
  const [totalUsage, setTotalUsage] = useState({
    fleetSize: 0,
    unitsOnline: 0,
    totalJobs: 0,
    activeCities: [],
  });
  const [dailyUsage, setDailyUsage] = useState({
    totalDailyJobs: 0,
  });
  const dailyUsageRef = useRef(dailyUsage);
  const usageRef = useRef(totalUsage);
  const locationRef = useRef(cellsLocation);
  const statusRef = useRef(status);
  const [search, setSearch] = useState("");
  const setCellDetails = (details) => {
    setCells({ ...SessionManager.cells });
  };
  const setMapUpdateFunction = (mapUpdate, updateStatus) => {
    updateMap = mapUpdate;
    updateMapStatus = updateStatus;
  };

  const setUpdateGraphFunction = (updateGraph) => {
    updateGraphData = updateGraph;
  };

  useEffect(() => {
    getCellsData();
    SessionManager.IOTEventListener = IOTEventListener;
    setActiveLocation({
      ...SessionManager.activeLocations,
    });
    setColors(SessionManager.colors);
    return () => {
      SessionManager.IOTEventListener = null;
    };
  }, []);

  const IOTEventListener = (cellID, topic, repeated) => {
    if (topic === Topics.CONNECTED_TOPIC_SUB) {
      console.log("I am inside IOTEventListener");
      var usage = { ...usageRef.current };
      if (SessionManager.cells[cellID].ConnectionStatus) {
        usage.unitsOnline++;
      } else if (usage.unitsOnline > 0) {
        usage.unitsOnline--;
      }
      setActiveLocation({
        ...SessionManager.activeLocations,
      });
      usageRef.current = usage;
      setCells({ ...SessionManager.cells });
      setTotalUsage(usageRef.current);
      updateGraphData();
    } else if (topic === Topics.LOCATION_UPDATE_TOPIC_ACCEPTED) {
      updateMap(cellID);
      var location = { ...locationRef.current };
      location[cellID] = SessionManager.cellsShadows[cellID].location;
      locationRef.current = location;
      setCellsLocation(locationRef.current);
      setActiveLocation({
        ...SessionManager.activeLocations,
      });
    } else if (topic === Topics.STATUS_UPDATE_TOPIC_ACCEPTED) {
      var cellStatus = { ...statusRef.current };
      cellStatus[cellID] = SessionManager.cellsShadows[cellID].status;
      statusRef.current = cellStatus;
      setStatus(statusRef.current);
      updateMapStatus(cellID);
    } else if (topic === Topics.PROCESS_COMPLETED_TOPIC) {
      var usage = { ...usageRef.current };
      usage.totalJobs = 0;
      Object.values(SessionManager.cells).forEach((cell) => {
        if (cell.Usage.TotalJobs) {
          usage.totalJobs += cell.Usage.TotalJobs;
        }
      });
      usageRef.current = usage;
      setTotalUsage(usageRef.current);
      updateGraphData();
    }
  };

  const getCellsData = () => {
    getCells(() => {
      var totalUsage = {};
      totalUsage.fleetSize = Object.keys(SessionManager.cells).length;
      totalUsage.unitsOnline = 0;
      totalUsage.totalJobs = 0;
      var cellLocation = {};
      var cellStatus = {};
      var dailyTotalJobs = 0;
      Object.values(SessionManager.cells).forEach((cell) => {
        if (cell.Usage.TotalJobs) {
          totalUsage.totalJobs += cell.Usage.TotalJobs;
        }
        if (cell.ConnectionStatus) {
          totalUsage.unitsOnline++;
        }
        cellStatus[cell.CellID] =
          SessionManager.cellsShadows[cell.CellID].status;
        cellLocation[cell.CellID] =
          SessionManager.cellsShadows[cell.CellID] &&
            SessionManager.cellsShadows[cell.CellID].location
            ? SessionManager.cellsShadows[cell.CellID].location
            : t("fms-unavailable");
      });
      updateGraphData();
      usageRef.current = { ...totalUsage };
      setTotalUsage(usageRef.current);
      dailyUsageRef.current = { totalDailyJobs: dailyTotalJobs };
      setDailyUsage(dailyUsageRef.current);
      statusRef.current = { ...cellStatus };
      locationRef.current = cellLocation;
      setCellsLocation(locationRef.current);
      setStatus({ ...statusRef.current });
      setLoading(false);
      setCells({ ...SessionManager.cells });
      setActiveLocation({
        ...SessionManager.activeLocations,
      });
    });
  };

  return (
    <>
      <SiteHeader search={search} setSearch={setSearch} />
      {loading && <Loader />}
      <PageBody width={"6.66"} mLeft="auto" mRight="auto">
        <Spacing setInlineFlex={true} width="100%">
          <PageHeading heading="controlRoom-heading" mLeft={"0"} />
          {auth.user.userType === "Admin" && (
            <RightAlignedContainer alignSelf={"center"} mBottomvh={"2.4"}>
              <A to={Routes.MANAGE_CELLS}>
                <SettingsIcon
                  width="35px"
                  height="35px"
                  color="black"
                  strokeWidth="0.7"
                  viewBox="-1 -1 26 26"
                />
              </A>
            </RightAlignedContainer>
          )}
        </Spacing>
        <Spacing mBottomvh="0.48" />
        <Row
          width="100%"
          shadow="0px"
          bgColor="textGrey"
          gap="1.145"
          heightvh="74.5"
        >
          <LeftColumn
            cells={cells}
            cellsLocation={cellsLocation}
            search={search}
            status={status}
            setCellDetails={setCellDetails}
          />
          <MiddleColumn
            colors={colors}
            setMapUpdateFunction={setMapUpdateFunction}
            fleetSize={totalUsage.fleetSize}
            totalJobs={totalUsage.totalJobs}
            unitsOnline={totalUsage.unitsOnline}
            activeLocations={activeLocation.activeCities}
          />
          <RightColumn setUpdateGraphFunction={setUpdateGraphFunction} />
        </Row>
      </PageBody>
    </>
  );
};

export default ControlRoom;
