import styled from "styled-components";
import React from "react";
import { Spacing, Rotate } from "..";

export const Spinner = styled.div`
  animation: 2s linear infinite ${Rotate};
  border: solid 3px #eee;
  border-bottom-color: ${(p) =>
    p.color ? p.theme.colors[p.color] : p.theme.colors.orange};
  border-radius: 50%;
  height: 10px;
  width: 10px;
  opacity: inherit;
  transform: translate3d(-50%, -50%, 0);
  transform-origin: center;
  will-change: transform;
  z-index: 1;
  margin: 10px 0 -10px 10px;
`;

export const Button = styled.button`
  cursor: pointer;
  outline: none;
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.lg};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.regular)};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.white)};
  border: none;
  border-radius: ${(p) =>
    p.borderRadius
      ? p.theme.borderRadius[p.borderRadius]
      : p.theme.borderRadius.sm};
  padding: ${(p) => (p.padding ? p.padding : "12px 40px 12px 40px")};
  background: ${(p) =>
    p.bgColor ? p.theme.colors[p.bgColor] : p.theme.colors.orange};
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.width && `width:${p.width}`};
`;

// Dimensions of icon passed into IconButton are height = 10px width = 10px
export const IconButton = ({
  text,
  icon,
  onClick,
  loading,
  loadingText,
  spinnerColor,
  leftIcon,
}) => {
  return (
    <Button
      style={{
        display: "flex",
        flexDirection: "row",
        whiteSpace: "nowrap",
      }}
      padding="10px 20px"
      borderRadius="xs"
      fontSize="sm"
      onClick={onClick}
    >
      {leftIcon && icon && (
        <>
          <div
            style={{
              alignSelf: "center",
            }}
          >
            {icon}
          </div>
          <Spacing mRightvh={"1"}></Spacing>
        </>
      )}
      {loading && loadingText}
      {!loading && text}
      <Spacing mRightvh={"1"}></Spacing>
      {loading && <Spinner color={spinnerColor} />}
      {!leftIcon && icon && (
        <div
          style={{
            alignSelf: "center",
          }}
        >
          {icon}
        </div>
      )}
    </Button>
  );
};

export const LoadingButton = ({
  text,
  loadingText,
  bgColor,
  spinnerColor,
  loading,
  onClick,
  fontSize,
  padding,
}) => {
  return (
    <Button
      style={{
        display: "flex",
        flexDirection: "row",
        whiteSpace: "nowrap",
      }}
      fontSize={fontSize}
      onClick={onClick}
      disabled={loading}
      bgColor={bgColor}
      padding={padding}
    >
      {loading && <Spinner color={spinnerColor} />}
      {loading && <span>{loadingText}</span>}
      {!loading && <span>{text}</span>}
    </Button>
  );
};

export const RoundedIconButton = ({
  text,
  icon,
  onClick,
  bgColor,
  padding,
  color,
  loading,
  loadingText,
  spinnerColor,
}) => {
  return (
    <Button
      style={{
        display: "flex",
        flexDirection: "row",
        whiteSpace: "nowrap",
      }}
      color={color}
      padding={padding ? padding : "10px 20px"}
      borderRadius="md"
      fontSize="sm"
      onClick={onClick}
      bgColor={bgColor ? bgColor : "orange"}
    >
      {icon && (
        <>
          <div
            style={{
              height: "14px",
              width: "14px",
              alignSelf: "center",
              color: "white",
            }}
          >
            {icon}
          </div>
          <Spacing mRightvh={"1.5"}></Spacing>
        </>
      )}
      {loading && <Spinner color={spinnerColor} />}
      {loading && loadingText}
      {!loading && text}
    </Button>
  );
};

export const TextButton = styled.button`
  cursor: ${(p) =>
    p.setPointer && p.setPointer === "false" ? "default" : "pointer"};
  outline: none;
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.md};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.regular)};
  color: ${(p) =>
    p.textColor ? p.theme.colors[p.textColor] : p.theme.colors.textDarkerGrey};
  border: none;
  border-radius: ${(p) =>
    p.borderRadius
      ? p.theme.borderRadius[p.borderRadius]
      : p.theme.borderRadius.sm};
  padding: ${(p) => (p.padding ? p.padding : "12px 40px 12px 40px")};
  background: ${(p) =>
    p.bgColor ? p.theme.colors[p.bgColor] : p.theme.colors.white};
  ${(p) => p.float && `float:${p.float}`};
`;

// Dimensions of icon passed into TextButton are height = 10px width = 10px
export const TextIconButton = ({ text, icon, onClick }) => {
  return (
    <TextButton
      style={{
        display: "flex",
        flexDirection: "row",
        whiteSpace: "nowrap",
      }}
      padding="10px 20px"
      borderRadius="xs"
      fontSize="sm"
      onClick={onClick}
    >
      <div
        style={{
          alignSelf: "center",
        }}
      >
        {icon}
      </div>
      <Spacing mRightvh={"1"}></Spacing>
      {text}
    </TextButton>
  );
};

export const ToggleButton = ({ id, name, isOn, onChange }) => {
  const SwitchInput = styled.input`
    height: 0;
    width: 0;
    visibility: hidden;
  `;

  const SwitchLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 37px;
    height: 22px;
    border-radius: 30px;
    position: relative;
    background: ${(p) => (p.color ? p.theme.colors[p.color] : "white")};
    transition: background-color 0.2s;
  `;

  const SwitchButton = styled.span`
    position: absolute;
    display: inline-block;
    top: 1px;
    left: 0px;
    width: 20.5px;
    height: 20px;
    border-radius: 30px;
    transition: 0.1s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    ${(p) =>
      p.checked &&
      `left: calc(100% - 1px);
      transform: translateX(-100%);`}
  `;
  return (
    <>
      <SwitchInput
        name={name}
        id={id}
        onChange={onChange}
        checked={isOn}
        type="checkbox"
      />
      <SwitchLabel color={isOn ? "green" : "backgroundGrey"} htmlFor={id}>
        <SwitchButton checked={isOn} />
      </SwitchLabel>
    </>
  );
};
