import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useRef } from "react";
import * as Routes from "./routes/Routes";
import { useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  ProfileIcon,
  CancelIcon,
  SearchIcon,
  SettingsIcon,
  LogoutIcon,
  AppHeading,
  Spacing,
  HeaderContainer,
  Menu,
  RightAlignedContainer,
  InlineText,
  Text,
  MenuContainer,
  RelativeDiv,
  Row,
  Input,
} from "./commonalities";
import { useHistory } from "react-router-dom";
import { useStore } from "./store";
import { appTheme } from "./commonalities/Theme";
import { SessionManager } from "./utils";
import i18n from "./commonalities/i18n";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      padding: "4px 0px",
      fontSize: appTheme.fontSize.md,
      font: appTheme.font.light,
    },
  },
});
const Header = ({ search, setSearch }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const menuRef = useRef(null);
  const history = useHistory();
  const [{ auth }] = useStore();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const isSearchInputRequired = () => {
    return (
      /^\/(en\/)?(da-DK\/)?$/.test(window.location.pathname) ||
      /^\/(en\/)?(da-DK\/)?cells$/.test(window.location.pathname) ||
      /^\/(en\/)?(da-DK\/)?users$/.test(window.location.pathname)
    );
  };

  const handleLogout = () => {
    setShowProfileMenu(false);
    sessionStorage.clear();
    if (SessionManager.sessionChecker) {
      clearInterval(SessionManager.sessionChecker);
    }
    window.open(Routes.LOGIN + i18n.language + "/", "_self");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowSearch(isSearchInputRequired());

    const routeChange = history.listen((location, action) => {
      setSearch("");
      setShowSearch(isSearchInputRequired());
    });
    return () => {
      routeChange();
    };
  }, []);
  return (
    <HeaderContainer position="relative" heightvh="8.14">
      <AppHeading redirection={Routes.CONTROL_ROOM} />
      <RightAlignedContainer
        heightvh={"3.7"}
        alignSelf={"flex-end"}
        alignItems="flex-end"
      >
        {showSearch && (
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("buttons-search")}
            width={"350px"}
            style={{
              marginBottom:
                "calc((var(--useMin) * 720px * 0.74 / 100) + (var(--usePercent) * 0.74vh))",
            }}
            InputProps={{
              className: classes.root,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="black" width={"15px"} />
                </InputAdornment>
              ),
              endAdornment: search ? (
                <InputAdornment onClick={() => setSearch("")} position="end">
                  <Spacing setPointer={true} height="18px">
                    <CancelIcon width={"18px"} />
                  </Spacing>
                </InputAdornment>
              ) : null,
            }}
          />
        )}
        <RelativeDiv
          width="auto"
          cursor="pointer"
          display="flex"
          onClick={() => setShowProfileMenu(!showProfileMenu)}
        >
          <Spacing mRightvw={"0.83"} mLeftvw={"1.67"} mTop={"auto"}>
            <ProfileIcon />
          </Spacing>
          {showProfileMenu && (
            <Menu width="212px" right="0px">
              <MenuContainer ref={menuRef}>
                <InlineText wordBreak="true" font="medium" color="black">
                  {auth.user.firstName + " " + auth.user.lastName}
                </InlineText>
                <Spacing mTopvh={"2.22"} />
                <Spacing mTopvh={"1.48"} mBottomvh={"1.48"}>
                  <Row
                    shadow="0px"
                    width="100%"
                    onClick={() => {
                      setShowProfileMenu(false);
                      history.push(Routes.USER_PROFILE);
                    }}
                    setPointer={true}
                  >
                    <ProfileIcon width={"24px"} color={"textGrey"} />

                    <Spacing mLeftvw="1.25" mTop="2%">
                      <InlineText fontSize="sm">
                        {t("usersProfile")}
                      </InlineText>
                    </Spacing>
                  </Row>
                </Spacing>
                {auth.user.userType === "Admin" && (
                  <Spacing mTopvh={"1.48"} mBottomvh={"1.48"}>
                    <Row
                      shadow="0px"
                      width="100%"
                      onClick={() => {
                        setShowProfileMenu(false);
                        history.push(Routes.MANAGE_USERS);
                      }}
                      setPointer={true}
                    >
                      <SettingsIcon color={"textGrey"} width={"24px"} />
                      <Spacing mLeftvw="1.25" mTop="2%">
                        <InlineText fontSize="sm">
                          {t("manageUsers")}
                        </InlineText>
                      </Spacing>
                    </Row>
                  </Spacing>
                )}
                <Spacing mTopvh={"1.48"} mBottomvh={"0.74"}>
                  <Row
                    shadow="0px"
                    width="100%"
                    onClick={handleLogout}
                    setPointer={true}
                  >
                    <Spacing mLeft={"3px"} />
                    <LogoutIcon width={"22px"} color={"textGrey"} />
                    <Spacing mLeftvw="1.25" mTop="2%">
                      <InlineText fontSize="sm">
                        {t("buttons-logout")}
                      </InlineText>
                    </Spacing>
                  </Row>
                </Spacing>
              </MenuContainer>
            </Menu>
          )}
          <Text
            mBottomvh="0.74"
            height="auto"
            width="auto"
            float="none"
            lineHeight="1.8"
            alignSelf="flex-end"
          >
            {auth.user.firstName + " " + auth.user.lastName}
          </Text>
        </RelativeDiv>
      </RightAlignedContainer>
      <Spacing mLeftvw="3.33" />
    </HeaderContainer>
  );
};

export default Header;
