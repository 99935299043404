import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { SuccessIcon, CancelIcon, DashedContainer } from "..";
import { Spacing } from "..";
import { useTranslation } from "react-i18next";

const IconContainer = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-top: 1px;
`;
const Text = styled.label`
  font-family: ${(p) => p.theme.font.light};
  font-size: ${(p) =>
    p.color ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.xs};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.black)};
  overflow: hidden;
  text-overflow: ellipsis;
  ${(p) => p.textAlign && `text-align: center;`}
  ${(p) => p.setJustify && `justify-content: center;`}
  width: 90%;
  ${(p) => p.setInlineFlex && `display: inline-flex;`};
`;

const DragDropContainer = ({
  name,
  fileName,
  multiFileSupport,
  validExtension,
  handleFileUpload,
  errorMsg,
}) => {
  const [isDrag, setIsDrag] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState(false);
  const [extensions, setExtensions] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    let exts = "";
    validExtension.forEach((ext, index) => {
      if (index === 0) exts = ext;
      else exts = exts + " " + ext;
    });

    setExtensions(exts);
  }, []);

  const handleExtension = (files) => {
    setError(false);
    Object.values(files).forEach((file) => {
      const extension = file.name.split(".").pop();
      if (!validExtension.includes(extension)) {
        setError(true);
      }
    });
  };

  const dragOver = (event) => {
    event.preventDefault();
  };

  const dragEnter = (event) => {
    event.preventDefault();
    setIsDrag(true);
  };

  const dragLeave = (event) => {
    event.preventDefault();
    setIsDrag(false);
  };

  const fileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setSelectedFiles(files);
    handleExtension(files);
    handleFileUpload(files);
    setIsDrag(false);
  };

  const handleChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);
    handleExtension(files);
    handleFileUpload(files);
  };

  return (
    <>
      <DashedContainer
        htmlFor={name}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        color={errorMsg === "" ? "textGrey" : "red"}
      >
        <input
          type="file"
          id={name}
          hidden
          multiple={multiFileSupport}
          onChange={handleChange}
        />
        {selectedFiles.length === 0 ? (
          <>
            <Text textAlign={true}>
              {!isDrag ? (
                fileName ? (
                  <Text textAlign={true} htmlFor={name}>
                    {fileName}
                  </Text>
                ) : (
                  <>
                    <Text textAlign={true} htmlFor={name}>
                      {t("product-dragDropMsg1")}
                    </Text>
                    <Text textAlign={true} htmlFor={name} color="orange">
                      {" " + t("product-dragDropMsg2")}
                    </Text>
                  </>
                )
              ) : (
                <Text textAlign={true}>{t("product-dragDropMsg63")}</Text>
              )}
            </Text>
            <Spacing mTopvh="0.5" />
            <Text
              textAlign={true}
              htmlFor={name}
              fontSize="xxs"
              color="textGrey"
            >
              {t("product-dragDropMsg4") + ": " + extensions}
            </Text>
            {errorMsg !== "" && (
              <Text textAlign={true} fontSize="xxs" color="red">
                {errorMsg}
              </Text>
            )}
          </>
        ) : error ? (
          <>
            <Text setJustify={true} setInlineFlex={true} htmlFor={name}>
              <IconContainer>
                <CancelIcon
                  width="13px"
                  color="red"
                  borderColor="red"
                  fillColor="white"
                  strokeWidth="1.8"
                />
              </IconContainer>
              <Spacing wrapText={true} htmlFor={name}>
                {selectedFiles[0].name + " "}
              </Spacing>
            </Text>
            <Spacing mTopvh="0.5" />
            <Text textAlign={true} htmlFor={name} fontSize="xxs" color="red">
              {t("product-dragDropMsg5")}
            </Text>
          </>
        ) : (
          <>
            <Text setJustify={true} setInlineFlex={true} htmlFor={name}>
              <IconContainer>
                <SuccessIcon
                  width={"12px"}
                  fillColor="white"
                  color="green"
                  borderColor="green"
                  strokeWidth="2.9"
                />
              </IconContainer>

              <Spacing wrapText={true} htmlFor={name}>
                {selectedFiles[0].name + " "}
              </Spacing>
            </Text>
            <Spacing mTopvh="0.5" />
            <Text
              textAlign={true}
              htmlFor={name}
              fontSize="xxs"
              color="textGrey"
            >
              {t("product-dragDropMsg6") + " " + new Date().toLocaleString()}
            </Text>
          </>
        )}
      </DashedContainer>
    </>
  );
};
export default DragDropContainer;
