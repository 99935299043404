import React from "react";
import { appTheme as theme } from "../Theme";

export const AlertIcon = ({ width, color }) => {
  const WIDTH = width || "18";
  const COLOR = theme.colors[color] || theme.colors.red;
  const FILL_COLOR = theme.colors.white;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={WIDTH}
      height={WIDTH}
      viewBox="0 0 24 24"
    >
      <g
        id="Ellipse_124"
        data-name="Ellipse 124"
        fill="none"
        stroke={COLOR}
        strokeWidth="1.25"
      >
        <circle cx="12" cy="12" r="12" stroke="none" />
        <circle cx="12" cy="12" r="11.375" fill={FILL_COLOR} />
      </g>
      <rect
        id="Rectangle_9274"
        data-name="Rectangle 9274"
        width="3"
        height="9.54"
        rx="1.5"
        transform="translate(10.5 4.979)"
        fill={COLOR}
      />
      <rect
        id="Rectangle_9275"
        data-name="Rectangle 9275"
        width="3"
        height="3"
        rx="1.5"
        transform="translate(10.5 16.021)"
        fill={COLOR}
      />
    </svg>
  );
};
