import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */

export const EditIcon = ({ width, color, fillcolor, strokeWidth, pointer }) => {
  const WIDTH = width || "20";
  const COLOR = color || theme.colors.white;
  const STROKE_WIDTH = strokeWidth || "1.5";
  const FILL_COLOR = theme.colors[fillcolor] || "none";

  return (
    <svg
      style={pointer && { cursor: "pointer" }}
      width={WIDTH}
      height={WIDTH}
      stroke={COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <g
        id="Group_2885"
        data-name="Group 2885"
        transform="translate(-816 -204)"
      >
        <g
          id="Group_2300"
          data-name="Group 2300"
          transform="translate(49 -369.32)"
        >
          <path
            id="Path_767"
            data-name="Path 767"
            d="M317.846,80.237l-3.1,1.119,1.19-3.03,10.713-10.634,1.912,1.912Z"
            transform="translate(453.256 510.965)"
            fill={FILL_COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth={STROKE_WIDTH}
          />
          <rect
            id="Rectangle_7849"
            data-name="Rectangle 7849"
            width="2.648"
            height="2.648"
            transform="translate(783.936 574.597) rotate(45)"
            fill={FILL_COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth={STROKE_WIDTH}
          />
        </g>
      </g>
    </svg>
  );
};
