import React from "react";
import { Spacing, Text, Tooltip } from "../../../../commonalities";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

const InformationCardItem = ({ data }) => {
  const { t } = useTranslation();
  const el = React.useRef(undefined);
  const [key, value] = data;

  return (
    <Fragment key={key}>
      <Spacing mTopvh={"1.48"} />
      <Text height="auto" color={"textDarkGrey"} fontSize={"xs"}>
        {t(key)}
      </Text>
      <Text
        wrapText={"true"}
        height={"auto"}
        ref={el}
        data-tip
        data-for={"informationCard" + value}
      >
        {value}
      </Text>
      <Tooltip
        el={el}
        id={"informationCard" + value}
        value={value}
        textColor={"black"}
      />
    </Fragment>
  );
};

export default InformationCardItem;
