import React, { useState, useEffect } from "react";
import { LoginModule } from "../../commonalities";
import { AuthActions } from "../../store";
import { NetworkController, SessionManager } from "../../utils";
import * as Routes from "../../routes/Routes";
import { useHistory } from "react-router-dom";
import image from "../../images/WSlogin.png";

const Login = () => {
  const history = useHistory();
  const authActions = AuthActions();
  const [loginSuccessful, setLoginSuccesful] = useState(false);

  useEffect(() => {
    if (loginSuccessful) {
      NetworkController.getIoTAccess({}, (res) => {
        try {
          sessionStorage.setItem("accessKeyId", res.data.iotAccess.AccessKeyId);
          sessionStorage.setItem(
            "secretKey",
            res.data.iotAccess.SecretAccessKey
          );
          sessionStorage.setItem(
            "sessionToken",
            res.data.iotAccess.SessionToken
          );
        } catch (err) {
          console.log(err);
        }
        authActions.setAuth(SessionManager.userData);
      });
      history.push(Routes.CONTROL_ROOM);
    }
  }, [loginSuccessful]);

  return (
    <LoginModule
      image={image}
      headerRedirection={Routes.CONTROL_ROOM}
      setLoginSuccesful={setLoginSuccesful}
    />
  );
};
export default Login;
