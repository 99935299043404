import MapLocationMarker from "../images/MapLocationMarker.svg";

const mapMarkerPadding = 1;

//getting the tooltips and the markers for a cell
export const getMapMarker = (
  mouseEnterCallback,
  onCloseCallback,
  innerCircleColor,
  circleColor
) => {
  const marker = document.createElement("div");
  const circle = document.createElement("div");
  const innerCircle = document.createElement("div");
  circle.appendChild(innerCircle);
  circle.style.background = circleColor;
  innerCircle.style.background = innerCircleColor;
  circle.style.zIndex = `0`;
  marker.className = "marker";
  marker.appendChild(circle);
  circle.className = "outerCircle";
  innerCircle.className = "innerCircle";
  marker.style.backgroundImage = `url(${MapLocationMarker}`;
  marker.style.width = `40px`;
  marker.style.height = `45px`;
  marker.style.marginBottom = `10px`;
  marker.style.backgroundSize = "100%";
  marker.onmouseenter = () => {
    marker.style.backgroundImage = `none`;
    circle.style.display = `none`;
    mouseEnterCallback();
  };
  const closePopUp = () => {
    circle.style.display = ``;
    marker.style.backgroundImage = `url(${MapLocationMarker})`;
    onCloseCallback();
  };
  marker.onmouseleave = closePopUp;
  return marker;
};

// getting the zoom level of the map based on the cell's status.
export const getMapZoomLevel = (coordinates) => {
  var maxdistance = 0;
  const things = Object.keys(coordinates);

  things.forEach((thing1) => {
    things.forEach((thing2) => {
      if (thing1 !== thing2) {
        var vector1 = coordinates[thing1];
        var vector2 = coordinates[thing2];
        var distance = vector1.distance(vector2);
        if (maxdistance < distance) {
          maxdistance = distance;
        }
      }
    });
  });
};

// getting the map center and adding some padding to it.
export const getMapCenter = (coordinates) => {
  const things = Object.keys(coordinates);
  var minX = 1000;
  var minY = 1000;
  var maxX = -1000;
  var maxY = -1000;
  things.forEach((thing) => {
    var vector1 = coordinates[thing];
    minX = Math.min(minX, vector1.x);
    maxX = Math.max(maxX, vector1.x);
    minY = Math.min(minY, vector1.y);
    maxY = Math.max(maxY, vector1.y);
  });
  return [
    [minX - mapMarkerPadding, minY - mapMarkerPadding],
    [maxX + mapMarkerPadding, maxY + mapMarkerPadding],
  ];
};
