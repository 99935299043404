import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  TextButton,
  Spacing,
  Row,
  InlineText,
  Expand,
  Shrink,
  AnimationContainer,
} from "..";

const Root = styled.div`
  position: fixed;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  overflow-y: hidden;
  display: flex;
  z-index: 2;
  width: calc(
    (var(--useMinWidth) * var(--widthLimit) * 22.09 / 100) +
      (var(--usePercentWidth) * 22vw)
  );
  margin-right: calc(
    (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
      (var(--usePercentWidth) * 3.33vw)
  );
  margin-bottom: calc(
    (var(--useMinWidth) * var(--widthLimit) * 1.67 / 100) +
      (var(--usePercentWidth) * 1.67vw)
  );
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: ${(p) => p.theme.borderRadius.sm};
`;

const ToastCardContainer = styled(AnimationContainer)`
  animation-name: ${(p) =>
    p.shrink ? Shrink("70px", "0px") : Expand("10px", "70px")};
  animation-duration: 0.5s;
  border: 1px solid green;
  width: 100%;
  padding: calc(
    (var(--useMinWidth) * var(--widthLimit) * 1.67 / 100) +
      (var(--usePercentWidth) * 1.67vw)
  );
  padding-bottom: calc(
    (var(--useMinWidth) * var(--widthLimit) * 1.25 / 100) +
      (var(--usePercentWidth) * 1.25vw)
  );
`;

const ToastCard = ({ heading, number, onRefresh }) => {
  const { t } = useTranslation();
  const [startShrink, setStartShrink] = useState(false);

  const handleRefresh = () => {
    setStartShrink(true);
    setTimeout(() => {
      onRefresh();
    }, 450);
  };

  return (
    <Root>
      <ToastCardContainer shrink={startShrink}>
        <Row shadow={"unset"} width={"100%"}>
          <InlineText color={"black"}>{heading}</InlineText>
        </Row>
        <Spacing mTopvw={"1.04"} />
        <Row shadow={"unset"} width={"100%"}>
          <TextButton
            onClick={handleRefresh}
            fontSize={"sm"}
            bgColor={"green"}
            textColor={"white"}
            borderRadius={"xxs"}
            padding={"8px 24px"}
            font={"medium"}
          >
            {t("refresh")}
          </TextButton>
          <Spacing margin="auto 6px auto auto">
            <InlineText font={"medium"} color={"green"}>
              {"+" + number}
            </InlineText>
          </Spacing>
        </Row>
      </ToastCardContainer>
    </Root>
  );
};

export default ToastCard;
