import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SettingsAccordion } from "../../../../../styles";
import { ToolIcon } from "../../../../../icons";

const ToolsSettings = ({ settings, config, show, setExpand, heading }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    "settings-selectedTool": settings ? settings.SelectedToolName : "-",
  });

  useEffect(() => {
    if (
      config &&
      config.tools.tools.length > 0 &&
      config.tools.tools[0].sub_tools
    ) {
      let toolData = {
        "settings-selectedTool": settings ? settings.SelectedToolName : "-",
      };
      var subtoolCount = config.tools.tools[0].sub_tools.length;
      config.tools.tools[0].sub_tools.map((element) => {
        return element.properties.map((prop) => {
          if (prop.key === "width") {
            let name =
              subtoolCount > 1
                ? element.sub_tool_name +
                  " " +
                  t("cell-width").toLocaleLowerCase()
                : "cell-settingsToolWidth";

            toolData[name] = prop.value + " mm";
          }
        });
      });

      setData(toolData);
    }
  }, [config, settings]);

  return (
    <SettingsAccordion
      expand={"tool"}
      iconImage={<ToolIcon width="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default ToolsSettings;
