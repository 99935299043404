import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const CancelIcon = ({
  width,
  color,
  fillColor,
  borderColor,
  strokeWidth,
  radius,
}) => {
  const WIDTH = width || "20";
  const COLOR = color || theme.colors.white;
  const FILL_COLOR = fillColor || theme.colors.textGrey;
  const STROKE_WIDTH = strokeWidth || "1";
  const RADIUS = radius || "10";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      fill={FILL_COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_1967"
        data-name="Group 1967"
        transform="translate(-1690 -50)"
      >
        <circle
          id="Ellipse_124"
          data-name="Ellipse 124"
          cx="10"
          cy="10"
          r={RADIUS}
          transform="translate(1692 52)"
          fill={FILL_COLOR}
          stroke={theme.colors[borderColor] || "none"}
          strokeWidth={strokeWidth || "0"}
        />
        <line
          id="Line_139"
          data-name="Line 139"
          y1="8"
          x2="8"
          transform="translate(1698 58)"
          fill="none"
          stroke={COLOR}
          strokeLinecap="round"
          strokeWidth={STROKE_WIDTH}
        />
        <line
          id="Line_140"
          data-name="Line 140"
          x1="8"
          y1="8"
          transform="translate(1698 58)"
          fill="none"
          stroke={COLOR}
          strokeLinecap="round"
          strokeWidth={STROKE_WIDTH}
        />
      </g>
    </svg>
  );
};
