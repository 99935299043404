import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  margin: auto;
`;
export const FormHeading = styled.div`
  font-size: ${(p) => p.theme.fontSize.xxl};
  font-family: ${(p) => p.theme.font.regular};
  color: #111111;
  text-align: ${(p) => (p.textAlign ? p.textAlign : "inherited")};
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  float: ${(p) => (p.float ? p.float : "none")};
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  height: ${(p) => (p.height ? p.height : "fit-content")};
  width: ${(p) => (p.width ? p.width : "100%")};
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  ${(p) =>
    (p.vPadding || p.hPadding) &&
    `padding: calc(
    (var(--useMinWidth) * var(--widthLimit) * ${p.vPadding || "0"} / 100) +
      (var(--usePercentWidth) * ${p.vPadding || "0"}vw)
  )
  calc(
    (var(--useMinWidth) * var(--widthLimit) * ${p.hPadding || "0"} / 100) +
      (var(--usePercentWidth) * ${p.hPadding || "0"}vw)
  )`};
  ${(p) =>
    p.mTopvh &&
    `margin-top: calc((var(--useMin) * var(--heightLimit) * ${p.mTopvh} / 100) + (var(--usePercent) * ${p.mTopvh}vh))`};
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  ${(p) => p.setJustify && `justify-content: space-between`};
  width: ${(p) =>
    p.widthvw
      ? `calc((var(--useMinWidth) * var(--widthLimit) * ${p.widthvw} / 100) + (var(--usePercentWidth) * ${p.widthvw}vw))`
      : p.width
      ? p.width
      : "100%"};
  ${(p) => p.height && `height:${p.height}`};
  ${(p) =>
    p.heightvh &&
    `height:calc((var(--useMin) * var(--heightLimit) * ${p.heightvh} / 100) + (var(--usePercent) * ${p.heightvh}vh))`};
  ${(p) =>
    p.borderRadius && `border-radius: ${p.theme.borderRadius[p.borderRadius]}`};
  ${(p) =>
    p.gap &&
    `gap:calc(
    (var(--useMin) * 720px * ${p.gap}/ 100) +
      (var(--usePercent) * ${p.gap}vh)
  )`};
  ${(p) => p.padding && `padding: ${p.padding}`}
  ${(p) =>
    p.marginvw &&
    `margin: calc(
    (var(--useMinWidth) * var(--widthLimit) * ${p.marginvw} / 100) +
      (var(--usePercentWidth) * ${p.marginvw}vw)
  )`};
`;

export const FormContainer = styled.div`
  width: 35%;
  min-width: 449px;
  padding: 0px 3.75%;
  background: #fff;
  display: flex;
`;
