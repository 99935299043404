import styled from "styled-components";
import { Fade, Shrink, Expand } from "..";

export const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.colors.backgroundGrey};
  width: ${(p) =>
    p.width
      ? `calc( 100% - ((var(--useMinWidth) * 1250px * ${p.width} / 100) +
      (var(--usePercentWidth) * ${p.width}vw)))`
      : `calc(
    (var(--useMinWidth) * var(--widthLimit)) + (var(--usePercentWidth) * 100%)
  )`};
  margin-top: ${(p) =>
    p.mTop
      ? p.mTop
      : `calc(
    (var(--useMin) * var(--heightLimit) * 6 / 100) +
      (var(--usePercent) * 6vh)
  )`};
  ${(p) => p.mLeft && `margin-left:${p.mLeft}`};
  ${(p) => p.mRight && `margin-right:${p.mRight}`};
`;

export const FullView = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const CenteredFullView = styled(FullView)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CenteredContent = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  ${(p) => p.width && `width: ${p.width}`};
  ${(p) => p.height && `height: ${p.height}`};
  ${(p) => p.display && `display: ${p.display}`};
`;

export const GrayPageContainer = styled.div`
  background: ${(p) => p.theme.colors.backgroundGrey};
  width: 100%;
  overflow-y: hidden;
  height: 100%;
`;

export const EmptyDiv = styled.div`
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "100%")};
  margin-top: ${(p) => (p.mTop ? p.mTop : "0px")};
  margin-left: ${(p) => (p.mLeft ? p.mLeft : "0px")};
  margin-right: ${(p) => (p.mRight ? p.mRight : "0px")};
  margin-bottom: ${(p) => (p.mBottom ? p.mBottom : "0px")};
  float: ${(p) => (p.float ? p.float : "left")};
  padding: ${(p) => (p.padding ? p.padding : "0px")};
`;

export const ShadowDiv = styled.div`
  display: ${(p) => (p.display ? p.display : "flex")};
  ${(p) => p.flexDirection && `flex-direction:${p.flexDirection}`};
  ${(p) => p.border && `border: ${p.border}`};
  border-radius: ${(p) =>
    p.borderRadius
      ? p.theme.borderRadius[p.borderRadius]
      : p.theme.borderRadius.md};
  box-shadow: ${(p) =>
    p.shadow ? p.shadow : "0px 3px 6px rgba(0, 0, 0, 0.16)"};
  background: ${(p) =>
    p.bgColor ? p.theme.colors[p.bgColor] : p.theme.colors.white};
  margin: ${(p) => (p.margin ? p.margin : "0px")};
  width: ${(p) =>
    p.width
      ? isNaN(Number(p.width)) // If the given width includes only numbers like "12" or "2.354" and not %, px or calc etc.
        ? p.width
        : `calc((var(--useMinWidth) * var(--widthLimit) * ${p.width} / 100) + (var(--usePercentWidth) * ${p.width}vw))`
      : "auto"};
  min-width: ${(p) =>
    p.minWidth
      ? isNaN(Number(p.minWidth))
        ? p.minWidth
        : `calc((var(--useMinWidth) * var(--widthLimit) * ${p.minWidth} / 100) + (var(--usePercentWidth) * ${p.minWidth}vw))`
      : "auto"};
  height: ${(p) =>
    p.height
      ? isNaN(Number(p.height))
        ? p.height
        : `calc((var(--useMin) * var(--heightLimit) * ${p.height} / 100) + (var(--usePercent) * ${p.height}vh))`
      : "auto"};
  padding: ${(p) =>
    p.padding
      ? isNaN(Number(p.padding))
        ? p.padding
        : `calc((var(--useMinWidth) * var(--widthLimit) * ${p.padding} / 100) + (var(--usePercentWidth) * ${p.padding}vw))`
      : "0"};
  ${(p) =>
    (p.vPadding || p.hPadding) &&
    `padding :  calc(
      (
          var(--useMin) * var(--heightLimit) *
            ${p.vPadding ? p.vPadding : "0"} / 100
        ) +
        (
          var(--usePercent) *
            ${p.vPadding ? p.vPadding : "0"}vh
        )
    )
    calc(
      (
          var(--useMinWidth) * var(--widthLimit) *
            ${p.hPadding ? p.hPadding : "0"} / 100
        ) +
        (var(--usePercentWidth) * ${p.hPadding ? p.hPadding : "0"}vw)
    )`};
  ${(p) =>
    p.gap &&
    `gap:calc(
            (var(--useMinWidth) * var(--widthLimit) * ${p.gap} / 100) +
              (var(--usePercentWidth) * ${p.gap}vw)
          )`};
`;

export const RoundedContainer = styled.div`
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
  float: ${(p) => (p.float ? p.float : "left")};
  background-color: ${(p) => (p.bgColor ? p.bgColor : p.theme.colors.white)};
  margin-top: ${(p) => (p.mTop ? p.mTop : "0px")};
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "100%")};
  box-shadow: ${(p) =>
    p.shadow ? p.shadow : "0px 2px 6px rgba(0, 0, 0, 0.2)"};
  border-radius: ${(p) =>
    p.borderRadius ? p.borderRadius : p.theme.borderRadius.sm};
  ${(p) => p.minHeight && `min-height:${p.minHeight}`};
  ${(p) => p.padding && `padding:${p.padding}`};
  ${(p) => p.display && `display:${p.display}`};
  ${(p) => p.alignItems && `align-items:${p.alignItems}`};
  ${(p) => p.position && `position:${p.position}`};
`;

export const FloatingContainer = styled.div`
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
  display: flex;
  float: ${(p) => (p.float ? p.float : "left")};
  ${(p) => (p.bgColor ? `background-color:${p.bgColor}` : "")};
  margin-top: ${(p) => (p.mTop ? p.mTop : "0px")};
  margin-left: ${(p) => (p.mLeft ? p.mLeft : "0px")};
  margin-right: ${(p) => (p.mRight ? p.mRight : "0px")};
  margin-bottom: ${(p) => (p.mBottom ? p.mBottom : "0px")};
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "100%")};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  justify-content: ${(p) => (p.justify ? p.justify : "center")};
`;

export const Flexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FlexContainer = styled.div`
  position: absolute;
  width: ${(p) => (p.width ? p.width : "100%")};
  margin: auto;
  display: flex;
  top: ${(p) => (p.top ? p.top : "0px")};
  bottom: ${(p) => (p.bottom ? p.bottom : "0px")};
  flex-wrap: wrap;
  ${(p) => p.minWidth && `min-width:${p.minWidth}`};
  ${(p) => p.minHeight && `min-height:${p.minHeight}`};
`;

export const ToolbarContainer = styled.div`
  display: flex;
  position: sticky;
  background: ${(p) => p.theme.colors.backgroundGrey};
  width: calc(
    100% -
      (
        (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
          (var(--usePercentWidth) * 3.33vw)
      ) * 2
  );
  padding: 0px
    calc(
      (var(--useMinWidth) * var(--widthLimit) * 3.33 / 100) +
        (var(--usePercentWidth) * 3.33vw)
    );
  margin-left: auto;
  margin-right: auto;
  padding-bottom: calc(
    (var(--useMin) * var(--heightLimit) * 1 / 100) + (var(--usePercent) * 1vh)
  );
`;

export const ScrollingContainer = styled.div`
  padding: ${(p) => (p.padding ? p.padding : "0")};
  height: ${(p) => (p.height ? p.height : "80%")};
  ${(p) => p.maxHeight && `max-height: ${p.maxHeight}`};
  ${(p) => p.position && `position: ${p.position}`};
  ${(p) => p.setFlexColumn && ` display: flex; flex-direction: column;`};
  width: ${(p) => (p.width ? p.width : "95%")};
  margin-left: ${(p) => (p.mLeft ? p.mLeft : "20px")};
  margin-right: ${(p) => (p.mRight ? p.mRight : "0px")};
  margin-top: ${(p) => (p.mTop ? p.mTop : "0px")};
  margin-bottom: ${(p) => (p.mBottom ? p.mBottom : "0px")};
  ${(p) =>
    p.mTopvw &&
    `margin-top: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mTopvw} / 100) + (var(--usePercentWidth) * ${p.mTopvw}vw))`};
  ${(p) =>
    p.mBottomvw &&
    `margin-bottom: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mBottomvw} / 100) + (var(--usePercentWidth) * ${p.mBottomvw}vw))`};
  ${(p) =>
    p.mLeftvw &&
    `margin-left: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mLeftvw} / 100) + (var(--usePercentWidth) * ${p.mLeftvw}vw))`};
  ${(p) =>
    p.mRightvw &&
    `margin-right: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mRightvw} / 100) + (var(--usePercentWidth) * ${p.mRightvw}vw))`};

  cursor: default;
  overflow-y: ${(p) => (p.overflowY ? p.overflowY : "auto")};
  overflow-x: ${(p) => (p.overflowX ? p.overflowX : "hidden")};
  scrollbar-width: thin;
  scrollbar-color: ${(p) => (p.color ? p.color : p.theme.colors.textDarkGrey)}
    ${(p) => (p.color ? p.color : p.theme.colors.iconBgGrey)};
  ::-webkit-scrollbar {
    width: ${(p) => (p.scrollWidth ? p.scrollWidth : "7px")};
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background: ${(p) => p.theme.colors.iconBgGrey};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(p) => p.theme.colors.textDarkGrey};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  float: ${(p) => (p.float ? p.float : "right")};
  height: ${(p) => (p.height ? p.height : "fit-content")};
  margin: ${(p) => (p.margin ? p.margin : "0px")};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "flex-end")};
  ${(p) => p.pLeft && `padding-left:${p.pLeft}`};
  ${(p) => p.pRight && `padding-right:${p.pRight}`};
  ${(p) => p.bgColor && `background-color:${p.bgColor}`};
`;

export const AbsolutePositionedBox = styled.div`
  visiblity: ${(p) => (p.visiblity ? p.visiblity : "collapse")};
  position: absolute;
  width: ${(p) => (p.width ? p.width : "auto")};
  height: ${(p) => (p.height ? p.height : "auto")};
  ${(p) => p.top && `top:${p.top}`};
  ${(p) => p.bottom && `bottom:${p.bottom}`};
  ${(p) => p.left && `left:${p.left}`};
  ${(p) => p.right && `right:${p.right}`};
  ${(p) => (p.bgColor ? `background-color:${p.bgColor}` : "")};
  ${(p) => p.margin && `margin: ${p.margin}`}
`;

export const RelativeDiv = styled.div`
  position: relative;
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "100%")};
  ${(p) => p.display && `display:${p.display}`};
  ${(p) => p.cursor && `cursor:${p.cursor}`};
`;

export const DashedContainer = styled.div`
  width: ${(p) => (p.width ? p.width : "110%")};
  height: ${(p) => (p.height ? p.height : "70px")};
  border: 1px dashed
    ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.textGrey)};
  border-radius: 10px;
  display: ${(p) => (p.display ? p.display : "flex")};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  justify-content: ${(p) => (p.justify ? p.justify : "center")};
  flex-direction: ${(p) => (p.flexDirection ? p.flexDirection : "column")};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  font-family: ${(p) => p.theme.font.light};
  font-size: ${(p) => p.theme.fontSize.xs};
  ${(p) => p.position && `position:${p.position}`};
  ${(p) => p.textAlign && `text-align:${p.textAlign}`};
  ${(p) => p.overflow && `overflow:${p.overflow}`};
`;

export const RightAlignedContainer = styled.div`
  display: flex;
  margin-left: auto;
  ${(p) => p.height && `height: ${p.height}`};
  ${(p) =>
    p.heightvh &&
    `height: calc((var(--useMin) * var(--heightLimit) * ${p.heightvh} / 100) + (var(--usePercent) * ${p.heightvh}vh))`};
  ${(p) => p.alignSelf && `align-self: ${p.alignSelf}`};
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) =>
    p.mBottomvh &&
    `margin-bottom: calc((var(--useMin) * var(--heightLimit) * ${p.mBottomvh} / 100) + (var(--usePercent) * ${p.mBottomvh}vh))`};
  ${(p) =>
    p.pBottomvh &&
    `margin-bottom: calc((var(--useMin) * var(--heightLimit) * ${p.pBottomvh} / 100) + (var(--usePercent) * ${p.pBottomvh}vh))`};
`;

export const HeaderContainer = styled.div`
  display: flex;
  z-index: 4;
  position: ${(p) => (p.position ? p.position : "fixed")};
  top: 0;
  background: ${(p) => p.theme.colors.backgroundGrey};
  width: ${(p) => (p.width ? p.width : "100%")};
  padding: 0px;
  height: ${(p) =>
    p.heightvh
      ? `calc(
    (var(--useMin) * var(--heightLimit) * ${p.heightvh} / 100) + (var(--usePercent) * ${p.heightvh}vh)
  )`
      : p.height
      ? p.height
      : "100px"};
`;

export const OptionsContainer = styled.div`
  z-index: 1;
  width: ${(p) =>
    p.width
      ? ` calc((var(--useMinWidth) * var(--widthLimit) * ${p.width} / 100) + (var(--usePercentWidth) * ${p.width}vw))`
      : "100%"};
  padding: calc(
      (var(--useMinWidth) * var(--widthLimit) * 0.42 / 100) +
        (var(--usePercentWidth) * 0.42vw)
    )
    0px;

  position: absolute;
  margin-top: ${(p) =>
    p.mTop
      ? `calc((var(--useMin) * var(--heightLimit) * ${p.mTop} / 100) + (var(--usePercent) * ${p.mTop}vh))`
      : `calc(
    (var(--useMin) * var(--heightLimit) * 5.18 / 100) +
      (var(--usePercent) * 5.18vh)
  )`};
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: ${(p) => p.theme.borderRadius.xs};
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(p) => p.width && `width: ${p.width}`};
  margin: ${(p) =>
    p.margin &&
    `calc(
    (var(--useMinWidth) * var(--widthLimit) * ${p.margin} / 100) +
      (var(--usePercentWidth) * ${p.margin}vw)
  )`};
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.flexDirection ? p.flexDirection : "column")};
  background: ${(p) => p.theme.colors.white};
  border-radius: ${(p) =>
    p.borderRadius
      ? p.theme.borderRadius[p.borderRadius]
      : p.theme.borderRadius.sm};
  animation-name: ${(p) =>
    p.shrink
      ? p.height
        ? Shrink(p.height, "0px")
        : Shrink()
      : p.height
      ? Expand("0px", p.height)
      : Expand()};
  animation-duration: ${(p) =>
    p.animationDuration ? p.animationDuration : "0.1s"};
  animation-iteration-count: 1;
`;

export const MenuContainer = styled(AnimationContainer)`
  display: block;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.26);
  margin-top: 35px;
  height: fit-content;
  ${(p) =>
    p.mRightvw &&
    `margin-right: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mRightvw} / 100) + (var(--usePercentWidth) * ${p.mRightvw}vw))`};
  padding: calc(
      (var(--useMin) * var(--heightLimit) * 2.4 / 100) +
        (var(--usePercent) * 2.4vh)
    )
    calc(
      (var(--useMinWidth) * var(--widthLimit) * 1.67 / 100) +
        (var(--usePercentWidth) * 1.67vw)
    );
  animation-name: ${Fade(0, 1)};
  animation-duration: 0.3s;
`;

export const DropDownContainer = styled(AnimationContainer)`
  ${(p) => p.width && `width: ${p.width}`};
  ${(p) => p.height && `height: ${p.height}`};
  border-radius: ${(p) => p.theme.borderRadius.md};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  ${(p) =>
    p.pTopvw &&
    `padding-top: calc(
    (var(--useMinWidth) * var(--widthLimit) * ${p.pTopvw} / 100) +
      (var(--usePercentWidth) * ${p.pTopvw}vw)
  )`};
  ${(p) =>
    p.pLeftvw &&
    `padding-left: calc(
    (var(--useMinWidth) * var(--widthLimit) * ${p.pLeftvw} / 100) +
      (var(--usePercentWidth) * ${p.pLeftvw}vw)
  )`};
`;
