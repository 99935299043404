import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PanelSettings,
  DistanceSettings,
  OperationModeSettings,
  PanelTypeSettings,
  SpeedSettings,
  StackSettings,
  VersionInformation,
} from "./";
import RepetitionSettings from "./RepetitionsSettings";

const EvaloSettings = ({ settings }) => {
  const [expand, setExpand] = useState("");
  const { t } = useTranslation();

  return (
    <>
      <PanelSettings
        settings={settings}
        show={expand === "panel"}
        setExpand={setExpand}
        heading={t("cell-settingsPanel")}
      />
      <SpeedSettings
        settings={settings}
        show={expand === "speed"}
        setExpand={setExpand}
        heading={t("cell-settingsSpeed")}
      />
      <RepetitionSettings
        settings={settings}
        show={expand === "repetitions"}
        setExpand={setExpand}
        heading={t("cell-settingsRepetitions")}
      />
      {
        /* <DistanceSettings
        settings={settings}
        show={expand === "distance"}
        setExpand={setExpand}
        heading={t("Distance")}
      />*/
        <StackSettings
          settings={settings}
          show={expand === "stack"}
          setExpand={setExpand}
          heading={t("cell-settingsStack")}
        />
        /*<OperationModeSettings
        settings={settings}
        show={expand === "operation"}
        setExpand={setExpand}
        heading={t("Operation mode")}
      /> 
       <VersionInformation
        settings={settings}
        show={expand === "version"}
        setExpand={setExpand}
        heading={t("cell-versionInformation")}
      /> */
      }
    </>
  );
};

export default EvaloSettings;
