import React, { useState, useRef } from "react";
import { Spacing, FormColumn, Row } from "../../../commonalities";
import MiddleHeader from "./MiddleHeader";
import MiddleContent from "./MiddleContent";
import { DateTime } from "luxon";

const MiddleColumn = ({ cellType, cellId, setLoading, setEventListener }) => {
  const [fromDate, setFromDate] = useState(
    DateTime.local().minus({ days: 31 })
  );
  const [toDate, setToDate] = useState(DateTime.local());
  const toDateRealTime = useRef(true);
  const [refresh, setRefresh] = useState(false);

  return (
    <Spacing widthvw="53.77" height="fit-content">
      <Row
        bgColor="backgroundGrey"
        shadow="0"
        height="fit-content"
        width="100%"
      >
        <FormColumn alignItems="stretch">
          <MiddleHeader
            cellType={cellType}
            cellId={cellId}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            toDateRealTime={toDateRealTime}
            refresh={refresh}
            setRefresh={setRefresh}
          />
          <Spacing mTopvw={"0.835"} />
          <MiddleContent
            setLoading={setLoading}
            cellId={cellId}
            cellType={cellType}
            setEventListener={setEventListener}
            fromDate={fromDate}
            toDate={toDate}
            toDateRealTime={toDateRealTime}
            setRefresh={setRefresh}
          />
        </FormColumn>
      </Row>
    </Spacing>
  );
};

export default MiddleColumn;
