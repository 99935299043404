import { createTheme } from "@material-ui/core";

export const appTheme = {
  heightLimit: "720px",
  widthLimit: "1350px",
  colors: {
    hrColor: "#C7C7CC",
    iconBgGrey: "#EFEFF4",
    grey: "#D1D1D6",
    white: "#fff",
    black: "#000",
    orange: "#FF9500",
    green: "#4CD964",
    red: "#FF3B30",
    textGrey: "#8E8E93",
    textDarkGrey: "#6d6d6f",
    textDarkerGrey: "#707074",
    backgroundGrey: "#F5F5F5",
    yellow: "#FFCC00",
  },
  font: {
    light: "SFProLight",
    regular: "SFProRegular",
    medium: "SFProMedium",
    semibold: "SFProSemiBold",
    bold: "SFProBold",
  },
  /*
    Adobe XD font mapping
    xxxl: "63"
    xxl: "48"
    xl: "31.5"
    lg: "22.5"
    md: "19.5"
    sm: "18"
    xs: "16"
  */
  fontSize: {
    xxxl: "40px",
    xxl: "30px",
    xl: "24px",
    lg: "18px",
    md: "16px",
    sm: "15px",
    xs: "14px",
    xxs: "13px",
    xxxs: "12px",
    xxxxs: "10px",
    xxxxxs: "9px",
  },
  borderRadius: {
    xxxs: "8px",
    xxs: "10px",
    xs: "12px",
    sm: "16px",
    md: "20px",
    lg: "24px",
  },
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: appTheme.colors.orange,
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        "&:before": {
          backgroundColor: "none",
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
        borderRadius: "0px !important",
        marginBottom: "1px",
        borderBottom: "1px solid #000",
        borderRight: "0px ",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: appTheme.colors.white,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: appTheme.colors.white,
      },
    },
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          fontFamily: "SFProBold",
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      },
    },
    MuiInputLabel: {
      asterisk: {
        color: appTheme.colors.orange,
      },
      root: {
        fontFamily: "SFProLight, Arial, Helvetica, sans-serif",
        fontSize: appTheme.fontSize.md,
        "&$focused": {
          color: appTheme.colors.textGrey,
        },
        "&.Mui-error": {
          color: appTheme.colors.textGrey,
        },
        "&.MuiInputLabel-shrink": {
          transform: "translate(0, 1.5px) scale(.83333)",
          transformOrigin: "top left",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: appTheme.borderRadius.xxxs,
      },
      input: {
        padding: "8px 10px",
      },
    },

    MuiInputBase: {
      input: {
        padding:
          "calc((var(--useMin) * var(--heightLimit) * 0.74 / 100) + (var(--usePercent) * 0.74vh)) 0px",
      },
      root: {
        fontFamily: "SFProLight, Arial, Helvetica, sans-serif",
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: "auto",
        fontFamily: "SFProLight, Arial, Helvetica, sans-serif",
        fontSize: appTheme.fontSize.xxs,
        "&.Mui-error": {
          marginTop:
            "calc((var(--useMin) * var(--heightLimit) * 0.74 / 100) + (var(--usePercent) * 0.74vh))",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before": {
          borderBottom: "1px solid #000",
        },
        "&:after": {
          borderBottom: "1px solid #000",
        },
        "&:before": {
          borderBottom: "1px solid #000",
        },
      },
      input: {
        font: appTheme.font.light,
        fontSize: appTheme.fontSize.md,
        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    },
  },
});
