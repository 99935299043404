var utility = null;

class Utility {
  isWorkDay = (date) => {
    var day = date.weekday; // Get day returns 6-7, respectively Sunday - Saturday
    if (day === 6 || day === 7) return false;
    return true;
  };

  getNumberOfWeekdays = (startDate, endDate) => {
    var daysBetween = 1;
    if (startDate.hasSame(endDate, "day")) {
      return daysBetween;
    }

    while (startDate.startOf("day") < endDate.startOf("day")) {
      if (this.isWorkDay(startDate)) {
        daysBetween += 1;
      }
      startDate = startDate.plus({ days: 1 });
    }

    if (!this.isWorkDay(endDate)) {
      daysBetween -= 1;
    }
    return daysBetween;
  };

  isJSON = (str) => {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  };
}

if (utility == null) {
  utility = new Utility();
}

export default utility;
