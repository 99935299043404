import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./commonalities/index.css";
import App from "./App";
import "mapbox-gl/dist/mapbox-gl.css";
import { NotSupportedBrowsers } from "./commonalities";
import { StoreProvider } from "./store";
export { default as Header } from "./Header";

const RenderNotSupportedBrowserPage = (heading, text) => {
  ReactDOM.render(
    <Suspense fallback={<div>Loading...</div>}>
      <NotSupportedBrowsers heading={heading} text={text} />
    </Suspense>,
    document.getElementById("root")
  );
};

const RenderAppWithStore = () => {
  ReactDOM.render(
    <Suspense fallback={<div>Loading...</div>}>
      <StoreProvider>
        <App />
      </StoreProvider>
    </Suspense>,
    document.getElementById("root")
  );
};

if (
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
  navigator.userAgent.match(/Windows Phone/i)
) {
  document.getElementById("InnerBody").style.minWidth = "0px";
  RenderAppWithStore();
} else if (navigator.userAgent.match("Trident")) {
  RenderNotSupportedBrowserPage(
    "routedPages-ieNotSupported",
    "routedPages-trySupportedBrowser"
  );
} else if (navigator.userAgent.match("OPR")) {
  RenderNotSupportedBrowserPage(
    "routedPages-operaNotSupported",
    "routedPages-trySupportedBrowser"
  );
} else if (navigator.userAgent.match("Edge")) {
  RenderNotSupportedBrowserPage(
    "routedPages-edgeNotSupported",
    "routedPages-updateEdge"
  );
} else if (!window.navigator.onLine) {
  RenderNotSupportedBrowserPage(
    "routedPages-noInternet",
    "routedPages-tryWithInternet"
  );
} else {
  RenderAppWithStore();
}
