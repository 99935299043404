import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const LogoutIcon = ({ width, color }) => {
  const WIDTH = width || "26";
  const COLOR = theme.colors[color] || theme.colors.black;

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      fill={COLOR}
      stroke={COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.303 25.69"
    >
      <g
        id="Group_1920"
        data-name="Group 1920"
        transform="translate(-32.293 -42.322)"
      >
        <path
          id="Path_1194"
          data-name="Path 1194"
          d="M48.825,60.079v3.69a2.716,2.716,0,0,1-2.645,2.782H35.688a2.716,2.716,0,0,1-2.645-2.782V45.144a2.716,2.716,0,0,1,2.645-2.782H46.18a2.716,2.716,0,0,1,2.645,2.782v3.69"
          transform="translate(0 0.71)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1195"
          data-name="Path 1195"
          d="M193.833,95.809l5.149,5.149-5.275,5.275"
          transform="translate(-141.136 -45.707)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_137"
          data-name="Line 137"
          x2="18.541"
          transform="translate(38.805 55.25)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
