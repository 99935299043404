import React from "react";
import { useState, useEffect } from "react";
import JwtDecode from "jwt-decode";
import * as Routes from "../../../routes/Routes";
import { useTranslation } from "react-i18next";
import { SessionManager } from "../../../utils";
import UserController from "../network/UserController";
import JWT from "jsonwebtoken";
import {
  Button,
  AppHeading,
  InputWithError,
  Spacing,
  A,
  Loader,
  FormHeading,
  EmptyDiv,
  GrayPageContainer,
  CenteredContent,
  FlexContainer,
  Span,
  HalfPageLevelImg,
  ShadowDiv,
} from "../..";

const LoginModule = ({ headerRedirection, setLoginSuccesful, image }) => {
  const [loading, setLoading] = useState(false);
  const [emailResent, setEmailResent] = useState(null);
  const { t } = useTranslation();
  const [values, setValues] = useState({
    emailID: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    emailID: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    setErrors({
      emailID: emailResent ? (
        <>
          {t("userNotVerified")}
          <Span
            setPointer={emailResent ? false : true}
            font={emailResent ? "light" : "bold"}
            onClick={() => {
              if (!emailResent) {
                resendEmail();
              }
            }}
            color={emailResent ? "green" : "orange"}
            margin="4px"
          >
            {emailResent ? emailResent : t("login-resendMail")}
          </Span>
        </>
      ) : (
        ""
      ),
      password: "",
    });
  }, [emailResent]);

  const resendEmail = () => {
    var userDetails = {
      email: values.emailID,
      resendMail: true,
      language: SessionManager.language,
      verificationLink: window.location.href.substr(
        0,
        window.location.href.length - 1
      ),
    };
    setLoading(true);
    UserController.user.registerUser(
      {
        userDetails: userDetails,
        redirect: window.location.href.replace(Routes.REGISTER, Routes.LOGIN),
      },
      (res) => {
        setLoading(false);
        if (res.data.status === 201) {
          setEmailResent(t("accountVerification-accountAlreadyVerified"));
        } else if (res.data.status === 202) {
          setTimeout(() => {
            setEmailResent(null);
          });
          setEmailResent(t("noUserExists"));
        } else {
          setEmailResent(t("emailResent"));
        }
      }
    );
  };

  const SubmitForm = () => {
    var hasError = false;
    var emailRegEx = new RegExp(/\S+@\S+\.\S+/);
    var emailError = "";
    var passwordError = "";

    if (!emailRegEx.test(values.emailID)) {
      hasError = true;
      emailError = t("invalidEmailAddress");
    }
    if (values.password === "") {
      hasError = true;
      passwordError = t("login-requiredField");
    }
    if (values.emailID === "") {
      hasError = true;
      emailError = t("login-requiredField");
    }
    setErrors({
      emailID: emailError,
      password: passwordError,
    });
    const loginDetails = {
      email: values.emailID,
      password: values.password,
    };
    if (hasError) {
      return;
    }

    setLoading(true);
    UserController.user.loginUser(loginDetails, (res) => {
      if (res.data.status === 202) {
        setLoading(false);
        setErrors({
          emailID: t("noUserExists"),
          password: "",
        });
        return;
      } else if (res.data.status === 201) {
        setLoading(false);
        setErrors({
          emailID: "",
          password: t("incorrectPassword"),
        });
        return;
      } else if (res.data.status === 203) {
        setLoading(false);
        setErrors({
          emailID: (
            <>
              {t("userNotVerified")}
              <Span
                setPointer={true}
                font={emailResent ? "light" : "bold"}
                onClick={resendEmail}
                color={emailResent ? "green" : "orange"}
                margin="4px"
              >
                {emailResent ? emailResent : t("resendEmail")}
              </Span>
            </>
          ),
          password: "",
        });
        return;
      } else if (res.data.status === 204) {
        setLoading(false);
        setErrors({
          emailID: t("login-userLocked"),
          password: "",
        });
        return;
      } else if (res.data.status === 205) {
        setLoading(false);
        setErrors({
          emailID: t("userApprovalPendingMessage"),
          password: "",
        });
        return;
      } else if (res.data.status === 200) {
        sessionStorage.setItem("token", res.data.token);
        var userData = JwtDecode(res.data.token);
        sessionStorage.setItem("UserData", JSON.stringify(userData.userData));
        SessionManager.token = res.data.token;
        SessionManager.userData = userData.userData;
        SessionManager.decodedToken = JWT.decode(res.data.token, {
          complete: true,
        });
        SessionManager.startSessionValidityCheck();
        SessionManager.email = userData.userData.email;
        setLoginSuccesful(true);
      }
    });
  };
  return (
    <>
      {loading && <Loader />}
      <GrayPageContainer>
        <AppHeading redirection={headerRedirection} />
        <CenteredContent textAlign={"left"}>
          <FlexContainer>
            <Spacing
              margin="auto"
              mRight="0"
              mLeftvw="3.33"
              setFlex={true}
              width="100%"
              pTopvw="5"
              pBottomvw="5"
            >
              <HalfPageLevelImg src={image} margin={"auto 1.67vw auto 0vw"} />
              <ShadowDiv
                width={"20.01%"}
                minWidth={"300px"}
                padding={"3.75%"}
                margin="auto 0px auto 0px"
              >
                <EmptyDiv>
                  <FormHeading>{t("buttons-login")}</FormHeading>
                  <Spacing mTopvh={"3.8"}>
                    <InputWithError
                      onChange={handleChange}
                      name="emailID"
                      value={values.emailID}
                      helperText={errors.emailID}
                      error={errors.emailID !== ""}
                      label={t("login-emailAddress")}
                      type={"email"}
                    />
                    <InputWithError
                      isLast={true}
                      onChange={handleChange}
                      value={values.password}
                      helperText={errors.password}
                      error={errors.password !== ""}
                      name="password"
                      label={t("password")}
                      type={"password"}
                    />
                  </Spacing>
                  <Spacing mTopvh={"2.69"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        SubmitForm();
                      }}
                      type={"submit"}
                    >
                      {t("buttons-login")}
                    </Button>
                  </Spacing>
                  <Spacing mTopvh={"4.07"}>
                    <A fontSize={"md"} to={Routes.FORGOT_PASSWORD}>
                      {t("login-forgotPassword")}
                    </A>
                  </Spacing>
                  <Spacing
                    setInline={true}
                    mTopvh={"1.48"}
                    fontSize={"md"}
                    color={"textGrey"}
                  >
                    {t("login-noAccount")}
                  </Spacing>
                  <Spacing mLeft={"4px"} setInline={true} mTopvh={"1.48"}>
                    <A
                      font={"bold"}
                      fontSize={"md"}
                      color={"orange"}
                      to={Routes.REGISTER}
                    >
                      {t("buttons-register")}
                    </A>
                  </Spacing>
                </EmptyDiv>
              </ShadowDiv>
            </Spacing>
          </FlexContainer>
        </CenteredContent>
      </GrayPageContainer>
    </>
  );
};

export default LoginModule;
