import React from "react";
import { appTheme as theme } from "../commonalities/Theme";

export const UnitsIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "72";
  const COLOR = theme.colors[color] || theme.colors.textDarkGrey;
  const STROKE_WIDTH = strokeWidth || "3";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
    >
      <g
        id="Group_2890"
        data-name="Group 2890"
        transform="translate(-788 -488)"
      >
        <g id="Layer_3" data-name="Layer 3" transform="translate(796 504)">
          <rect
            id="Rectangle_5008"
            data-name="Rectangle 5008"
            width="56"
            height="14"
            rx="5"
            transform="translate(0 25)"
            fill="none"
            stroke={COLOR}
            strokeMiterlimit="10"
            strokeWidth={STROKE_WIDTH}
          />
          <line
            id="Line_46"
            data-name="Line 46"
            x2="56"
            transform="translate(0 6.567)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth={STROKE_WIDTH}
          />
          <path
            id="Path_809"
            data-name="Path 809"
            d="M841.872,661.133l6.566-6.566L841.872,648"
            transform="translate(-792.439 -648)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <path
            id="Path_810"
            data-name="Path 810"
            d="M728.227,661.133l-6.567-6.566L728.227,648"
            transform="translate(-721.661 -648)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
        </g>
        <rect
          id="Rectangle_9607"
          data-name="Rectangle 9607"
          width="72"
          height="72"
          transform="translate(788 488)"
          fill="none"
        />
      </g>
    </svg>
  );
};
