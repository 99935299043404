import { SessionManager } from "../../../utils";

const getDays = () => {
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let chartDays = [];
  let nextDayIndex = (new Date().getDay() + 1) % 7;
  for (let i = 0; i < 7; i++) {
    chartDays.push(weekDays[nextDayIndex % 7]);
    nextDayIndex++;
  }
  return chartDays;
};

// Getting the cells data according to the selected filter and changing it in the format required by BrokenLineChart.js component
// The Format - 1 is used in this case
export const getData = (filter) => {
  const timeStamp24HrsAgo = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  ).getTime();

  const totalUsage = {};
  let listOfCells = [];

  Object.keys(SessionManager.jobHistory).forEach((CellID) => {
    listOfCells.push(CellID);
    totalUsage[CellID] = 0;
    Object.values(SessionManager.jobHistory[CellID]).forEach((jobs) => {
      totalUsage[CellID] += jobs;
    });
  });

  //Cells active in last 24hrs
  if (filter === "active") {
    listOfCells.sort((a, b) => {
      if (totalUsage[a] < totalUsage[b]) {
        return 1;
      } else {
        return -1;
      }
    });
    listOfCells = listOfCells.filter((cellId) => {
      let onlineStats = SessionManager.cells[cellId].Usage.OnlineStats;
      if (
        onlineStats &&
        onlineStats[6].lastSession.online > timeStamp24HrsAgo
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  //5 most active cells
  else if (filter === "mostUsed") {
    listOfCells.sort((a, b) => {
      if (totalUsage[a] < totalUsage[b]) {
        return 1;
      } else {
        return -1;
      }
    });
    listOfCells = listOfCells.splice(0, 5);
  }

  //5 least active cells
  else if (filter === "leastUsed") {
    listOfCells.sort((a, b) => {
      if (totalUsage[a] > totalUsage[b]) {
        return 1;
      } else {
        return -1;
      }
    });
    listOfCells = listOfCells.splice(0, 5);
  }

  const dataset = listOfCells.map((cellId) => {
    const color = `rgba(${SessionManager.cells[cellId].Color.r},${SessionManager.cells[cellId].Color.g},${SessionManager.cells[cellId].Color.b},${SessionManager.cells[cellId].Color.a})`;
    return {
      name: SessionManager.cells[cellId].Name,
      xcoords: getDays(),
      ycoords: Object.values(SessionManager.jobHistory[cellId]),
      style: {
        borderColor: color,
        backgroundColor: color,
        pointRadius: 2.5,
        pointHoverRadius: 4,
        borderWidth: 0.8,
      },
    };
  });

  if (dataset.length === 0) {
    return [
      {
        xcoords: getDays(),
        ycoords: [null, null, null, null, null, null, null],
      },
    ];
  }

  return dataset;
};
