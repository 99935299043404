import styled from "styled-components";
import { Link } from "react-router-dom";

export const A = styled(Link)`
  text-decoration: none;
  color: ${(p) =>
    p.color ? p.theme.colors[p.color] : p.theme.colors.textGrey};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.sm};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.light)};
  ${(p) => p.height && `height: ${p.height}`};
  ${(p) => p.padding && `padding: ${p.padding}`};
  ${(p) =>
    p.setCenteredFlex &&
    `display: flex;
  align-items: center;`};
`;

export const ButtonLink = styled(Link)`
  margin-left: auto;
  margin-right: auto;
  border: 0;
  border: none;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  text-align: center;
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.lg};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.regular)};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.white)};
  border: none;
  border-radius: ${(p) => p.theme.borderRadius.sm};
  padding: ${(p) => (p.padding ? p.padding : "12px 40px 12px 40px")};
  background: ${(p) =>
    p.bgColor ? p.theme.colors[p.bgColor] : p.theme.colors.orange};
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) => p.margin && `margin:${p.margin}`};
`;
