import styled from "styled-components";

export const HR = styled.hr`
  background-color: ${(p) =>
    p.color ? p.theme.colors[p.color] : p.theme.colors.black};
  height: ${(p) => (p.height ? p.height : "3px")};
  border: 0;
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "0px")};
  width: ${(p) => (p.width ? p.width : "100%")};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) =>
    p.vMargin &&
    `margin: calc(
      (var(--useMin) * var(--heightLimit) * ${p.vMargin} / 100) +
        (var(--usePercent) * ${p.vMargin}vh)
    )
    0px;`}
`;

export const Spacing = styled.div`
  ${(p) => p.textAlign && `text-align: ${p.textAlign}`};
  ${(p) => p.alignSelf && `align-self: ${p.alignSelf}`};
  ${(p) => p.margin && `margin:${p.margin}`};
  ${(p) => p.mTop && `margin-top: ${p.mTop}`};
  ${(p) => p.mBottom && `margin-bottom: ${p.mBottom}`};
  ${(p) => p.mLeft && `margin-left: ${p.mLeft}`};
  ${(p) => p.mRight && `margin-right: ${p.mRight}`};
  ${(p) =>
    p.mTopvw &&
    `margin-top: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mTopvw} / 100) + (var(--usePercentWidth) * ${p.mTopvw}vw))`};
  ${(p) =>
    p.mBottomvw &&
    `margin-bottom: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mBottomvw} / 100) + (var(--usePercentWidth) * ${p.mBottomvw}vw))`};
  ${(p) =>
    p.mLeftvw &&
    `margin-left: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mLeftvw} / 100) + (var(--usePercentWidth) * ${p.mLeftvw}vw))`};
  ${(p) =>
    p.mRightvw &&
    `margin-right: calc((var(--useMinWidth) * var(--widthLimit) * ${p.mRightvw} / 100) + (var(--usePercentWidth) * ${p.mRightvw}vw))`};
  ${(p) =>
    p.mTopvh &&
    `margin-top: calc((var(--useMin) * var(--heightLimit) * ${p.mTopvh} / 100) + (var(--usePercent) * ${p.mTopvh}vh))`};
  ${(p) =>
    p.mBottomvh &&
    `margin-bottom: calc((var(--useMin) * var(--heightLimit) * ${p.mBottomvh} / 100) + (var(--usePercent) * ${p.mBottomvh}vh))`};
  ${(p) =>
    p.mLeftvh &&
    `margin-left: calc((var(--useMin) * var(--heightLimit) * ${p.mLeftvh} / 100) + (var(--usePercent) * ${p.mLeftvh}vh))`};
  ${(p) =>
    p.mRightvh &&
    `margin-right: calc((var(--useMin) * var(--heightLimit) * ${p.mRightvh} / 100) + (var(--usePercent) * ${p.mRightvh}vh))`};
  ${(p) => p.setInline && `display:inline-block`};
  ${(p) => p.setInlineFlex && `display: inline-flex;`};
  ${(p) => p.setFlex && `display: flex;`};
  ${(p) => p.alignItems && `align-items: ${p.alignItems}`};
  ${(p) => p.setFitContent && `width:fit-content`};
  ${(p) => p.setFitContentHeight && `height:fit-content`};
  ${(p) => p.width && `width:${p.width}`};
  ${(p) => p.height && `height:${p.height}`};
  ${(p) => p.minHeight && `min-height:${p.minHeight}`};
  ${(p) => p.minWidth && `min-width:${p.minWidth}`};
  ${(p) => p.maxWidth && `max-width:${p.maxWidth}`};
  ${(p) => p.font && `font-family:${p.theme.font[p.font]}`};
  ${(p) => p.fontSize && `font-size:${p.theme.fontSize[p.fontSize]}`};
  ${(p) => p.color && `color:${p.theme.colors[p.color]}`};
  ${(p) =>
    p.wrapText &&
    ` overflow: hidden;
    text-overflow: ellipsis;`};
  ${(p) => p.setPointer && `cursor: pointer;`};
  ${(p) =>
    p.borderRadius &&
    `border-radius: ${p.theme.borderRadius[p.borderRadius]};`};
  ${(p) => p.hideYOverflow && `overflow-y:hidden`};
  ${(p) => p.padding && `padding:${p.padding}`};
  ${(p) =>
    p.paddingvw &&
    `padding: calc((var(--useMinWidth) * var(--widthLimit) * ${p.paddingvw} / 100) + (var(--usePercentWidth) * ${p.paddingvw}vw));`};
  ${(p) =>
    p.paddingvh &&
    `padding:calc((var(--useMin) * 720px * ${p.paddingvh} / 100) + (var(--usePercent) * ${p.paddingvh}vh))`};
  ${(p) =>
    p.pTopvw &&
    `padding-top: calc((var(--useMinWidth) * var(--widthLimit) * ${p.pTopvw} / 100) + (var(--usePercentWidth) * ${p.pTopvw}vw));`};
  ${(p) =>
    p.pBottomvw &&
    `padding-bottom: calc((var(--useMinWidth) * var(--widthLimit) * ${p.pBottomvw} / 100) + (var(--usePercentWidth) * ${p.pBottomvw}vw));`};
  ${(p) =>
    p.pLeftvw &&
    `padding-left: calc((var(--useMinWidth) * var(--widthLimit) * ${p.pLeftvw} / 100) + (var(--usePercentWidth) * ${p.pLeftvw}vw));`};
  ${(p) =>
    p.pRightvw &&
    `padding-right: calc((var(--useMinWidth) * var(--widthLimit) * ${p.pRightvw} / 100) + (var(--usePercentWidth) * ${p.pRightvw}vw));`};
  ${(p) =>
    p.pBottomvh &&
    `padding-bottom: calc((var(--useMin) * var(--heightLimit) * ${p.pBottomvh} / 100) + (var(--usePercent) * ${p.pBottomvh}vh))`};
  ${(p) =>
    p.widthvw &&
    `width: calc(
      (var(--useMinWidth) * var(--widthLimit) * ${p.widthvw} / 100) +
        (var(--usePercentWidth) * ${p.widthvw}vw)
    )`};
  ${(p) =>
    p.heightvh &&
    `height:calc((var(--useMin) * var(--heightLimit) * ${p.heightvh} / 100) + (var(--usePercent) * ${p.heightvh}vh))`};
  ${(p) =>
    p.minWidthvw &&
    `min-width:calc(
        (var(--useMinWidth) * var(--widthLimit) * ${p.minWidthvw} / 100) +
          (var(--usePercentWidth) * ${p.minWidthvw}vw)
      )`};
`;

export const Footer = styled.div`
  position: absolute;
  width: ${(p) => (p.width ? p.width : "inherit")};
  ${(p) => p.height && `height:${p.height}`};
  bottom: ${(p) => (p.bottom ? p.bottom : "0")};
  ${(p) => p.left && `left: ${p.left};`}
`;

export const Span = styled.span`
  ${(p) => p.setPointer && `cursor:pointer`};
  ${(p) => p.margin && `margin:${p.margin}`};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.black)};
  ${(p) => p.fontSize && `font-size: ${(p) => p.theme.fontSize[p.fontSize]}`};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.regular)};
`;

export const Dot = styled.div`
  padding: ${(p) => (p.padding ? p.padding : "3px")};
  margin-left: ${(p) => (p.mLeft ? p.mLeft : "10px")};
  ${(p) => p.setPointer && `cursor: pointer`};
  border-radius: 100%;
  background: ${(p) => p.theme.colors.orange};
  ${(p) => p.position && `position:${p.position}`};
  ${(p) => p.zIndex && `z-index:${p.zIndex}`};
  ${(p) => p.top && `top:${p.top}`};
  ${(p) => p.right && `right:${p.right}`};
`;

export const Menu = styled.div`
  height: ${(p) => (p.height ? p.height : "270px")};
  z-index: 1;
  width: ${(p) => (p.width ? p.width : "200px")};
  position: absolute;
  margin-left: ${(p) => (p.mLeft ? p.mLeft : "auto")};
  margin-right: 0px;
  ${(p) =>
    p.top &&
    `top:calc((var(--useMin) * 720px * ${p.top} / 100) + (var(--usePercent) * ${p.top}vh))`};

  ${(p) => p.right && `right:${p.right}`};
`;

export const IconHover = styled(Spacing)`
  z-index: ${(p) => (p.zIndex ? p.zIndex : "1")};
  vertical-align: ${(p) => (p.vAlign ? p.vAlign : "middle")};
  &:hover {
    svg {
      stroke: ${(p) =>
        p.hoverColor ? p.theme.colors[p.hoverColor] : p.theme.colors.orange};
    }
  }
`;
