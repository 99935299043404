import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {
  PlusIcon,
  MinusIcon,
  Spacing,
  FormColumn,
  FormRow,
  Text,
} from "../commonalities";

const useStyles = makeStyles({
  accordionRoot: {
    width: "95%",
    padding: "0px",
    "&.Mui-expanded": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  accordionSummaryRoot: {
    padding: "0px",
    minHeight:
      "calc((var(--useMin) * var(--heightLimit) * 5.18 / 100) + (var(--usePercent) * 5.18vh))",
    height:
      "calc((var(--useMin) * var(--heightLimit) * 5.18 / 100) + (var(--usePercent) * 5.18vh))",
    "&.Mui-expanded": {
      minHeight:
        "calc((var(--useMin) * var(--heightLimit) * 5.18 / 100) + (var(--usePercent) * 5.18vh))",
      height:
        "calc((var(--useMin) * var(--heightLimit) * 5.18 / 100) + (var(--usePercent) * 5.18vh))",
      marginBottom: "0px",
    },
  },
  accordionDetailsRoot: {
    padding: "0px",
    paddingBottom: "1.76",
  },
});

const AccordionRow = ({ name, value }) => {
  return (
    <FormRow mTopvh="1.02">
      <Text
        float="none"
        fontSize="xs"
        height="fit-content"
        lineHeight="1.2"
        color={"textGrey"}
        width={"45%"}
      >
        {name}
      </Text>
      <Text
        float="none"
        fontSize="xs"
        height="fit-content"
        lineHeight="1.2"
        textAlign={"right"}
        width={"55%"}
      >
        {value}
      </Text>
    </FormRow>
  );
};

const AccordionHeader = ({ value }) => {
  return (
    <FormRow mTopvh="1.02">
      <Text
        float="none"
        height="fit-content"
        lineHeight="1.2"
        fontSize={"xxxs"}
        width={"50%"}
      >
        {value}
      </Text>
      <Text
        float="none"
        fontSize="xs"
        height="fit-content"
        lineHeight="1.2"
        textAlign={"right"}
        width={"45%"}
      ></Text>
    </FormRow>
  );
};

const CalibrationAccordion = ({
  id,
  title,
  calib,
  handleChange,
  expanded,
  isLast,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Accordion
      style={isLast ? { borderBottom: "0px" } : null}
      onClick={(e) => e.stopPropagation()}
      className={classes.accordionRoot}
      expanded={expanded === id}
      onChange={handleChange(id)}
    >
      <AccordionSummary
        className={classes.accordionSummaryRoot}
        expandIcon={
          expanded === id ? (
            <MinusIcon />
          ) : (
            <PlusIcon width="14px" strokeWidth="1.2" />
          )
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <FormRow mTopvh="1.02">
          <Text
            float="none"
            fontSize="xs"
            height="fit-content"
            lineHeight="1.2"
            font={"regular"}
            width={"95%"}
          >
            {title}
          </Text>
        </FormRow>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsRoot}>
        <FormColumn alignItems="unset">
          {calib &&
            calib.transform &&
            calib.transform.map((tf, i) => {
              return (
                <div key={i}>
                  <Text
                    float="none"
                    mTopvh="1.02"
                    fontSize={"xxs"}
                    font={"semibold"}
                    height="fit-content"
                  >
                    {calib.transform.length > 1 && tf.name}
                  </Text>
                  <AccordionHeader
                    value={t("cell-settingsToolPosition").toUpperCase()}
                  ></AccordionHeader>
                  <AccordionRow
                    name={"X"}
                    value={Math.round(tf.value.position.x * 1e6) / 1e6 + " mm"}
                  ></AccordionRow>
                  <AccordionRow
                    name={"Y"}
                    value={Math.round(tf.value.position.y * 1e6) / 1e6 + " mm"}
                  ></AccordionRow>
                  <AccordionRow
                    name={"Z"}
                    value={Math.round(tf.value.position.z * 1e6) / 1e6 + " mm"}
                  ></AccordionRow>
                  <AccordionHeader
                    value={t("cell-settingsToolOrientation").toUpperCase()}
                  ></AccordionHeader>
                  <AccordionRow
                    name={"X"}
                    value={
                      Math.round(tf.value.orientation.x * 1e6) / 1e6 + " mm"
                    }
                  ></AccordionRow>
                  <AccordionRow
                    name={"Y"}
                    value={
                      Math.round(tf.value.orientation.y * 1e6) / 1e6 + " mm"
                    }
                  ></AccordionRow>
                  <AccordionRow
                    name={"Z"}
                    value={
                      Math.round(tf.value.orientation.z * 1e6) / 1e6 + " mm"
                    }
                  ></AccordionRow>
                  <AccordionRow
                    name={"W"}
                    value={
                      Math.round(tf.value.orientation.w * 1e6) / 1e6 + " mm"
                    }
                  ></AccordionRow>
                </div>
              );
            })}
          {calib && calib.size && (
            <>
              <AccordionHeader
                value={t("cell-settingsSize").toUpperCase()}
              ></AccordionHeader>
              <AccordionRow
                name={t("cell-length")}
                value={calib.size[0] + " mm"}
              ></AccordionRow>
              <AccordionRow
                name={t("cell-width")}
                value={calib.size[1] + " mm"}
              ></AccordionRow>
              <AccordionRow
                name={t("cell-height")}
                value={calib.size[2] + " mm"}
              ></AccordionRow>
            </>
          )}
          {calib && calib.calibSettings && (
            <AccordionHeader
              value={t("cell-settings").toUpperCase()}
            ></AccordionHeader>
          )}
          {calib &&
            calib.calibSettings &&
            Object.keys(calib.calibSettings).map((element, i) => (
              <AccordionRow
                key={i}
                name={t(element)}
                value={calib.calibSettings[element]}
              ></AccordionRow>
            ))}
          {calib && calib.calibMeasurements && (
            <AccordionHeader
              value={t("cell-measurements").toUpperCase()}
            ></AccordionHeader>
          )}
          {calib &&
            calib.calibMeasurements &&
            Object.keys(calib.calibMeasurements).map((element, i) => (
              <AccordionRow
                key={i}
                name={element}
                value={calib.calibMeasurements[element]}
              ></AccordionRow>
            ))}
          <Spacing mBottomvh={"1.02"} />
        </FormColumn>
      </AccordionDetails>
    </Accordion>
  );
};

export default CalibrationAccordion;
