import React from "react";
import { appTheme as theme } from "../Theme";

/**
 * Search icon
 *
 * @param {string} width
 * @param {string} color
 */
export const TickIcon = ({ width, height, color }) => {
  const WIDTH = width || "19.018";
  const HEIGHT = height || "14.658";
  const COLOR = theme.colors[color] || theme.colors.black;

  return (
    <svg
      width={WIDTH}
      height={HEIGHT}
      fill={COLOR}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.018 14.658"
    >
      <path
        id="checkmark"
        d="M16.875,0,6.2,10.407,2.112,6.493,0,8.648l6.14,6.011h.026L19.018,2.111Z"
        fill={color}
      />
    </svg>
  );
};
