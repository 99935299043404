import React from "react";
import { appTheme as theme } from "../commonalities/Theme";

export const FixtureIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "72";
  const COLOR = theme.colors[color] || theme.colors.textDarkGrey;
  const STROKE_WIDTH = strokeWidth || "3";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
    >
      <g
        id="Group_2892"
        data-name="Group 2892"
        transform="translate(-784 -744)"
      >
        <g id="Layer_3" data-name="Layer 3" transform="translate(-3.372 -204)">
          <path
            id="Path_807"
            data-name="Path 807"
            d="M777.872,918.161l-.082,36.949a2.058,2.058,0,0,1-2.059,2.059h-9.059a2.059,2.059,0,0,1-2.059-2.059l.082-23.772a2.059,2.059,0,0,0-2.059-2.059H723.931a2.059,2.059,0,0,1-2.059-2.059v-9.059a2.059,2.059,0,0,1,2.059-2.059h51.882A2.059,2.059,0,0,1,777.872,918.161Z"
            transform="translate(73.5 47.898)"
            fill="none"
            stroke={COLOR}
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth={STROKE_WIDTH}
          />
        </g>
        <rect
          id="Rectangle_9609"
          data-name="Rectangle 9609"
          width="72"
          height="72"
          transform="translate(784 744)"
          fill="none"
        />
      </g>
    </svg>
  );
};
