import React from "react";
import { SettingsAccordion } from "../../../../../styles";
import { RubberMatIcon } from "../../../../../icons";

const RepetitionSettings = ({ settings, show, heading, setExpand }) => {
  const data = {
    "cell-settingsTop": settings ? settings.Top_Reps : "-",
    "cell-settingsSide": settings ? settings.Side_Reps : "-",
  };

  return (
    <SettingsAccordion
      expand={"repetitions"}
      iconImage={<RubberMatIcon width="100%" height="100%" />}
      show={show}
      heading={heading}
      setExpand={setExpand}
      children={data}
    />
  );
};

export default RepetitionSettings;
