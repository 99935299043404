import React from "react";
import { appTheme as theme } from "../Theme";

export const InfoIcon = ({ width, height, color, fillColor, strokeWidth }) => {
  const WIDTH = width || "32";
  const HEIGHT = height || "34";
  const COLOR = theme.colors[color] || theme.colors.white;
  const FILL_COLOR = theme.colors[fillColor] || theme.colors.textGrey;
  const STROKE_WIDTH = strokeWidth || "1";

  return (
    <svg
      width={WIDTH}
      height={HEIGHT}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 34"
    >
      <g
        id="Group_3021"
        data-name="Group 3021"
        transform="translate(-816 -632)"
      >
        <circle
          id="Ellipse_124"
          data-name="Ellipse 124"
          cx="12"
          cy="12"
          r="12"
          transform="translate(844 656) rotate(180)"
          fill={FILL_COLOR}
        />
        <g
          id="Group_3017"
          data-name="Group 3017"
          transform="translate(1660.5 1320) rotate(180)"
        >
          <g
            id="Rectangle_9274"
            data-name="Rectangle 9274"
            transform="translate(826.5 668)"
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={STROKE_WIDTH}
          >
            <rect width="3" height="11" rx="1.5" stroke="none" />
            <rect x="0.5" y="0.5" width="2" height="10" rx="1" fill="none" />
          </g>
          <g
            id="Rectangle_9275"
            data-name="Rectangle 9275"
            transform="translate(826.5 681)"
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={STROKE_WIDTH}
          >
            <rect width="3" height="3" rx="1.5" stroke="none" />
            <rect x="0.5" y="0.5" width="2" height="2" rx="1" fill="none" />
          </g>
        </g>
        <rect
          id="Rectangle_9700"
          data-name="Rectangle 9700"
          width="32"
          height="24"
          transform="translate(816 632)"
          fill="none"
        />
      </g>
    </svg>
  );
};
