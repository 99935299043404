import React from "react";
import { useTranslation } from "react-i18next";
import {
  Spacing,
  Button,
  PopUpHeading,
  PopUpText,
  PopUpTemplate,
} from "../../..";
import { SessionManager } from "../../../../utils";
import * as Routes from "../../../../routes/Routes";

const SessionExpiredPopUp = () => {
  const { t } = useTranslation();

  const handleRedirect = () => {
    sessionStorage.clear();
    if (SessionManager.sessionChecker) {
      clearInterval(SessionManager.sessionChecker);
    }
    window.open(Routes.LOGIN, "_self");
  };

  return (
    <PopUpTemplate setShowPopUp={() => {}}>
      <Spacing paddingvh={"6.67"} pRightvw={"6.25"} pLeftvw={"6.25"}>
        <PopUpHeading>{t("session-expired")}</PopUpHeading>
        <Spacing mTopvh={"3.7"} />
        <PopUpText color={"textGrey"}>{t("session-expiredMsg1")}</PopUpText>
        <Spacing
          mTopvh={"1.11"}
          textAlign="center"
          mLeft={"auto"}
          mRight="auto"
        />
        <PopUpText color={"textGrey"}>{t("session-expiredMsg2")}</PopUpText>
        <Spacing
          width="max-content"
          mTopvh={"2.96"}
          mLeft={"auto"}
          mRight={"auto"}
        >
          <Button onClick={handleRedirect} fontSize={"md"}>
            {t("buttons-login")}
          </Button>
        </Spacing>
      </Spacing>
    </PopUpTemplate>
  );
};

export default SessionExpiredPopUp;
