import React from "react";
import { appTheme as theme } from "../Theme";

export const VersionIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "72";
  const COLOR = theme.colors[color] || theme.colors.textDarkGrey;
  const STROKE_WIDTH = strokeWidth || "3";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
    >
      <g
        id="Group_2904"
        data-name="Group 2904"
        transform="translate(-1696 -496)"
      >
        <rect
          id="Rectangle_9622"
          data-name="Rectangle 9622"
          width="72"
          height="72"
          transform="translate(1696 496)"
          fill="none"
        />
        <g
          id="Group_2903"
          data-name="Group 2903"
          transform="translate(2080.552 807.279)"
        >
          <path
            id="Path_2074"
            data-name="Path 2074"
            d="M-333.9-298.993a28.018,28.018,0,0,1,13.027,23.713,27.919,27.919,0,0,1-27.839,28,27.92,27.92,0,0,1-27.839-28,27.919,27.919,0,0,1,27.839-28h0"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeWidth={STROKE_WIDTH}
          />
          <path
            id="Path_1041"
            data-name="Path 1041"
            d="M-334.175-285.779v-13.266h13.265"
            transform="translate(0.358)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <path
            id="Path_1042"
            data-name="Path 1042"
            d="M-348.569-290.525v18.063l-10.151,7.923"
            transform="translate(0.14 0.296)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
        </g>
      </g>
    </svg>
  );
};
