import React from "react";
import { appTheme as theme } from "../Theme";
/**
 * @param {string} width
 * @param {string} height
 * @param {string} color
 */
export const SolidArrowIcon = ({ width, height, color, type }) => {
  const WIDTH = width || "10";
  const HEIGHT = height || "5";
  const COLOR = theme.colors[color] || theme.colors.textDarkerGrey;
  const TYPE = type || "up";

  const transformations = {};
  transformations["up"] = "scale(1 1)";
  transformations["down"] = "scale(1 -1)";
  transformations["right"] = "rotate(90)";
  transformations["left"] = "rotate(-90)";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={WIDTH}
      height={HEIGHT}
      viewBox="0 0 10 5"
      transform={transformations[TYPE]}
    >
      <g
        id="Polygon_4"
        data-name="Polygon 4"
        fill={COLOR}
      >
        <path
          d="M 8.189339637756348 4.499998569488525 L 1.810660004615784 4.499998569488525 C 1.659890055656433 4.499998569488525 1.598909974098206 4.392078399658203 1.579689979553223 4.345678329467773 C 1.56046998500824 4.299268245697021 1.527269959449768 4.17982816696167 1.63389003276825 4.07321834564209 L 4.823219776153564 0.8838784098625183 C 4.886929988861084 0.8201783895492554 4.961329936981201 0.8106583952903748 5 0.8106583952903748 C 5.038670063018799 0.8106583952903748 5.113070011138916 0.8201783895492554 5.176780223846436 0.8838784098625183 L 8.366120338439941 4.07321834564209 C 8.472729682922363 4.17982816696167 8.439530372619629 4.299268245697021 8.420310020446777 4.345678329467773 C 8.401089668273926 4.392078399658203 8.340109825134277 4.499998569488525 8.189339637756348 4.499998569488525 Z"
          stroke="none"
        />
        <path
          d="M 5 1.414218425750732 L 2.414219856262207 3.999998331069946 L 7.585780143737793 3.999998331069946 L 5 1.414218425750732 M 5 0.3106608390808105 C 5.191942691802979 0.3106608390808105 5.383884906768799 0.3838834762573242 5.530330181121826 0.5303282737731934 L 8.719670295715332 3.719668388366699 C 9.192139625549316 4.192138671875 8.85752010345459 4.999998569488525 8.189339637756348 4.999998569488525 L 1.810660362243652 4.999998569488525 C 1.14247989654541 4.999998569488525 0.8078603744506836 4.192138671875 1.280329704284668 3.719668388366699 L 4.469669818878174 0.5303282737731934 C 4.616115093231201 0.3838834762573242 4.808057308197021 0.3106608390808105 5 0.3106608390808105 Z"
          stroke="none"
          fill={COLOR}
        />
      </g>
    </svg>
  );
};
