import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { H5, Rotate } from "..";

const LoadingBG = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  z-index: 4;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const LoaderDiv = styled.div`
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export function Loader() {
  return (
    <LoadingBG>
      <LoaderDiv>
        <CircularProgress />
      </LoaderDiv>
    </LoadingBG>
  );
}

export const LoadingContainer = styled.div`
  position: absolute;
  visibility: ${(p) => (p.visibility ? p.visibility : "visible")};
  left: ${(p) => (p.left ? p.left : "0px")};
`;

const LoadingDiv = styled.div`
  width: 200px;
  height: 25px;
  background-color: #3f3f3f;
  border-radius: 10px;
`;

const ProgressBar = styled.div`
  width: ${(p) => (p.width ? p.width : "50px")};
  height: 25px;
  background-color: #8e8e8e;
  border-radius: 10px;
`;

const LoadingInfo = styled.p`
  color: #fff;
  width: 100%;
  margin-left: 60px;
  margin-top: -21px;
  font-size: 12px;
  font-family: ${(p) => p.theme.font.regular};
  text-shadow: 0px 0px 5px #000;
`;

export function LoadingBar({ width, text, leftPadding, visibility }) {
  return (
    <LoadingContainer left={leftPadding} visibility={visibility}>
      <LoadingDiv>
        <ProgressBar width={width}></ProgressBar>
        <LoadingInfo>{text}</LoadingInfo>
      </LoadingDiv>
    </LoadingContainer>
  );
}

export const UpdateSpinner = styled.div`
  animation: 2s linear infinite ${Rotate};
  border: solid 3px #eee;
  border-bottom-color: ${(p) => p.theme.colors.orange};
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  top: 50%;
  margin-top: 40px;
  transform: translate3d(-50%, -50%, 0);
  transform-origin: center;
  will-change: transform;
  z-index: 9;
`;

const SpinnerText = styled.div`
  z-index: 9;
  display: none;
  width: 100%;
  position: absolute;
  top: 45%;
`;

export function TextSpinner({ id, text }) {
  return (
    <SpinnerText id={id}>
      <H5 textAlign={"center"}>{text}</H5>
      <UpdateSpinner />
    </SpinnerText>
  );
}
