import React, { useState } from "react";
import { LogsPopUp } from "../..";
import {
  TextButton,
  Spacing,
  ShadowDiv,
  Row,
  FormColumn,
  RightAlignedContainer,
  InlineText,
} from "../../../commonalities";
import { useTranslation } from "react-i18next";
import CNMHardwareStatus from "./CNMHardwareStatus";
import EvaloHardwareStatus from "./EvaloHardwareStatus";
import CarpenHardwareStatus from "./CarpenHardwareStatus";

const RightColumn = ({ cellType, hardwareStatus, isOnline, cellId }) => {
  const { t } = useTranslation();
  const [showPopUp, setShowPopUp] = useState(false);

  function HardwareStatus(props) {
    const cellType = props.cellType;
    if(cellType === "CNM"){
      return <CNMHardwareStatus hardwareStatus={hardwareStatus} />;
    }
    if(cellType === "Evalo"){
      return <EvaloHardwareStatus hardwareStatus={hardwareStatus} />;
    }
    return <CarpenHardwareStatus hardwareStatus={hardwareStatus} />;
  }

  return (
    <>
      <Spacing widthvw="22.09" height="fit-content">
        <Row
          shadow="0"
          width="100%"
          height="fit-content"
          bgColor="backgroundgrey"
        >
          <FormColumn alignItems="stretch" padding="0px">
            <ShadowDiv width="100%" borderRadius="sm">
              <Spacing
                setFlex={true}
                alignItems="center"
                width="100%"
                paddingvw="1.04"
                pLeftvw="1.67"
              >
                <InlineText
                  color="black"
                  fontSize={"lg"}
                  alignSelf={"center"}
                  font="medium"
                >
                  {t("realtimeLogs")}
                </InlineText>
                <RightAlignedContainer>
                  <TextButton
                    onClick={() => setShowPopUp(isOnline)}
                    fontSize={"sm"}
                    bgColor={isOnline ? "green" : "grey"}
                    textColor={isOnline ? "white" : "textGrey"}
                    borderRadius={"xxs"}
                    padding="8px 24px"
                    font="medium"
                    setPointer={isOnline ? "true" : "false"}
                  >
                    {t("fetch")}
                  </TextButton>
                </RightAlignedContainer>
              </Spacing>
            </ShadowDiv>
            <Spacing mTopvw={"1.67"} />
            <ShadowDiv width="100%" borderRadius="sm">
              <FormColumn alignItems="stretch" marginvw="1.67">
                <InlineText
                  color="black"
                  font={"medium"}
                  fontSize={"lg"}
                  alignSelf={"left"}
                >
                  {t("hardwareStatus")}
                </InlineText>
                <Spacing mBottomvh={"1.27"} />
                <HardwareStatus cellType />
              </FormColumn>
            </ShadowDiv>
          </FormColumn>
        </Row>
      </Spacing>

      {showPopUp && <LogsPopUp cellId={cellId} setShowPopUp={setShowPopUp} />}
    </>
  );
};

export default RightColumn;
