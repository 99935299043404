import React from "react";
import styled from "styled-components";
import {
  Button,
  Spacing,
  AppHeading,
  CenteredContent,
  Text,
  GrayPageContainer,
  FlexContainer,
} from "../../../commonalities";
import * as Routes from "../../../routes/Routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Img = styled.img`
  height: calc(
    (var(--useMin) * var(--heightLimit) * 0.5) + (var(--usePercent) * 50vh)
  );
  object-fit: contain;
`;

const PageError = ({ image }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = window.location.pathname.split("/");
  return (
    <GrayPageContainer>
      <AppHeading redirection={Routes.LOGIN} />
      <CenteredContent>
        <FlexContainer>
          <Spacing margin="auto">
            <Spacing mTopvh="6" />
            <Img src={image} />
            <Spacing mTop={"14px"} />
            <Text
              float="none"
              fontSize={"xl"}
              textAlign={"center"}
              font={"semibold"}
            >
              {t(
                pathname[2] === Routes.PAGE_ERROR.slice(1)
                  ? "404-pageNotFound"
                  : "403-pageNotFound"
              )}
            </Text>
            <Spacing mTop={"16px"} />
            <Text
              float="none"
              color={"textGrey"}
              fontSize={"md"}
              textAlign={"center"}
            >
              {t(
                pathname[2] === Routes.PAGE_ERROR.slice(1)
                  ? "404-message"
                  : "403-message"
              )}
            </Text>
            <Spacing mTop={"32px"} />
            <Button onClick={(e) => history.push("/")} type={"submit"}>
              {t("404-backToHomePage")}
            </Button>

            <Spacing mBottomvh="6" />
          </Spacing>
        </FlexContainer>
      </CenteredContent>
    </GrayPageContainer>
  );
};

export default PageError;
