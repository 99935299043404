import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { FormRow, Spacing, InlineText, DropDown, Text, ChartTooltip } from "..";
import _ from "lodash";
import { appTheme } from "../Theme";

const getTooltip = (context, chartStyle) => {
  // Array of objects where each object represents a point and holds name, xcoords and ycoords of the same.
  let tooltipData = [];
  if (context.tooltip.body) {
    tooltipData = context.tooltip.body.map((bodyItem, i) => {
      // The bodyItem holds a property 'linear' which is an array of strings. The string contains '{dataName}: {nonIndexAxisCoordinate}'
      const body = bodyItem.lines.toString().replace(" ", "").split(":");

      // If the data name is not provided
      if (body.length === 1) body.unshift("");

      let name = body[0];
      const [xcoord, ycoord] =
        chartStyle.indexAxis === "x"
          ? [context.tooltip.title[i], body[1]]
          : [body[1], context.tooltip.title[i]];

      return {
        name,
        xcoord,
        ycoord,
      };
    });
  }

  const customTooltip = {
    // This string will be displayed on the tooltip after replacing the placeholders with appropriate values
    displayTemplate: "{{xcoord}} {{ycoord}}",
    data: tooltipData,
    // Specify which variables in the displayTemplate should be colored by including them in this object and setting their values to true.
    colors: {
      ycoord: true,
    },
    styles: {
      bannerStyle: {},
      pointerStyle: {},
    },
  };

  // If a custom tooltip function is provided, it is called with the context and customTooltip as the arguments
  if (chartStyle?.customChartStyle?.plugins?.tooltip?.external) {
    chartStyle.customChartStyle.plugins.tooltip.external(
      context,
      customTooltip
    );
  } else {
    ChartTooltip(context, customTooltip);
  }
};

const getChartStyleOptions = (chartStyle) => {
  const defaultChartStyle = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          size: appTheme.fontSize.xxs,
          font: appTheme.font.light,
        },
      },
      title: {
        font: {
          size: appTheme.fontSize.xxs,
          family: appTheme.font.light,
        },
      },

      // When hovered over a point, there are three information that can be displayed
      // 1. name (name of the bar)
      // 2. xcoord (the x coordinate)
      // 3. ycoord (the y coordinate)

      tooltip: {
        enabled: false,
        // Gets executed when a bar is hovered on the graph
        external: (context) => getTooltip(context, chartStyle),
      },
    },
    indexAxis: chartStyle.indexAxis,
    layout: {
      padding: {
        top: 20,
      },
    },
    scales: {
      x: {
        offset: true,
        display: false,
        labels: [],
        ticks: {
          // This callback function is used to modify the ticks displayed in x-axis
          callback: function (value, index, values) {
            // If a custom callback function is provided, it is called with the tickLabel, index and values as the arguments
            // The modified tick returned by the custom callback function is returned
            if (chartStyle?.customChartStyle?.scales?.x?.ticks?.callback) {
              return chartStyle.customChartStyle.scales.x.ticks.callback(
                this.getLabelForValue(value),
                index,
                values
              );
            }
            // If no custom callback function is provided, the tick is just displayed as it is
            return this.getLabelForValue(value);
          },
        },
      },
      y: {
        labels: [],
        grid: {
          borderWidth: 0,
          tickLength: 0,
          tickWidth: 0,
        },
        ticks: {
          padding: 7,
          labelOffset: -6,
          font: {
            weight: 500,
            size: 8,
            family: appTheme.font.regular,
          },
          // This callback function is used to modify the ticks displayed in y-axis
          callback: function (value, index, values) {
            // If a custom callback function is provided, it is called with the tickLabel, index and values as the arguments
            // The modified tick returned by the custom callback function is returned
            if (chartStyle?.customChartStyle?.scales?.y?.ticks?.callback) {
              return chartStyle.customChartStyle.scales.y.ticks.callback(
                this.getLabelForValue(value),
                index,
                values
              );
            }
            // If no custom callback function is provided, the tick is just displayed as it is
            return this.getLabelForValue(value);
          },
        },
      },
    },
  };

  // Override custom chart styles with default chart styles
  return _.mergeWith(
    defaultChartStyle,
    chartStyle.customChartStyle,
    (existingValue, newValue, property) => {
      // Exclude following properties from being overriden
      if (property === "callback") return existingValue;
      if (property === "external") return existingValue;
    }
  );
};

// Function to set the indexAxis of the bar chart
const setIndexAxis = (dataset, chartStyle) => {
  /* The bar chart component receives dataset which is an array of objects. Every object represents
     a data. The data is passed as xcoords and ycoords and either of them is a categorical axis. 

     Example - 
     dataset = [
        {
          name: "FY '22",
          xcoords: ["Dormer", "Staircase", "Archer"]
          ycoords: [43, 12, 33]
        }, 
        {
          name: "FY '23", 
          xcoords: ["Dormer", "Staircase", "Archer"],
          ycoords: [12, 0, 45]
        }
     ]

     The indexAxis must be set to the axes which holds the categorical data
     So, we check the type of the first entry in xcoords. If it's a string then indexAxis = "x" else indexAxis = "y"
     In the example given above, indexAxis is set to "x"

  */
  chartStyle.indexAxis = "x";
  if (typeof dataset[0]?.xcoords?.[0] === "number") chartStyle.indexAxis = "y";
};

const getFormattedData = (canvas, inputDataset) => {
  const datasets = inputDataset.map((inputData) => {
    const { xcoords, ycoords } = inputData;

    if (typeof inputData?.style?.backgroundColor === "function") {
      inputData.style.backgroundColor = inputData.style.backgroundColor(
        canvas,
        inputData
      );
    }

    let data = xcoords.map((x, index) => ({ x, y: ycoords[index] }));

    /* The style object contains the styling of the set of bars belonging to a particular group (Ex. FY'22). 
       The following properties can be used to customize the appearance of the same.
       If different styles need to supplied to different bars of the same group, then the style values can be passed as an array
       For Example, in group 'FY '22', if the bars representing "Dormer", "Staircase", "Archer" need to be of different colors, It can be achieved using the following code

       dataset = [
        {
          name: "FY '22",
          xcoords: ["Dormer", "Staircase", "Archer"]
          ycoords: [43, 12, 33]
          backgroundColor: ["red", "blue", "green"]
        }, 
      ]

      This holds for all the relevant properties. 

      backgroundColor: Color (color of the bar)
      hoverBackgroundColor: Color (color of the bar when hovered)

      borderColor: Color (color of the border)
      hoverBorderColor: Color (color of the border when hovered)

      borderWidth: number (border width of the bar)
      hoverBorderWidth: number (border width of the bar when hovered)

      borderRadius: number (border radius of the bar)
      hoverBorderRadius: number (border radius of the bar when hovered)

      barThickness: number (thickness of the bar)
      maxBarThickness: number (maximum thickness of the bar)

      borderSkipped: string | boolean (this disables the border radius at a particular/all positions when it is set to 'start', 'end' etc.  
      It can be set to true or false to skip all borders or no borders respectively)

      stack: string (If there are many groups in the bar chart (ex. FY '21, FY '22 etc.) then a stack can be assigned to each of the group.
      Groups belonging to the same stack will be stacked together.)

    */

    const transformedData = {
      label: inputData.name,
      data,
      ...inputData.style,
    };
    return transformedData;
  });
  return { datasets };
};

export default function BarChart({
  dataset,
  chartStyle,
  filters,
  handleFilterChange,
}) {
  const [filter, setFilter] = useState(
    filters ? Object.keys(filters)[0] || "" : ""
  );

  setIndexAxis(dataset, chartStyle);

  return (
    <>
      <FormRow>
        <Spacing mTop="auto" mBottom="auto">
          {chartStyle?.title && (
            <InlineText fontSize="lg" font="medium" color="black">
              {chartStyle.title}
            </InlineText>
          )}
          {chartStyle?.subtitle && (
            <>
              <Spacing mBottomvh="0.8" />
              <InlineText fontSize="xxxxs">{chartStyle.subtitle}</InlineText>
            </>
          )}
        </Spacing>
        {filters && (
          <>
            {Object.keys(filters).length > 1 ? (
              <DropDown
                scrollToView={false}
                boxTopMargin={"0px"}
                width={"47%"}
                height="auto"
                fontSize="xs"
                widthvw={"5"}
                font="light"
                optionList={filters}
                id="typeField"
                onChange={(value) => {
                  setFilter(value);
                  handleFilterChange(value);
                }}
                value={filter}
                name="userType"
              />
            ) : null}
          </>
        )}
      </FormRow>
      <Spacing mBottomvh="1.67" />
      <Spacing
        margin="auto"
        mTop="0px"
        width="100%"
        mLeft="-10px"
        heightvh="23.48"
      >
        <Bar
          data={(canvas) => getFormattedData(canvas, dataset)}
          options={getChartStyleOptions(chartStyle)}
        />
      </Spacing>
      {chartStyle?.caption && (
        <Text
          fontSize="xxs"
          textAlign="center"
          color="textDarkGrey"
          height="auto"
        >
          {chartStyle.caption}
        </Text>
      )}
    </>
  );
}
