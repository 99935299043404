import { SessionManager } from "../../utils";
import * as Topics from "../IoTTopics";
import i18n from "../../commonalities/i18n";
import Nominatim from "nominatim-geocoder";
import utility from "../../utils/Utility";

export const UpdateCellShadows = async (cellID, topic, data) => {
  var recievedTopic = "";

  //program status shadow updated
  if (
    topic.includes(Topics.STATUS_UPDATE_TOPIC_ACCEPTED) ||
    topic.includes(Topics.STATUS_GET_TOPIC_ACCEPTED)
  ) {
    /* The following if statement can be removed once all the cells are migrated to a CNM version >= 5.0.0 
    and the properties can be accessed using data.status.<property> instead of data.<property> */
    if (data.status) data = data.status;
    if (!data.ProgramStatus) return;

    var statusEnd = "";
    let programEnd = data.ProgramStatusEnd;
    if (programEnd) {
      programEnd.forEach((st) => {
        statusEnd += st.Value ? i18n.t(st.Key) : st.Key;
        return;
      });
    }

    SessionManager.cellsShadows[cellID].status = {
      ...SessionManager.cellsShadows[cellID].status,
      programStatus: data.ProgramStatus,
      programStatusCode: data.ProgramStatusCode,
      programStatusEnd: statusEnd,
    };
    recievedTopic = Topics.STATUS_UPDATE_TOPIC_ACCEPTED;
  }

  //settings shadow updated
  else if (
    topic.includes(Topics.SETTINGS_UPDATE_TOPIC_ACCEPTED) ||
    topic.includes(Topics.SETTINGS_GET_TOPIC_ACCEPTED)
  ) {
    SessionManager.cellsShadows[cellID].settings = data.settings;
    recievedTopic = Topics.SETTINGS_UPDATE_TOPIC_ACCEPTED;
  }

  //config shadow updated
  else if (
    topic.includes(Topics.CONFIG_UPDATE_TOPIC_ACCEPTED) ||
    topic.includes(Topics.CONFIG_GET_TOPIC_ACCEPTED)
  ) {
    let configData = data.config.replaceAll("'", '"');
    if (utility.isJSON(configData)) {
      SessionManager.cellsShadows[cellID].config = JSON.parse(
        data.config.replaceAll("'", '"')
      );
      recievedTopic = Topics.CONFIG_UPDATE_TOPIC_ACCEPTED;
    }
  }

  //CNM hardware status shadow update
  /*CNM_STATUS_SHADOW topic and related code should be removed once all cells are igrated to version >= 5.0.0*/
  else if (
    topic.includes(Topics.CNM_STATUS_UPDATE_TOPIC_ACCEPTED) ||
    topic.includes(Topics.CNM_STATUS_GET_TOPIC_ACCEPTED)
  ) {
    Object.assign(
      SessionManager.cellsShadows[cellID].hardwareStatus,
      data.status
    );
    recievedTopic = Topics.CNM_STATUS_UPDATE_TOPIC_ACCEPTED;
  }

  //hardware status shadow updated
  else if (
    topic.includes(Topics.HARDWARE_STATUS_UPDATE_TOPIC_ACCEPTED) ||
    topic.includes(Topics.HARDWARE_STATUS_GET_TOPIC_ACCEPTED)
  ) {
    Object.assign(
      SessionManager.cellsShadows[cellID].hardwareStatus,
      data.status
    );
    recievedTopic = Topics.HARDWARE_STATUS_UPDATE_TOPIC_ACCEPTED;
  }

  //version shadow updated
  else if (
    topic.includes(Topics.SOFTWARE_VERSION_UPDATE_TOPIC_ACCEPTED) ||
    topic.includes(Topics.SOFTWARE_VERSION_GET_TOPIC_ACCEPTED)
  ) {
    SessionManager.cellsShadows[cellID].softwareVersions = data.versions
      ? data.versions
      : data.SoftwareVersions;
    recievedTopic = Topics.SOFTWARE_VERSION_UPDATE_TOPIC_ACCEPTED;
  }

  //location shadow updated
  else if (
    topic.includes(Topics.LOCATION_UPDATE_TOPIC_ACCEPTED) ||
    topic.includes(Topics.LOCATION_GET_TOPIC_ACCEPTED)
  ) {
    var location = data.location ? data.location : data.Location;
    SessionManager.cellsShadows[cellID].locationData = location;
    SessionManager.cellsShadows[cellID].location =
      SessionManager.t("fms-unavailable");

    if (location.status === "running") {
      var geocoder = new Nominatim({ secure: true });
      var query = {
        lat: location.latitude,
        lon: location.longitude,
        "accept-language": i18n.language,
      };
      SessionManager.cellsShadows[cellID].locationCoordinates =
        location.latitude + "," + location.longitude;
      var response = await geocoder.reverse(query);
      var locationResult = {
        city: response["address"].city
          ? response["address"].city
          : response["address"].town
          ? response["address"].town
          : SessionManager.t("fms-unavailable"),
        state: response["address"].state
          ? response["address"].state
          : SessionManager.t("fms-unavailable"),
        country: response["address"].country
          ? response["address"].country
          : SessionManager.t("fms-unavailable"),
        country_code: response["address"].country_code
          ? response["address"].country_code
          : SessionManager.t("fms-unavailable"),
        postal: response["address"].postcode
          ? response["address"].postcode
          : SessionManager.t("fms-unavailable"),
      };
      SessionManager.cellsShadows[cellID].locationData = locationResult;
      SessionManager.cellsShadows[cellID].location =
        locationResult.city !== SessionManager.t("fms-unavailable") &&
        locationResult.country_code !== SessionManager.t("fms-unavailable")
          ? locationResult.city +
            ", " +
            locationResult.country_code.toUpperCase()
          : SessionManager.t("fms-unavailable");
      UpdateActiveLocations();
    }
    recievedTopic = Topics.LOCATION_UPDATE_TOPIC_ACCEPTED;
  }
  return recievedTopic;
};

//this function updates the location of the cells.
const UpdateActiveLocations = () => {
  var cities = [];
  Object.keys(SessionManager.cellsShadows).forEach((cellsId) => {
    var locData = SessionManager.cellsShadows[cellsId];
    var city = null;
    if (
      locData.location &&
      locData.locationCoordinates &&
      ((locData.locationData.status &&
        locData.locationData.status === "running") ||
        !locData.locationData.status)
    ) {
      city = locData.location.split(",")[0];
    }
    if (
      city &&
      locData.location &&
      city !== undefined &&
      !city.includes("undefined") &&
      !cities.includes(city)
    ) {
      cities.push(city);
    }
  });
  SessionManager.activeLocations = {
    activeCities: cities.length,
  };
};
