import React from "react";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";
import { Spacing } from "..";
import styled from "styled-components";

const Text = styled.div`
  align-self: ${(p) => p.alignSelf};
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.black)};
  font-size: ${(p) =>
    p.fontSize ? p.theme.fontSize[p.fontSize] : p.theme.fontSize.lg};
  font-family: ${(p) => (p.font ? p.theme.font[p.font] : p.theme.font.regular)};
  ${(p) => p.setInline && `display:inline-block`};
`;

const PaginationRow = styled.div`
  display: flex;
  width: 100%;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(
    (var(--useMin) * var(--heightLimit) * 1.47 / 100) +
      (var(--usePercent) * 1.47vh)
  );
  background: ${(p) => p.theme.colors.backgroundGrey};
`;

export const TablePagination = ({
  displayCount,
  totalPages,
  totalItems,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Spacing mTop={"10px"} />
      <PaginationRow>
        <Text
          alignSelf={"center"}
          color={"textGrey"}
          font={"light"}
          fontSize={"sm"}
        >
          {t("displaying")}
        </Text>
        <Spacing mLeft={"8px"} />
        <Text alignSelf={"center"} fontSize={"sm"} font={"bold"}>
          {displayCount}
        </Text>
        <Spacing mLeft={"8px"} />
        <Text
          alignSelf={"center"}
          color={"textGrey"}
          font={"light"}
          fontSize={"sm"}
        >
          {t("of")}
        </Text>
        <Spacing mLeft={"8px"} />
        <Text alignSelf={"center"} font={"light"} fontSize={"sm"}>
          {totalItems}
        </Text>
        <Spacing mLeft={"auto"}>
          <Pagination
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            showFirstButton
            showLastButton
            siblingCount={1}
            boundaryCount={1}
            count={totalPages}
          />
        </Spacing>
      </PaginationRow>
    </>
  );
};

export default TablePagination;
