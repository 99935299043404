import { SET_AUTH_USER, CLEAR_AUTH_USER } from "../actions/AuthActions";

export const authInitialState = {
  user: null,
};

export const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        user: action.payload.auth,
      };
    case CLEAR_AUTH_USER: {
      return {
        ...state,
        ...authInitialState,
      };
    }

    default:
      return state;
  }
};
