import React from "react";
import { appTheme as theme } from "../commonalities/Theme";

export const SpeedIcon = ({ width, color, strokeWidth }) => {
  const WIDTH = width || "72";
  const COLOR = theme.colors[color] || theme.colors.textDarkGrey;
  const STROKE_WIDTH = strokeWidth || "3";

  return (
    <svg
      width={WIDTH}
      height={WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
    >
      <g
        id="Group_2897"
        data-name="Group 2897"
        transform="translate(-888 -912)"
      >
        <g
          id="Layer_3"
          data-name="Layer 3"
          transform="translate(-397.62 38.941)"
        >
          <path
            id="Path_799"
            data-name="Path 799"
            d="M1375.153,915.772a1.143,1.143,0,0,1,.076,1.531l-14.675,17.937a3.442,3.442,0,0,1-4.867-4.867l17.934-14.678a1.142,1.142,0,0,1,1.532.076Z"
            transform="translate(-38.659 -17.585)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
          <path
            id="Path_800"
            data-name="Path 800"
            d="M1348.655,940.535a28,28,0,1,0-38.019.048"
            transform="translate(-8 -6.94)"
            fill="none"
            stroke={COLOR}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={STROKE_WIDTH}
          />
        </g>
        <rect
          id="Rectangle_9614"
          data-name="Rectangle 9614"
          width="72"
          height="72"
          transform="translate(888 912)"
          fill="none"
        />
      </g>
    </svg>
  );
};
