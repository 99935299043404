import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { NetworkController } from "../../../../utils";
import { PropertyCard } from "../../../../styles";
import { InlineText } from "../../../../commonalities";
import {
  DropDownContainer,
  Spacing,
  FormRow,
  FormColumn,
  Text,
} from "../../../../commonalities";

const EvaloSettingsDropDown = ({ shrink, job }) => {
  const [settings, setSettings] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    NetworkController.cells.getSettingsData({ id: job.Payload.Id }, (res) => {
      if (res.data.settings) {
        var settings = res.data.settings.Items[0].Payload.Settings;
        setSettings(settings);
      } else {
        setSettings(res.data.Items[0].Payload.Settings);
      }
    });
  }, []);

  return (
    <Spacing mTopvw={"0.83"}>
      <DropDownContainer width={"100%"} height={"200px"} shrink={shrink}>
        {settings ? (
          <Spacing
            mTopvw={"1.25"}
            mRightvw={"1.25"}
            mLeftvw={"2.5"}
            mBottomvw={"2.5"}
          >
            <FormRow height="100%">
              <FormColumn
                alignItems="stretch"
                height="fit-content"
                width={"21.705%"}
              >
                <Spacing mTopvh="1.02" />
                <FormRow height="100%">
                  <Text
                    lineHeight="1.2"
                    height="fit-content"
                    fontSize={"xs"}
                    font={"semibold"}
                  >
                    {t("cell-settingsPanel")}
                  </Text>
                </FormRow>
                <PropertyCard
                  name={"fms-type"}
                  value={settings.PanelType ? settings.PanelType : "-"}
                />
                <PropertyCard
                  name={"cell-height"}
                  value={settings.Height ? settings.Height + " mm" : "-"}
                />
                <PropertyCard
                  name={"cell-length"}
                  value={settings.Length ? settings.Length + " mm" : "-"}
                />
                <PropertyCard
                  name={"cell-width"}
                  value={settings.Width ? settings.Width + " mm" : "-"}
                />
                <PropertyCard
                  name={"cell-weight"}
                  value={settings.Weight ? settings.Weight + " mm" : "-"}
                />
              </FormColumn>
              <FormColumn
                alignItems="stretch"
                height="fit-content"
                width={"21.705%"}
              >
                <Spacing mTopvh="1.02" />
                <FormRow height="100%">
                  <Text
                    lineHeight="1.2"
                    height="fit-content"
                    fontSize={"xs"}
                    font={"semibold"}
                  >
                    {t("cell-settingsSpeed")}
                  </Text>
                </FormRow>
                <PropertyCard
                  name={"cell-settingsTop"}
                  value={
                    settings.Top_Speed ? settings.Top_Speed + " mm/s" : "-"
                  }
                />
                <PropertyCard
                  name={"cell-settingsSide"}
                  value={
                    settings.Side_Speed ? settings.Side_Speed + " mm/s" : "-"
                  }
                />
              </FormColumn>
              <FormColumn
                alignItems="stretch"
                height="fit-content"
                width={"21.705%"}
              >
                <Spacing mTopvh="1.02" />
                <Text
                  lineHeight="1.2"
                  height="fit-content"
                  fontSize={"xs"}
                  font={"semibold"}
                >
                  {t("cell-settingsRepetitions")}
                </Text>
                <PropertyCard
                  name={"cell-settingsTop"}
                  value={settings.Top_Reps ? settings.Top_Reps : "-"}
                />
                <PropertyCard
                  name={"cell-settingsSide"}
                  value={settings.Side_Reps ? settings.Side_Reps : "-"}
                />
              </FormColumn>

              <FormColumn
                alignItems="stretch"
                height="fit-content"
                width={"21.705%"}
              >
                <Spacing mTopvh="1.02" />
                <Text
                  lineHeight="1.2"
                  height="fit-content"
                  fontSize={"xs"}
                  font={"semibold"}
                >
                  {t("cell-settingsStack")}
                </Text>
                <PropertyCard
                  name={"cell-settingsNoOfPanels"}
                  value={settings.No_Of_Panels ? settings.No_Of_Panels : "-"}
                />
              </FormColumn>
            </FormRow>
          </Spacing>
        ) : (
          <Spacing
            mTopvw={"1.25"}
            mRightvw={"1.25"}
            mLeftvw={"2.5"}
            mBottomvw={"2.5"}
            height="289px"
          >
            <InlineText color="black">{t("cell-loadingSettings")}</InlineText>
          </Spacing>
        )}
      </DropDownContainer>
    </Spacing>
  );
};

export default EvaloSettingsDropDown;
