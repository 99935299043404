import styled from "styled-components";
import React, { useState } from "react";
import { PlusMinusIcon } from "..";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OperatorButton = styled.div`
  padding: 5px 0px;
  cursor: pointer;
`;

const Input = styled.input`
  width: 25px;
  height: 100%;
  font-size: ${(p) => p.theme.fontSize.md};
  font-family: ${(p) => p.theme.font.regular};
  border: none;
  text-align: center;
  border: none;
  outline: none;
  &::-webkit-appearance: textfield;
  &::-moz-appearance: textfield;
  appearance: textfield;

 ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const IncDecOperator = ({ value, onChange, min, max }) => {
  const [count, setCount] = useState(value);
  const [prevCount, setPrevCount] = useState(value);

  const incrementCount = () => {
    if (count < max) {
      let val = Number(count) + 1;
      updateCount(val);
      onChange(val);
    }
  };

  const updateCount = (value) => {
    setPrevCount(count);
    setCount(value);
  };

  const decrementCount = () => {
    if (count > min) {
      let val = Number(count) - 1;
      updateCount(val);
      onChange(val);
    }
  };

  const validateKeystroke = (event) => {
    //Send data on pressing Enter
    if (event.keyCode === 13) {
      event.target.blur();
    }
  };

  const handleBlur = (e) => {
    //check if the value entered is a valid number
    if (!isNaN(parseInt(e.target.value))) {
      let val = e.target.value;
      if (val > max) {
        val = max;
      } else if (val < min) {
        val = min;
      }
      updateCount(val);
      onChange(val);
    } else {
      setCount(prevCount);
    }
  };

  const handleChange = (e) => {
    setCount(e.target.value);
  };

  return (
    <Root>
      <OperatorButton onClick={decrementCount}>
        <PlusMinusIcon width="18px" height="16px" isDecrement={true} />
      </OperatorButton>
      <Input
        type="number"
        value={count}
        onChange={handleChange}
        onKeyDown={validateKeystroke}
        onBlur={handleBlur}
      />
      <OperatorButton onClick={incrementCount}>
        <PlusMinusIcon width="18px" height="16px" />
      </OperatorButton>
    </Root>
  );
};

export default IncDecOperator;
